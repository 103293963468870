<template>
    <div>
        <div class="drag-bo x" v-drag v-if="isShowDrag">
            <el-row :gutter="10">
                <el-col :xl="!checkData() ? 6 : 13" :md="!checkData() ? 6 : 13" style="max-width: 175px;">
                    <!-- 차량정보버튼 -->
                    <el-button size="mini" type="info" @click="isDrawerOpen = isDrawerOpen ? false : true">car
                        info</el-button>
                    <el-button size="mini" type="info" v-if="checkData()" @click="filter()"
                        :class="filterData ? '' : 'btn-red'">
                        {{ fitlerbuttontitle }}</el-button>
                </el-col>
                <el-col :xl="!checkData() ? 18 : 11" :md="!checkData() ? 18 : 11" class="relative">
                    <!-- 검색창 -->
                    <el-input size="mini" class="w100p" v-model="hotName" placeholder="검색어를 입력하세요."
                        @click.native="showlist = showlist ? false : true;" @blur="closeShowlist()"
                        @keyup.native="showlist = false" @keyup.enter.native="selectHotName(hotName)"></el-input>
                </el-col>
            </el-row>
            <!-- 검색창리스트 -->
            <div v-if="showlist" class="absolute bigbadge-yellow borderbox p-2"
                style="left:360px;top:0px;width:200px;height:300px;overflow:hidden; overflow-y: auto;">
                <div v-for="(item, index) in data.hostNameList" :key="index" class="p-1 bigbadge-hover"
                    @click="selectHotName(item.name); showlist = false;">
                    <span>{{ item.name }}</span>
                </div>
            </div>
        </div>
        <!-- <div class="overlay" v-if="isDrawerOpen" @click="closeDrawer"></div> -->
        <!-- 슬라이드창 -->
        <transition name="slide">
            <div class="drawer" v-if="isDrawerOpen">
                <i class="fas fa-circle-xmark hand absolute colorgraylight" style="right:10px;"
                    @click="isDrawerOpen = false"></i>
                <div>
                    <img :src="data.imgLogo" alt="" class="w100p">
                </div>
                <div>
                    <el-tabs v-model="activeName" @tab-click="optionSpecClick">
                        <el-tab-pane label="차량옵션" name="option"
                            style="height:calc(100vh - 230px);overflow: hidden;overflow-y: auto;">
                            <table class="table-params w100p mb-5">
                                <tbody>
                                    <tr v-for="(value, key) in data.getCarsConfig" :key="key">
                                        <th style="width:120px;">{{ key }}</th>
                                        <td>{{ value }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </el-tab-pane>
                        <el-tab-pane label="기본사양" name="spec"
                            style="height:calc(100vh - 230px);overflow: hidden;overflow-y: auto;">
                            <table class="table-params w100p mb-5">
                                <tbody>
                                    <tr v-for="(value, key) in data.carSpec" :key="key">
                                        <th style="width:120px;">{{ key }}</th>
                                        <td>{{ value }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </el-tab-pane>
                    </el-tabs>
                </div>
                <div>
                </div>
                <button @click="closeDrawer">Close Drawer</button>
            </div>
        </transition>
    </div>
</template>

<script>
import componentInputText from './component-input-text.vue'

export default {
    props: ['data'],
    name: 'carinfofilterbar',
    components: { componentInputText },
    data() {
        return {
            info: null,
            activeName: 'option', //true 옵션, false 스펙나타냄
            isDrawerOpen: false,
            hotName: null,
            hostNameList: [],
            showlist: false,
            isShowDrag: true,
            visible: false,
            tmpTabledata: [],
            filterData: true,
            fitlerbuttontitle: '필터해제',
        }
    },
    // watch:{
    //    data(newvalue){
    //     this.data=newvalue
    //    }
    // },
    async created() {
        this.$brand = 'all'
    },
    methods: {
        async selectHotName(params) {
            params = this.$removeSpace(params)
            const data = {
                query: params,
                vin: this.data.vin
            }
            if (!params) {
                alert('검색어를 입력하세요.')
                return
            }
            this.showlist = false
            this.hotName = params
            this.returnCallback({ filter: this.data.filter, hotNameInfo: data, getCarsPartBySearchKey: true })
        },

        closeShowlist() {
            setTimeout(() => {
                this.showlist = false
            }, 500)
        },
        async returnCallback(params) {
            const data = {
                num: this.data.num,
                group1title: this.data.group1title,
                filter: params.filter,
                hotNameInfo: params.hotNameInfo,
                getCarsPartBySearchKey: params.getCarsPartBySearchKey
            }
            this.$emit('callback', data)
        },
        filter() {
            if (this.filterData) {
                this.filterData = false;
                this.fitlerbuttontitle = '필터적용';
            } else {
                this.filterData = true;
                this.fitlerbuttontitle = '필터해제';
            }
            this.returnCallback({ filter: this.filterData })
        },

        toggleDrawer() {
            this.isDrawerOpen = !this.isDrawerOpen;
        },
        closeDrawer() {
            this.isDrawerOpen = false;
        },
        optionSpecClick(tab, event) { },
        checkData() {
            if (this.data?.num || this.data?.group1title) {
                return true
            } else {
                return false
            }
        }

    },
    directives: {
        drag: {
            bind: function (el) {
                let oDiv = el;
                oDiv.onmousedown = (e) => {
                    let disX = e.clientX - oDiv.offsetLeft;
                    let disY = e.clientY - oDiv.offsetTop;
                    document.onmousemove = (e) => {
                        let left = e.clientX - disX;
                        let top = e.clientY - disY;

                        oDiv.style.left = left + 'px';
                        oDiv.style.top = top + 'px';

                    };
                    document.onmouseup = (e) => {
                        document.onmousemove = null;
                        document.onmouseup = null;
                    }
                }
            }
        }
    }
}
</script>

<style scoped>
.titleDiv {
    padding: 5px;
    margin-bottom: 10px;
}

.title {
    font-weight: bold;
}

.drag-box {
    cursor: move;
    padding: 10px;
    /* position: absolute; */
    top: 125px;
    /*125px*/
    left: 635px;
    width: 350px;
    min-height: 50px;
    max-height: 650px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, .15);
    z-index: 99;
}


.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}

.drawer {
    position: fixed;
    top: 0;
    right: 0;
    /* 수정: 오른쪽에 배치합니다. */
    width: 350px;
    height: 100%;
    background-color: white;
    z-index: 10000;
    padding: 20px;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
    /* 수정: 그림자를 오른쪽에 표시합니다. */
}

.slide-enter-active,
.slide-leave-active {
    transition: transform 0.3s ease;
}

.slide-enter,
.slide-leave-to {
    transform: translateX(100%);
    /* 수정: 오른쪽으로 이동하도록 변경합니다. */
}
</style>