<template>
  <div class="container mt-3 relative noselect" style="max-width: 1300px !important;">
    <TopBar />
    <TopMenu />
    <!-- <TopBanner /> -->
    <!-- <TopNotice /> -->
    <el-row :gutter="15">
      <el-col :xl="5" :xs="24" :sm="5">
        <centermenu />
      </el-col>
      <el-col :xl="24" :xs="24" :sm="24" v-if="isShow">
        <el-row class="borderbox mt-2 p-2">
          <el-col class="borderbottom1px lh40 relative">
            <span class="font18 bold">
              회사정보수정
            </span>
            <span class="absolute hand fixedTooltip-link" style="top:5px;right:5px;">

            </span>
          </el-col>
          <el-col class="relative mt-2">
            <el-row :gutter="10" class="font15 lh40">
              <el-col :xl="4" :xs="8" :sm="4">사업자등록번호</el-col>
              <el-col :xl="8" :xs="16" :sm="8">
                {{ $loginUserInfo.companyInfo.license }}
              </el-col>
            </el-row>
            <el-row :gutter="10" class="font15 lh40">
              <el-col :xl="4" :xs="8" :sm="4">상호</el-col>
              <el-col :xl="8" :xs="16" :sm="8">
                {{ $loginUserInfo.companyInfo.name }}
              </el-col>
            </el-row>
            <el-row :gutter="10" class="font15 lh40">
              <el-col :xl="4" :xs="8" :sm="4">대표</el-col>
              <el-col :xl="8" :xs="16" :sm="8">
                {{ $loginUserInfo.companyInfo.ceo }}
              </el-col>
            </el-row>
            <el-row :gutter="10" class="font15 lh40">
              <el-col :xl="4" :xs="8" :sm="4">회사전화</el-col>
              <el-col :xl="8" :xs="16" :sm="8">
                <componentInputText :minlength="5" :maxlength="11" :type="'text'" :size="'mini'"
                  :placeholder="'회사전화를 입력하세요.'" v-model="formData.tel" />
              </el-col>
            </el-row>
            <el-row :gutter="10" class="font15 lh40">
              <el-col :xl="4" :xs="8" :sm="4">모바일</el-col>
              <el-col :xl="8" :xs="16" :sm="8">
                <componentInputText :minlength="5" :maxlength="11" :type="'text'" :size="'mini'"
                  :placeholder="'휴대폰번호를 입력하세요.'" v-model="formData.phone" />
              </el-col>
            </el-row>
            <el-row :gutter="10" class="font15 lh40">
              <el-col :xl="4" :xs="8" :sm="4">메일주소</el-col>
              <el-col :xl="8" :xs="16" :sm="8">
                <componentInputText :minlength="5" :maxlength="20" :type="'text'" :size="'mini'"
                  :placeholder="'메일주소를 입력하세요.'" v-model="formData.mail" />
              </el-col>
            </el-row>
            <el-row :gutter="10" class="font15 lh40">
              <el-col :xl="4" :xs="8" :sm="4">팩스</el-col>
              <el-col :xl="8" :xs="16" :sm="8">
                <componentInputText :minlength="5" :maxlength="11" :type="'text'" :size="'mini'"
                  :placeholder="'팩스번호를 입력하세요.'" v-model="formData.fax" />
              </el-col>
            </el-row>
            <el-row :gutter="10" class="font15 lh40">
              <el-col :xl="4" :xs="8" :sm="4">우편번호</el-col>
              <el-col :xl="8" :xs="8" :sm="8">
                <componentInputText :minlength="5" :maxlength="11" :type="'text'" :size="'mini'" :hiddeinfo="true"
                  :placeholder="'우편번호'" v-model="formData.postMain" />
              </el-col>
              <el-col :xl="12" :xs="16" :sm="12">
                <componentInputText :minlength="5" :maxlength="11" :type="'text'" :size="'mini'" :placeholder="'주소'"
                  v-model="formData.addrMain" />
              </el-col>
            </el-row>
            <el-row :gutter="10" class="font15 lh40">
              <el-col :xl="4" :xs="8" :sm="4">세부주소</el-col>
              <el-col :xl="20" :xs="16" :sm="20">
                <componentInputText :minlength="5" :maxlength="11" :type="'text'" :size="'mini'" :placeholder="'세부주소'"
                  v-model="formData.addrSub" />
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row class="mt-2">
          <el-col class="text-right">
            <el-button type="" size="mini" class="el-btn-orange" :disabled="!reclick" @click="updateMallCompanyInfo()">
              <i class="fas fa-floppy-disk mr-1"></i>
              {{ updateButtontitle }}</el-button>
          </el-col>
        </el-row>
      </el-col>
      <el-col :xl="24" :xs="24" :sm="24" v-if="!isShow">
        <el-row class="borderbox mt-2 p-2">
          <el-col class="borderbottom1px lh40 relative">
            <span class="font18 bold">
              회사정보수정
            </span>
            <span class="absolute hand fixedTooltip-link" style="top:5px;right:5px;">

            </span>
          </el-col>
          <el-col class="relative mt-2">
            <el-row :gutter="10" class="font15 lh40">
              <el-col :xl="4" :xs="8" :sm="4">비밀번호</el-col>
              <el-col :xl="16" :xs="16" :sm="16">
                <componentInputText :icon="'fas fa-user-shield'" :minlength="5" :maxlength="20" :type="'password'"
                  :size="'mini'" :placeholder="'현재비밀번호를 입력하세요.'" v-model="checkFormData.pw"
                  @keyup.enter.native="checkPassword()" />
              </el-col>
              <el-col :xl="4" :xs="24" :sm="4">
                <el-button type="" size="mini" class="el-btn-orange w100p" @click="checkPassword()">
                  <i class="el-icon-success mr-1"></i>
                  확인</el-button>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-col>

    </el-row>
    <FootBar />
    <el-backtop>
    </el-backtop>
  </div>
</template>
<script>
import componentInputText from './component-input-text.vue';

import TopBar from './component-top.vue'
import TopBanner from './component-top-banner.vue'
import TopNotice from './component-top-notice.vue'
import TopMenu from './component-top-menu.vue'
import FootBar from './component-foot.vue'
import centermenu from './component-cener-left-menu.vue'
import { Loading } from 'element-ui'
export default {
  components: { centermenu, TopBar, TopBanner, TopNotice, TopMenu, FootBar, Loading, componentInputText },
  data() {
    return {
      updateButtontitle: '정보제출',
      reclickSecond: 5,
      reclick: true,
      isShow: false,
      loading: null,
      checkFormData:{pw:''},
      formData: {
        tel: '',
        phone: '',
        mail: '',
        fax: '',
        postMain: '',
        addrMain: '',
        addrSub: '',
      }
    }
  },
  created() {
    window.scrollTo(0, 0);
    this.loading = Loading.service({ fullscreen: true })
    this.$checkAuth(this.$loginUserInfo) // type==0 대표권한
    this.formData = {
      tel: this.$loginUserInfo.companyInfo.tel,
      phone: this.$loginUserInfo.companyInfo.phone,
      mail: this.$loginUserInfo.companyInfo.mail,
      fax: this.$loginUserInfo.companyInfo.fax,
      postMain: this.$loginUserInfo.companyInfo.postMain,
      addrMain: this.$loginUserInfo.companyInfo.addrMain,
      addrSub: this.$loginUserInfo.companyInfo.addrSub,
    }
    this.$overay(this.loading)
  },
  methods: {
    async updateMallCompanyInfo() {
      this.reclick = false  // 재차갱신 여부
      this.loading = Loading.service({ fullscreen: true })
      await this.$http.post(`${this.$serverurl}/api/updateMallCompanyInfo`, this.formData)
        .then(res => {
          if (res.data.code == 201) {

          }
          this.$notify.success({
            title: '',
            message: res.data.message,
            position: 'bottom-right',
            showClose: true,
            duration: 1000,
          });
          this.updateButtontitle = `${this.reclickSecond}초 후 갱신`

          const countDown = setInterval(() => {
            if (this.reclickSecond > 0) {
              this.reclickSecond--
              this.updateButtontitle = `${this.reclickSecond}초 후 갱신`
            } else {
              this.reclickSecond = 5
              this.updateButtontitle = '정보제출'
              this.reclick = true
              clearInterval(countDown)
            }
          }, 1000);
        })
      this.$overay(this.loading)
    },
    async checkPassword() {
      this.loading = Loading.service({ fullscreen: true })
      if (!this.checkFormData.pw.trim()) {
        alert('현재 비밀번호를 입력하세요.'); return
      }
      await this.$http.post(`${this.$serverurl}/api/checkPassword`, this.checkFormData)
        .then(res => {
          if(res.data.code==200){
            this.isShow = true
          this.$notify.success({
            title: '',
            message: res.data.message,
            position: 'bottom-right',
            showClose: true,
            duration: 1000,
          });
          }else{
            this.$notify.error({
            title: '',
            message: res.data.message,
            position: 'bottom-right',
            showClose: true,
            duration: 1000,
          });
          }
        })
      this.$overay(this.loading)
    },
  },
}

</script>
<style></style>
