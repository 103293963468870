<template>
    <div>
        <el-row justify="left">
            <el-col :span="24 / 6" v-for="(item, index) in data" :key="index">
                <div class="category-group-col relative" @click="returnCallback(item)">
                    <div ref="topTitle" class="absolute group-img-title-top w100p text-center" style="z-index: 10;">
                        <span v-html="item.name + `<br/>` + item.model"></span>
                        <div v-if="item.description" v-html="'Note:' + item.description">
                        </div>
                    </div>
                    <img class="sub-img" :src="getCatetoryImg(item.imgShortener)" />
                    <div class="absolute" style="top:0px;left:5px;" v-if="item.colorvalue == 0">
                        <i class="fas fa-ban colorred"></i>
                    </div>
                    <div class="absolute group-img-title text-center" style="left:0px;bottom:0px;width:100%;">
                        {{ item.mid }}
                    </div>
                </div>
            </el-col>
        </el-row>
        <span v-if="!data.length">로딩중입니다...</span>
    <!-- <json-viewer :value="data" :expand-depth=1 copyable boxed sort class="mt-3"></json-viewer> -->
    </div>
</template>

<script>

export default {
    name: 'partsgroup2',
    components: {},
    data() {
        return {
            getCarsStructureList: [],
        }
    },
    async created() { },
    props: ['data'],
    watch: {
        data: {
            deep: true,
            immediate: true,
            async handler(newValue, oldValue) {
            }
        }
    },
    methods: {
        async returnCallback(item) {
            const data = item
            this.$emit('callback', data)
        },

        getCatetoryImg(params) {
            if (!params) {
                return 'images/no-image.webp'
            }
            return `${params}`
        },
        
    },

}

</script>

<style scoped>
.category-group-col {
    overflow: hidden;
    position: relative;
    margin: 0px;
    padding: 0px;
    border: 1px #ddd solid;
    border-radius: 2px;
    width: 128px;
    height: 128px;
    cursor: pointer;
    margin-right: 10px;
    margin-bottom: 10px;
    background: no-repeat;
}

.category-group-col .group-img-title-top {
    display: none
}

.category-group-col:hover {
    background-color: #999 !important;
}

.category-group-col:hover .group-img-title-top {
    display: block;
}

.sub-img {
    width: 100%;
    height: 100%;
    opacity: 0.8;
}

.group-img-title {
    overflow-wrap: break-word;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 13px;
    color: #000;
    background-color: rgba(0, 0, 5, 0.0);
}

.group-img-title-top {
    padding-left: 5px;
    padding-right: 5px;
    font-size: 13px;
    color: #fff;
    background-color: rgba(0, 0, 5, 0.8) !important;
}
</style>