import DashboardLayout from 'src/pages/Dashboard/Layout/DashboardLayout.vue'
// GeneralViews
import NotFound from 'src/pages/GeneralViews/NotFoundPage.vue'

// Pages
import tmpRoute1 from 'src/pages/Dashboard/Pages/Login.vue'
import Login from 'src/pages/Dashboard/Pages/Login.vue'
import JoinUs from 'src/pages/Dashboard/Pages/Joinus.vue'
import Main from 'src/pages/Dashboard/Pages/Main.vue'
import Notice from 'src/pages/Dashboard/Pages/Notice.vue'
import NoticeView from 'src/pages/Dashboard/Pages/NoticeView.vue'
import ProductView from 'src/pages/Dashboard/Pages/productView.vue'
import SearchCenter from 'src/pages/Dashboard/Pages/SearchCenter.vue'
import checkOut from 'src/pages/Dashboard/Pages/checkout.vue'
import userProfile from 'src/pages/Dashboard/Pages/UserProfile.vue'
import buyPoint from 'src/pages/Dashboard/Pages/buyPoint.vue'
import buyPointAuto from 'src/pages/Dashboard/Pages/buyPointAuto.vue'
import paymentSuccess from 'src/pages/Dashboard/Pages/paymentSuccess.vue'
import myCart from 'src/pages/Dashboard/Pages/myCart.vue'
import orders from 'src/pages/Dashboard/Pages/orders.vue'
import ordersView from 'src/pages/Dashboard/Pages/ordersView.vue'
import retunGoodsRequest from 'src/pages/Dashboard/Pages/ordersRetunGoodsRequest.vue'
import shippingAddress from 'src/pages/Dashboard/Pages/shippingAddress.vue'
import security from 'src/pages/Dashboard/Pages/security.vue'
import companyInfo from 'src/pages/Dashboard/Pages/companyInfo.vue'
import accountInfo from 'src/pages/Dashboard/Pages/accountInfo.vue'
import buyLicense from 'src/pages/Dashboard/Pages/buyLicense.vue'
import accountBook from 'src/pages/Dashboard/Pages/accountBook.vue'
import WithdrawMember from 'src/pages/Dashboard/Pages/WithdrawMember.vue'
import epc from 'src/pages/Dashboard/Pages/epc.vue'
import epcsub from 'src/pages/Dashboard/Pages/epc.vue'
import aboutUs from 'src/pages/Dashboard/Pages/aboutUs.vue'
import policiesService from 'src/pages/Dashboard/Pages/policiesService.vue'
import privacyPolicy from 'src/pages/Dashboard/Pages/privacyPolicy.vue'
import board from 'src/pages/Dashboard/Pages/board.vue'
import boardView from 'src/pages/Dashboard/Pages/boardView.vue'
import qnaboard from 'src/pages/Dashboard/Pages/qnaEpcBoard.vue'

import qnaboardView from 'src/pages/Dashboard/Pages/qnaEpcBoardView.vue'

import orderOutExport from 'src/pages/Dashboard/Pages/orderOutExport.vue'


let loginPage = {
  path: '/login',
  name: 'Login',
  component: Login
}
let JoinUsPage = {
  path: '/joinUs',
  name: 'JoinUs',
  component: JoinUs
}
let mainPage = {
  path: '/main/',
  name: 'main',
  component: Main
}
let noticePage = {
  path: '/Notice',
  name: 'Notice',
  component: Notice
}
let searchCenterpage = {
  path: '/SearchCenter/:keyword',
  name: 'SearchCenter',
  component: SearchCenter
}
let noticeViewPage = {
  path: '/NoticeView/:type/:catetory/:id',
  name: 'NoticeView',
  component: NoticeView
}
let ProductViewPage = {
  path: '/ProductView/:id',
  name: 'ProductView',
  component: ProductView
}
let checkOutPage = {
  path: '/checkOut',
  name: 'checkOut',
  component: checkOut
}
let profilePage = {
  path: '/account/profile',
  name: 'profile',
  component: userProfile
}
let buyPointPage = {
  path: '/account/buyPoint',
  name: 'buyPoint',
  component: buyPoint
}
let buyPointAutoPage = {
  path: '/account/buyPointAuto',
  name: 'buyPointAuto',
  component: buyPointAuto
}
let paymentSuccessPage = {
  path: '/paymentSuccess',
  name: 'paymentSuccess',
  component: paymentSuccess
}
let myCartPage = {
  path: '/myCart',
  name: 'myCart',
  component: myCart
}
let ordersPage = {
  path: '/orders',
  name: 'orders',
  component: orders
}
let ordersViewPage = {
  path: '/orders/ordersView/:sn',
  name: 'ordersView',
  component: ordersView
}
let retunGoodsRequestPage = {
  path: '/orders/retunGoodsRequest',
  name: 'retunGoodsRequest',
  component: retunGoodsRequest
}
let shippingAddressPage = {
  path: '/account/shippingAddress',
  name: 'shippingAddress',
  component: shippingAddress
}
let securityPage = {
  path: '/account/security',
  name: 'security',
  component: security
}
let companyInfoPage = {
  path: '/account/companyInfo',
  name: 'companyInfo',
  component: companyInfo
}
let accountInfoPage = {
  path: '/account/accountInfo',
  name: 'accountInfo',
  component: accountInfo
}
let buyLicensePage = {
  path: '/account/buyLicense',
  name: 'buyLicense',
  component: buyLicense
}
let accountBookPage = {
  path: '/orders/accountBook',
  name: 'accountBook',
  component: accountBook
}
let WithdrawMemberPage = {
  path: '/account/WithdrawMember',
  name: 'WithdrawMember',
  component: WithdrawMember
}
let epcPage = {
  path: '/epc',
  name: 'epc',
  component: epc
}
let epcsubPage = {
  path: '/epc/:vin?/:hotName?',
  name: 'epcsub',
  component: epcsub
}
let aboutUsPage = {
  path: '/aboutUs',
  name: 'aboutUs',
  component: aboutUs
}
let policiesServicePage = {
  path: '/policiesService',
  name: 'policiesService',
  component: policiesService
}
let privacyPolicyPage = {
  path: '/privacyPolicy',
  name: 'privacyPolicy',
  component: privacyPolicy
}
let boardPage = {
  path: '/account/board',
  name: 'board',
  component: board
}
let boardViewPage = {
  path: '/account/board/:id',
  name: 'boardView',
  component: boardView
}
let qnaboardPage = {
  path: '/account/qnaboard',
  name: 'qnaboard',
  component: qnaboard
}
let qnaboardViewPage = {
  path: '/account/qnaboard/:id',
  name: 'qnaboardView',
  component: qnaboardView
}
let tmpRoute1Page = {
  path: '/shop/epc',
  name: 'tmpepc',
  component: tmpRoute1
}
let orderOutExportPage = {
  path: '/orders/transaction',
  name: 'transaction',
  component: orderOutExport
}



const routes = [
  {
    path: '/',
    redirect: '/main'
  },

  orderOutExportPage,
  tmpRoute1Page,
  qnaboardViewPage,
  qnaboardPage,
  boardViewPage,
  boardPage,
  privacyPolicyPage,
  policiesServicePage,
  aboutUsPage,
  epcPage,
  epcsubPage,
  WithdrawMemberPage,
  buyLicensePage,
  companyInfoPage,
  securityPage,
  shippingAddressPage,
  retunGoodsRequestPage,
  ordersViewPage,
  ordersPage,
  myCartPage,
  paymentSuccessPage,
  buyPointAutoPage,
  buyPointPage,
  loginPage,
  JoinUsPage,
  mainPage,
  searchCenterpage,
  noticePage,
  noticeViewPage,
  ProductViewPage,
  checkOutPage,
  profilePage,
  accountInfoPage,
  accountBookPage,
  {
    path: '/admin',
    component: DashboardLayout,
    redirect: '/main',
    children: [

      // {
      //   path: 'ecount',
      //   name: 'Ecount',
      //   component: Ecount
      // },
    ]
  },
  { path: '*', component: NotFound }
]


export default routes
