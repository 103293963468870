<template>
  <div class="container mt-3 relative noselect" style="max-width: 800px !important;">
    <SubTopBar @callback="callbackData" :hiddenSearchbox="true"/>
    <div class="mt-3 formTitle bold">
      고객이용약관
    </div>
    <!-- <div class="bold font15 mt-5 mb-3">고객이용약관</div> -->
    <div class="pt-2 pb-2" style="justify-content: center;">
      <div class="lh40" v-html="pilicyContent1()"></div>
    </div>


    <el-backtop>
    </el-backtop>
  </div>
</template>

<script>
import SubTopBar from './component-sub-top.vue'
import { Loading } from 'element-ui'
export default {
  components: {
   Loading,SubTopBar
  },
  data() {
    return {
      searchKeyword:null,
    };
  },
  beforeDestroy() {
  },
  mounted() {

  },
  async created() {
    window.scrollTo(0, 0);
  },
  methods: {
    callbackData(data) { },
    pilicyContent1() {
      let tmpvalue = this.$policiesService.replace(/\n/g, "<br>")
      // tmpvalue = tmpvalue.replace(/^(.*)$/gm, '? $1<br>');
      tmpvalue = tmpvalue.replace(/xxxcompanyname/g, this.$회사이름한글)
      tmpvalue = tmpvalue.replace(/xxxmalldomain/g, this.$도메인)
      tmpvalue = tmpvalue.replace(/xxxcompanytel/g, this.$회사전화)
      tmpvalue = tmpvalue.replace(/xxxcompanymail/g, this.$회사메일)
      tmpvalue = tmpvalue.replace(/xxx개인정보보호책임자/g, this.$개인정보보호책임자)
      tmpvalue = tmpvalue.replace(/xxx사이트사용문의/g, this.$사이트사용문의)
      tmpvalue = tmpvalue.replace(/xxx개인정보보호책임자이메일/g, this.$개인정보보호책임자이메일)
      return tmpvalue
    },
  }
}
</script>

<style scoped>
@font-face {
  font-family: 'Handletter';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/naverfont_05@1.0/Handletter.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TAEBAEKmilkyway';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2310@1.0/TAEBAEKmilkyway.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

.penFont {
  font-family: TAEBAEKmilkyway;
}

.penFont * {
  font-family: TAEBAEKmilkyway;
}

@media (max-width: 767px) {
  .contentBox {
    width: 100%;
  }
}

.contentBox {
  /* position: absolute;
  top: 50px;
  left: 50px; */
  padding: 20px;
  width: 100%;
  max-height: 550px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, .15);
  z-index: 99;
  overflow: hidden;
  overflow-y: auto;
}
</style>
