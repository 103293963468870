<template>
  <div class="container mt-3 relative noselect" style="max-width: 1300px !important;">
    <TopBar />
    <TopMenu />
    <!-- <TopBanner /> -->
    <!-- <TopNotice /> -->
    <el-row :gutter="15">
      <el-col :xl="5" :xs="24" :sm="5">
        <centermenu />
      </el-col>
      <el-col :xl="24" :xs="24" :sm="24">
        <el-row class="borderbox mt-2 p-2">
          <el-col class="font15 bold borderbottom3px-orange pb-2">
            <span>
              입출금관리
            </span>
            <span class="absolute hand fixedTooltip-link" style="top:5px;right:5px;" @click="changePassword = true">
            </span>
          </el-col>
          <el-col class="mt-3 font13">
            <el-row :gutter="10">
              <el-col :xl="6" :xs="24" :sm="6">
                <div class="relative" style="right:0px;">
                  <el-input size="mini" type="text" placeholder="검색어를 입력하세요."
                    style="margin: 0px !important; padding: 0px !important;" v-model="searchKeyword"
                    @keyup.enter.native="read()"></el-input>
                  <button class="searchButton absolute" style="top:0px;right:0px;" @click="read()">
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </el-col>
              <el-col :xl="18" :xs="24" :sm="18" class="text-right">
                <!-- 구간검색 -->
                <datearea @callback="dateAreaCallback" />
              </el-col>
            </el-row>
            <el-table size="mini" :data="tmpTableData" border style="width: 100%" class="mt-3">
              <el-table-column label="거래날자" width="130">
                <template slot-scope="scope">
                  <span class="colorblue hand" @click="detailViewWindow(scope.row)">
                    {{ $YYYYMMDD_HHMM(scope.row.updated, '.') }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="거래구분" width="150">
                <template slot-scope="scope">
                    <span>{{ $accountBookType.find(item => item.value == scope.row.mark).label }}</span>
                </template>
              </el-table-column>
              <el-table-column label="거래금액" width="130">
                <template slot-scope="scope">
                  <span class="nowrap" v-if="scope.row.pointMinus">- {{ $comma(scope.row.pointMinus) }}</span>
                  <span class="nowrap" v-if="scope.row.pointPlus">+ {{ $comma(scope.row.pointPlus) }}</span>
                </template>
              </el-table-column>
              <el-table-column label="잔액" width="110" :align="'center'">
                <template slot-scope="scope">
                  <span class="nowrap">+ {{ $comma(scope.row.pointResidual) }}</span>
                </template>
              </el-table-column>
              <el-table-column label="나의메모">
                <template slot-scope="scope">
                  <div class="nowrap">
                    <span>{{ scope.row.memo }}</span>
                    <span v-if="!scope.row.memo" class="colorlight"> 메모를 작성하세요.</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div class="text-right mt-2">
              <el-pagination background layout="prev, pager, next" :total="totalCount" :page-size="req.size"
                @current-change="handlePageChange">
              </el-pagination>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>

    <accountBookView :data="selectitem" v-if="showDetailView" @callback="createMallMemberinfo" />
    <FootBar />
    <el-backtop>
    </el-backtop>
  </div>
</template>
<script>
import componentInputText from './component-input-text.vue';
import datearea from './component-date-area.vue'
import TopBar from './component-top.vue'
import TopBanner from './component-top-banner.vue'
import TopNotice from './component-top-notice.vue'
import TopMenu from './component-top-menu.vue'
import FootBar from './component-foot.vue'
import centermenu from './component-cener-left-menu.vue'
import { Loading } from 'element-ui'
import accountBookView from './component-accountBook-view.vue'
export default {
  components: {
    centermenu, TopBar, TopBanner, TopNotice, TopMenu, FootBar, Loading, componentInputText,
    accountBookView, datearea
  },
  data() {
    return {
      selectitem: null,
      showDetailView: false,
      loginUserInfo: null,
      loading: null,
      tmpTableData: [],
      totalCount: 0,
      req: {
        objSearchMapper: {
          startDate: null,
          endDate: null,
          searchKeyword: null,
          state: 0,
        },
        page: 1,
        size: 10
      },
      searchKeyword: null,

      checkedIds: [],
      checkedAll: false,
    }
  },
  created() {
    window.scrollTo(0, 0);
    this.loading = Loading.service({ fullscreen: true })
    // this.$checkAuth(this.$loginUserInfo) // type==0 대표권한
    this.$http.defaults.headers.common['exjwtauthorization'] = this.$loginUserInfo?.token
    this.getMallPointAccountInfo()
    this.$overay(this.loading)
  },
  methods: {

    dateAreaCallback(data) { // 구간검색함수
      this.req.objSearchMapper.startDate = data.startDate
      this.req.objSearchMapper.endDate = data.endDate
      this.req.page = 1
      this.getMallPointAccountInfo()
    },
    detailViewWindow(item) {
      if (item) { this.selectitem = JSON.parse(JSON.stringify(item)) } else {
        this.selectitem = null
      }
      this.showDetailView = true
    },
    createMallMemberinfo(data) {
      this.showDetailView = false
      this.getMallPointAccountInfo()
    },

    handlePageChange(page) {
      this.req.page = page
      this.getMallPointAccountInfo()
    },
    async read() {
      this.req.objSearchMapper.startDate = null
      this.req.objSearchMapper.endDate = null
      this.req.objSearchMapper.searchKeyword = this.searchKeyword
      this.req.page = 1
      await this.getMallPointAccountInfo()
    },
    async getMallPointAccountInfo() {
      await this.$http.post(`${this.$serverurl}/api/getMallPointAccountInfo`, this.req)
        .then(res => {
          if (200 == res.data.code) {
            this.tmpTableData = res.data.body.results
            this.tmpTableData.sort((a, b) => {
              return new Date(a.created) - new Date(b.created);
            });
            this.totalCount = res.data.totalCount
          } else {
            // this.$notify.error({
            //   title: '',
            //   message: res.data.message,
            //   position: 'bottom-right',
            //   showClose: true,
            //   duration: 1000,
            // });
            this.tmpTableData = []
          }
        })
      this.$overay(this.loading)
    },

  },
}

</script>
<style></style>
