<template>
  <div class="container mt-3 mb-5 relative" style="max-width: 800px !important;">
    <SubTopBar :hiddenSearchbox="true"/>

    <div class="mt-5 formTitle bold">
      {{ tmpTableData.title }}
    </div>
    <label class="font15 colorgray">{{ $YYYYMMDD(tmpTableData.updated) }}</label>

    <div class="mt-5" v-html="tmpTableData.content"></div>
    <div class="mt-5 bold">
      <span>Tags: </span>
      <span v-html="tmpTableData.tag"></span>
    </div>
  </div>
</template>

<script>
import SubTopBar from './component-sub-top.vue'
import TopBar from './component-top.vue'
import goNotice from './component-gonotice.vue'
export default {
  components: {
    TopBar, goNotice,SubTopBar
  },
  data() {
    return {
      title: '공지사항',
      types: [
        { id: 0, title: '공지사항' },
        { id: 2, title: '사용가이드' },
        { id: 3, title: '자주하는 질문' },
      ],
      categorys: [
        { id: 0, title: '일반공지' },
        { id: 1, title: '정책안내' },
        { id: 2, title: '분쟁사례' },
      ],
      req: {
        objSearchMapper: {
          seq: null,
          searchKeyword: null,
          category: 0,
          type: 0,
        },
        page: 1,
        size: 50
      },
      tmpTableData: [],
      totalCount: 0,
    };
  },
  methods: {

  },
  beforeDestroy() {
  },
  async created() {
    this.$http.defaults.headers.common['Jwtauthorization'] = 'Bearer ' + this.$cookies.get('token');
    await this.read()
  },
  methods: {
    async read() {
      this.req.objSearchMapper.type= this.$route.params.type
      this.req.objSearchMapper.category= this.$route.params.category
      this.req.objSearchMapper.seq= this.$route.params.id
      await this.$http.post(`${this.$serverurl}/api/getMallNoticeInfo`, this.req)
        .then(res => {
          if (200 == res.data.code) {
            this.tmpTableData = res.data.body.results?.length ? res.data.body.results[0] : null
            this.totalCount = res.data.body.total
          }
        })
    },
  }
}
</script>

<style></style>
