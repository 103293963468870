import { startDrag } from './drag.js'

/**
 * 
 * @param {*} el
 * @param {*} binding 
 * 
 */
const draggable = (el, binding) => {
    startDrag(el.querySelector('.el-dialog__header'), el.querySelector('.el-dialog'), binding.value);
};
const directives = {
    draggable,
};
export default {
  install(Vue) {
      Object.keys(directives).forEach((key) => {
      Vue.directive(key, directives[key]);
    });
  },
};