import { render, staticRenderFns } from "./component-product-view.vue?vue&type=template&id=94ee05be&scoped=true"
import script from "./component-product-view.vue?vue&type=script&lang=js"
export * from "./component-product-view.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "94ee05be",
  null
  
)

export default component.exports