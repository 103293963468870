<template>
    <div>
        <div class="drag-box" v-if="isShowDrag">
            <div class="mb-2 move" v-drag>
                <span class="font18 bold">1:1고객문의</span>
            </div>
            <el-row :gutter="10" class="mt-2">
                <el-col :xl="24" :xs="24" :md="24">
                    {{ $loginUserInfo.name }}
                </el-col>
            </el-row>
            <el-row :gutter="10" class="mt-3">
                <el-col :xl="24" :xs="24" :md="24">
                    {{ $loginUserInfo.phone }}
                </el-col>
            </el-row>
            <el-row :gutter="10" class="mt-3">
                <el-col :xl="24" :xs="24" :md="24">
                    <el-select size="mini" v-model="formData.category" class="w100p">
                        <el-option v-for="item in $siteQnaType" :key="item.value" :value="item.value"
                            :label="item.label">
                        </el-option>
                    </el-select>
                </el-col>
            </el-row>
            <el-row :gutter="10" class="mt-3">
                <el-col :xl="24" :xs="24" :md="24" class="relative">
                    <el-input type="textarea" v-model="formData.content" placeholder="궁금하신 사항을 입력하세요." show-word-limit
                        maxlength="100">
                    </el-input>
                </el-col>
            </el-row>
            <el-row :gutter="10" class="mt-3 mb-3">
                <el-col :xl="24" :md="24" class="text-right">
                    <el-button type="info" size="mini" icon="el-icon-error" @click="closeMe()">
                        창닫기
                    </el-button>
                    <el-button size="mini" class="el-btn-orange" icon="el-icon-success" @click="setMallQnaInfo()">
                        정보제출
                    </el-button>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
export default {
    name: 'siteQna',
    data() {
        return {
            isShowDrag: false,
            formData: {
                type: 4,
                category: 0,
                title: '광고 및 제휴문의 ' + this.$YYYYMMDD_HHMM(new Date(), '-'),
                content: ''
            }
        }
    },
    props: ['switch'],
    watch: {
        switch: {
            deep: true,
            immediate: true,
            async handler(newValue, oldValue) {
                this.isShowDrag = newValue
            }
        }
    },
    async created() {
        // this.$loginUserInfo = JSON.parse(localStorage.getItem('loginUserInfo'))
        this.$http.defaults.headers.common['exjwtauthorization'] = this.$loginUserInfo?.token
    },
    methods: {
        closeMe() {
            this.$emit('callback', false)
        },
        async setMallQnaInfo() {
            if (this.formData.content.trim().length < 10) {
                alert('10자이상의 문의글을 입력하세요.')
                return
            }
            await this.$http.post(`${this.$serverurl}/api/setMallQnaInfo`, this.formData)
                .then(res => {
                    if (res.data.code == 201) {
                        this.$notify.success({
                            title: '',
                            message: res.data.message,
                            position: 'bottom-right',
                            showClose: false
                        });
                        this.formData.title = ''
                        this.formData.content = ''
                        this.closeMe()
                    } else {
                        alert(res.data.message)
                    }
                })
        },
    },
    directives: {
        drag: { // 타이틀 드래그 > 부모div드래그
            bind: function (el) {
                let offsetX, offsetY;
                el.addEventListener('mousedown', onMouseDown);
                function onMouseDown(event) {
                    const parent = el.parentElement;
                    offsetX = event.clientX - parent.offsetLeft;
                    offsetY = event.clientY - parent.offsetTop;
                    document.addEventListener('mousemove', onMouseMove);
                    document.addEventListener('mouseup', onMouseUp);
                }

                function onMouseMove(event) {
                    const parent = el.parentElement;
                    parent.style.left = (event.clientX - offsetX) + 'px';
                    parent.style.top = (event.clientY - offsetY) + 'px';
                }
                function onMouseUp() {
                    document.removeEventListener('mousemove', onMouseMove);
                    document.removeEventListener('mouseup', onMouseUp);
                }
            }
        }
    }
}
</script>

<style scoped>
.titleDiv {
    padding: 5px;
    margin-bottom: 10px;
}

.title {
    font-weight: bold;
}

.drag-box {
    padding: 10px;
    position: fixed;
    top: 200px;
    /*창이 가운데 오게함 */
    left: 50%;
    /*창이 가운데 오게함 */
    transform: translateX(-50%);
    /* transform: translateY(-50%); */
    width: 450px;
    min-height: 100px;
    max-height: 750px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, .15);
    z-index: 99;
}
</style>