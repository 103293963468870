<template>
  <div class="productView relative mt-3">
    <div class="viewDiv">
      <div class="button" @click="$changeRoute('productView/' + data.seq)" v-if="data.hasAmount>0">
        <div class="mb-3 blur03 font18 bold">
          <i class="fas fa-screwdriver-wrench colorwhite font30"></i>
          <!-- PARTS <br/>ALLIANCE -->
        </div>
        <div>
          {{ data.name }}
        </div>
        <div>
          {{$totalInTax(data.price,1,true) }}
        </div>
      </div>
      <div class="button" v-else>
        <div class="mb-3 blur03 font18 bold">
          <i class="fas fa-ban colorred font30"></i>
          <!-- PARTS <br/>ALLIANCE -->
        </div>
        <div class="hand">
          품절상품
        </div>
      </div>
    </div>
    <!-- new,hot -->
    <div class="badge badge-red absolute" style="z-index: 1;top:10px;left:10px;">
      {{ data.badges[0] }}
    </div>
    <div class="badge badge-blue absolute" style="z-index: 1;top:30px;left:10px;" v-if="data.priceSales">
      SALE
    </div>
    <div class="badge absolute" style="z-index:3333;top:5px;right:5px;" v-if="data.productWishInfo">
      <i class="far fa-heart font18 colorred"></i>
    </div>
    <div class="badge badge-blue absolute" style="z-index:10;top:108px;left:5px;">
      <div>{{ data.pid }}</div>
      <div>{{ data.factoryNum }} </div>
    </div>
    <div class="productImage">
      <div :style="setbackground(data)" class="productImageItem">
      </div>
    </div>
    <div class="productInven badge badge-darklight mt-1">
      {{ $productType[data.type].label }}
    </div>
    <div class="mt-1">
      <span class="productPrice">
        {{ $totalInTax(data.price,1,true) }}
      </span>
      <span class="ml-2 bold colorgraylight fontline-through colorgray" v-if="data.priceSales">
        {{ $totalInTax(data.price + data.priceSales,1,true) }}
      </span>
    </div>
    <div class="productTitle">
      {{ data.name }}
    </div>
    <div class="productInven badge badge-black" v-if="data.hasAmount">
      <img class="absolute" style="width:14px;" :class="data.import==1?'china':'korea'" />
      <span class="ml-3">{{ data.storageInfo.name }}</span>
      <span class="ml-1">(Qty:{{ data.hasAmount }})</span>
    </div>
    <div v-else class="productInven badge badge-red">
      SOLD OUT
    </div>
  </div>
</template>
<script>
export default {
  name: 'productcardbutton',
  props: ['data'],

  methods: {
    setbackground(item) {
      if (item && item.images && item.images.length > 0) {
        const https = item.images[0].includes('://')
        if (!https) {
          item.images[0] = this.$imageurl + item.images[0]
        }
        return {
          background: `url(${item.images[0]}) no-repeat center center / cover !important`
        };
      } else {
        return {
          // background: `url('${this.$imageurl}/upload/no-image.webp') no-repeat center center / cover`
          background: `url('/static/img/no-image.webp') no-repeat center center / cover`
        };
      }
    },
  }
}
</script>