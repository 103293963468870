<template>
  <div class="container" style="max-width: 800px;">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <goHome />
        <div class="formTitle mt-5">
          새로운 경험을 체험하세요
        </div>
        <div class="relative">
          <div class="input-container mt-3">
            <input ref="id" type="text" class="formtextbox" v-model="formData.id" @focus="onFocus('id')"
              @blur="onBlur('id')" maxlength="20" />
            <label class="placeholder" :class="{ 'active': isFocused.id }">로그인계정</label>
            <i class="fa fa-lock absolute font18" style="top:35px;right:5px;"></i>
          </div>
          <div class="input-container mt-3">
            <input ref="pw" type="password" class="formtextbox" v-model="formData.pw" @focus="onFocus('pw')"
              @blur="onBlur('pw')" maxlength="20" autocomplete="off" />
            <label class="placeholder" :class="{ 'active': isFocused.pw }">로그인암호</label>
            <i class="fa fa-eye-slash absolute font18" style="top:35px;right:5px;"></i>
          </div>
          <div class="input-container">
            <input ref="valiSecurityNumber" type="text" class="formtextbox" v-model="formData.valiSecurityNumber"
              @keyup.enter="read()" maxlength="6" @focus="onFocus('valiSecurityNumber')"
              @blur="onBlur('valiSecurityNumber')" />
            <span class="badge badge-warning absolute font15 hand" style="top:30px;right:5px;"
              @click="newValiSecretNumber(); onFocus('valiSecurityNumber')">{{ formData.tmpSecurityNumber }}</span>
            <label class="placeholder colorgray hand"
              :class="{ 'active': isFocused.valiSecurityNumber || formData.valiSecurityNumber }">검증번호*
            </label>
          </div>
          <div class="mt-5">
            <button class="btn btn-danger w100p lh40" type="button" @click="read()">로그인</button>
          </div>
          <div class="absolute" style="right:0px;bottom:-50px;">
            <el-breadcrumb separator-class="el-icon-arrow-right" class="mt-3  text-right">
              <el-breadcrumb-item @click.native="$changeRoute('main', 'old')" class="hand">홈페이지
                둘러보기</el-breadcrumb-item>
              <el-breadcrumb-item :to="{ path: '/joinus' }" class="hand">계정이 없으세요? 회원가입하기</el-breadcrumb-item>
              <el-breadcrumb-item></el-breadcrumb-item>
              <!-- {{ mallConfigInfo}} -->
            </el-breadcrumb>
          </div>
          <!-- <div class="absolute freeday90" style="bottom:-130px;" @click="$changeRoute('joinus')"></div> -->

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import goHome from './component-gohome.vue'

export default {
  components: {
    goHome
  },
  data() {
    return {
      mallConfigInfo: null,
      isFocused: {
        id: false,
        pw: false,
        valiSecurityNumber: false,
      },
      formData: {
        id: '',
        pw: '',
        valiSecurityNumber: '',
        tmpSecurityNumber: ''
      }
    };
  },
  created() {
    localStorage.removeItem('loginUserInfo')
    this.$loginUserInfo = null //{}
    if (this.formData.id) { this.isFocused['id'] = true }
    if (this.formData.pw) { this.isFocused['pw'] = true }
    this.formData.tmpSecurityNumber = this.$createSecuryNumber(4)
    this.getMallConfig()
    // this.formData.id = 'kingkong'
    // this.formData.pw = 'jinro'
    // this.formData.valiSecurityNumber = this.formData.tmpSecurityNumber
  },
  methods: {
    async getMallConfig() {
      const response = await this.$http.get(this.$serverurl + '/api/getMallConfigInfoAd')
      if (response.data.licenseFreeDays) {
        this.mallConfigInfo = `신규가입 EPC ${response.data.licenseFreeDays}일 무료사용!!!`
      }
    },
    newValiSecretNumber() {
      this.tmpSecurityNumber = this.$createSecuryNumber(4)
    },
    onFocus(field) {
      this.isFocused[field] = true;
    },
    onBlur(field) {
      if (!this[field] && !this.formData[field]) {
        this.isFocused[field] = false;
      }
    },
    // --------------------------------------------------
    // -- action
    // --------------------------------------------------
    async read() {
      if (this.formData.valiSecurityNumber.trim() == '') {
        alert('검증번호를 입력하세요.')
        return
      }
      if (this.formData.tmpSecurityNumber != this.formData.valiSecurityNumber) {
        alert('검증번호가 일치하지 않습니다.')
        return
      }
      await this.$http.post(`${this.$serverurl}/api/LoginByMall`, { id: this.formData.id, pw: this.formData.pw })
        .then(res => {
          if (200 != res.data.code) {
            alert(res.data.message)
          } else {
            localStorage.setItem('loginUserInfo', JSON.stringify(res.data.body.results));
            this.$loginUserInfo = res.data.body.results
            this.$http.defaults.headers.common['exjwtauthorization'] = this.$loginUserInfo?.token
            this.$changeRoute('main', 'old')
          }
        })
    },
  },
  beforeDestroy() {
  },

}
</script>

<style>
.freeday90 {
  cursor: pointer;
  width: 770px;
  height: 50px;
  background: url('/static/img/90FREEEPC.webp');
}
</style>
