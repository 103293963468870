<template>
  <div class="container mt-3 relative noselect" style="max-width: 1300px !important;">
    <TopBar />
    <TopMenu />
    <!-- <TopBanner /> -->
    <!-- <TopNotice /> -->
    <el-row :gutter="15">
      <el-col :xl="5" :xs="24" :sm="5">
        <centermenu />
      </el-col>
      <el-col :xl="24" :xs="24" :sm="24">
        <el-row class="borderbox mt-2 p-2">
          <el-col class="borderbottom1px lh40 relative">
            <span class="font18 bold">
              배송지관리
            </span>
            <span class="absolute hand fixedTooltip-link" style="top:5px;right:5px;" @click="changePassword = true">
            </span>
          </el-col>
          <el-col class="relative mt-2 font13">
            <el-row>
              <el-col :xl="12" :xs="12" :sm="12">
                <div class="relative" style="right:0px;">
                  <el-input size="mini" type="text" placeholder="검색어를 입력하세요."
                    style="margin: 0px !important; padding: 0px !important;" v-model="req.objSearchMapper.searchKeyword"
                    @keyup.enter.native="read()"></el-input>
                  <button class="searchButton absolute" style="top:0px;right:0px;" @click="read()">
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </el-col>
              <el-col :xl="12" :xs="12" :sm="12" class="text-right">
                <el-button type="primary" size="mini" @click="showCreateshoppingWindow()">
                  <i class="fas fa-pen-to-square"></i>
                  배송지등록</el-button>
              </el-col>
            </el-row>
            <el-table size="mini" :data="tmpTableData" border style="width: 100%" class="mt-3">
              <el-table-column label="" width="60">
                <template slot-scope="scope">
                  <span v-if="scope.row.basic == 'true'">
                    <i class="fas fa-fingerprint"></i>
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="최근날자" width="110">
                <template slot-scope="scope">
                  <span class="fixedTooltip-link-f hand" @click="showCreateshoppingWindow(scope.row)">
                    {{ $YYYYMMDD(scope.row.updated) }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="이름" width="100">
                <template slot-scope="scope">
                  <div class="nowrap" :class="scope.row.basic == 'true' ? 'bold' : ''">{{ scope.row.name }}</div>
                </template>
              </el-table-column>
              <el-table-column label="연락처" width="110" :align="'center'">
                <template slot-scope="scope">
                  <div class="nowrap">{{ scope.row.phone }}</div>
                </template>
              </el-table-column>
              <el-table-column label="주소">
                <template slot-scope="scope">
                  <div class="nowrap listSplit">
                    <span>({{ scope.row.postNum }})</span>
                    <span>{{ scope.row.address }}</span>
                    <span>{{ scope.row.addrSub }}</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div class="text-right mt-2">
              <el-pagination background layout="prev, pager, next" :total="totalCount" :page-size="req.size"
                @current-change="handlePageChange">
              </el-pagination>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <shippingaddresscreate :data="selectitem" v-if="showShippingaddresscrete" @callback="createShippingAddress" />
    <FootBar />
    <el-backtop>
    </el-backtop>
  </div>
</template>
<script>
import componentInputText from './component-input-text.vue';

import TopBar from './component-top.vue'
import TopBanner from './component-top-banner.vue'
import TopNotice from './component-top-notice.vue'
import TopMenu from './component-top-menu.vue'
import FootBar from './component-foot.vue'
import centermenu from './component-cener-left-menu.vue'
import { Loading } from 'element-ui'
import shippingaddresscreate from './component-checkout-shipping-address-create.vue'
export default {
  components: {
    centermenu, TopBar, TopBanner, TopNotice, TopMenu, FootBar, Loading, componentInputText,
    shippingaddresscreate
  },
  data() {
    return {
      selectitem: null,
      showShippingaddresscrete: false,
      loginUserInfo: null,
      loading: null,
      tmpTableData: [],
      totalCount: 0,
      req: {
        objSearchMapper: {
          searchKeyword: null,
        },
        page: 1,
        size: 10
      }
    }
  },
  created() {
    window.scrollTo(0, 0);
    this.loading = Loading.service({ fullscreen: true })
    this.$loginUserInfo = JSON.parse(localStorage.getItem('loginUserInfo'))
    this.$http.defaults.headers.common['exjwtauthorization'] = this.$loginUserInfo?.token
    this.getMemberShippingInfo()
    this.$overay(this.loading)
  },
  methods: {
    showCreateshoppingWindow(item) {
      if (item) { this.selectitem = JSON.parse(JSON.stringify(item)) } else {
        this.selectitem = null
      }
      this.showShippingaddresscrete = true
    },
    createShippingAddress(data) {
      this.showShippingaddresscrete = false
      this.getMemberShippingInfo()
    },
    reload() {
      this.formData = {
        tel: this.$loginUserInfo.tel,
        phone: this.$loginUserInfo.phone,
        mail: this.$loginUserInfo.mail,
      }
    },
    handlePageChange(page) {
      this.req.page = page
      this.getMemberShippingInfo()
    },
    async read() {
      this.req.objSearchMapper.searchKeyword = this.searchKeyword
      this.req.page = 1
      await this.getMemberShippingInfo()
    },
    async getMemberShippingInfo() {
      await this.$http.post(`${this.$serverurl}/api/getMemberShippingInfo`, this.req)
        .then(res => {
          if (200 == res.data.code) {
            this.tmpTableData = res.data.body.results
            this.totalCount = res.data.totalCount
            // this.$notify.success({
            //   title: '',
            //   message: res.data.message,
            //   position: 'bottom-right',
            //   showClose: true,
            //   duration: 1000,
            // });
            localStorage.setItem('loginUserInfo', JSON.stringify(this.$loginUserInfo))
          } else {
            this.$notify.error({
              title: '',
              message: res.data.message,
              position: 'bottom-right',
              showClose: true,
              duration: 1000,
            });
          }
        })
      this.$overay(this.loading)
    },

  },
}

</script>
<style></style>
