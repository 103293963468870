<template>
    <div>
        <el-dialog title="배송지선택" :fullscreen="$isMobile?true:false" :visible.sync="visible" width="50%"
            :before-close="handleClose" center v-draggable>
            <div class="text-center mb-4 font15 lh30">
                <el-row class="mb-2">
                    <el-col :xl="6" :xs="24" :sm="6">
                        <div class="relative">
                            <el-input placeholder="검색어+ENTER" size="mini" 
                            prefix-icon="el-icon-search" clearable
                            @clear="read()"
                            v-model="req.objSearchMapper.searchKeyword"
                                @keyup.enter.native="read()">
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :xl="18" :xs="24" :sm="18" class="text-right">
                        <el-button size="mini" type="primary" 
                         icon="el-icon-circle-plus" @click="showShippingaddresscrete=true">배송지등록</el-button>
                    </el-col>
                </el-row>
                <el-table :data="tmpTabledata" border style="width: 100%" size="mini">
                    <el-table-column label="선택" width="90">
                        <template slot-scope="scope">
                            <span class="bigbadge-blue hand" @click="returnMessage(scope.row)">선택</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="수취인" width="90">
                    </el-table-column>
                    <el-table-column label="세부정보">
                        <template slot-scope="scope">
                            {{ scope.row.phone }}
                            <span v-if="!$isMobile">
                                ({{ scope.row.postNum }}){{ scope.row.address }} {{ scope.row.addrSub }}
                            </span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="text-center">
                <el-button @click="returnMessage(false)">창닫기</el-button>
            </div>
        </el-dialog>
        <shippingaddresscreate v-if="showShippingaddresscrete" @callback="createShippingAddress" />
    </div>
</template>

<script>
import shippingaddresscreate from './component-checkout-shipping-address-create.vue'
export default {
    name: 'shippingaddress',
    components:{shippingaddresscreate},
    data() {
        return {
            showShippingaddresscrete:false,
            visible: true,
            tmpTabledata: [],
            req: {
                objSearchMapper: {
                    searchKeyword: null,
                },
                page: 1,
                size: 10
            }
        }
    },
    async created() {
        this.read()
    },
    methods: {
        createShippingAddress(data) {
            this.showShippingaddresscrete = false
            this.read()
        },
        async returnMessage(params) {
            this.$emit('callback', params)
        },
        handleClose(done) {
            this.$emit('callback', false)
            done()
        },
        async read() {
            await this.$http.post(`${this.$serverurl}/api/getMemberShippingInfo`, this.req)
                .then(res => {
                        this.tmpTabledata = res.data?.body?.results || []
                })
        }

    }
}
</script>

<style scoped></style>