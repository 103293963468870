<template>
    <div>
        <!-- <div class="mt-2 borderbox" v-if="!$isMobile">
            <div v-if="$loginUserInfo&&$loginUserInfo.type == 0">
                <div v-for="(item, index) in menulist" :key="index">
                    <div v-if="item.type == 1" class="borderbottom1px"></div>
                    <div>
                        <div v-if="item.type == 0" class="menuitem font13 relative" @click="$changeRoute(item.route)">
                            <span class="pl-3">
                                {{ item.name }}</span>
                            <span class="badge badge-small ml-2"
                                :style="'background-color:' + item.bg + ';color:' + item.color + ';'" v-if="item.badge">
                                {{ item.badge }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div v-for="(item, index) in menulist" :key="index">
                    <div v-if="item.type == 1 && item.userType != 0" class="borderbottom1px"></div>
                    <div>
                        <div v-if="item.type == 0 && item.userType != 0" class="menuitem font13 relative"
                            @click="$changeRoute(item.route)">
                            <span class="pl-3">
                                {{ item.name }}</span>
                            <span class="badge badge-small ml-2"
                                :style="'background-color:' + item.bg + ';color:' + item.color + ';'" v-if="item.badge">
                                {{ item.badge }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="mt-2">
            <div v-if="$loginUserInfo.type == 0" class="listSplit nowrap centerleftmenuRow">
                <span v-for="(item, index) in menulist" :key="index">
                    <span v-if="item.type == 0" class="menuitem font13 relative" @click="$changeRoute(item.route)">
                        <span>
                            {{ item.name }}</span>
                        <span class="badge badge-small ml-2 absolute" style="top:-10px;right:15px;"
                            :style="'background-color:' + item.bg + ';color:' + item.color + ';'" v-if="item.badge">
                            {{ item.badge }}
                        </span>
                    </span>
                </span>
            </div>
        </div> -->
    </div>

</template>

<script>
export default {
    name: 'centermenu',
    components: {
    },
    data() {
        return {
            menulist: [
                { name: '관리센터', type: 0, route: 'account/cenermain', badge: '', },
                { name: '입출금관리', type: 0, route: 'orders/accountBook', badge: 'NEW',bg:'#00aa00',color: '#fff'},
                { name: '구매내역', type: 0, route: 'orders', badge: '', },
                { name: 'split', type: 1, route: '' },
                { name: '제품문의', type: 0, route: 'account/cenermain', badge: '', },
                { name: '1:1문의글', type: 0, route: 'account/cenermain', badge: '', },
                { name: 'split', type: 1, route: '' },
                { name: '나의정보수정', type: 0, route: 'account/profile', badge: '' },
                { name: '배송지관리', type: 0, route: 'account/shippingAddress', badge: '' },
                { name: 'split', type: 1, route: '' },
                { name: '보안번호설정', type: 0, route: 'account/security', badge: 'SAFE', bg: '#ff0055', color: '#fff', userType: 0, },
                { name: '회사정보수정', type: 0, route: 'account/companyInfo', badge: '', userType: 0, },
                { name: '계정관리', type: 0, route: 'account/accountInfo', badge: '', userType: 0, },
                { name: 'split', type: 1, route: '', userType: 0, },
                { name: '충전요청', type: 0, route: 'account/buyPoint', badge: '삭제예정', bg: '#eee', color: '#000', userType: 0, },
                { name: '온라인결제(충전)', type: 0, route: 'account/buyPointAuto', badge: 'NEW', bg: '#00aa00', color: '#fff', userType: 0, },
                { name: '라이센스관리', type: 0, route: 'account/buyLicense', badge: '', userType: 0, },
                { name: 'split', type: 1, route: '', userType: 0, },
                { name: '회원탈퇴', type: 0, route: 'account/WithdrawMember', badge: '' },
                { name: 'split', type: 1, route: '' },
                { name: '로그아웃', type: 0, route: 'account/cenermain', badge: '' },
            ],
        }
    },
    async created() { },
    methods: {
    }
}
</script>

<style scoped></style>