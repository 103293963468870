<template>
  <div class="container noselect" style="max-width: 800px;">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 relative">
        <goHome />
        <div class="absolute" style="right:0px;top:0px;">
          <el-breadcrumb separator-class="el-icon-arrow-right" class="mt-3  text-right">
            <el-breadcrumb-item :to="{ path: '/login' }" class="hand">기존 회원은 로그인하세요.</el-breadcrumb-item>
            <el-breadcrumb-item></el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="formTitle mt-5">
          <el-row>
            <el-col :xl=12 :md=12>
              {{ !subAccount ? ` 회원가입을 환영합니다.` : `서브계정가입을 환영합니다.` }}
            </el-col>
            <el-col :xl=12 :md=12 :align="'right'">
              <!-- <span class="font18 colorreddeep"> 
                 {{ mallConfigInfo }}</span> -->
            </el-col>
          </el-row>

        </div>
        <div class="relative" v-if="!subAccount">
          <el-row :gutter="5">
            <el-col :xs="24" :md="8" :sm="24">
              <div class="input-container">
                <input ref="license" type="text" class="formtextbox" v-model="formData.license"
                  @focus="onFocus('license')" @blur="onBlur('license')" />
                <label class="placeholder colorgray"
                  :class="{ 'active': isFocused.license || formData.license }">사업자등록번호
                  10자리 숫자*</label>
              </div>
            </el-col>
            <el-col :xs="24" :md="8" :sm="24">
              <div class="input-container">
                <input ref="startDate" type="text" class="formtextbox" v-model="formData.startDate"
                  @focus="onFocus('startDate')" @blur="onBlur('startDate')" />
                <label class="placeholder colorgray"
                  :class="{ 'active': isFocused.startDate || formData.startDate }">개업연월일 19971231*</label>
              </div>
            </el-col>
            <el-col :xs="24" :md="8" :sm="24">
              <div class="input-container">
                <input ref="ceo" type="text" class="formtextbox" v-model="formData.ceo" @focus="onFocus('ceo')"
                  @blur="onBlur('ceo')" />
                <label class="placeholder colorgray" :class="{ 'active': isFocused.ceo || formData.ceo }">대표자
                  이름*</label>
                <span class="absolute btn btn-gray btn-mini" style="bottom:0px;right:0px;"
                  @click="checklicense()">사업자검증</span>
              </div>
            </el-col>
          </el-row>
          <!-- v-if="!ckLicense" -->
          <el-row :gutter="5" class="mt-3">
            <el-col :xs="24" :md="24" :sm="24">
              <div class="input-container">
                <input ref="name" type="text" class="formtextbox" v-model="formData.name" @focus="onFocus('name')"
                  @blur="onBlur('name')" />
                <label class="placeholder colorgray" :class="{ 'active': isFocused.name || formData.name }">상호*</label>
              </div>
            </el-col>
            <el-col :xs="24" :md="24" :sm="24">
              <div class="input-container">
                <input ref="addressMain" type="text" class="formtextboxreadonly hand" v-model="formData.addressMain"
                  @focus="onFocus('addressMain')" @blur="onBlur('addressMain')" @click="loadDaumPostcodeScript"
                  readonly />
                <label class="placeholder colorgray"
                  :class="{ 'active': isFocused.addressMain || formData.addressMain }">주소*</label>
              </div>
            </el-col>
            <el-col :xs="24" :md="24" :sm="24">
              <div class="input-container">
                <input ref="addressSub" type="text" class="formtextbox" v-model="formData.addressSub"
                  @focus="onFocus('addressSub')" @blur="onBlur('addressSub')" />
                <label class="placeholder colorgray"
                  :class="{ 'active': isFocused.addressSub || formData.addressSub }">세부주소*</label>
              </div>
            </el-col>
            <el-col :xs="24" :md="24" :sm="24">
              <div class="input-container">
                <input ref="tel" type="text" class="formtextbox" v-model="formData.tel" @focus="onFocus('tel')"
                  @blur="onBlur('tel')" />
                <label class="placeholder" :class="{ 'active': isFocused.tel || formData.tel }">사무실전화</label>
              </div>
            </el-col>
            <el-col :xs="24" :md="24" :sm="24">
              <div class="input-container">
                <input ref="phone" type="text" class="formtextbox" v-model="formData.phone" @focus="onFocus('phone')"
                  @blur="onBlur('phone')" />
                <label class="placeholder colorgray"
                  :class="{ 'active': isFocused.phone || formData.phone }">모바일*</label>
              </div>
            </el-col>
            <el-col :xs="24" :md="24" :sm="24">
              <div class="input-container">
                <input ref="fax" type="text" class="formtextbox" v-model="formData.fax" @focus="onFocus('fax')"
                  @blur="onBlur('fax')" />
                <label class="placeholder" :class="{ 'active': isFocused.fax || formData.fax }">팩스</label>
              </div>
            </el-col>
            <el-col :xs="24" :md="24" :sm="24">
              <div class="input-container">
                <input ref="mail" type="text" class="formtextbox" v-model="formData.mail" @focus="onFocus('mail')"
                  @blur="onBlur('mail')" />
                <label class="placeholder colorgray" :class="{ 'active': isFocused.mail || formData.mail }">이메일*
                  address@mail.com</label>
              </div>
            </el-col>
            <el-col :xs="24" :md="24" :sm="24">
              <div class="input-container">
                <input ref="id" type="text" class="formtextbox" v-model="formData.id" @focus="onFocus('id')"
                  @blur="onBlur('id')" />
                <label class="placeholder colorgray" :class="{ 'active': isFocused.id || formData.id }">로그인계정*</label>
              </div>
            </el-col>
            <el-col :xs="24" :md="24" :sm="24">
              <div class="input-container">
                <input ref="pw" type="password" class="formtextbox" autocomplete="off" v-model="formData.pw"
                  @focus="onFocus('pw')" @blur="onBlur('pw')" />
                <label class="placeholder colorgray" :class="{ 'active': isFocused.pw || formData.pw }">비밀번호*</label>
              </div>
            </el-col>
            <el-col :xs="24" :md="24" :sm="24">
              <div class="input-container">
                <input ref="rpw" type="password" class="formtextbox" autocomplete="off" v-model="formData.rpw"
                  @focus="onFocus('rpw')" @blur="onBlur('rpw')" />
                <label class="placeholder colorgray"
                  :class="{ 'active': isFocused.rpw || formData.rpw }">비밀번호확인*</label>
              </div>
            </el-col>
            <el-col :xs="24" :md="24" :sm="24">
              <div class="input-container">
                <input ref="valiSecurityNumber" type="text" class="formtextbox" v-model="formData.valiSecurityNumber"
                  @focus="onFocus('valiSecurityNumber')" @blur="onBlur('valiSecurityNumber')" />
                <span class="badge badge-warning absolute font15 hand" style="top:30px;right:5px;"
                  @click="newValiSecretNumber(); onFocus('valiSecurityNumber')">{{ tmpSecurityNumber }}</span>
                <label class="placeholder colorgray hand"
                  :class="{ 'active': isFocused.valiSecurityNumber || formData.valiSecurityNumber }">검증번호*
                </label>
              </div>
            </el-col>
          </el-row>
        </div>

        <!-- 서브계정 -->
        <div class="relative" v-if="subAccount">
          <el-row :gutter="5">
            <el-col :xs="24" :md="12" :sm="24">
              <div class="input-container">
                <input ref="license" type="text" class="formtextbox" v-model="formData.license"
                  @focus="onFocus('license')" @blur="onBlur('license')" />
                <label class="placeholder colorgray"
                  :class="{ 'active': isFocused.license || formData.license }">사업자등록번호
                  10자리 숫자*</label>
              </div>
            </el-col>
            <el-col :xs="24" :md="12" :sm="24">
              <div class="input-container">
                <input ref="securityNum" type="text" class="formtextbox" v-model="formData.securityNum"
                  @focus="onFocus('securityNum')" @blur="onBlur('securityNum')" />
                <label class="placeholder colorgray"
                  :class="{ 'active': isFocused.securityNum || formData.securityNum }">보안번호(대표자한테
                  문의하세요.)*</label>
              </div>
            </el-col>
            <el-col :xs="24" :md="24" :sm="24">
              <div class="input-container">
                <input ref="memberName" type="text" class="formtextbox" v-model="formData.memberName"
                  @focus="onFocus('memberName')" @blur="onBlur('memberName')" />
                <label class="placeholder colorgray"
                  :class="{ 'active': isFocused.memberName || formData.memberName }">이름*</label>
              </div>
            </el-col>
            <el-col :xs="24" :md="24" :sm="24">
              <div class="input-container">
                <input ref="phone" type="text" class="formtextbox" v-model="formData.phone" @focus="onFocus('phone')"
                  @blur="onBlur('phone')" />
                <label class="placeholder colorgray"
                  :class="{ 'active': isFocused.phone || formData.phone }">휴대폰번호*</label>
              </div>
            </el-col>
            <el-col :xs="24" :md="24" :sm="24">
              <div class="input-container">
                <input ref="id" type="text" class="formtextbox" v-model="formData.id" @focus="onFocus('id')"
                  @blur="onBlur('id')" />
                <label class="placeholder colorgray" :class="{ 'active': isFocused.id || formData.id }">로그인계정*</label>
              </div>
            </el-col>
            <el-col :xs="24" :md="24" :sm="24">
              <div class="input-container">
                <input ref="pw" type="password" class="formtextbox" autocomplete="off" v-model="formData.pw"
                  @focus="onFocus('pw')" @blur="onBlur('pw')" />
                <label class="placeholder colorgray" :class="{ 'active': isFocused.pw || formData.pw }">비밀번호*</label>
              </div>
            </el-col>
            <el-col :xs="24" :md="24" :sm="24">
              <div class="input-container">
                <input ref="rpw" type="password" class="formtextbox" autocomplete="off" v-model="formData.rpw"
                  @focus="onFocus('rpw')" @blur="onBlur('rpw')" />
                <label class="placeholder colorgray"
                  :class="{ 'active': isFocused.rpw || formData.rpw }">비밀번호확인*</label>
              </div>
            </el-col>
            <el-col :xs="24" :md="24" :sm="24">
              <div class="input-container">
                <input ref="valiSecurityNumber" type="text" class="formtextbox" v-model="formData.valiSecurityNumber"
                  @focus="onFocus('valiSecurityNumber')" @blur="onBlur('valiSecurityNumber')" />
                <span class="badge badge-warning absolute font15 hand" style="top:30px;right:5px;"
                  @click="newValiSecretNumber(); onFocus('valiSecurityNumber')">{{ tmpSecurityNumber }}</span>
                <label class="placeholder colorgray hand"
                  :class="{ 'active': isFocused.valiSecurityNumber || formData.valiSecurityNumber }">검증번호*
                </label>
              </div>
            </el-col>
          </el-row>
        </div>

        <!-- 이용약관 및 개인정보처리 동의 -->
        <el-row :gutter="10" class="mt-3">
          <el-col :xs="24" :md="10" :sm="24">
            <el-checkbox v-model="agreeConfig[0].agree">
              {{ $회사이름한글 }} 이용약관 동의(필수)</el-checkbox>
            <span class="ml-2 hand" @click="$changeRoute('policiesService', 'new')">보기</span>
          </el-col>
          <el-col :xs="24" :md="12" :sm="24">
            <el-checkbox v-model="agreeConfig[1].agree">
              {{ $회사이름한글 }} 개인정보처리 동의(필수)</el-checkbox>
            <span class="ml-2 hand" @click="$changeRoute('privacyPolicy', 'new')">보기</span>

          </el-col>
        </el-row>
        <!-- 회원가입버튼 -->
        <div class="mt-5">
          <button class="btn btn-danger w100p lh40" type="button" v-if="agreeConfig[0].agree && agreeConfig[1].agree"
            @click="registerUserInfo()">회원가입</button>
        </div>





        <el-row>
          <el-col>
          </el-col>

        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import goHome from './component-gohome.vue'

export default {
  components: {
    goHome
  },
  data() {
    return {
      mallConfigInfo: '',
      subAccount: false,
      imageUrl: null,
      isFocused: {
        license: false,
        startDate: false,
        name: false,
        ceo: false,
        postMain: false,
        addressMain: false,
        addressSub: false,
        tel: false,
        fax: false,
        mail: false,
        phone: false,
        memberName: false,
        id: false,
        pw: false,
        rpw: false,
        securityNum: false,
        valiSecurityNumber: false,
      },
      formData: {
        license: '',
        startDate: '',
        name: '',
        ceo: '',
        postMain: null,
        addressMain: null,
        addressSub: '',
        tel: null,
        fax: null,
        mail: '',
        phone: '',
        memberName: '',
        id: '',
        pw: '',
        rpw: '',
        securityNum: null,
        valiSecurityNumber: null,
      },
      tmpSecurityNumber: null,
      ckLicense: false,
      agreeConfig: [
        {
          id: 1,
          title: `<${this.$MallCompanyName}>이용약관`,
          content: this.pilicyContent1(),
          agree: false,
        },
        {
          id: 2,
          title: `<${this.$MallCompanyName}> 개인정보처리방침`,
          content: this.pilicyContent2(),
          agree: false,
        }
      ],
    };
  },
  methods: {
    onFocus(field) {
      this.isFocused[field] = true;
    },
    onBlur(field) {
      if (!this[field] && !this.formData[field]) {
        this.isFocused[field] = false;
      }
    },
    loadDaumPostcodeScript() {
      const script = document.createElement("script");
      script.src =
        "//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";
      script.async = true;
      script.onload = () => {
        this.initializeDaumPostcode();
      };
      document.head.appendChild(script);
    },
    initializeDaumPostcode() {
      const daum = null
      new (window).daum.Postcode({
        oncomplete: (data) => {
          // data에 선택한 주소 정보가 들어 있음
          this.formData.postMain = data.zonecode
          this.formData.addressMain = data.address
        },
      }).open();
    },
    newValiSecretNumber() {
      this.tmpSecurityNumber = this.$createSecuryNumber()
    },
    async checklicense() {
      if (!this.formData.license || this.formData.license.toString().trim() == '') {
        alert('사업자등록번호를 입력하세요.'); return;
      }
      if (!this.formData.startDate || this.formData.startDate.toString().trim() == '') {
        alert('개업연월일을 입력하세요.'); return;
      }
      if (!this.formData.ceo || this.formData.ceo.toString().trim() == '') {
        alert('대표를 입력하세요.'); return;
      }
      try {
        var data = {
          businesses:
          {
            b_no: this.formData.license, // 사업자등록번호 4478602481
            start_dt: this.formData.startDate,   // 영업개시일 20211101
            p_nm: this.formData.ceo  // 대표자이름 YAN GUANGRI
          }
        }
        const res = await this.$http.post(this.$serverurl + '/api/businessmanValidate', data);
        switch (res.data.code) {
          case 200: {
            this.ckLicense = true
            alert(res.data.message)
            break
          }
          case 202: {
            this.subAccount = true
            break
          }
          default: {
            this.ckLicense = false
            alert(res.data.message)
            break
          }
        }
      } catch (error) {
        // console.error('error:', error);
      }
    },
    // --------------------------------------------------
    // -- action
    // --------------------------------------------------

    pilicyContent1() {
      let tmpvalue = this.$policiesService.replace(/\n/g, "<br>")
      tmpvalue = tmpvalue.replace(/xxxcompanyname/g, this.$회사이름한글)
      tmpvalue = tmpvalue.replace(/xxxmalldomain/g, this.$도메인)
      tmpvalue = tmpvalue.replace(/xxxcompanytel/g, this.$회사전화)
      tmpvalue = tmpvalue.replace(/xxxcompanymail/g, this.$회사메일)
      tmpvalue = tmpvalue.replace(/xxx개인정보보호책임자/g, this.$개인정보보호책임자)
      tmpvalue = tmpvalue.replace(/xxx사이트사용문의/g, this.$사이트사용문의)
      tmpvalue = tmpvalue.replace(/xxx개인정보보호책임자이메일/g, this.$개인정보보호책임자이메일)
      return tmpvalue
    },
    pilicyContent2() {
      let tmpvalue = this.$privacyPolist.replace(/\n/g, "<br>")
      tmpvalue = tmpvalue.replace(/xxxcompanyname/g, this.$회사이름한글)
      tmpvalue = tmpvalue.replace(/xxxmalldomain/g, this.$도메인)
      tmpvalue = tmpvalue.replace(/xxxcompanymail/g, this.$회사메일)
      tmpvalue = tmpvalue.replace(/xxxcompanytel/g, this.$회사전화)
      tmpvalue = tmpvalue.replace(/xxx개인정보보호책임자/g, this.$개인정보보호책임자)
      tmpvalue = tmpvalue.replace(/xxx사이트사용문의/g, this.$사이트사용문의)
      tmpvalue = tmpvalue.replace(/xxx개인정보보호책임자이메일/g, this.$개인정보보호책임자이메일)
      return tmpvalue
    },
    async registerUserInfo() {
      if (this.subAccount) {
        this.registerSubUserInfo()
        return
      }
      if (!this.ckLicense) {
        alert('사업장 검증을 진행하세요.')
        return false
      }
      if (this.formData.valiSecurityNumber != this.tmpSecurityNumber) {
        alert('검증번호가 일치하지 않습니다.')
        return false
      }
      if (this.agreeConfig[this.agreeTypes | 0].agree == false && this.agreeConfig[this.agreeTypes | 0].id == 1) {
        alert('고객이용약관에 동의하여 주세요.')
        return
      }
      if (this.agreeConfig[this.agreeTypes | 1].agree == false && this.agreeConfig[this.agreeTypes | 1].id == 2) {
        alert('개인정보보호처리에 동의하여 주세요.')
        return
      }
      try {
        const transData = {
          license: this.formData.license,
          startDate: this.formData.startDate,
          name: this.formData.name,
          ceo: this.formData.ceo,
          postMain: this.formData.postMain,
          addressMain: this.formData.addressMain,
          addressSub: this.formData.addressSub,
          tel: this.formData.tel,
          fax: this.formData.fax,
          mail: this.formData.mail,
          phone: this.formData.phone,
          mallMemberInfo: {
            name: this.formData.ceo,
            phone: this.formData.memberPhone,
            mail: null,
            id: this.formData.id,
            pw: this.formData.pw,
            rpw: this.formData.rpw,
          }
        }
        const response = await this.$http.post(this.$serverurl + '/api/JoinShop', transData)
        const res = JSON.parse(JSON.stringify(response))
        if (res.data.code == 201) {
          alert(res.data.message)
          this.$router.push('/login');
        } else {
          if (res.data.message == '이미 등록된 회사입니다.') {
            this.subAccount = true
          } else {
            alert(res.data.message)
          }
          // Vue.notify({ type: 'info', text: res.data.message })
        }
      } catch (error) {
        console.error('Error sending Mall User Info:', error);
        // throw error;
      }
    },
    async registerSubUserInfo() {
      this.formData.name = this.formData.memberName
      if (this.formData.valiSecurityNumber != this.tmpSecurityNumber) {
        alert('검증번호가 일치하지 않습니다.')
        return false
      }
      if (this.agreeConfig[this.agreeTypes | 0].agree == false && this.agreeConfig[this.agreeTypes | 0].id == 1) {
        alert('고객이용약관에 동의하여 주세요.')
        return
      }
      if (this.agreeConfig[this.agreeTypes | 1].agree == false && this.agreeConfig[this.agreeTypes | 1].id == 2) {
        alert('개인정보보호처리에 동의하여 주세요.')
        return
      }
      if (this.formData.pw != this.formData.rpw) {
        alert('비밀번호가 일치하지 않습니다.')
        return;
      }
      try {
        const response = await this.$http.post(this.$serverurl + '/api/JoinShopSub', this.formData)
        const res = JSON.parse(JSON.stringify(response))
        if (res.data.code == 201) {
          alert('회원가입이 반영되었습니다.')
          this.$router.push('/login');
        } else {
          alert(res.data.message)
        }
      } catch (error) {
        console.error('Error sending Mall User Info:', error);
      }
    },
    async getMallConfig() {
      const response = await this.$http.get(this.$serverurl + '/api/getMallConfigInfoAd')
      if(response.data.licenseFreeDays){
        this.mallConfigInfo = `신규가입 EPC ${response.data.licenseFreeDays}일 무료사용!!!`
      }
    },
  },

  beforeDestroy() {
  },
  async created() {

    this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$cookies.get('token');
    this.tmpSecurityNumber = this.$createSecuryNumber()

    this.getMallConfig()
  }
}
</script>

<style></style>
