<template>
  <div class="container mt-3 relative noselect" style="max-width: 1300px !important;">
    <TopBar />
    <TopMenu />
    <el-row :gutter="15">
      <el-col :xl="5" :xs="24" :sm="5">
        <centermenu />
      </el-col>
      <el-col :xl="24" :xs="24" :sm="24">
        <template>
          <div v-if="!tmpTableData">
            <div class="font15 borderbottom1px">
              앗! 구매한 상품이 없습니다.
            </div>
            <div class="mt-3">
              <el-row :gutter="10">
                <el-col :xl="12" :xs="12" :md="12" :sm="12" @click.native="readRecommand()" class="font15 bold">
                  이런상품 좋아요.
                </el-col>
                <el-col :xl="12" :xs="12" :md="12" :sm="12" @click.native="readRecommand()">
                  <span class="hand fixedTooltip-link" style="right:10px;">다른상품 보기</span>
                </el-col>
              </el-row>
            </div>
            <el-row :gutter="10">
              <el-col :xl="4" :xs="8" :md="4" :sm="8" v-for="(item, index) in recommandProducts" :key="index"
                @click.native="$router.push('/productView/' + item.seq)" class="carousel-container ">
                <productcard :data="item" />
              </el-col>
            </el-row>
          </div>
          <!-- 구매상품리스트 PC -->
          <el-row class="borderbox mt-2 p-2">
            <el-col class="font15 bold borderbottom3px-orange pb-2">
              구매한 상품
            </el-col>
            <el-col class="mt-3 font13">
              <el-row>
                <el-col :xl="6" :xs="24" :sm="6">
                  <div class="relative" style="right:0px;">
                    <el-input size="mini" type="text" placeholder="검색어를 입력하세요."
                      style="margin: 0px !important; padding: 0px !important;" v-model="searchKeyword"
                      @keyup.enter.native="read()"></el-input>
                    <button class="searchButton absolute" style="top:0px;right:0px;" @click="read()">
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </el-col>
                <el-col :xl="18" :xs="24" :sm="18" class="text-right">
                  <!-- 구간검색 -->
                  <datearea @callback="dateAreaCallback" />
                </el-col>
              </el-row>
              <el-table size="mini" :data="tmpTableData && tmpTableData" :border="true" style="width: 100%"
                class="mt-3">
                <el-table-column label="거래날자" width="110" :align="'center'">
                  <template slot-scope="scope">
                    <div>
                      {{ $YYYYMMDD(scope.row.updated) }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="구분" width="70" :align="'center'">
                  <template slot-scope="scope">
                    <span class="bigbadge-small bigbadge-light"> {{ scope.row.status < 3 ? '구매' : '취소' }} </span>
                  </template>
                </el-table-column>
                <el-table-column label="상품" min-width="250">
                  <template slot-scope="scope">
                    <div class="nowrap" :class="scope.row.retrungoodsStatus == 2 ? 'delline colorgraylight' : ''">{{
                      $moreIfo(scope.row.mallOrderProductInfo, 'salesProductInfo', 'name') }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="금액" width="100" :align="'right'">
                  <template slot-scope="scope">
                    <div class="nowrap" :class="scope.row.retrungoodsStatus == 2 ? 'delline colorgraylight' : ''">
                      {{ scope.row.status >= 3 ? '-' : '' }}
                      {{ $totalInTax(sum(scope.row.mallOrderProductInfo, 'qty', 'price'), 1, true) }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="배송결제" width="80" :align="'center'">
                  <template slot-scope="scope">
                    <div class="nowrap" :class="scope.row.retrungoodsStatus == 2 ? 'delline colorgraylight' : ''">
                      {{ $shippingPayType[scope.row.shippingPayType].label }}
                    </div>
                  </template>
                </el-table-column>
                <!-- <el-table-column label="배송비용" width="80" :align="'right'">
                  <template slot-scope="scope">
                    <div class="nowrap" :class="scope.row.retrungoodsStatus == 2 ? 'delline colorgraylight' : ''">
                      <div v-if="scope.row.oldId && scope.row.oldOrderInfo.status == 2">
                        -
                      </div>
                      <div v-if="!scope.row.oldId || scope.row.oldOrderInfo.status<2">
                        {{ scope.row.status >= 3 ? '-' : '' }}
                        {{ $totalInTax(scope.row.shipping, 1, true) }}
                      </div>
                    </div>
                  </template>
                </el-table-column> -->
                <el-table-column label="거래상태" width="130" :align="'center'" class-name="">
                  <template slot-scope="scope">
                    <div :class="scope.row.retrungoodsStatus == 2 ? 'delline colorgraylight' : ''">
                      {{ $orderProcess[scope.row.status].label }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="배송상태" width="130" :align="'center'">
                  <template slot-scope="scope">
                    <div v-if="scope.row.barcode && scope.row.deliveryInfo">
                      <span class="bigbadge-small bigbadge-light">
                        {{ scope.row.deliveryInfo.state.text }}
                      </span>
                    </div>
                    <span v-else-if="scope.row.reasonType == null" class="bigbadge-small bigbadge-light">준비중</span>
                    <span v-else-if="scope.row.reasonType != null" class="bigbadge-small bigbadge-light">-</span>
                  </template>
                </el-table-column>
                <el-table-column label="출력" width="70" :align="'center'" class-name="">
                  <template slot-scope="scope">
                    <div>
                      <div @click="printView(scope.row)" v-if="scope.row.status < 3">
                        <span class="bigbadge-small bigbadge-blue hand">
                          출력
                        </span>
                      </div>
                      <div v-else>
                        <span class="bigbadge-small bigbadge-light">
                          출력
                        </span>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="상세" width="70" :align="'center'" class-name="">
                  <template slot-scope="scope">
                    <div>
                      <div @click="$changeRoute('orders/ordersView/' + scope.row.sn)">
                        <span class="bigbadge-small bigbadge-blue hand">
                          보기
                        </span>
                      </div>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <div class="text-right mt-2">
                <el-pagination background layout="prev, pager, next" :total="totalCount" :page-size="req.size"
                  @current-change="handlePageChange">
                </el-pagination>
              </div>
            </el-col>
          </el-row>
        </template>
      </el-col>
    </el-row>
    <!-- <json-viewer :value="tmpTableData" :expand-depth=1 copyable boxed sort class="mt-3"></json-viewer> -->

    <FootBar />
    <el-backtop></el-backtop>
  </div>
</template>

<script>
import alert from './component-alert.vue'
import TopBar from './component-top.vue'
import TopBanner from './component-top-banner.vue'
import TopNotice from './component-top-notice.vue'
import TopMenu from './component-top-menu.vue'
import FootBar from './component-foot.vue'
import productcard from './component-product-card.vue'
import productcardbutton from './component-product-card-button.vue'
import shippingaddress from './component-checkout-shipping-address.vue'
import centermenu from './component-cener-left-menu.vue'
import { Loading } from 'element-ui'
import datearea from './component-date-area.vue'
export default {
  components: {
    TopBar,
    FootBar,
    TopBanner,
    TopNotice,
    TopMenu,
    Loading,
    productcard,
    productcardbutton,
    alert, shippingaddress, centermenu, datearea,
  },
  data() {
    return {
      recommandProducts: [],
      showShippingAddressValue: false,
      showAlertValue: false,
      delmessage: '해당항목이 곧 삭제됩니다.<br/>계속하시겠습니까?',
      delItems: {},
      cartInfo: {},
      loginUserInfo: null,
      tmpTableData: [],
      totalCount: 0,
      totalAmount: 0,
      totalQty: 0,
      totalType: 0,
      changeFromInfo: false, // 발송자정보변경스위치
      policyAgree: false, // 구매약관동의스위치
      formData: {
        fromName: null,  // 발송자이름
        fromPhone: null,    // 발송자연락처
        postNum: null,
        address: null,
        addrSub: null,
        name: null,
        phone: null,
        shipping: 0,//배송요금
        shippingValue: 1, // 배송사 선택값
        arrive: null,//화물영업소지점
        barcode: null, // 운송번호
        memo: null, // 배송메모

        shippingPayType: 0, // 배송요금입력판단값
      },
      formOrderData: [],
      notBuylist: [],
      searchKeyword: null,
      req: {
        objSearchMapper: {
          startDate: null,
          endDate: null,
          searchKeyword: null,
        },
        page: 1,
        size: 10
      }
    }
  },
  beforeDestroy() {
  },

  async created() {
    window.scrollTo(0, 0);
    this.loading = Loading.service({ fullscreen: true })
    this.$loginUserInfo = JSON.parse(localStorage.getItem('loginUserInfo'))
    this.$http.defaults.headers.common['exjwtauthorization'] = this.$loginUserInfo?.token
    await this.getMallOrderInfo()
  },

  mounted() {
  },
  methods: {

    printView(item) {
      const routerUrl = this.$router.resolve({
        name: 'transaction',
        params:item.seq
      })
      localStorage.setItem('printInfo', JSON.stringify(item))
      window.open(routerUrl.href, '거래명세서', 'width=800,height=700')
    },

    // 합계  c += a*b
    sum(item, field1, field2) {
      if (field1 && field2) {
        return item.reduce((total, item) => {
          if (item.returnGoodsInfo && item.returnGoodsInfo.status < 3 || !item.returnGoodsInfo) {
            return total + (item[field1] * item[field2])
          } else { return total }
        }, 0);
      } else {
        return item.reduce((total, item) =>
          total + item[field1], 0);
      }

    },

    showAlert(data) {
      this.delItems = data
      this.showAlertValue = true
    },

    async calctoal(data) {
      this.totalAmount = 0
      this.formOrderData.forEach(item => {
        this.totalAmount += item.qty * item.price
      })
      await this.$http.post(`${this.$serverurl}/api/addToCart`, data)
        .then(res => {
          setTimeout(() => {
            this.read()
          }, 300);
        })

    },
    handlePageChange(page) {
      this.req.page = page
      this.getMallOrderInfo()
    },
    dateAreaCallback(data) { // 구간검색함수
      this.req.objSearchMapper.startDate = data.startDate
      this.req.objSearchMapper.endDate = data.endDate
      this.req.page = 1
      this.getMallOrderInfo()
    },
    async read() {
      this.req.objSearchMapper.startDate = null
      this.req.objSearchMapper.endDate = null
      this.req.objSearchMapper.searchKeyword = this.searchKeyword
      this.req.page = 1
      await this.getMallOrderInfo()
    },
    async getMallOrderInfo() {
      await this.$http.post(`${this.$serverurl}/api/getMallOrderInfo`, this.req)
        .then(res => {
          if (200 == res.data.code) {
            this.tmpTableData = res.data.body.results
            this.tmpTableData.sort((a, b) => {
              return new Date(a.created) - new Date(b.created);
            });
            this.totalCount = res.data.totalCount
            this.totalAmount = 0
            this.totalQty = 0
            this.totalType = 0
            this.notBuylist = []
            for (var orderitems of this.tmpTableData) {
              let 임시반품정보수 = 0
              for (var item of orderitems.mallOrderProductInfo) {
                if (item.returnGoodsInfo) { 임시반품정보수 += 1 }
                if (item.buy != 9) {
                  this.totalAmount += item.qty * item.price
                  this.totalQty += item.qty
                  this.totalType += 1
                }
              }
              const tmpLength = orderitems.mallOrderProductInfo.length
              if (임시반품정보수 == tmpLength) {
                orderitems.retrungoodsStatus = 2
              } else if (임시반품정보수 > 0 && 임시반품정보수 < tmpLength) {
                orderitems.retrungoodsStatus = 1
              } else {
                orderitems.retrungoodsStatus = 0
              }
            }

          } else {
            if (!res?.data?.body?.results?.length) {
              // this.$notify.error({
              //   title: '',
              //   message: res.data.message,
              //   position: 'bottom-right',
              //   showClose: false
              // });
              this.tmpTableData = []
            }
          }

          this.$overay(this.loading)
        })
    },
    setbackground(item) {
      if (item && item.images && item.images.length > 0) {
        return {
          background: `url(${this.$imageurl}${item.images[0]}) no-repeat center center / cover !important`
        };
      } else {
        return {
          background: `url('${this.$imageurl}/upload/no-image.webp') no-repeat center center / cover`
        };
      }
    },

  }

}
</script>