<template>
  <div class="container relative noselect" style="max-width: 1300px !important;">
    <TopBar />
    <TopMenu />
    <el-row :gutter="15">
      <el-col :xl="5" :xs="24" :sm="5">
        <centermenu />
      </el-col>
      <el-col :xl="24" :xs="24" :sm="24">
        <template>
          <!-- 반품요청정보 PC -->
          <div class="borderbox mt-2 p-2">
            <el-row class="font15 pb-2">
              <el-col :xl="12" :md="12" :xs="12" class="font15 bold borderbottom3px-orange mb-2 lh40">
                {{ returnGoodsInfo.salesProductInfo.name }}
              </el-col>
              <el-col :xl="12" :md="12" :xs="12" class="borderbottom3px-orange lh40 text-right">
                <span class="badge badge-darklight font13 hand" @click="$router.go(-1)" style="font-weight: normal;">
                  <i class="el-icon-back mr-1 bold"></i>
                  돌아가기</span>
              </el-col>
            </el-row>
            <!-- 반품/교환신청 -->
            <div v-if="returnGoodsInfo.status < 3" class="font15">
              <el-row class="mt-2">
                <el-col :xl="4" :xs="7" :md="4">
                  신청유형
                </el-col>
                <el-col :xl="20" :xs="17" :md="20">
                  <el-radio-group size="mini" v-model="formData.reasonType">
                    <el-radio-button type="danger" v-for="item in $reasonType.filter(item => !item.hidden)"
                      :key="item.value" :label="item.value" :value="item.value">
                      {{ item.label }}
                    </el-radio-button>
                  </el-radio-group>
                </el-col>
              </el-row>
              <el-row class="mt-2">
                <el-col :xl="4" :xs="7" :md="4">
                  제품이름
                </el-col>
                <el-col :xl="20" :xs="17" :md="20">
                  {{ returnGoodsInfo.salesProductInfo.name }}
                </el-col>
              </el-row>
              <el-row class="mt-3">
                <el-col :xl="4" :xs="7" :md="4">
                  수량
                </el-col>
                <el-col :xl="20" :xs="17" :md="20">
                  <el-input-number v-model="formData.orderInfo.returnQty" :min="1" :max="returnGoodsInfo.qty" :step="1"
                    size="mini" readonly class="font15"></el-input-number>
                </el-col>
              </el-row>
              <el-row class="mt-3">
                <el-col :xl="4" :xs="7" :md="4">
                  픽업주소
                </el-col>
                <el-col :xl="20" :xs="17" :md="20">
                  <el-row :gutter="10" class=" font15 pb-2">
                    <el-col :xl="24" :xs="24" :sm="24" class="mb-2">
                      <el-row :gutter="10">
                        <el-col :xl="5" :xs="24" :sm="5">
                          <el-input placeholder="우편번호" size="mini" prefix-icon="el-icon-search"
                            v-model="formData.postNum" class="hand" readonly @click.native="loadDaumPostcodeScript">
                          </el-input>
                        </el-col>
                        <el-col :xl="10" :xs="24" :sm="10" class="mobile-mt15">
                          <el-input placeholder="주소" size="mini" v-model="formData.address" readonly>
                          </el-input>
                        </el-col>
                        <el-col :xl="9" :xs="24" :sm="9" class="mobile-mt15">
                          <el-input placeholder="세부주소를 입력하세요." size="mini" v-model="formData.addrSub">
                          </el-input>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
              <el-row class="mb-2">
                <el-col :xl="4" :xs="7" :md="4" class="mobile-mt15">
                  연락처
                </el-col>
                <el-col :xl="20" :xs="17" :md="20">
                  <el-row :gutter="10">
                    <el-col :xl="5" :xs="24" :sm="5" class="mobile-mt15">
                      <el-input placeholder="수추인" size="mini" v-model="formData.name">
                      </el-input>
                    </el-col>
                    <el-col :xl="19" :xs="24" :sm="19" class="mobile-mt15">
                      <el-input placeholder="연락처" size="mini" v-model="formData.phone">
                      </el-input>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
              <el-row class="mt-3">
                <el-col :xl="4" :xs="7" :md="4">
                  사유구분
                </el-col>
                <el-col :xl="20" :xs="17" :md="20">
                  <el-select size="mini" v-model="formData.reasonSubType" placeholder="사유구분">
                    <el-option v-for="item in $reasonSubType" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-col>
              </el-row>
              <el-row class="mt-3">
                <el-col :xl="4" :xs="7" :md="4">
                  요청사유
                </el-col>
                <el-col :xl="20" :xs="17" :md="20">
                  <el-input type="textarea" show-word-limit minlength="10" maxlength="200"
                    :autosize="{ minRows: 2, maxRows: 4 }" placeholder="교환/반품 사유를 적어주세요." v-model="formData.detailInfo">
                  </el-input>
                </el-col>
              </el-row>
              <el-row class="mt-3">
                <el-col :xl="4" :xs="7" :md="4">
                  유의사항
                </el-col>
                <el-col :xl="20" :xs="17" :md="20">
                  <div v-html="$toHtml($반품교환유의사항)">
                  </div>
                </el-col>
              </el-row>
              <el-row class="mt-3">
                <el-col :xl="4" :xs="7" :md="4">
                  처리불가 안내
                </el-col>
                <el-col :xl="20" :xs="17" :md="20">
                  <div v-html="$toHtml($반품불가사전안내)">
                  </div>
                </el-col>
              </el-row>
              <el-row class="mt-3">
                <el-col :xl="4" :xs="7" :md="4">
                  사전확인
                </el-col>
                <el-col :xl="20" :xs="17" :md="20">
                  <el-checkbox class="ml-2 colorred bold" v-model="policyAgree" @change="policyAgreeChange">
                    <span class="font18">위의 사항을 확인하였으며 계속 합니다.</span>
                  </el-checkbox>
                </el-col>
              </el-row>
              <div class="mt-3">
                <el-button type="danger" size="small" icon="el-icon-success" class="el-btn-orange font13 w100p"
                  @click="returnGoodsRequest()" v-if="policyAgree">
                  {{ $reasonType[formData.reasonType].label }}신청 완료하기
                </el-button>
              </div>
            </div>
            <!-- 반품/교환 정보 -->
            <div v-if="returnGoodsInfo.status > 2" class="font15">
              <el-row class="mt-2">
                <el-col :xl="4" :xs="7" :md="4">
                  신청유형
                </el-col>
                <el-col :xl="20" :xs="17" :md="20">
                  {{ $reasonType[returnGoodsInfo.reasonType].label }}
                </el-col>
              </el-row>
              <el-row class="mt-2">
                <el-col :xl="4" :xs="7" :md="4">
                  요청날자
                </el-col>
                <el-col :xl="20" :xs="17" :md="20">
                  {{ $YYYYMMDD(returnGoodsInfo.updated) }}
                </el-col>
              </el-row>
              <el-row class="mt-2 borderbottom1px pb-2">
                <el-col :xl="4" :xs="7" :md="4">
                  처리상태
                </el-col>
                <el-col :xl="20" :xs="17" :md="20">
                  <span>
                    {{ $orderProcess[returnGoodsInfo.status].label }}
                  </span>
                </el-col>
              </el-row>
              <el-row class="mt-2">
                <el-col :xl="4" :xs="7" :md="4">
                  제품이름
                </el-col>
                <el-col :xl="20" :xs="17" :md="20">
                  {{ getItem(returnGoodsInfo, 'salesProductInfo', 'name') }}
                </el-col>
              </el-row>
              <el-row class="mt-3">
                <el-col :xl="4" :xs="7" :md="4">
                  구매정보
                </el-col>
                <el-col :xl="20" :xs="17" :md="20" class="listSplit">
                  <span>{{ returnGoodsInfo.qty }}개</span>
                  <span>×</span>
                  <span>{{ $totalInTax(returnGoodsInfo.price, 1, true) }}원</span>
                </el-col>
              </el-row>
              <el-row class="mt-3 borderbottom1px pb-2">
                <el-col :xl="4" :xs="7" :md="4">
                  반품수량
                </el-col>
                <el-col :xl="20" :xs="17" :md="20">
                  {{ returnGoodsInfo.qty }}개
                </el-col>
              </el-row>
              <el-row class="mt-3">
                <el-col :xl="4" :xs="7" :md="4">
                  픽업주소
                </el-col>
                <el-col :xl="20" :xs="17" :md="20">
                  <el-row :gutter="10" class=" font15 pb-2">
                    <el-col :xl="24" :xs="24" :sm="24" class="mb-2">
                      <el-row :gutter="10">
                        <el-col class="listSplit">
                          <span>
                            {{ returnGoodsInfo.orderInfo.postNum }}
                          </span>
                          <span>
                            {{ returnGoodsInfo.orderInfo.address }}
                          </span>
                          <span>
                            {{ returnGoodsInfo.orderInfo.addrSub }}
                          </span>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
              <el-row class="mb-2">
                <el-col :xl="4" :xs="7" :md="4" class="mobile-mt15">
                  연락처
                </el-col>
                <el-col :xl="20" :xs="17" :md="20">
                  <el-row :gutter="10">
                    <el-col :xl="24" :xs="24" :sm="24" class="listSplit mobile-mt15">
                      <span>{{ returnGoodsInfo.orderInfo.name }}</span>
                      <span>{{ returnGoodsInfo.orderInfo.phone }}</span>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
              <el-row class="mt-3" v-if="returnGoodsInfo.status != 8">
                <el-col :xl="4" :xs="7" :md="4">
                  사유구분
                </el-col>
                <el-col :xl="20" :xs="17" :md="20">
                  {{ getlistname($reasonSubType, returnGoodsInfo.orderInfo.reasonSubType) }}
                </el-col>
              </el-row>
              <el-row class="mt-3" v-if="returnGoodsInfo.status != 8">
                <el-col :xl="4" :xs="7" :md="4">
                  세부정보
                </el-col>
                <el-col :xl="20" :xs="17" :md="20">
                  {{ returnGoodsInfo.orderInfo.detailInfo }}
                </el-col>
              </el-row>
            </div>
          </div>
          <!-- <json-viewer :value="returnGoodsInfo" :expand-depth=1 copyable boxed sort class="mt-3"></json-viewer> -->
        </template>
      </el-col>
    </el-row>
    <FootBar />
    <el-backtop>
    </el-backtop>
  </div>
</template>

<script>
import alert from './component-alert.vue'
import TopBar from './component-top.vue'
import TopBanner from './component-top-banner.vue'
import TopNotice from './component-top-notice.vue'
import TopMenu from './component-top-menu.vue'
import FootBar from './component-foot.vue'
import productcard from './component-product-card.vue'
import productcardbutton from './component-product-card-button.vue'
import shippingaddress from './component-checkout-shipping-address.vue'
import centermenu from './component-cener-left-menu.vue'
import { Loading } from 'element-ui'
export default {
  components: {
    TopBar,
    FootBar,
    TopBanner,
    TopNotice,
    TopMenu,
    Loading,
    productcard,
    productcardbutton,
    alert, shippingaddress, centermenu,
  },
  data() {
    return {
      // tmpTableData: null,
      policyAgree: false,
      returnGoodsInfo: {},
      totalAmount: 0,
      totalQty: 0,
      totalType: 0,
      req: {
        objSearchMapper: {
          searchKeyword: null,
          orderItemId: null,
        },
        page: 1,
        size: 1
      },
      formData: {
        reasonSubType: 0, // 교환 혹은 반품
        reasonType: 0, // 사유
        name: '',
        postNum: '',
        address: '',
        addrSub: '',
        phone: '',
        detailInfo: '', // 세부사유
        orderInfo: {
          returnQty: 0,
          buyQty: 0
        }

      },
    }
  },
  beforeDestroy() {
  },

  async created() {
    window.scrollTo(0, 0);
    this.loading = Loading.service({ fullscreen: true })
    this.$loginUserInfo = JSON.parse(localStorage.getItem('loginUserInfo'))
    this.$http.defaults.headers.common['exjwtauthorization'] = this.$loginUserInfo?.token
    this.returnGoodsInfo = JSON.parse(localStorage.getItem('returnGoodsRequest'))
    this.formData.name = this.$loginUserInfo.basicShippingInfo.name
    this.formData.phone = this.$loginUserInfo.basicShippingInfo.phone
    this.formData.address = this.$loginUserInfo.basicShippingInfo.address
    this.formData.addrSub = this.$loginUserInfo.basicShippingInfo.addrSub
    this.formData.postNum = this.$loginUserInfo.basicShippingInfo.postNum
    this.formData.orderInfo.returnQty = this.returnGoodsInfo.qty
    this.formData.orderInfo.buyQty = this.returnGoodsInfo.qty


    this.$overay(this.loading)
  },

  mounted() {
  },
  methods: {
    async returnGoodsRequest() {
      this.loading = Loading.service({ fullscreen: true })
      this.formData.orderInfo.orderId = this.returnGoodsInfo.orderId
      this.formData.orderInfo.orderItemId = this.returnGoodsInfo.seq
      this.formData.orderInfo.productId = this.returnGoodsInfo.productId
      this.formData.orderInfo.price = this.returnGoodsInfo.price
      this.formData.orderInfo.salesProductInfo = this.returnGoodsInfo.salesProductInfo
      this.formData.orderInfo.shipping = this.returnGoodsInfo.orderInfo.shipping
      this.formData.orderInfo.shippingPayType = this.returnGoodsInfo.orderInfo.shippingPayType
      this.formData.orderInfo.shippingValue = this.returnGoodsInfo.orderInfo.shippingValue
      await this.$http.post(`${this.$serverurl}/api/returnGoodsRequest`, this.formData)
        .then(res => {
          if (201 == res.data.code) {
            this.$notify.success({
              title: '',
              message: '요청이 반영되었습니다.',
              position: 'bottom-right',
              showClose: false
            });
            this.$router.go(-1)
          }
        })
      this.$overay(this.loading)
    },
    policyAgreeChange(data) {
      if (!this.formData.postNum) {
        this.$notify.error({
          title: '',
          message: '픽업주소를 선택하세요.',
          position: 'bottom-right',
          showClose: false
        });
        this.policyAgree = false
        return
      }
      if (!this.formData.name || !this.formData.phone) {
        this.$notify.error({
          title: '',
          message: '연락처 정보를 입력하세요.',
          position: 'bottom-right',
          showClose: false
        });
        this.policyAgree = false
        return
      }
      if (!this.formData.detailInfo) {
        this.$notify.error({
          title: '',
          message: '사유세부정보를 입력하세요.',
          position: 'bottom-right',
          showClose: false
        });
        this.policyAgree = false
        return
      }
      this.policyAgree = data
    },

    loadDaumPostcodeScript() {
      const script = document.createElement("script");
      script.src =
        "//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";
      script.async = true;
      script.onload = () => {
        this.initializeDaumPostcode();
      };
      document.head.appendChild(script);
    },
    initializeDaumPostcode() {
      const daum = null
      new (window).daum.Postcode({
        oncomplete: (data) => {
          // data에 선택한 주소 정보가 들어 있음
          this.formData.postNum = data.zonecode
          this.formData.address = data.address
        },
      }).open();
    },

    getlistname(list, item) {
      return list[item]?.label
    },
    getItem(item, parent, field) {
      if (parent) {
        return item[parent] && item[parent][field]
      } else {
        return item && item[field]
      }
    }
  }

}
</script>