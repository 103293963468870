<template>
  <div class="relative no select" style="max-width: 100vw !important;">
    <!-- <div style="
    background: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
background-size: cover;background-size: 100% 200%;position: fixed;left: 0;top: 0;height: 50px;width: 100vw;color: #fff;
    " class="font15 bold text-center p-2 topSmallmenu">
      <span @click="$changeRoute('main')">
        메인페이지
      </span>
      <span @click="$changeRoute('epc')">
        EPC차대검색
      </span>
      <span @click="$changeRoute('notice')">
        공지사항
      </span>
    </div> -->

    <div class="video" style="
    background: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
background-size: cover;background-size: 100% 100%;width: 100vw; height:calc(100vh - 70px); color: #fff;
    ">
      <div class="font15 bold text-center p-2 topSmallmenu relative" style="z-index: 9;">
        <span @click="$changeRoute('main')">
          메인페이지
        </span>
        <span @click="$changeRoute('epc')">
          EPC차대검색
        </span>
        <span @click="$changeRoute('notice')">
          공지사항
        </span>
      </div>
      <video autoplay loop muted playsinline
        style="width: 120%; height:calc(100%  - 70px);object-fit: cover;filter: blur(10px);z-index: -10;">
        <source :src="videoSource" type="video/mp4">
        현재 브라우저를 지원하지 않습니다.
      </video>
      <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.9);">
      </div>
      <div class="contentBox penFont bold" style="justify-content: center;">
        <div class="lh40 blur1px" style="color:#fff;" v-html="$toHtml($대표인사말)"></div>
        <div class="lh40 text-right mt-3" style="color:#fff" v-html="'대표이사 엄경평'"></div>
      </div>
    </div>




    <div class="map relative">
      <div class="font15 bold mb-2 mt-3">
        <span class="colorgraylight ml-4">서울특별시 성동구 성수동2가300-78 1층 101호</span>
      </div>
      <div class="block-map block">
        <div class="block-map__body">
          <iframe title="Google Map"
            src="https://maps.google.com/maps?q=성수동2가300-78&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=&amp;output=embed"></iframe>
        </div>
      </div>
    </div>

    <div style="position: fixed;left:0px;bottom:0px;filter: blur(0.8px);
    height:70px;color:#fff;width:100vw;" class="font20 text-center p-2 topSmallmenu">
      https://www.partsalliance.co.kr
    </div>

    <el-backtop>
    </el-backtop>

  </div>
</template>

<script>
import TopBar from './component-top.vue'
import TopBanner from './component-top-banner.vue'
import TopNotice from './component-top-notice.vue'
import TopMenu from './component-top-menu.vue'
import FootBar from './component-foot.vue'
import productcard from './component-product-card.vue'
import productcardbutton from './component-product-card-button.vue'
export default {
  components: {
    TopBar, FootBar, TopBanner, TopNotice, TopMenu, productcard, productcardbutton
  },
  data() {
    return {
      videoSource: "/static/img/bg.mp4",
    };
  },
  beforeDestroy() {
  },
  mounted() {
  },
  async created() {
    window.scrollTo(0, 0);
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
@font-face {
  font-family: 'Handletter';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/naverfont_05@1.0/Handletter.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TAEBAEKmilkyway';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2310@1.0/TAEBAEKmilkyway.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

.penFont {
  font-family: TAEBAEKmilkyway;
}

.penFont * {
  font-family: TAEBAEKmilkyway;
}

.video {
  margin-top: 0px;
  width: 100%;
  height: 400px;
  z-index: -1;
}

@media (max-width: 767px) {
  .contentBox {
    position: absolute;
    top: 50px !important;
    padding: 0px 40px 0px 40px !important;
    width: 100vw;
    font-size: 13px !important;

  }

  // .map {
  //   margin-top:150px;
  //   width: 100% !important;
  //   height: 1000px;
  //   z-index: 99999;
  // }
  .map {
    width: 100vw;
    height: calc(100vh - 70px);
    z-index: 99999;
  }

  .video {
    height: 100vh;
  }

  video {
    object-fit: contain !important;
  }
}



.blur1px {
  filter: blur(0.5px);
  // text-shadow: 0 0 0px #fff;
}

.contentBox {
  position: absolute;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px;
  max-width: 750px;
  max-height: 750px;
  /*border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, .15);*/
  color: #fff;
  z-index: 99;
  font-size: 18px;
}

/*
// .block-map
*/
.block-map {
  margin-bottom: 10px;
}

.block-map__body {
  height: 100vh;
  position: relative;

  &::before {
    position: absolute;
    content: '';
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
    z-index: 1;
    background: #fff;
    pointer-events: none;
  }

  &::after {
    position: absolute;
    content: '';
    display: block;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    z-index: 1;
    background-image: #fff;
    ;
    pointer-events: none;
  }

  iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: none;
    margin: 0;
  }
}


@media (max-width: breakpoint(lg-end)) {
  .block-map__body {
    height: 500px;
  }
}

@media (max-width: breakpoint(md-end)) {
  .block-map__body {
    height: 440px;
  }
}

@media (max-width: breakpoint(sm-end)) {
  .block-map__body {
    height: 380px;
  }
}

@media (max-width: breakpoint(xs-end)) {
  .block-map__body {
    height: 300px;
  }
}
</style>
