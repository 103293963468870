<template>
    <div>
        <el-row justify="left" :gutter="10">
            <el-col :span="24 / 5" v-for="(item, index) in data" :key="index">
                <!-- 서브그룹 존재할때 -->
                <div v-if="checkHasChild(item, 1)" class="category-group-col relative" style="overflow: hidden;"
                    @click="returnCallback(item)">
                    <div ref="topTitle" class="absolute group-img-title-top w100p text-center" style="z-index: 10;">
                        {{ item.name + ' Note: ' + item.description }}
                    </div>
                    <img class="sub-img" :src="getCatetoryImg(item.imgShortener)" />
                    <div class="absolute" v-if="item.colorvalue == 0">
                        <i class="fas fa-ban"></i>
                    </div>
                    <div class="absolute group-img-title text-center" :class="item.colorvalue == 0 ? 'text-red' : ''"
                        style="left:0px;bottom:0px;width:100%;">{{ item.mid }}
                    </div>
                </div>
                <!-- 메인그룹 -->
                <div v-else class="category-group-col relative" @click="returnCallback(item)">
                    <div ref="topTitle" class="absolute group-img-title-top w100p text-center" style="z-index: 10;">
                        <span v-html="$convert_br(item.name)"></span>
                        <div v-if="item.description" v-html="'Note:' + item.description">
                        </div>
                    </div>
                    <img class="sub-img" :src="getCatetoryImg(item.imgShortener)" />
                    <div class="absolute" style="top:0px;left:5px;" v-if="item.colorvalue == 0">
                        <i class="fas fa-ban colorred"></i>
                    </div>
                    <div class="absolute group-img-title text-center" style="left:0px;bottom:0px;width:100%;">
                        {{ item.mid }}
                    </div>
                </div>
            </el-col>
        </el-row>
        <span v-if="!data.length">로딩중입니다...</span>
    </div>
</template>

<script>

export default {
    name: 'partsgroup1',
    components: {},
    data() {
        return {
            getCarsStructureList: [],
            showlist: false,
            isShow: true,
        }
    },
    async created() { },
    props: ['data'],
    watch: {
        data: {
            deep: true,
            immediate: true,
            async handler(newValue, oldValue) { }
        }
    },
    methods: {
        async returnCallback(item) {
            this.$emit('callback', item)
        },
        checkHasChild(item, type) {
            if (item?.has_child == type) {
                return true
            }
            return false
        },

        getCatetoryImg(params) {
            if (!params) {
                return 'images/no-image.webp'
            }
            return `${params}`
        },
    },

}

</script>

<style scoped>
.category-group-col {
    overflow: hidden;
    position: relative;
    margin: 0px;
    padding: 0px;
    border: 1px #ddd solid;
    border-radius: 2px;
    width: 136px;
    height: 136px;
    cursor: pointer;
    margin-right: 10px;
    margin-bottom: 10px;
    background: no-repeat;
}

.category-group-col .group-img-title-top {
    display: none
}

.category-group-col:hover {
    background-color: #999 !important;
}

.category-group-col:hover .group-img-title-top {
    display: block;
}

.sub-img {
    width: 100%;
    height: 100%;
    opacity: 0.8;
}

.group-img-title {
    overflow-wrap: break-word;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 13px;
    color: #000;
    background-color: rgba(0, 0, 5, 0.0);
}

.group-img-title-top {
    padding-left: 5px;
    padding-right: 5px;
    font-size: 13px;
    color: #fff;
    background-color: rgba(0, 0, 5, 0.8) !important;
}
</style>