<template>
  <div class="container mt-3 relative noselect" style="max-width: 1300px !important;">
    <TopBar />
    <TopMenu />
    <!-- <TopBanner /> -->
    <!-- <TopNotice /> -->
    <el-row :gutter="15">
      <el-col :xl="5" :xs="24" :sm="5">
        <centermenu />
      </el-col>
      <!-- 정보변경 -->
      <el-col :xl="24" :xs="24" :sm="24">
        <el-row class="borderbox mt-2 p-2">
          <el-col class="borderbottom1px lh40 relative">
            <span class="font18 bold">
              회원탈퇴
            </span>
            <!-- <span class="absolute hand fixedTooltip-link" style="top:5px;right:5px;" @click="changePassword = true">
              비밀번호변경
            </span> -->
          </el-col>
          <el-col class="relative mt-2">
            <table class="table-params w100p">
              <tbody>
                <tr>
                  <th style="width: 100px;">회사이름</th>
                  <td style="width: 200px;">{{ $loginUserInfo.companyInfo.name }}</td>
                  <th style="width: 100px;">사용자</th>
                  <td>{{ $loginUserInfo.name }}</td>
                </tr>
                <tr>
                  <th style="width: 100px;">계정유형</th>
                  <td style="width: 200px;">{{ $accountType[$loginUserInfo.type].label }}</td>
                  <th style="width: 100px;">계정</th>
                  <td>{{ $toStar($loginUserInfo.id,2) }}</td>
                </tr>
                <tr>
                  <th style="width: 100px;">계정유형</th>
                  <td style="width: 200px;" colspan="3">{{ $YYYYMMDD_HHMM($loginUserInfo.created,'.') }}</td>
                </tr>
              </tbody>
            </table>
            <el-row>
              <el-col>
                <div class="borderbottom1px font15 bold lh40 mt-2">
                  회원탈퇴안내
                </div>
                <div v-html="$toHtml($회원탈퇴안내)" class="pr-3 pl-3 pt-3"></div>
              </el-col>
              <el-col>
                <div class="borderbottom1px font15 bold lh40">
                  탈퇴후 개인정보 보관 안내
                </div>
                <div v-html="$toHtml($개인정보보관안내)" class="pr-3 pl-3 pt-3"></div>
              </el-col>
              <el-col>
                <div class="borderbottom1px font15 bold lh40">
                  주의사항
                </div>
                <div class="pr-3 pl-3 pt-3">
                  메인계정 탈퇴 시 그 부속계정들도 자동으로 탈퇴됩니다.
                </div>
              </el-col>
              <el-col>
                <div class="borderbottom1px font15 bold lh40">
                  회원탈퇴 서약동의 약관
                </div>
                <div v-html="$toHtml($회원탈퇴동의약관)" class="pr-3 pl-3 pt-3"></div>
              </el-col>
              <el-col>
                <div class="borderbottom1px font15 bold lh40">
                  동의자({{ $loginUserInfo.name }}) 서명
                </div>
                <div class="pr-3 pl-3 pt-3">
                  <el-input type="text" show-word-limit minlength="10" maxlength="10"
                    :autosize="{ minRows: 2, maxRows: 4 }" :placeholder="$loginUserInfo.name +'를(을) 입력하세요'" v-model="consenter">
                  </el-input>
                </div>
              </el-col>
              <el-col>
                <div class="borderbottom1px font15 bold lh40">
                 현재비밀번호
                </div>
                <div class="pr-3 pl-3 pt-3">
                  <componentInputText :minlength="5" :maxlength="20" :type="'password'"
                  :size="'small'" :placeholder="'현재비밀번호를 입력하세요.'" v-model="pw" :hiddeinfo="true" />
                </div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row class="mt-2">
          <el-col class="text-right">
            <el-button type="" size="mini" class="el-btn-orange" @click="WithdrawMember()" v-if="consenter==$loginUserInfo.name && pw.trim()">
              <i class="el-icon-success"></i>
              정보제출</el-button>
              <el-button type="" size="mini" class="el-btn-orange" disabled
              v-if="consenter!=$loginUserInfo.name || !pw.trim()">
              <i class="el-icon-success"></i>
              정보제출</el-button>
          </el-col>
        </el-row>
      </el-col>
      
    </el-row>
    <FootBar />
    <el-backtop>
    </el-backtop>
  </div>
</template>
<script>
import componentInputText from './component-input-text.vue';

import TopBar from './component-top.vue'
import TopBanner from './component-top-banner.vue'
import TopNotice from './component-top-notice.vue'
import TopMenu from './component-top-menu.vue'
import FootBar from './component-foot.vue'
import centermenu from './component-cener-left-menu.vue'
import { Loading } from 'element-ui'
export default {
  components: { centermenu, TopBar, TopBanner, TopNotice, TopMenu, FootBar, Loading,componentInputText },
  data() {
    return {
      loginUserInfo: null,
      loading: null,
      consenter:'',
      pw:'',
    }
  },
  created() {
    window.scrollTo(0, 0);
    this.$loginUserInfo = JSON.parse(localStorage.getItem('loginUserInfo'))
    this.$http.defaults.headers.common['exjwtauthorization'] = this.$loginUserInfo?.token
    this.loading = Loading.service({ fullscreen: true })
    this.$overay(this.loading)
  },
  methods: {
    async WithdrawMember() {
      const formData = { pw: this.pw }
      await this.$http.post(`${this.$serverurl}/api/WithdrawMember`, formData)
        .then(res => {
          if (201 == res.data.code) {
            window.location.href = '/login'
            // this.$changeRoute('login')
          } else {
            this.$notify.error({
              title: '',
              message: res.data.message,
              position: 'bottom-right',
              showClose: true,
              duration: 1000,
            });
          }
        })
      this.$overay(this.loading)
    },
    
  },
}

</script>
<style></style>
