<template>
  <el-breadcrumb separator-class="el-icon-arrow-left" class="mt-3">
    <el-breadcrumb-item></el-breadcrumb-item>
    <el-breadcrumb-item :to="{ path: '/notice' }" class="hand">돌아가기</el-breadcrumb-item>
  </el-breadcrumb>

</template>

<script>

export default {
  name: 'goNotice',
  data() {
    return {

    };
  },
  methods: {
  },
  beforeDestroy() {
  },
  created() {

  }
}
</script>

<style></style>
