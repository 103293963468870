<template>
  <div>
    <input type="date" v-model="startDate" class="mr-2"
      style="border-radius: 5px;border: 1px #EBEEF5 solid;padding:3px;" @change="returnDataforStart">
    ~
    <input type="date" class="ml-2" v-model="endDate" style="border-radius: 5px;border: 1px #EBEEF5 solid;padding:3px;"
      @change="returnDataforEnd">

    <el-button class="ml-2" size="mini" @click="submit">
      검색
    </el-button>

    <!-- 모바일버전 -->
  </div>

</template>

<script>

export default {
  name: 'datearea',
  data() {
    return {
      startDate: '',
      endDate: ''
    };
  },
  beforeDestroy() {
  },
  created() { },
  mounted() {
    // 현재 날짜로 초기화
    const today = new Date().toISOString().substr(0, 10);
    this.startDate = today;
    this.endDate = today;
  },
  methods: {
    returnDataforEnd() {
      // 마감날자가 작을때 
      // const startDate = new Date(this.startDate);
      // const endDate = new Date(this.endDate);
      // if (endDate <= startDate) {
      //   startDate.setDate(endDate.getDate() - 7);
      //   this.startDate = startDate.toISOString().substr(0, 10);
      // }
    },
    returnDataforStart() {
      // const startDate = new Date(this.startDate);
      // const endDate = new Date(this.endDate);
      // const startDate = new Date(this.startDate);
      // const endDate = new Date(this.endDate);
      // if (endDate <= startDate) {
      //   endDate.setDate(startDate.getDate() + 7);
      //   this.endDate = endDate.toISOString().substr(0, 10);
      // }
    },
    submit() {
      const startDate = new Date(this.startDate);
      const endDate = new Date(this.endDate);
      if(startDate>endDate){
        const tmpDate=  JSON.parse(JSON.stringify(this.startDate))
        this.startDate= this.endDate
        this.endDate = tmpDate
      }
      this.$emit('callback', { startDate: this.startDate, endDate: this.endDate })
    }

  }
}
</script>

<style></style>
