
const methods = {

    getLang(word) {
        const temp = this.$wordlist.find(item =>
            this.$removeSpace(item.cn) == this.$removeSpace(word)
        )
        // return word
            if (temp) {
            return temp.kr
        } else {
            return word
        }
    },
    wordlist: [
        { cn: ' 排气系统和部件 ', kr: '배기시스템,부품', en: '', vtn: '' },
        { cn: ' 进气和供油系统 ', kr: '흡기,공급시스템', en: '', vtn: '' },
        { cn: ' 发动机管理/尾气排放系统 ', kr: '엔진관리/배기시스템', en: '', vtn: '' },
        { cn: ' 排气装置 ', kr: '배기장치', en: '', vtn: '' },
        { cn: '配件，信息娱乐系统，其他', kr: '부품,인포테인먼트,기타', en: '', vtn: '' },
        { cn: ' 发动机 ', kr: '엔진', en: '', vtn: '' },
        { cn: ' 燃油，排气，空调冷却 ', kr: '연료,배기,공조냉각', en: '', vtn: '' },
        { cn: '变速箱', kr: '미션', en: '', vtn: '' },
        { cn: '前轴，转向装置', kr: '전축,조향장치', en: '', vtn: '' },
        { cn: '后轴', kr: '뒤축', en: '', vtn: '' },
        { cn: '车轮，制动器', kr: '차륜,브레이크', en: '', vtn: '' },
        { cn: '踏板机构', kr: '엔진페달및관련장치', en: '', vtn: '' },
        { cn: '车身', kr: '차체', en: '', vtn: '' },
        { cn: '电子设备', kr: '전자장치', en: '', vtn: '' },
        { cn: '传动机构部件', kr: '변속기부품', en: '', vtn: '' },
        { cn: '发动机功率电子控制', kr: '엔진동력전자제어', en: '', vtn: '' },
        { cn: '发动机外壳', kr: '엔진케이스', en: '', vtn: '' },
        { cn: ' 燃油喷射装置 ', kr: '연료분사장치', en: '', vtn: '' },
        { cn: ' 动力转向泵，制冷压缩机和  ', kr: '동력스티어링펌프,냉동압축', en: '', vtn: '' },
        { cn: ' 进气管和排气歧管  ', kr: '흡기호스및배기시스템', en: '', vtn: '' },
        { cn: '发动机电子装置', kr: '엔진전자장치', en: '', vtn: '' },
        { cn: '发动机润滑系统', kr: '엔진윤활시스템', en: '', vtn: '' },
        { cn: ' 发动机悬挂  ', kr: '엔진서스펜션', en: '', vtn: '' },
        { cn: '发动机冷却系统', kr: '엔진냉각시스템', en: '', vtn: '' },
        { cn: ' 自动变速箱  ', kr: '자동변속기', en: '', vtn: '' },
        { cn: ' MB 自动变速箱  ', kr: 'MB자동변속기', en: '', vtn: '' },
        { cn: ' 调节装置  ', kr: '조정 장치', en: '', vtn: '' },
        { cn: ' 前轴  ', kr: '전축', en: '', vtn: '' },
        { cn: ' 车轮  ', kr: '후륜', en: '', vtn: '' },
        { cn: ' 传动轴  ', kr: '전동축', en: '', vtn: '' },
        { cn: ' 制动系统  ', kr: '제동시스템', en: '', vtn: '' },
        { cn: ' 冷却器  ', kr: '냉각기', en: '', vtn: '' },
        { cn: ' 底盘钢板/进气口  ', kr: '섀시강판/공기흡기구', en: '', vtn: '' },
        { cn: ' 发动机线束 ', kr: '엔진하니스', en: '', vtn: '' },
        { cn: ' 主总成安装件 ', kr: '메인어셈블리설치부품', en: '', vtn: '' },
        { cn: ' 换档系统 ', kr: '기어변속시스템', en: '', vtn: '' },
        { cn: ' 离合器 ', kr: '클러치', en: '', vtn: '' },
        { cn: ' 发动机悬架 ', kr: '엔진서스펜션', en: '', vtn: '' },
        { cn: ' 挂车装置 ', kr: '트레일러장치', en: '', vtn: '' },
        { cn: ' 弹簧、固定座和液压系统 ', kr: '스프링,마운트및유압장치', en: '', vtn: '' },
        { cn: ' 转向系 ', kr: '조향시스템', en: '', vtn: '' },
        { cn: ' 工具和配件 ', kr: '도구및액세서리', en: '', vtn: '' },
        { cn: ' 电气设备和仪表盘 ', kr: '전자장치및계기판', en: '', vtn: '' },
        { cn: ' 燃油系统 ', kr: '연료시스템', en: '', vtn: '' },
        { cn: ' 排气系统 ', kr: '배기시스템', en: '', vtn: '' },
        { cn: ' 白车身 ', kr: '흰색차체', en: '', vtn: '' },
        { cn: '  车尾  ', kr: '차체후방', en: '', vtn: '' },
        { cn: ' 后车门 ', kr: '뒷도어', en: '', vtn: '' },
        { cn: ' 电气系统 ', kr: '전기시스템', en: '', vtn: '' },
        { cn: ' 加热和通风 ', kr: '열선및통품', en: '', vtn: '' },
        { cn: ' 风挡玻璃清洗系统，应急设备 ', kr: '(앞)유리세척시스템및응급장치', en: '', vtn: '' },
        { cn: ' 安装件 ', kr: '마운트', en: '', vtn: '' },
        { cn: ' 驾驶员座椅 ', kr: '운전석', en: '', vtn: '' },
        { cn: ' 后排座椅 ', kr: '뒷좌석', en: '', vtn: '' },
        { cn: ' 特殊装备 ', kr: '특수설비', en: '', vtn: '' },
        { cn: ' 配件 ', kr: '부품', en: '', vtn: '' },
        { cn: ' 驾驶员侧车门 ', kr: '보조석도어', en: '', vtn: '' },
        { cn: ' 行李箱盖 ', kr: '트렁크도어', en: '', tn: '' },
        { cn: ' 真空系统/液压系统 ', kr: '진공및유압시스템', en: '', vtn: '' },
        { cn: '  下部结构  ', kr: '하체구조', en: '', vtn: '' },
        { cn: ' 前端总成、前镶板 ', kr: '(앞)에셈블리,(앞)패널 ', en: '', vtn: '' },
        { cn: '  车顶  ', kr: '루프,', en: '', vtn: '' },
        { cn: '  车窗系统  ', kr: '윈도우시스템', en: '', vtn: '' },
        { cn: ' 饰件和偏差 ', kr: '트림,편차', en: '', vtn: '' },
        { cn: ' 行李箱盖 ', kr: '트렁크도어', en: '', vtn: '' },
        { cn: ' 饰板 ', kr: '트림프레임', en: '', vtn: '' },
        { cn: ' 侧壁 ', kr: '측면', en: '', vtn: '' },
        { cn: ' 滑动天窗 ', kr: '슬라이딩선루프', en: '', vtn: '' },
        { cn: ' 车身和底盘 ', kr: '차체및하체', en: '', vtn: '' },
        { cn: ' 冷却装置 ', kr: '냉각장치', en: '', vtn: '' },
        { cn: ' 技术文献 ', kr: '기술문서', en: '', vtn: '' },
        { cn: ' 保养和维修范围 ', kr: '유지보수및수리범위', en: '', vtn: '' },
        { cn: ' 混合气制备和调节装置 ', kr: '혼합가스 제조 및 조절 장치', en: '', vtn: '' },
        { cn: ' 加装 / 改装 / 附件 ', kr: '개조및액세서리', en: '', vtn: '' },
        { cn: ' 发动机电气系统 ', kr: '엔진전기시스템', en: '', vtn: '' },
        { cn: ' 燃油供应 ', kr: '연료공급', en: '', vtn: '' },
        { cn: ' 发动机和变速箱悬挂装置 ', kr: '엔진및변속기서스펜션', en: '', vtn: '' },
        { cn: ' 手动变速箱 ', kr: '수동변속기', en: '', vtn: '' },
        { cn: ' 转向系和车桥测量 ', kr: '조향 시스템 및 차축 측정', en: '', vtn: '' },
        { cn: ' 后桥 ', kr: '백브릿지', en: '', vtn: '' },
        { cn: ' 前桥 ', kr: '(앞)브릿지', en: '', vtn: '' },
        { cn: ' 制动 ', kr: '제동', en: '', vtn: '' },
        { cn: ' 车轮及轮胎 ', kr: '차륜및타이어', en: '', vtn: '' },
        { cn: ' 踏板装置 ', kr: '페달장치', en: '', vtn: '' },
        { cn: ' 换档机构 ', kr: '기어변속장치', en: '', vtn: '' },
        { cn: ' 座椅 ', kr: '좌석', en: '', vtn: '' },
        { cn: ' 车身装备 ', kr: '차체장치', en: '', vtn: '' },
        { cn: ' 仪表 ', kr: '계기판', en: '', vtn: '' },
        { cn: ' 毛坯车身 ', kr: '프레임', en: '', vtn: '' },
        { cn: ' 活动天窗和折叠式软顶 ', kr: '선루프및접이식소프트탑', en: '', vtn: '' },
        { cn: ' 一般车辆电气系统 ', kr: '일반차량전기시스템', en: '', vtn: '' },
        { cn: ' 车灯 ', kr: '라이트', en: '', vtn: '' },
        { cn: ' 冷暖空调 ', kr: '냉난방공조', en: '', vtn: '' },
        { cn: ' 车距控制系统，定速控制系统 ', kr: '차간거리,속도제어시스템', en: '', vtn: '' },
        { cn: ' 零件和附件 (发动机和底盘) ', kr: '부품및액세서리(엔진,하체)', en: '', vtn: '' },
        { cn: ' 音频、导航、信息系统 ', kr: '음향,네비,정보시스템', en: '', vtn: '' },
        { cn: ' 零件和附件 (车身) ', kr: '부품및액세서리(차체)', en: '', vtn: '' },
        { cn: ' 特殊附件 ', kr: '특수부품', en: '', vtn: '' },
        { cn: ' 辅料和消耗材料 / 颜色系统 ', kr: '부자재/소모품 컬러시스템', en: '', vtn: '' },
        { cn: ' 通信系统 ', kr: '통신시스템', en: '', vtn: '' },
        { cn: ' 完整车轮、轮辋和轮胎 ', kr: '휠,타이어', en: '', vtn: '' },
        { cn: ' 保养检查组件 ', kr: '유지보수점검구성', en: '', vtn: '' },
        { cn: ' 个性化装备 ', kr: '맞춤형장비', en: '', vtn: '' },
        { cn: ' 发动机壳体 ', kr: '엔진케이스', en: '', vtn: '' },
        { cn: ' 驱动单元部件 ', kr: '구동유닛부품', en: '', vtn: '' },
        { cn: ' 发动机控制 ', kr: '엔진컨트롤', en: '', vtn: '' },
        { cn: ' 空气滤清器和发动机增压装置 ', kr: '공기필터및엔진터보장치', en: '', vtn: '' },
        { cn: ' 动力转向泵、制冷剂压缩机和 ', kr: '동력스티어링펌프,냉동압축', en: '', vtn: '' },
        { cn: ' 进气歧管和排气歧管 ', kr: '습기/배기매니폴드', en: '', vtn: '' },
        { cn: ' 发动机润滑 ', kr: '엔진윤활', en: '', vtn: '' },
        { cn: ' 燃油供给 ', kr: '연료공급', en: '', vtn: '' },
        { cn: ' 排气和冷却 ', kr: '배기,냉각', en: '', vtn: '' },
        { cn: ' 车身电器和空调 ', kr: '차체전기,공조', en: '', vtn: '' },
        { cn: ' 传动系 ', kr: '동력전송계열', en: '', vtn: '' },
        { cn: ' 制动系 ', kr: '제동계열', en: '', vtn: '' },
        { cn: ' 行驶系（车轴、悬架、车轮） ', kr: '주행계열(차축,서스펜션,차륜)', en: '', vtn: '' },
        { cn: ' 车身（前部、顶盖、地板） ', kr: '차체(전면,루프,바닥)', en: '', vtn: '' },
        { cn: ' 车身（侧围、后部） ', kr: '차체(측면부,뒤부분)', en: '', vtn: '' },
        { cn: ' 车身（背门、后围） ', kr: '차체(트렁크도어,후측방)', en: '', vtn: '' },
        { cn: ' 座椅及安全带 ', kr: '좌석및안전벨트', en: '', vtn: '' },
        { cn: '  冷却和加热  ', kr: '냉각,가열', en: '', vtn: '' },
        { cn: ' 其他 ', kr: '기타', en: '', vtn: '' },
        { cn: ' 车桥与悬挂 ', kr: '차축및서스펜션', en: '', vtn: '' },
        { cn: ' 车身电系 ', kr: '차체전시계열', en: '', vtn: '' },
        { cn: ' 刹车系统 ', kr: '제동시스템', en: '', vtn: '' },
        { cn: ' 内饰 ', kr: '내부인테리어', en: '', vtn: '' },
        { cn: ' 转向系统 ', kr: '조향시스템', en: '', vtn: '' },
        { cn: ' 润滑剂与清洁剂 ', kr: '윤활제,청결제', en: '', vtn: '' },
        { cn: ' 8速自动变速箱 Gen3 ', kr: '8단자동변속기 Gen3', en: '', vtn: '' },
        { cn: ' 8速自动变速器 ', kr: '8단자동변속기', en: '', vtn: '' },
        { cn: ' 2.0升16气门涡轮汽油机 ', kr: '2.0L16기통터보 가솔린엔진', en: '', vtn: '' },
        { cn: ' 3.0升AJ20D6高压柴油机 ', kr: '3.0L AJ20D6고압 디젤엔진', en: '', vtn: '' },
        { cn: ' 5.0L OHC SGDI NA V8 汽油 ', kr: ' 5.0L OHC SGDI NA V8 가솔린', en: '', vtn: '' },
        { cn: ' 3.0 柴油 24V DOHC TC ', kr: '3.0 디젤 24V DOHC TC ', en: '', vtn: '' },
        { cn: ' 2.0涡轮汽油机（AJ200P） ', kr: '2.0터보 가솔린엔진', en: '', vtn: '' },
        { cn: ' 8速自动箱（8HP76） ', kr: ' 8단자동변속기(8HP76) ', en: '', vtn: '' },
        { cn: ' 分动箱 ', kr: '변속기(分动箱)', en: '', vtn: '' },
        { cn: ' 3.0L DOHC GDI SC V6 汽油 ', kr: '3.0L DOHC GDI SC V6 가솔린', en: '', vtn: '' },
        { cn: ' 8速自动变速器 8HP45 ', kr: ' 8단자동변속기 8HP45 ', en: '', vtn: '' },
        { cn: ' 附件 ', kr: '액세서리', en: '', vtn: '' },
        { cn: ' 地板金属板／进气口 ', kr: '바닥판금/흡기구', en: '', vtn: '' },
        { cn: ' 电气设备和仪表 ', kr: '전기장치,계기판', en: '', vtn: '' },
        { cn: ' 车身底板 ', kr: '하체판금', en: '', vtn: '' },
        { cn: ' 发动机冷却装置 ', kr: '엔진냉각장치', en: '', vtn: '' },
        { cn: ' 用于各装置的连接配件 ', kr: '각장치연결부품', en: '', vtn: '' },
        { cn: ' 踏板总成 ', kr: '페달에셈블리', en: '', vtn: '' },
        { cn: ' 调节 ', kr: '조절', en: '', vtn: '' },
        { cn: ' 弹簧，悬挂和液压装置 ', kr: '스프링,서스펜션,유압장치', en: '', vtn: '' },
        { cn: ' 车身本体 ', kr: '차체', en: '', vtn: '' },
        { cn: ' 尾部 ', kr: '뒷면', en: '', vtn: '' },
        { cn: ' 前端，前壁 ', kr: '전면,전측', en: '', vtn: '' },
        { cn: ' 车窗 ', kr: '윈도우', en: '', vtn: '' },
        { cn: ' 挡板 ', kr: '대시보드', en: '', vtn: '' },
        { cn: ' 侧板 ', kr: '측면', en: '', vtn: '' },
        { cn: ' 前车门 ', kr: '앞도어', en: '', vtn: '' },
        { cn: ' 滑动车顶 ', kr: '슬라이딩선루프', en: '', vtn: '' },
        { cn: ' 加热装置和通风装置 ', kr: '열선및통풍장치', en: '', vtn: '' },
        { cn: ' 加装件 ', kr: '추가장치', en: '', vtn: '' },
        { cn: ' 转向装置 ', kr: '조향장치', en: '', vtn: '' },
        { cn: ' 风挡玻璃清洗系统，紧急情况用装置 ', kr: '앞유리세척시스템,비사용장치', en: '', vtn: '' },
        { cn: ' 722.6，自动 MB-变速箱 ', kr: ' 722.6,자동MB-변속기 ', en: '', vtn: '' },
        { cn: ' 挡板和套筒 ', kr: '칸막이,소켓', en: '', vtn: '' },
        { cn: ' 变速箱和动力传递 ', kr: '변속기,동력전달', en: '', vtn: '' },
        { cn: ' 转向和悬挂系统 ', kr: '조향,서스펜션시스템', en: '', vtn: '' },
        { cn: ' 空调/加热/通风 ', kr: '공조/가열/통풍', en: '', vtn: '' },
        { cn: ' 雨刮及清洗系统 ', kr: '와이퍼및청결시스템', en: '', vtn: '' },
        { cn: ' 电路系统 ', kr: '전기시스템', en: '', vtn: '' },
        { cn: ' 信息系统和控制器 ', kr: '인포테인먼트시스템,컨트롤러', en: '', vtn: '' },
        { cn: ' 电瓶/启动机/发电机 ', kr: '배터리/시동모터/발전기', en: '', vtn: '' },
        { cn: ' 车内娱乐设施 ', kr: '차량 내 엔터테인먼트 시스템', en: '', vtn: '' },
        { cn: ' 照明: 车外/车内 ', kr: '조명:외부/내부', en: '', vtn: '' },
        { cn: ' 遥控门锁/安全系统/喇叭 ', kr: '원격제어도어락/안전시스템/스피커', en: '', vtn: '' },
        { cn: ' 坐椅系统 ', kr: '좌석시스템', en: '', vtn: '' },
        { cn: ' 行人保护系统 ', kr: '보행자보호시스템', en: '', vtn: '' },
        { cn: ' 内饰和衬垫 ', kr: '인테리어,패드', en: '', vtn: '' },
        { cn: ' 外部配件和天窗 ', kr: '외부부품,선루프', en: '', vtn: '' },
        { cn: ' 车身密封件 ', kr: '차체바디씰', en: '', vtn: '' },
        { cn: ' 车身金属面板和密封件 ', kr: '차체판금,바디씰', en: '', vtn: '' },
        { cn: ' 动力传输 ', kr: '동력전달', en: '', vtn: '' },
        { cn: ' 弹簧与车轮 ', kr: '스프링,차륜', en: '', vtn: '' },
        { cn: ' 悬挂与转向 ', kr: '서스펜션,조향', en: '', vtn: '' },
        { cn: ' 弹簧与车轮 ', kr: '스프링,차륜', en: '', vtn: '' },
        { cn: ' 车身与内部 ', kr: '차체,내부', en: '', vtn: '' },
        { cn: ' 刹车 ', kr: '제동', en: '', vtn: '' },
        { cn: '发动机与安装架及设备 ', kr: '엔진,설치 프레임 및 장비', en: '', vtn: '' },
        { cn: ' 附件及其他 ', kr: '액세서리및기타', en: '', vtn: '' },
        { cn: ' 燃油系统，排气系统 ', kr: '연료및배기시스템', en: '', vtn: '' },
        { cn: ' 双离合器变速箱 ', kr: '듀얼클러치변속기', en: '', vtn: '' },
        { cn: 'ㅁㅁㅁㅁㅁㅁㅁㅁㅁ', kr: '기타', en: '', vtn: '' },
        { cn: 'ㅁㅁㅁㅁㅁㅁㅁㅁㅁ', kr: '기타', en: '', vtn: '' },
        { cn: 'ㅁㅁㅁㅁㅁㅁㅁㅁㅁ', kr: '기타', en: '', vtn: '' },
        { cn: 'ㅁㅁㅁㅁㅁㅁㅁㅁㅁ', kr: '기타', en: '', vtn: '' },
        { cn: 'ㅁㅁㅁㅁㅁㅁㅁㅁㅁ', kr: '기타', en: '', vtn: '' },
        { cn: 'ㅁㅁㅁㅁㅁㅁㅁㅁㅁ', kr: '기타', en: '', vtn: '' },
        { cn: 'ㅁㅁㅁㅁㅁㅁㅁㅁㅁ', kr: '기타', en: '', vtn: '' },
        { cn: 'ㅁㅁㅁㅁㅁㅁㅁㅁㅁ', kr: '기타', en: '', vtn: '' },
        { cn: 'ㅁㅁㅁㅁㅁㅁㅁㅁㅁ', kr: '기타', en: '', vtn: '' },
        { cn: 'ㅁㅁㅁㅁㅁㅁㅁㅁㅁ', kr: '기타', en: '', vtn: '' },
        { cn: 'ㅁㅁㅁㅁㅁㅁㅁㅁㅁ', kr: '기타', en: '', vtn: '' },
        { cn: 'ㅁㅁㅁㅁㅁㅁㅁㅁㅁ', kr: '기타', en: '', vtn: '' },
        { cn: 'ㅁㅁㅁㅁㅁㅁㅁㅁㅁ', kr: '기타', en: '', vtn: '' },


        { cn: '车辆识别号（最后 7 位）', kr: 'VIN(끝7자리)', en: '', vtn: '' },
        { cn: '车辆识别号（17 位）', kr: 'VIN(17자리)', en: '', vtn: '' },
        { cn: '品牌', kr: '브랜드', en: '', vtn: '' },
        { cn: '车系', kr: '차량계열', en: '', vtn: '' },
        { cn: '车型', kr: '차종', en: '', vtn: '' },
        { cn: '驾驶', kr: '운전석', en: '', vtn: '' },
        { cn: '驱动系统', kr: '구동시스템', en: '', vtn: '' },
        { cn: '坐垫代码', kr: '시트코드', en: '', vtn: '' },
        { cn: '油漆代码	', kr: '도색코드', en: '', vtn: '' },
        { cn: '出厂日期', kr: '제조일자', en: '', vtn: '' },
        { cn: '销售代码	', kr: '판매코드', en: '', vtn: '' },
        { cn: '排量	', kr: '배기량', en: '', vtn: '' },
        { cn: '市场', kr: '시장', en: '', vtn: '' },
        { cn: '发动机款式	', kr: '엔진구분', en: '', vtn: '' },
        { cn: '变速器', kr: '변속기', en: '', vtn: '' },
        { cn: '生产订单编号', kr: '생산일련번호', en: '', vtn: '' },
        { cn: '底盘号', kr: 'VIN(底盘号)', en: '', vtn: '' },
        { cn: '车型年份', kr: '제조년도', en: '', vtn: '' },
        { cn: '车型名称', kr: '명칭', en: '', vtn: '' },
        { cn: '变速箱标识字母', kr: '변속기식별자', en: '', vtn: '' },
        { cn: '发动机标识字母', kr: '엔진식별자', en: '', vtn: '' },
        { cn: '顶部颜色', kr: '상부색상', en: '', vtn: '' },
        { cn: '发动机编号	', kr: '엔진코드', en: '', vtn: '' },
        { cn: '生产日期', kr: '제조일자', en: '', vtn: '' },
        { cn: '外观颜色／油漆号', kr: '외관색상/도색코드', en: '', vtn: '' },
        { cn: '销售类型	', kr: '판매유형', en: '', vtn: '' },
        { cn: '生产周', kr: '생산주차', en: '', vtn: '' },
        { cn: '年份	', kr: '제조년도', en: '', vtn: '' },
        { cn: '车身颜色', kr: '차체색상', en: '', vtn: '' },
        { cn: '车身款式', kr: '차체스타일', en: '', vtn: '' },
        { cn: '内饰/内部代码', kr: '인테리어 코드', en: '', vtn: '' },
        { cn: '工厂代码', kr: '공장코드', en: '', vtn: '' },
        { cn: '配置', kr: '구성코드', en: '', vtn: '' },
        { cn: ' 手柄，组合踏板 ', kr: '기어봉,조합페달', en: '', vtn: '' },
        { cn: ' 弹簧列表 ', kr: '스프링', en: '', vtn: '' },
        { cn: '地毯颜色代码', kr: '패드색상코드', en: '', vtn: '' },
        { cn: '前部弹簧', kr: '스프링코드(전면)', en: '', vtn: '' },
        { cn: '后部弹簧', kr: '스프링코드(후면)', en: '', vtn: '' },
        { cn: '前悬架滑柱', kr: '서스펜션 슬라이드 튜브(앞)', en: '', vtn: '' },
        { cn: '交货日期', kr: '출고날자', en: '', vtn: '' },
        { cn: '左前轴', kr: '전축(좌)', en: '', vtn: '' },
        { cn: '集成式托架', kr: '일체형브라켓', en: '', vtn: '' },
        { cn: '喷漆 1', kr: '도색1', en: '', vtn: '' },
        { cn: '生产厂', kr: '제조공장', en: '', vtn: '' },
        { cn: '集成式托架款式', kr: '통합브래킷', en: '', vtn: '' },
        { cn: '前后桥标识', kr: '브릿지(전,후)코드', en: '', vtn: '' },
        { cn: '外观颜色 / 油漆号', kr: '외관색상/도색코드', en: '', vtn: '' },
        { cn: '座椅组合号码', kr: '좌석코드', en: '', vtn: '' },
        { cn: 'Z订单数量', kr: 'Z주문수량', en: '', vtn: '' },
        { cn: '后部垫片', kr: '후면가스켓', en: '', vtn: '' },
        { cn: 'ㅁㅁㅁㅁㅁㅁㅁㅁㅁ', kr: '기타', en: '', vtn: '' },
        { cn: 'ㅁㅁㅁㅁㅁㅁㅁㅁㅁ', kr: '기타', en: '', vtn: '' },
        { cn: 'ㅁㅁㅁㅁㅁㅁㅁㅁㅁ', kr: '기타', en: '', vtn: '' },
        { cn: 'ㅁㅁㅁㅁㅁㅁㅁㅁㅁ', kr: '기타', en: '', vtn: '' },
    ]
}

export default {
    install(Vue) {
        Vue.prototype.$getLang = methods.getLang
        Vue.prototype.$wordlist = methods.wordlist
    }
}