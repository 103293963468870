<template>
  <div class="container mt-3 relative noselect" style="max-width: 1300px !important;">
    <TopBar />
    <TopMenu />
    <div class="mt-4 divCenter p-3" v-if="tmpDataTable" style="max-width: 330px;min-height: 350px;">
      <div class="font15">결제가 완료되었습니다.</div>
      <div class="font20 bold borderbottom1px p-2">{{ $comma(tmpDataTable.useCash) }}원</div>
      <div class="font15 mt-3" style="max-width: 300px;">
        <el-row :gutter="10">
          <el-col :xl="5" :xs="10" :sm="5">주문금액</el-col>
          <el-col :xl="19" :xs="14" :sm="19" class="text-right">{{ $comma(tmpDataTable.useCash) }}원</el-col>
          <el-col :xl="5" :xs="10" :sm="5">승인번호</el-col>
          <el-col :xl="19" :xs="14" :sm="19" class="text-right">{{ tmpDataTable.sn }}</el-col>
          <el-col :xl="5" :xs="10" :sm="5">상호</el-col>
          <el-col :xl="19" :xs="14" :sm="19" class="text-right">{{ $loginUserInfo.companyInfo.name}}</el-col>
          <el-col :xl="5" :xs="10" :sm="5">구매자</el-col>
          <el-col :xl="19" :xs="14" :sm="19" class="text-right">{{ $loginUserInfo.name }}</el-col>
        </el-row>
        <div class="mt-3 text-right fixedTooltip-link hand font18" style="width:300px;"
        @click="$changeRoute('main')">
          홈페이지로 돌아가기
          <i class="fas fa-chevron-right"></i>
        </div>
      </div>

    </div>
    <!-- <json-viewer :value="paymentSuccess" :expand-depth=1 copyable boxed sort class="mt-3"></json-viewer> -->
    <FootBar />
    <el-backtop>
    </el-backtop>
  </div>
</template>
<script>
import TopBar from './component-top.vue'
import TopBanner from './component-top-banner.vue'
import TopNotice from './component-top-notice.vue'
import TopMenu from './component-top-menu.vue'
import FootBar from './component-foot.vue'
import centermenu from './component-cener-left-menu.vue'
import { Loading } from 'element-ui'
export default {
  components: { centermenu, TopBar, TopBanner, TopNotice, TopMenu, FootBar, Loading },
  data() {
    return {
      loading: null,
      paymentSuccess: null,
      tmpDataTable: null,
    }

  },
  created() {
    window.scrollTo(0, 0);
    this.loading = Loading.service({ fullscreen: true })
    this.paymentSuccess = JSON.parse(localStorage.getItem('paymentSuccess'))
    if (!this.paymentSuccess) { this.$router.go(-1); return }
    this.tmpDataTable = this.paymentSuccess.data.body.results
    this.$overay(this.loading)
  },

  methods: {

    handleBeforeUnload(event) {
      event.preventDefault();
      event.returnValue = '이 페이지를 나가시겠습니까?';
    }
  },
  beforeRouteLeave(to, from, next) {
    // 라우터변경시
    // if (confirm('페이지를 떠나시겠습니까?')) {
    //   localStorage.removeItem('paymentSuccess')
    //   next();
    // } else {
    //   // next(false);
    // }
    next()
  },
  mounted() {
    // 새로고침시
    // window.addEventListener('beforeunload', this.handleBeforeUnload);
    // localStorage.removeItem('paymentSuccess')

  },

}

</script>
<style></style>
