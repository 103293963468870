<template>
  <div class="container mt-3 relative noselect" style="max-width: 1300px !important;">
    <TopBar />
    <TopBanner />
    <TopMenu :searchKeywordsValue="req.objSearchMapper.searchKeyword" @callback="sarechcallback"/>
    <!-- 검색리스트 최대100개-->
    <div class="font15 bold" style="line-height: 30px;" v-if="req.objSearchMapper.searchKeyword != 'null'">
      현재 [{{ req.objSearchMapper.searchKeyword }}] 로 {{ totalCount }} 건(최대100건) 검색되었습니다.
    </div>
    <el-row :gutter="10" class="mt-3">
      <el-col :xl="3" :md="3" :xs="8" v-for="(item, index) in tmpTableData" :key="index"
        @click.native="$router.push('/productView/' + item.seq)" class="carousel-container ">
        <productcard :data="item" />
      </el-col>
    </el-row>
    <FootBar />
    <el-backtop>
    </el-backtop>
  </div>
</template>

<script>
import TopBanner from './component-top-banner.vue'
import TopBar from './component-top.vue'
import TopMenu from './component-top-menu.vue'
import FootBar from './component-foot.vue'
import productcard from './component-product-card.vue'
import { Loading } from 'element-ui'
export default {
  components: {
    TopBar, FootBar, TopMenu, Loading, productcard,TopBanner
  },
  data() {
    return {
      req: {
        objSearchMapper: {
          searchKeyword: null,
        },
        page: 1,
        size: 100
      },
      tmpTableData: [],
      subList: [],
      totalCount: 0,
      loading: null,
    };
  },
  beforeDestroy() {
  },

  async created() {
    window.scrollTo(0, 0);
    this.loading = Loading.service({ fullscreen: true })
    this.$loginUserInfo = JSON.parse(localStorage.getItem('loginUserInfo'))
    this.$http.defaults.headers.common['exjwtauthorization'] = this.$loginUserInfo?.token
    this.req.objSearchMapper.searchKeyword = this.$route.params.keyword
    await this.read()
  },
  beforeRouteUpdate(to, from, next) {
    this.req.objSearchMapper.searchKeyword = to.params.keyword
    this.read()
    next()
  },
  methods: {
    async sarechcallback(data){
      if(data){
        this.read()
      }
    },
    async read() {
      this.tmpTableData=[]
      await this.$http.post(`${this.$serverurl}/api/getMallProductInfo`, this.req)
        .then(res => {
          if (200 == res.data.code) {
            this.tmpTableData = res.data.body.results
            this.totalCount = res.data.body.total
          }
        })
      this.$overay(this.loading)
    },

    setbackground(item) {
      if (item && item.images && item.images.length > 0) {
        return {
          background: `url(${this.$imageurl}${item.images[0]}) no-repeat center center / cover !important`
        };
      } else {
        return {
          background: `url('${this.$imageurl}/upload/no-image.webp') no-repeat center center / cover`
        };
      }
    },
  }

}
</script>

<style>
/* .mobile-scroll {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.VueCarousel-inner {
  max-width: 275px;
}

.VueCarousel-slidex {
  width: 140px !important;
  margin: 5px;
}

@media (max-width: 767px) {
  .productView {
    min-width: 90px !important;
  }

  .VueCarousel-inner {
    max-width: 195px;
  }

  .VueCarousel-slidex {
    max-width: 100px;
    margin: 5px;
  }
}

@media (min-width: 768px) {

  .mobile-scroll {
    overflow-x: auto;
  }
} */
</style>
