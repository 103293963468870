<template>
  <div class="container mt-3 relative noselect" style="max-width: 1300px !important;">
    <TopBar />
    <TopMenu />
    <!-- <TopBanner /> -->
    <!-- <TopNotice /> -->
    <el-row :gutter="15">
      <el-col :xl="5" :xs="24" :sm="5">
        <centermenu />
      </el-col>
      <!-- 정보변경 -->
      <el-col :xl="24" :xs="24" :sm="24" v-if="!changePassword">
        <el-row class="borderbox mt-2 p-2">
          <el-col class="borderbottom1px lh40 relative">
            <span class="font18 bold">
              {{ $loginUserInfo.name }} 님 프로필
            </span>
            <span class="absolute hand fixedTooltip-link" style="top:5px;right:5px;" @click="changePassword = true">
              비밀번호변경
            </span>
          </el-col>
          <el-col class="relative mt-2">
            <el-row :gutter="10" class="font15 lh40">
              <el-col :xl="4" :xs="8" :sm="4">계정유형</el-col>
              <el-col :xl="20" :xs="16" :sm="20">
                <span>
                  {{ $loginUserInfo.type == 0 ? '대표계정' : '일반계정' }}
                </span>
              </el-col>
              <el-col :xl="4" :xs="8" :sm="4">로그인계정</el-col>
              <el-col :xl="20" :xs="16" :sm="20" class="relative">
                <span>
                  {{ $toStar($loginUserInfo.id, 3) }}
                </span>
                <span class="fixedTooltip-info ml-3 d-none d-md-block" style="top:0px;right:5px;">계정변경은 고객센터로
                  연락주세요.</span>
              </el-col>
              <el-col :xl="4" :xs="8" :sm="4">이름</el-col>
              <el-col :xl="20" :xs="16" :sm="20">
                {{ $loginUserInfo.name }}
              </el-col>
              <el-col :xl="4" :xs="8" :sm="4">전화번호</el-col>
              <el-col :xl="20" :xs="16" :sm="20">
                <el-input type="text" size="mini" maxlength="11" v-model="formData.tel" placeholder="전화번호"
                  show-word-limit></el-input>
              </el-col>
              <el-col :xl="4" :xs="8" :sm="4">모바일</el-col>
              <el-col :xl="20" :xs="16" :sm="20">
                <el-input type="text" size="mini" maxlength="11" v-model="formData.phone" placeholder="모바일"
                  show-word-limit></el-input>
              </el-col>
              <el-col :xl="4" :xs="8" :sm="4">이메일</el-col>
              <el-col :xl="20" :xs="16" :sm="20">
                <el-input type="text" size="mini" maxlength="50" v-model="formData.mail" placeholder="이메일"
                  show-word-limit></el-input>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row class="mt-2">
          <el-col class="text-right">
            <el-button type="info" size="mini" @click="reload">
              <i class="fas fa-rotate-right"></i>
              reload</el-button>
            <el-button type="" size="mini" class="el-btn-orange" @click="setMyInfo()">
              <i class="fas fa-floppy-disk"></i>
              정보제출</el-button>
          </el-col>
        </el-row>
      </el-col>
      <!-- 비번변경 -->
      <el-col :xl="24" :xs="24" :sm="24" v-if="changePassword">
        <el-row class="borderbox mt-2 p-2">
          <el-col class="borderbottom1px lh40 relative">
            <span class="font18 bold">
              {{ $loginUserInfo.name }} 님 비밀번호 관리
            </span>
            <span class="fixedTooltip-link absolute hand" style="top:5px;right:5px;" @click="changePassword = false">
              정보수정
            </span>
          </el-col>
          <el-col class="mt-2">
            <form>
              <el-row :gutter="10" class="font15 lh40">
                <el-col :xl="4" :xs="8" :sm="4">현재비밀번호</el-col>
                <el-col :xl="20" :xs="16" :sm="20" >
                  <componentInputText 
                  :icon="'fas fa-user-shield'"
                  :minlength="5"
                  :maxlength="20"
                  :type="'password'"
                  :size="'mini'"
                  :placeholder="'현재비밀번호를 입력하세요.'"
                  v-model="formPwData.originPw"
                  />
                </el-col>
                <el-col :xl="4" :xs="8" :sm="4">새비밀번호</el-col>
                <el-col :xl="20" :xs="16" :sm="20">
                  <componentInputText 
                  :icon="'fas fa-user-shield'"
                  :minlength="5"
                  :maxlength="20"
                  :type="'password'"
                  :size="'mini'"
                  :placeholder="'새비밀번호를 입력하세요.'"
                  v-model="formPwData.newPw"
                  />
                </el-col>
                <el-col :xl="4" :xs="8" :sm="4">새비밀번호확인</el-col>
                <el-col :xl="20" :xs="16" :sm="20">
                  <componentInputText 
                  :icon="'fas fa-user-shield'"
                  :minlength="5"
                  :maxlength="20"
                  :type="'password'"
                  :size="'mini'"
                  :placeholder="'새비밀번호를 한번 더 입력하세요.'"
                  v-model="formPwData.verifyPw"
                  />
                </el-col>
              </el-row>
            </form>
          </el-col>
        </el-row>
        <el-row class="mt-2 bordertop1px">
          <el-col>
            <div class="font12 pl-3 mt-2" v-html="$toHtml($비번변경안내문)"></div>
          </el-col>
        </el-row>
        <el-row class="mt-2">
          <el-col class="text-right">
            <el-button type="info" size="mini" @click="reload">
              <i class="fas fa-rotate-right"></i>
              reload</el-button>
            <el-button type="" size="mini" class="el-btn-orange" @click="setPassword()">
              <i class="fas fa-floppy-disk"></i>
              정보제출</el-button>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <FootBar />
    <el-backtop>
    </el-backtop>
  </div>
</template>
<script>
import componentInputText from './component-input-text.vue';

import TopBar from './component-top.vue'
import TopBanner from './component-top-banner.vue'
import TopNotice from './component-top-notice.vue'
import TopMenu from './component-top-menu.vue'
import FootBar from './component-foot.vue'
import centermenu from './component-cener-left-menu.vue'
import { Loading } from 'element-ui'
export default {
  components: { centermenu, TopBar, TopBanner, TopNotice, TopMenu, FootBar, Loading,componentInputText },
  data() {
    return {
      loginUserInfo: null,
      loading: null,
      emoji: [
        '😀', '😅', '🥰', '😍', '🤩', '😘', '😚', '😜',
        '🥳', '🤓', '🥸', '🧐', '🥱', '🤬', '💀', '👺'
      ],
      formData: {
        name: '',
        tel: '',
        phone: '',
        mail: '',
        id: '',
        availabelIp: '',
      },
      formPwData: {
        originPw: '',
        newPw: '',
        verifyPw: '',
      },
      changePassword: false,
    }
  },
  created() {
    window.scrollTo(0, 0);
    this.loading = Loading.service({ fullscreen: true })
    this.$loginUserInfo = JSON.parse(localStorage.getItem('loginUserInfo'))
    this.formData = {
      tel: this.$loginUserInfo.tel,
      phone: this.$loginUserInfo.phone,
      mail: this.$loginUserInfo.mail,
      id: this.$loginUserInfo.id,
      name: this.$loginUserInfo.name,
    }
    this.$overay(this.loading)
  },
  methods: {
    reload() {
      this.formData = {
        tel: this.$loginUserInfo.tel,
        phone: this.$loginUserInfo.phone,
        mail: this.$loginUserInfo.mail,
      }
    },
    async setMyInfo() {
      await this.$http.post(`${this.$serverurl}/api/setMyInfo`, this.formData)
        .then(res => {
          if (201 == res.data.code) {
            this.$loginUserInfo.tel = res.data.body.results.tel
            this.$loginUserInfo.phone = res.data.body.results.phone
            this.$loginUserInfo.mail = res.data.body.results.mail
            this.$notify.success({
              title: '',
              message: res.data.message,
              position: 'bottom-right',
              showClose: true,
              duration: 1000,
            });
            localStorage.setItem('loginUserInfo', JSON.stringify(this.$loginUserInfo))
          } else {
            this.$notify.error({
              title: '',
              message: res.data.message,
              position: 'bottom-right',
              showClose: true,
              duration: 1000,
            });
          }
        })
      this.$overay(this.loading)
    },
    async setPassword() {
      if (!this.formPwData.originPw.trim()) {
        alert('현재 비밀번호를 입력하세요.'); return
      }
      if (!this.formPwData.newPw.trim()) {
        alert('새 비밀번호를 입력하세요.'); return
      }
      if (!this.formPwData.verifyPw.trim()) {
        alert('새 비밀번호를 한번 더 입력하세요.'); return
      }
      if (this.formPwData.originPw.trim() == this.formPwData.newPw.trim()) {
        alert('현재 사용하고 있는 비밀번호입니다.'); return
      }
      if (this.formPwData.verifyPw.trim() != this.formPwData.newPw.trim()) {
        alert('비밀번호가 동일하지 않습니다.'); return
      }
      await this.$http.post(`${this.$serverurl}/api/setPassword`, this.formPwData)
        .then(res => {
          this.$notify.success({
            title: '',
            message: res.data.message,
            position: 'bottom-right',
            showClose: true,
            duration: 1000,
          });
        })
      this.$overay(this.loading)
    },
  },
}

</script>
<style></style>
