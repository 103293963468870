<template>
    <div>
        <transition name="slide">
            <div class="drawer" v-if="isDrawerOpenOnOff">
                <i class="fas fa-circle-xmark hand absolute colorgraylight" style="right:10px;" @click="callback()"></i>
                <div>
                    <img :src="carInfoImg" alt="" class="w100p">
                </div>
                <div>
                    <el-tabs v-model="activeName" @tab-click="optionSpecClick">
                        <el-tab-pane label="차량옵션" name="option"
                            style="height:calc(100vh - 230px);overflow: hidden;overflow-y: auto;">
                            <table class="table-params w100p mb-5">
                                <tbody>
                                    <tr v-for="(value, key) in carOption" :key="key">
                                         <th style="width:120px;">{{ key }}</th>
                                        <td>{{ value }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </el-tab-pane>
                        <el-tab-pane label="기본사양" name="spec"
                            style="height:calc(100vh - 230px);overflow: hidden;overflow-y: auto;">
                            <table class="table-params w100p mb-5">
                                <tbody>
                                    <tr v-for="(value, key) in carSpec" :key="key">
                                        <th style="width:120px;">{{ key }}</th>
                                        <td>{{ value }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </el-tab-pane>
                    </el-tabs>
                </div>
                <div>
                </div>
                <button @click="closeDrawer">Close Drawer</button>
            </div>
        </transition>
    </div>

</template>

<script>
export default {
    props: ['carInfoImg', 'carOption','carSpec', 'isDrawerOpen'],
    name: 'carinfoSlide',
    components: {
    },
    data() {
        return {
            activeName:'option',
            isDrawerOpenOnOff: false,
        }
    },
    watch: {
        carOption: {
            deep: true,
            immediate: true,
            async handler(newValue, oldValue) {
            }
        },
        carSpec: {
            deep: true,
            immediate: true,
            async handler(newValue, oldValue) {
            }
        },
        isDrawerOpen: {
            deep: true,
            immediate: true,
            async handler(newValue, oldValue) {
                this.isDrawerOpenOnOff = newValue
            }
        },
    },
    async created() { },
    methods: {
        callback() {
            this.$emit('callback', false)
        },
        toggleDrawer() {
            this.isDrawerOpen = !this.isDrawerOpen;
        },
        closeDrawer() {
            this.isDrawerOpen = false;
        },
        optionSpecClick(tab, event) { },
    }
}
</script>

<style scoped>
.drawer {
    position: fixed;
    top: 0;
    right: 0;
    width: 350px;
    height: 100%;
    background-color: white;
    z-index: 10000;
    padding: 20px;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
}

.slide-enter-active,
.slide-leave-active {
    transition: transform 0.3s ease;
}

.slide-enter,
.slide-leave-to {
    transform: translateX(100%);
}
</style>