<template>
  <div class="detailPage">
    <div v-if="data">
      <el-row :gutter="10" class="mt-3">
        <el-col :xl="8" :xs="8" :sm="8" style="min-width:300px;min-height: 300px;">
          <carousel :perPageCustom="[[300, 1]]" :value.sync="currentIndex" :navigation-enabled="false" :navigation="false"
            class="bigImageSize VueCarousels">
            <slide v-for="(image, index) in data.images" :key="index">
              <el-image style="width: 300px; height: 300px" :src="setImage(image)"
                :preview-src-list="setImageList(data.images)">
              </el-image>
            </slide>
          </carousel>
          <div>
            <label class="productSmallImage" v-for="(item, index) in data.images" :key="index" :style="backimage(item)"
              @click="currentIndex = index"></label>
          </div>
        </el-col>
        <el-col :xl="16" :xs="24" :sm="16">
          <div class="mt-1 font30 bold productPrice">
            <span class="font20 bold colorgraylight fontline-through mr-2" style="top:13px;right:330px;"
              v-if="data.priceSales">
              {{ data && $totalInTax(data.price + data.priceSales,1,true) }}원
            </span>
            {{ data && $totalInTax(data.price,1,true) }}
          </div>
          <div class="font20 mt-3">
            {{ data.name }}
            {{ data.pid }}
          </div>
          <div class="mt-3">
            <span class="font15 colorgray">
              브랜드 :
              {{ data.type && $productType[data.type].label }}
            </span>
            <el-divider direction="vertical"></el-divider>
            <span class="font15 colorgray">
              정품품번 :
              {{ data.pid }}
            </span>
            <el-divider direction="vertical"></el-divider>
            <span class="font15 colorgray">
              공장품번 :
              {{ data.factoryNum }}
            </span>
            <el-divider direction="vertical"></el-divider>
            <span class="font15 badge0 badge-bluedeep relative">
              <img class="absolute" style="top:4px;" :class="data.import==1?'china18':'korea18'" />
              <span class="ml-4">
                {{ data && data.storageInfo && data.storageInfo.name }}
              </span>
            </span>
          </div>
          <div v-if="data.hasAmount">
            <div class="mt-3 font15 bold">
              수량
              <span class="colorred">
                ({{ data.hasAmount }}EA)
              </span>
              :
              <span class="quantitybox" style="position: relative;">
                <input type="number" v-model="formData.hasAmount" value="1" min="1" :max="data.hasAmount" readonly
                  @blur="checkHasAmount()" />
                <i class="el-icon-minus minus" @click="changeQuantity('minus')"></i>
                <i class="el-icon-plus plus" @click="changeQuantity('plus')"></i>
              </span>
            </div>
            <el-progress :text-inside="true" :stroke-width="2" :percentage="formData.hasAmountPercent"
              status="exception"></el-progress>
            <el-row>
              <el-col :xl="10" :md="10">
                <div class=" mt-3 nowrap colorgray font18 productPrice">
                  예정결제금액 : {{ $totalInTax(data.price * formData.hasAmount,1,true) }}
                </div>
              </el-col>
              <el-col :xl="14" :md="14" class="right">
                <div class="mt-3 font15">
                  <span v-if="data.import==1" class="bigbadge-dark pr-1">
                    해당제품은
                    <span class="empty mr-2">
                      수입제품으로 수령까지 3~7일이
                    </span>
                    소요됩니다.
                  </span>
                </div>
              </el-col>
            </el-row>
  
          </div>
          <div v-else class="mt-3">
            <span class="bigbadge-red font18 bold"> SOLD OUT </span>
          </div>
  
          <div class="mt-3">
            <div class="warningbox">
              구매전 필히 제품정보를 확인하여 주십시오. <br />
              국내 재고제품 외 수입제품은 반품이 불가하오니 사전 확인부탁드립니다.
            </div>
          </div>
  
        </el-col>
      </el-row>
  
      <div class="mt-1 buttonsbox lastBlur" v-if="$loginUserInfo">
        <el-button :type="data && data.productWishInfo ? 'warning' : 'info'" size="small" class="font13"
          @click="submit('fav')">
          <i class="fas fa-star"></i>
        </el-button>
        <span v-if="data.hasAmount" class="ml-2">
          <el-button v-if="data.pop" type="warning" size="small" icon="el-icon-shopping-bag-1" class="font13"
            @click="$changeRoute(`productView/${data.seq}`)">상세보기</el-button>
          <el-button v-if="!data.cartInfo.length" type="primary" size="small" icon="el-icon-shopping-cart-full"
            class="font13" @click="submit('cart')">장바구니담기</el-button>
          <el-button v-if="!data.cartInfo.length" type="danger" size="small" icon="el-icon-shopping-bag-1" class="font13"
            @click="submit('buy')">구매하기</el-button>
          <el-button v-if="data.cartInfo.length" type="danger" size="small" icon="el-icon-shopping-bag-1" class="font13"
            @click="checkout">결제하기</el-button>
        </span>
      </div>
      <div class="mt-1 buttonsbox lastBlur" v-else>
        <el-button type="info" size="small" icon="el-icon-user" class="font13"
          @click="$router.push('/joinus')">회원가입</el-button>
        <el-button type="danger" size="small" icon="el-icon-user-solid" class="font13"
          @click="$router.push('/login')">로그인</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { data } from 'vue-barcode';

export default {
  name: 'productView',
  props: ['data'],
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
      }
    }
  },
  data() {
    return {
      formData: {
        fav: false,
        cart: false,
        buy: false,
        hasAmount: 1,
        hasAmountPercent: 1,
        price: 0,
      },
      currentIndex: 1,
    }
  },
  async created() {
  },
  mounted() {
    this.loadData().then(data => {
      this.formData.hasAmountPercent = (this.formData.hasAmount / data.hasAmount) * 100;
    });
  },
  methods: {
    checkout() {
      this.$changeRoute('checkout')
    },
    loadData() {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          const data = { hasAmount: this.data?.hasAmount || 1 }
          resolve(data)
        }, 500)
      });
    },
    checkHasAmount() {
      if (!this.formData.hasAmount || this.formData.hasAmount == 0) {
        this.formData.hasAmount = 1
      }
    },
    submit(params) { // fav,cart,buy
      switch (params) {
        case 'fav': {
          this.formData.fav = true
          this.formData.cart = false
          this.formData.buy = false
          this.$emit('operationCallback', this.formData)
          break;
        }
        case 'cart': {
          this.formData.fav = false
          this.formData.cart = true
          this.formData.buy = false
          this.formData.price = this.data.price
          this.$emit('operationCallback', this.formData)
          break;
        }
        case 'buy': {
          this.formData.fav = false
          this.formData.cart = false
          this.formData.buy = true
          this.formData.price = this.data.price
          this.$emit('operationCallback', this.formData)
          break;
        }
      }
    },
    changeQuantity(params) {
      if (params == 'minus') {
        if (this.formData.hasAmount > 1) {
          this.formData.hasAmount -= 1
        }
      } else {
        if (this.formData.hasAmount < this.data.hasAmount) {
          this.formData.hasAmount += 1
        }
      }
      this.formData.hasAmountPercent = (this.formData.hasAmount / this.data.hasAmount) * 100
    },
    setImage(image) {
      if (!image.includes('no-image')) {
        const https = image.includes('://')
        if (!https) {
          image = this.$imageurl + image
        }
        return `${image}`
      } else {
        return image
      }
    },
    setImageList(images) {
      return images.map(items => {
        if (!items.includes('no-image')) {
          const https = items.includes('://')
          if (!https) {
            items = this.$imageurl + items
          }
          return `${items}`
        } else {
          return items
        }
      })



    },
    backimage(image) {
      let background = ''
      if (image.includes('no-image')) {
        background = `background:url('${this.$imageurl}/upload/no-image.webp') no-repeat center center / cover`
      } else {
        const https = image.includes('://')
        if (!https) {
          image = this.$imageurl + image
        }
        background = `background: url(${image}) no-repeat center center / cover !important;`
      }
      return background
    },
  }
}
</script>

<style scoped></style>