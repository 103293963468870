<template>
  <div class="productView relative mt-3">
    <!-- new,hot -->
    <div class="badge badge-red absolute" style="z-index: 1;top:10px;left:10px;">
      {{ data.badges[0] }}
    </div>
    <div class="badge badge-blue absolute" style="z-index: 1;top:30px;left:10px;" v-if="data.priceSales">
      SALE
    </div>
    <div class="badge absolute" style="z-index:333;top:5px;right:5px;" v-if="data.productWishInfo">
      <i class="far fa-heart font18 colorred"></i>
    </div>
     <div class="badge badge-blue absolute" style="z-index:333;top:30px;left:10px;" v-if="data.storageInfo.import==1">
      수입
    </div>
    <div class="badge badge-blue absolute" style="z-index:10;top:108px;left:5px;">
      <div>{{ data.pid }}</div>
      <div>{{ data.factoryNum }} </div>
    </div>
    <div class="productImage">
      <div :style="setbackground(data)" class="productImageItem"></div>
    </div>
    <div class="productInven badge badge-darklight mt-1">
      {{ $productType[data.type].label }}
    </div>
    <div class="mt-1">
      <span class="productPrice">
        {{ $totalInTax(data.price,1,true) }}
      </span>
      <span class="ml-2 bold colorgraylight fontline-through colorgray" v-if="data.priceSales">
        {{ $comma(data.price + data.priceSales) }}
      </span>
    </div>
    <div class="productTitle">
      {{ data.name }}
    </div>
    <div class="product Title">
      <!-- {{ data.pid }}
        {{ data.factoryNum }} -->
    </div>
    <div class="productInven badge badge-black" v-if="data.hasAmount">
      <img class="absolute" style="width:14px;" :class="data.storageInfo.import==1?'china':'korea'" />
      <span class="ml-3">{{ data.storageInfo.name }}</span>
      <span class="ml-1">(Qty:{{ data.hasAmount }})</span>
    </div>
    <div v-else class="productInven badge badge-red">
      SOLD OUT
    </div>
  </div>
</template>
<script>
export default {
  props: ['data'],
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
      }
    }
  },
  mounted() {
  },
  methods: {

    setbackground(item) {
      if (item && item.images && item.images.length > 0) {
        const https = item.images[0].includes('://')
        if (!https) {
          item.images[0] = this.$imageurl + item.images[0]
        }
        return {
          background: `url(${item.images[0]}) no-repeat center center / cover !important`
        };
      } else {
        return {
          // background: `url('${this.$imageurl}/upload/no-image.webp') no-repeat center center / cover`
          background: `url('/static/img/no-image.webp') no-repeat center center / cover`
        };
      }
    },
  }
}
</script>

