<template>
  <div class="container mt-3 relative noselect" style="max-width: 1300px !important;">
    <TopBar />
    <TopMenu />
    <el-row :gutter="15">
      <el-col :xl="5" :xs="24" :sm="5">
        <centermenu />
      </el-col>
      <el-col :xl="24" :xs="24" :sm="24">
        <template>
          <!-- 구매상품리스트 PC -->
          <el-row class="borderbox mt-2 p-2">
            <el-col :xl="12" :md="12" :xs="12" class="font15 bold borderbottom3px-orange mb-2 lh40">
              거래세부정보
            </el-col>
            <el-col :xl="12" :md="12" :xs="12" class="borderbottom3px-orange lh40 text-right">
              <span class="badge badge-darklight font13 hand" @click="$router.go(-1)" style="font-weight: normal;">
                <i class="el-icon-back mr-1 bold"></i>
                돌아가기</span>
            </el-col>
            <el-col>
              <!-- <json-viewer :value="tmpTableData" :expand-depth=1 copyable boxed sort class="mt-3"></json-viewer> -->
              <div v-if="tmpTableData && tmpTableData.returnGoodsInfo" class="borderbox p-2 bigbadge-dark">
                <span class="hand" @click="$changeRoute('orders/ordersView/' + tmpTableData.returnGoodsInfo.sn, 'old')">
                  반품정보가 존재합니다. 상세보기
                </span>
              </div>


              <table class="table-params mt-2 w100p" v-if="tmpTableData">
                <tbody>
                  <tr>
                    <th width="100" class="text-center">거래날자</th>
                    <td width="200">{{ $YYYYMMDD(tmpTableData.updated) }}</td>
                    <th width="100" class="text-center">
                      <div v-if="tmpTableData.reasonType != null">
                        철회구분
                      </div>
                      <div v-else>
                        거래구분
                      </div>
                    </th>
                    <td width="150" class="bold">
                         <div v-if="tmpTableData.reasonType != null && tmpTableData.reasonType >= 0">
                        {{ $reasonType[tmpTableData.reasonType].label }}
                      </div>
                      <div v-else>
                        구매
                      </div>

                    </td>
                    <th width="100" class="text-center">
                      <div v-if="tmpTableData && tmpTableData.reasonType != null">
                        철회상태
                      </div>
                      <div v-else>
                        거래상태
                      </div>
                    </th>
                    <td class="bold">
                      {{ tmpTableData.status >= 0 && $orderProcess[tmpTableData.status].label }}
                    </td>
                  </tr>
                  <tr>
                    <th width="100" class="text-center">주문번호</th>
                    <td width="200">{{ tmpTableData.sn }}</td>
                    <th width="100" class="text-center">구매금액</th>
                    <td>{{ $totalInTax(totalAmount, 1, true) }}</td>
                    <th width="100" class="text-center">배송비용</th>
                    <td>
                      <!-- {{ $totalInTax(tmpTableData.shipping, 1, true) }} -->
                     {{ getlistname($shippingPayType, tmpTableData.shippingPayType) }}
                    </td>
                  </tr>
                  <tr>
                    <th width="100" class="text-center">배송정보</th>
                    <td colspan="5">
                      <div class="listSplit">
                        <span>{{ tmpTableData.name }}</span>
                        <span>{{ tmpTableData.phone }}</span>
                        <span v-if="tmpTableData.postNum">({{ tmpTableData.postNum }})</span>
                        <span>{{ tmpTableData.address }}</span>
                        <span>{{ tmpTableData.addrSub }}</span>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="tmpTableData.status < 3">
                    <th width="100" class="text-center">배송메모</th>
                    <td colspan="5">
                      <div class="listSplit">
                        <span>{{ tmpTableData.memo }}</span>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="tmpTableData.status < 3">
                    <th width="100" class="text-center">택배번호</th>
                    <td colspan="5">
                      <div class="listSplit">
                        <span>{{ tmpTableData.barcode || '-' }}</span>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="tmpTableData.status < 3">
                    <th width="100" class="text-center">배송상태</th>
                    <td colspan="5">
                      <div class="listSplit" v-if="!tmpTableData.deliveryInfo">
                        <span>{{ getlistname($shippingStatus, tmpTableData.status) }}</span>
                      </div>
                      <div v-else>
                        <span>
                          {{ tmpTableData.deliveryInfo.state.text }}
                        </span>
                      </div>
                    </td>
                  </tr>

                </tbody>
              </table>
              <div class="mt-3 bold" v-if="tmpTableData.deliveryInfo">택배실시간</div>
              <table class="table-params mt-2 w100p" v-if="tmpTableData.deliveryInfo">
                <thead>
                  <th width="150" class="center"> 처리시간 </th>
                  <th width="200"> 영업소 </th>
                  <th> 상태 </th>
                </thead>
                <tbody>
                  <tr v-for="item, index in tmpTableData.deliveryInfo.progresses" :key="index">
                    <td class="center">{{ $YYYYMMDD_HHMM(item.time) }}</td>
                    <td>{{ item.location.name }}</td>
                    <td>{{ item.status.text }}</td>
                  </tr>
                </tbody>
              </table>
              <div class="mt-3 bold">상품목록</div>
              <table class="table-params mt-2 w100p">
                <thead>
                  <tr>
                    <th width="50" class="text-center">ID</th>
                    <th class="text-center">상품</th>
                    <th width="50" class="text-right">수량</th>
                    <th width="80" class="text-right">금액</th>
                    <th width="80" class="text-right">소계</th>
                    <th width="120" class="text-center">상태</th>
                    <th width="80" class="text-center" v-if="tmpTableData.status!=1">세부</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in tmpTableData.mallOrderProductInfo" :key="item.seq">
                    <td class="text-center">{{ item.seq }}</td>
                    <td>{{ item.salesProductInfo.name }} 
                      [{{ item.storageName }}]
                      {{ item.salesProductInfo.pid }} {{ item.salesProductInfo.factoryNum }}</td>
                    <td class="text-right">{{ item.qty }}</td>
                    <td class="text-right">{{ $totalInTax(item.price, 1, true) }}</td>
                    <td class="text-right">{{ $totalInTax(item.price, item.qty, true) }}</td>
                    <td class="text-center">
                      <span class="bigbadge-small bigbadge-light" v-if="!item.returnGoodsItem">
                        {{ $orderProcess[item.status].label }}
                      </span>
                      <span class="bigbadge-small bigbadge-light" v-else>
                        {{ $orderProcess[item.returnGoodsItem.status].label }}
                      </span>
                    </td>
                    <td class="text-center"  v-if="tmpTableData.status!=1">
                      <el-button v-if="!item.returnGoodsItem" size="mini" class="btn-mini"
                        @click="retrurnGoodsRequest(item)">
                        세부
                      </el-button>
                      <el-button v-else size="mini" class="btn-mini" disabled>
                        세부
                      </el-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </el-col>
          </el-row>
        </template>
      </el-col>
    </el-row>
    <FootBar />
    <el-backtop>
    </el-backtop>
  </div>
</template>

<script>
import alert from './component-alert.vue'
import TopBar from './component-top.vue'
import TopBanner from './component-top-banner.vue'
import TopNotice from './component-top-notice.vue'
import TopMenu from './component-top-menu.vue'
import FootBar from './component-foot.vue'
import productcard from './component-product-card.vue'
import productcardbutton from './component-product-card-button.vue'
import shippingaddress from './component-checkout-shipping-address.vue'
import centermenu from './component-cener-left-menu.vue'
import { Loading } from 'element-ui'
export default {
  components: {
    TopBar,
    FootBar,
    TopBanner,
    TopNotice,
    TopMenu,
    Loading,
    productcard,
    productcardbutton,
    alert, shippingaddress, centermenu
  },
  data() {
    return {

      tmpTableData: {},
      totalAmount: 0,
      totalQty: 0,
      totalType: 0,
      req: {
        objSearchMapper: {
          searchKeyword: null,
        },
        page: 1,
        size: 1
      }
    }
  },
  beforeDestroy() {
  },

  async created() {
    window.scrollTo(0, 0);
    this.loading = Loading.service({ fullscreen: true })
    this.$loginUserInfo = JSON.parse(localStorage.getItem('loginUserInfo'))
    this.$http.defaults.headers.common['exjwtauthorization'] = this.$loginUserInfo?.token
    await this.read()
  },

  mounted() {
  },
  methods: {
    retrurnGoodsRequest(item) {
      const tmpOrderInfo = { ... this.tmpTableData }
      delete tmpOrderInfo.mallOrderProductInfo
      item.orderInfo = tmpOrderInfo
      item.totalAmount = this.totalAmount || 0
      item.totalQty = this.totalQty || 0
      item.totalType = this.totalType || 0
      localStorage.setItem('returnGoodsRequest', JSON.stringify(item))
      this.$changeRoute('orders/retunGoodsRequest')
    },

    async read() {
      this.req.objSearchMapper.searchKeyword = this.$route.params.sn
      await this.$http.post(`${this.$serverurl}/api/getMallOrderInfo`, this.req)
        .then(res => {
          if (200 == res.data.code) {
            this.tmpTableData = res.data.body.results[0]
            this.totalAmount = 0
            this.totalQty = 0
            this.totalType = 0
            this.notBuylist = []
            for (var item of this.tmpTableData.mallOrderProductInfo) {
              if (item.buy != 9) {
                this.totalAmount += item.qty * item.price
                this.totalQty += item.qty
                this.totalType += 1
              }
            }

          } else {
            if (!res.data.body.results.length) {
              this.$router.go(-1)
              this.$notify.error({
                title: '',
                message: res.data.message,
                position: 'bottom-right',
                showClose: false
              });
            }
          }
          this.$overay(this.loading)
        })
    },
    setbackground(item) {
      if (item && item.images && item.images.length > 0) {
        return {
          background: `url(${this.$imageurl}${item.images[0]}) no-repeat center center / cover !important`
        };
      } else {
        return {
          background: `url('${this.$imageurl}/upload/no-image.webp') no-repeat center center / cover`
        };
      }
    },
    getlistname(list, item) {
      return list[item]?.label
    }

  }

}
</script>