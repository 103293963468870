<template>
    <div class="mt-2">
        <!-- 공지 Container -->
        <el-row :gutter="10" class=" mt-3 mb-3" v-if="$route.name != 'SearchCenter' && $route.name != 'ProductView'">
            <el-col :xs="12" :md="12" :sm="24">
                <div class="font15 badge0 badge-darklight">
                    <span class="mr-1">등록된상품: {{ $comma(countinfo.totalQuantity) }}건</span>
                    보유자산: {{ $comma(countinfo.totalAsset) }}원
                </div>
            </el-col>
            <el-col :xs="12" :md="12" :sm="24">
                <div class="newsContainer text-right">
                    <div class="text-right" v-if="newsTitles">
                        <span class="hand"
                            @click="$router.push('/noticeView/' + newsTitles.type + '/' + newsTitles.category + '/' + newsTitles.seq)">
                            공지 {{ $YYYYMMDD(newsTitles.created) }} 
                            <i class="el-icon-arrow-right"></i>
                            {{ newsTitles.title }}
                        </span>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import goHome from './component-gohome.vue'
export default {
  name: 'TopNotice',
  // props: ['searchKeywordsValue'],
  components: {
    goHome
  },
  data() {
    return {
      newsTitles: null,
      countinfo: {
        totalAsset: 0,
        totalQuantity: 0
      }
    }
  },
  async created() {
    this.$loginUserInfo = JSON.parse(localStorage.getItem('loginUserInfo'))
    this.$http.defaults.headers.common['exjwtauthorization'] = this.$loginUserInfo?.token
    await this.read()
    await this.countInfo()
  },
  methods: {
    async countInfo() {
      await this.$http.post(`${this.$serverurl}/api/getMallProductCalc`, this.req)
        .then(res => {
          if (200 == res.data.code) {
            this.countinfo = {
              totalAsset: res.data.body.results.totalAsset,
              totalQuantity: res.data.body.results.totalQuantity
            }
          }
        })
      this.$overay(this.loading)
    },
    async read() {
      await this.$http.post(`${this.$serverurl}/api/getMallNoticeInfoLast`, this.$setHeader())
        .then(res => {
          if (200 == res.data.code) {
            this.newsTitles = res.data.body.results
          }
        })
    },

  }
}
</script>

<style scoped>
.newsContainer {
    color: #000;
    height: 20px;
    overflow: hidden;
    font-weight: bold;
    font-size: 15px;
}
</style>