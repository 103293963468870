<template>
    <div class="container mt-3 relative noselect" style="max-width: 1300px !important;">
        <TopBar />
        <TopMenu />
        <TopBanner />
        <TopNotice />
    
        <template>
            <div v-if="!formOrderData.length">
                <div class="font15 borderbottom1px colorreddeep bold pb-2">
                    앗! 장바구니에 구매예정 상품이 없습니다.
                </div>
    
                <div class="mt-3">
                    <el-row :gutter="10">
                        <el-col :xl="12" :xs="12" :md="12" :sm="12" @click.native="readRecommand()" class="font15 bold">
                            이런상품 좋아요.
                        </el-col>
                        <el-col :xl="12" :xs="12" :md="12" :sm="12" @click.native="readRecommand()">
                            <span class="hand fixedTooltip-link" style="right:10px;">다른상품 보기</span>
                        </el-col>
                    </el-row>
                </div>
                <el-row :gutter="10">
                    <el-col :xl="4" :xs="8" :md="4" :sm="8" v-for="(item, index) in recommandProducts" :key="index"
                        @click.native="$router.push('/productView/' + item.seq)" class="carousel-container ">
                        <productcard :data="item" />
                    </el-col>
                </el-row>
    
            </div>
            <div v-if="formOrderData.length">
                <el-row v-if="notBuylist.length">
                    <el-col :xl="24" :xs="24" :sm="24" class="mb-2 font15 bold notosanskr">
                        구매불가상품
                    </el-col>
                </el-row>
                <el-row v-for="(item, index) in notBuylist" :key="index" :gutter="10" class="font15 borderbottom1px">
                    <el-col :xl="1" :xs="24" :sm="1" class="p-2">
                        <div :style="setbackground(item.salesProductInfo)" class="productImageItem hand grayscale"
                            @click="openView(item)" style="min-height: 25px;max-width:25px;filter:">
                        </div>
                    </el-col>
                    <el-col :xl="10" :xs="24" :sm="10" class="p-2">
                        <div class="hand colorgraylight fontline-through" @click="openView(item)">
                            {{ item.salesProductInfo.name }}
                        </div>
                    </el-col>
                    <el-col :xl="13" :xs="24" :sm="13" class="p-2 text-right">
                        <el-row>
                            <el-col :xl="22" :xs="22" :sm="22">
                                <div class="mb-2">
                                    <!-- 카트수량: {{ item.qty }} -->
                                    <span class="badge badge-red">SOLD OUT</span>
                                </div>
                            </el-col>
                            <el-col :xl="2" :xs="2" :sm="2">
                                <i class="el-icon-close hand" @click="showAlert(item)"></i>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
            </div>
            <div v-if="formOrderData.length">
                <el-row>
                    <el-col :xl="24" :xs="24" :sm="24" class="mb-2 font15 bold">
                        카트상품
                    </el-col>
                </el-row>
                <el-row v-for="item in formOrderData" :key="item.seq" :gutter="10" class="font15 borderbottom1px">
                    <el-col :xl="4" :xs="24" :sm="4" class="p-2">
                        <div :style="setbackground(item.salesProductInfo)" class="productImageItem hand"
                            @click="openView(item)">
                        </div>
                    </el-col>
                    <el-col :xl="8" :xs="24" :sm="8" class="p-2">
                        <div class="hand font15 bold" @click="openView(item)">{{ item.salesProductInfo.name }}</div>
                        <div>{{ item.salesProductInfo.pid }}</div>
                        <div>{{ item.salesProductInfo.factoryNum }}</div>
                    </el-col>
                    <el-col :xl="12" :xs="24" :sm="12" class="p-2 text-right">
                        <el-row>
                            <el-col :xl="22" :xs="22" :sm="22">
                                <div class="mb-2">
                                    <el-input-number v-model="item.qty" :min="1" :max="item.salesProductInfo.hasAmount"
                                        :step="1" size="mini" readonly class="font15"
                                        @change="calctoal(item)"></el-input-number>
                                </div>
                                <div>
                                    금액: {{ $totalInTax(item.price, 1, true) }}
                                </div>
                                <div>
                                    소계: {{ $totalInTax(item.qty, item.price, true) }}
                                </div>
                            </el-col>
                            <el-col :xl="2" :xs="2" :sm="2">
                                <i class="el-icon-close hand" @click="showAlert(item)"></i>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
                <div class="mt-4 text-right font15">
                    <div class="bigbadge-border w100p">
                        <span class="mr-2">
                            상품건수: {{ $comma(totalType) }}건
                        </span>
                        <span class="mr-2">
                            수량: {{ $comma(totalQty) }}건
                        </span>
                        <span class="mr-2">
                            예정금액: {{ $comma(totalAmount + totalAmount * 0.1) }}원
                        </span>
                    </div>
                </div>
                <el-row class="mt-4">
                    <el-col>
                        <el-button type="danger" size="small" icon="el-icon-bank-card" class="el-btn-orange font13 w100p"
                            @click="$changeRoute('checkout')">
                            {{ $comma(totalAmount + totalAmount * 0.1 + $totalInTax(formData.shipping, 1, false)) }}원
                            결제하기</el-button>
                    </el-col>
                </el-row>
            </div>
        </template>
        <alert :message="delmessage" v-if="showAlertValue" @callback="handleDelete" />
    
        <FootBar />
        <el-backtop>
        </el-backtop>
    </div>
</template>

<script>
import alert from './component-alert.vue'
import TopBar from './component-top.vue'
import TopBanner from './component-top-banner.vue'
import TopNotice from './component-top-notice.vue'
import TopMenu from './component-top-menu.vue'
import FootBar from './component-foot.vue'
import productcard from './component-product-card.vue'
import productcardbutton from './component-product-card-button.vue'
import shippingaddress from './component-checkout-shipping-address.vue'
import { Loading } from 'element-ui'
export default {
  components: {
    TopBar,
    FootBar,
    TopBanner,
    TopNotice,
    TopMenu,
    Loading,
    productcard,
    productcardbutton,
    alert, shippingaddress
  },
  data() {
    return {
      recommandProducts: [],
      showShippingAddressValue: false,
      showAlertValue: false,
      delmessage: '해당항목이 곧 삭제됩니다.<br/>계속하시겠습니까?',
      delItems: {},
      cartInfo: {},
      loginUserInfo: null,
      tmpTableData: [],
      totalAmount: 0,
      totalQty: 0,
      totalType: 0,
      changeFromInfo: false, // 발송자정보변경스위치
      policyAgree: false, // 구매약관동의스위치
      formData: {
        fromName: null,  // 발송자이름
        fromPhone: null,    // 발송자연락처
        postNum: null,
        address: null,
        addrSub: null,
        name: null,
        phone: null,
        shipping: 0,//배송요금
        shippingValue: 1, // 배송사 선택값
        arrive: null,//화물영업소지점
        barcode: null, // 운송번호
        memo: null, // 배송메모

        shippingPayType: 0, // 배송요금입력판단값
      },
      formOrderData: [],
      notBuylist: [],
    }
  },
  beforeDestroy() {
  },

  async created() {
    window.scrollTo(0, 0);
    this.loading = Loading.service({ fullscreen: true })
    this.$loginUserInfo = JSON.parse(localStorage.getItem('loginUserInfo'))
    this.$http.defaults.headers.common['exjwtauthorization'] = this.$loginUserInfo?.token
    await this.read()
    await this.readRecommand()
  },

  mounted() {
  },
  methods: {
    showAlert(data) {
      this.delItems = data
      this.showAlertValue = true
    },
    async readRecommand() {
      const req = {
        objSearchMapper: { searchKeyword: null, }, page: 1, size: 30
      }
      await this.$http.post(`${this.$serverurl}/api/getRecommandProductInfo`, req)
        .then(res => {
          if (200 == res.data.code) {
            this.recommandProducts = res.data.body.results
          }
        })
      this.$overay(this.loading)
    },
    async calctoal(data) {
      this.totalAmount = 0
      this.formOrderData.forEach(item => {
        this.totalAmount += item.qty * item.price
      })
      await this.$http.post(`${this.$serverurl}/api/addToCart`, data)
        .then(res => {
          // this.$notify.success({
          //     title: '',
          //     message: res.data.message,
          //     position: 'bottom-right',
          //     showClose: false
          // });
          setTimeout(() => {
            this.read()
          }, 300);
        })

    },
    async handleDelete(data) {
      this.showAlertValue = false
      if (!data.agree) { return }
      this.loading = Loading.service({ fullscreen: true })
      await this.$http.post(`${this.$serverurl}/api/delCartInfo`, this.delItems)
        .then(res => {
          if (201 == res.data.code) {
            this.$notify.success({
              title: '',
              message: res.data.message,
              position: 'bottom-right',
              showClose: false
            });
            setTimeout(() => {
              this.read()
            }, 300);
            this.$overay(this.loading)
          }
        })
    },
    async read() {
      const req = {
        objSearchMapper: {
          searchKeyword: null,
        },
        page: 1,
        size: 50
      }
      await this.$http.post(`${this.$serverurl}/api/getCartInfo`, req)
        .then(res => {
          if (200 == res.data.code) {
            this.tmpTableData = res.data.body.results
            this.totalAmount = 0
            this.totalQty = 0
            this.totalType = 0
            this.formOrderData = []
            this.notBuylist = []
            for (var item of this.tmpTableData) {
              if (item.buy != 9) {
                this.totalAmount += item.qty * item.price
                this.totalQty += item.qty
                this.totalType += 1
                this.formOrderData.push(item)
              } else {
                this.notBuylist.push(item)
              }
            }
          } else {
            this.tmpTableData = []
            this.totalAmount = 0
            this.totalQty = 0
            this.totalType = 0
            this.formOrderData = []
            // if (!res?.data?.body?.results?.length) {
            //     // this.$changeRoute('main')
            //     this.$notify.error({
            //         title: '',
            //         message: res.data.message,
            //         position: 'bottom-right',
            //         showClose: false
            //     });
            // }
          }
          this.$overay(this.loading)
        })
    },
    setbackground(item) {
      if (item && item.images && item.images.length > 0) {
        return {
          background: `url(${this.$imageurl}${item.images[0]}) no-repeat center center / cover !important`
        };
      } else {
        return {
          background: `url('${this.$imageurl}/upload/no-image.webp') no-repeat center center / cover`
        };
      }
    },

  }

}
</script>