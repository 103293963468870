<template>
  <div class="container mt-3 relative noselect" style="max-width: 1300px !important;">
    <TopBar />
    <TopMenu />
    <div class="mt-3 font15 bold borderbottom3px-orange pb-2">
      고객 문의글
    </div>
    <table class="table-params mt-2 w100p" v-if="tmpTableData">
      <tbody>
        <tr>
          <th style="width:100px;"> 문의글제목 </th>
          <td>
            {{ tmpTableData.title }}
          </td>
        </tr>
        <tr>
          <th style="width:100px;"> 문의내용 </th>
          <td>
            <div v-html="$toHtml(tmpTableData.content)"></div>
          </td>
        </tr>
        <tr>
          <th style="width:100px;"> 문의날자 </th>
          <td>
            {{ $YYYYMMDD_HHMM(tmpTableData.created) }}
          </td>
        </tr>
        <tr>
          <th style="width:100px;"> 상태 </th>
          <td>
            <div v-if="tmpTableData.views < 2">
              {{ getViewLabel(tmpTableData) }}
            </div>
            <div v-else> {{ '확인' }}</div>
          </td>
        </tr>
        
        <tr v-if="tmpTableData.views > 1">
          <th style="width:100px;"> 관리자 답글 </th>
          <td>
            {{ tmpTableData.reply }}
          </td>
        </tr>
        <tr v-else>
          <th style="width:100px;"> 관리자 답글 </th>
          <td>
            답글 준비중입니다...
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-right mt-2">
      <el-button size="mini" icon="fas fa-arrow-circle-left" class="el-btn-orange"
      @click="$router.go(-1)">
        돌아가기
      </el-button>
    </div>
    <FootBar />
    <el-backtop>
    </el-backtop>
  </div>
</template>

<script>
import TopBar from './component-top.vue'
import TopBanner from './component-top-banner.vue'
import TopNotice from './component-top-notice.vue'
import TopMenu from './component-top-menu.vue'
import FootBar from './component-foot.vue'
import { Loading } from 'element-ui'
export default {
  components: {
    Loading, TopBar, TopBanner, TopMenu, FootBar
  },
  data() {
    return {
      tmpTableData: null,
      req: {
        objSearchMapper: {
          seq: null,
        }
      }
    }
  },
  beforeDestroy() {
  },

  async created() {
    window.scrollTo(0, 0);
    this.loading = Loading.service({ fullscreen: true })
    this.$loginUserInfo = JSON.parse(localStorage.getItem('loginUserInfo'))
    this.$http.defaults.headers.common['exjwtauthorization'] = this.$loginUserInfo?.token
    this.req.objSearchMapper.seq = this.$route.params.id
    await this.read()
  },

  mounted() {
  },
  methods: {
    getViewLabel(item) {
      return this.$boardViewsType.find(typeItem => typeItem.value == item?.views)?.label
    },
    async read() {
      this.req.objSearchMapper.searchKeyword = this.$route.params.sn
      await this.$http.post(`${this.$serverurl}/api/getMallQnaInfoOne`, this.req)
        .then(res => {
          if (200 == res.data.code) {
            this.tmpTableData = res.data.body.results
          } else {
            if (!res.data.body.results.length) {
              this.$notify.error({
                title: '',
                message: res.data.message,
                position: 'bottom-right',
                showClose: false
              });
            }
          }

          this.$overay(this.loading)
        })
    },
  }

}
</script>