<template>
    <el-dialog title="경고" :visible.sync="visible" width="30%" 
    :before-close="handleClose"
    center v-draggable>
        <div class="text-center font15 lh30" style="margin-bottom:50px !important;">
            <span v-html="message"></span>
        </div>
        <div class="text-center">
            <el-button @click="returnMessage(false)">취소</el-button>
            <el-button type="danger" @click="returnMessage(true)">계속하기</el-button>
        </div>
    </el-dialog>

</template>

<script>
export default {
    name: 'alert',
    props: ['message'],
    data() {
        return {
            visible: true
        }
    },
    async created() {

    },
    methods: {
        async returnMessage(params) {
            this.$emit('callback', {agree:params})
        },
        handleClose(done){
            this.$emit('callback',{agree:false})
            done()
        }
    }
}
</script>

<style scoped></style>