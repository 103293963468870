<template>
    <div>

        <!-- 첫페이지 -->
        <!-- <label class="hand pageButton" @click="goToFirstPage" v-if="currentPage != 0">
            <i class="el-icon-d-arrow-left"></i>
        </label>
        <label class="pageButton" v-else>
            <i class="el-icon-d-arrow-left colorlight"></i>
        </label> -->

        <!-- 이전페이지 -->
        <!-- <label class="hand pageButton" @click="prevPage" v-if="currentPage != 0">
            <i class="el-icon-arrow-left"></i>
        </label>
        <label class="pageButton" v-else>
            <i class="el-icon-arrow-left colorlight"></i>
        </label> -->

        <!-- 페이지 -->
        <!-- <label style="max-width:200px; overflow: hidden;overflow-x: auto;" class="nowrap">
            <label v-for="(item, pageNumber) in pageData" :key="pageNumber" @click="goToPage(pageNumber)"
                :class="{ active: currentPage === pageNumber }" class="pageButton hand">
                {{ pageNumber + 1 }}
            </label>
        </label> -->

        <!-- 다음페이지 -->
        <!-- <label class="hand pageButton" v-if="currentPage !== pageData.length - 1" @click="nextPage">
            <i class="el-icon-arrow-right"></i>
        </label>
        <label class="pageButton" v-else>
            <i class="el-icon-arrow-right colorlight"></i>
        </label> -->

        <!-- 마지막페이지 -->
        <!-- <label class="hand pageButton" @click="goToLastPage" v-if="currentPage != pageData.length - 1">
            <i class="el-icon-d-arrow-right"></i>
        </label>
        <label class="pageButton" v-else>
            <i class="el-icon-d-arrow-right colorlight"></i>
        </label> -->

        <span class="scroll-container">
            <!-- 좌측 스크롤 버튼 -->
            <label class="hand pageButton" @click="scrollPage(-1)">
                <i class="el-icon-d-arrow-left"></i>
            </label>
            <!-- 페이지 버튼 컨테이너 -->
            <div class="pageButtonContainer" ref="pageButtonContainer">
                <!-- 페이지 버튼들 -->
                <label v-for="(item, pageNumber) in pageData" :key="pageNumber" @click="goToPage(pageNumber)"
                    :class="{ active: currentPage === pageNumber }" class="pageButton hand">
                    {{ pageNumber + 1 }}
                </label>
            </div>
            <!-- 우측 스크롤 버튼 -->
            <label class="hand pageButton" @click="scrollPage(1)">
                <i class="el-icon-d-arrow-right"></i>
            </label>
        </span>
    </div>
</template>

<script>
export default {
    name: 'pagingbar',
    props: {
        currentMid: {
            type: String,
            required: true
        },
        pageData: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            currentPage: 1
        }
    },
    watch: {
        currentMid: {
            deep: true,
            immediate: true,
            async handler(newValue, oldValue) {
                this.currentPage = this.pageData.findIndex(item => item.mid == this.currentMid)
            }
        }
    },
    methods: {
        scrollPage(direction) {
            const container = this.$refs.pageButtonContainer;
            if (container) {
                const scrollAmount = 305; // 이동할 양 (조정 가능)
                const startPosition = container.scrollLeft;
                const targetPosition = startPosition + (scrollAmount * direction);
                const duration = 500; // 애니메이션 지속 시간 (밀리초)

                this.animateScroll(container, startPosition, targetPosition, duration);
            }
        },
        animateScroll(container, startPosition, targetPosition, duration) {
            const startTime = performance.now();
            const easeInOutQuad = t => t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;

            const animate = (currentTime) => {
                const elapsedTime = currentTime - startTime;
                if (elapsedTime < duration) {
                    const progress = easeInOutQuad(elapsedTime / duration);
                    container.scrollLeft = startPosition + ((targetPosition - startPosition) * progress);
                    requestAnimationFrame(animate);
                } else {
                    container.scrollLeft = targetPosition;
                }
            };

            requestAnimationFrame(animate);
        },


        goToPage(pageNumber) { // 페이지이동 및 콜백
            this.currentPage = pageNumber
            const middata = this.pageData[pageNumber]
            this.$emit('callback', middata);
        },
        // goToFirstPage() {    // 첫페이지
        //     this.goToPage(0);
        // },
        // goToLastPage() { // 마지막페이지
        //     this.goToPage(this.pageData.length - 1)
        // },
        // prevPage() {    // 이전페이지
        //     if (this.currentPage > 0) {
        //         this.goToPage(this.currentPage - 1)
        //     }
        // },
        // nextPage() {    // 다음페이지
        //     if (this.currentPage < this.pageData.length) {
        //         this.goToPage(this.currentPage + 1)
        //     }
        // }
    }
};
</script>

<style scoped>
.scroll-container {
    max-width: 410px;
    display: flex;
    align-items: center;
    overflow-x: hidden;
}

.pageButtonContainer {
    display: flex;
    align-items: center;
    overflow-x: auto;
    scroll-behavior: smooth;
}

.scrollButton {
    cursor: pointer;
    padding: 5px;
}



.pageButton {
    margin: 0 2px;
    padding: 2px 2px 2px;
    border: 1px #efefef solid;
    min-width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 5px;
}

.pageButton:hover {
    background-color: #efefef;
    color: #000;
}
.pageButton:active {
    background-color: #333;
    color: #fff;
}

/* .pageButton:disabled {
    opacity: 0.5;
    cursor: not-allowed;
} */

.pageButton.active {
    font-weight: bold;
    background-color: #000;
    color: #fff;
}
</style>