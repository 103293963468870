<template>
  <div class="container mt-3 relative noselect" style="max-width: 1300px !important;">
    <TopBar />
    <TopMenu />
    <!-- <TopBanner /> -->
    <!-- <TopNotice /> -->
    <el-row :gutter="15">
      <el-col :xl="5" :xs="24" :sm="5">
        <centermenu />
      </el-col>
      <el-col :xl="24" :xs="24" :sm="24">
        <el-row class="borderbox mt-2 p-2">
          <el-col class="borderbottom1px lh40 relative">
            <span class="font18 bold">
              계정관리
            </span>
            <span class="absolute hand fixedTooltip-link" style="top:5px;right:5px;" @click="changePassword = true">
            </span>
          </el-col>
          <el-col class="relative font13">
            <el-row :gutter="10">
              <el-col :xl="6" :xs="24" :sm="6" class="mt-2">
                <div class="relative" style="right:0px;">
                  <el-input size="mini" type="text" placeholder="검색어를 입력하세요."
                    style="margin: 0px !important; padding: 0px !important;" v-model="searchKeyword"
                    @keyup.enter.native="read()"></el-input>
                  <button class="searchButton absolute" style="top:0px;right:0px;" @click="read()">
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </el-col>
              <el-col :xl="18" :xs="24" :sm="18" class="text-right mt-2">
                <el-button type="info" size="mini" @click="readState()">
                  {{ req.objSearchMapper.state == 0 ? '정지계정' : '사용계정' }}
                </el-button>
                <el-button type="primary" size="mini" @click="shopCreateMemberInfoWindow()">
                  <i class="fas fa-pen-to-square"></i>
                  계정등록</el-button>
              </el-col>
            </el-row>
            <el-table size="mini" :data="tmpTableData" border style="width: 100%" class="mt-3">
              <el-table-column label="" width="50" align="center">
                <template slot="header">
                  <!-- <i class="fas fa-fingerprint"></i> -->
                  <el-checkbox disabled></el-checkbox>
                </template>
                <template slot-scope="scope">
                  <span v-if="scope.row.type == 0">
                    <i class="fas fa-fingerprint"></i>
                  </span>
                    <el-checkbox @change="pushId($event, scope.row)" v-if="scope.row.type"></el-checkbox>

                </template>
              </el-table-column>
              <el-table-column label="최근날자" width="110">
                <template slot-scope="scope">
                  <span class="fixedTooltip-link-f hand" @click="shopCreateMemberInfoWindow(scope.row)">
                    {{ $YYYYMMDD(scope.row.updated) }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="상태" width="100">
                <template slot-scope="scope">
                  <span class="nowrap" v-if="scope.row.withdrawalAt">탈퇴</span>
                  <span class="nowrap" v-if="!scope.row.withdrawalAt">정상</span>
                </template>
              </el-table-column>
              <el-table-column label="계정" width="150">
                <template slot-scope="scope">
                  <span class="nowrap">{{ scope.row.id }}</span>
                </template>
              </el-table-column>
              <el-table-column label="이름" width="100">
                <template slot-scope="scope">
                  <span class="nowrap">{{ scope.row.name }}</span>
                </template>
              </el-table-column>
              <el-table-column label="연락처" width="110" :align="'center'">
                <template slot-scope="scope">
                  <span class="nowrap">{{ scope.row.phone }}</span>
                </template>
              </el-table-column>
              <el-table-column label="메일">
                <template slot-scope="scope">
                  <div class="nowrap">
                    <span>{{ scope.row.mail }}</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <el-row>
              <el-col :xl="16" :xs="24" :md="16" class="mt-2" v-if="checkedIds.length">
                <el-button type="" size="mini" class="el-btn-light" @click="setMallAccount('initPassword')">
                  암호초기화
                  {{ !$isMobile ? '(123456)' : '' }}
                </el-button>
                <el-button type="" size="mini" class="el-btn-light" @click="setMallAccount('accountPause')"
                  v-if="req.objSearchMapper.state == 0">
                  {{ !$isMobile ? '계정사용' : '' }}
                  정지
                </el-button>
                <el-button type="" size="mini" class="el-btn-light" @click="setMallAccount('accountUse')"
                  v-if="req.objSearchMapper.state == 1">
                  {{ !$isMobile ? '계정' : '' }}
                  사용</el-button>
                <!-- <el-button type="" size="mini" class="el-btn-orange" @click="setMallAccount('global')"
                  v-if="req.objSearchMapper.state == 0">
                  대표권한
                  {{ !$isMobile ? '설정' : '' }}
                </el-button> -->
              </el-col>
              <el-col :xl="checkedIds.length ? 8 : 24" :xs="24" :md="checkedIds.length ? 8 : 24">
                <div class="text-right mt-2">
                  <el-pagination background layout="prev, pager, next" :total="totalCount" :page-size="req.size"
                    @current-change="handlePageChange">
                  </el-pagination>
                </div>
              </el-col>
            </el-row>

          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <mallmemberinfocreate :data="selectitem" v-if="showMallMemberinfocrete" @callback="createMallMemberinfo" />
    <FootBar />
    <el-backtop>
    </el-backtop>
  </div>
</template>
<script>
import componentInputText from './component-input-text.vue';

import TopBar from './component-top.vue'
import TopBanner from './component-top-banner.vue'
import TopNotice from './component-top-notice.vue'
import TopMenu from './component-top-menu.vue'
import FootBar from './component-foot.vue'
import centermenu from './component-cener-left-menu.vue'
import { Loading } from 'element-ui'
import mallmemberinfocreate from './component-mall-member-info-create.vue'
export default {
  components: {
    centermenu, TopBar, TopBanner, TopNotice, TopMenu, FootBar, Loading, componentInputText,
    mallmemberinfocreate
  },
  data() {
    return {
      selectitem: null,
      showMallMemberinfocrete: false,
      loginUserInfo: null,
      loading: null,
      tmpTableData: [],
      totalCount: 0,
      req: {
        objSearchMapper: {
          searchKeyword: null,
          state: 0,
        },
        page: 1,
        size: 10
      },
      searchKeyword: null,

      checkedIds: [],
      checkedAll: false,
    }
  },
  created() {
    window.scrollTo(0, 0);
    this.loading = Loading.service({ fullscreen: true })
    this.$loginUserInfo = JSON.parse(localStorage.getItem('loginUserInfo'))
    this.$checkAuth(this.$loginUserInfo) // type==0 대표권한
    this.$http.defaults.headers.common['exjwtauthorization'] = this.$loginUserInfo?.token
    this.getMallMebmerInfo()
    this.$overay(this.loading)
  },
  methods: {

    async setMallAccount(params) {
      await this.$http.post(`${this.$serverurl}/api/${params}`, this.checkedIds)
        .then(res => {
          if (201 == res.data.code) {
            this.checkedIds = []
            this.getMallMebmerInfo()
            this.$notify.success({
              title: '',
              message: res.data.message,
              position: 'bottom-right',
              showClose: true,
              duration: 1000,
            });
          }
        })
    },
    pushId(isChecked, item) {
      const seq = item.seq
      const exists = this.checkedIds.includes(seq);
      if (isChecked && !exists) {
        this.checkedIds.push(seq);
      } else if (!isChecked && exists) {
        const index = this.checkedIds.indexOf(seq);
        this.checkedIds.splice(index, 1);
      }
    },
    pushIdAll(isChecked) {
      const ids = this.tmpTableData.map(item => item.seq)
      if (isChecked) {
        this.checkedAll = true
        this.checkedIds = ids
      } else {
        this.checkedAll = false
        this.checkedIds = []
      }
    },
    readState() {
      this.req.objSearchMapper.state = this.req.objSearchMapper.state == 0 ? 1 : 0
      this.read()
    },
    shopCreateMemberInfoWindow(item) {
      if (item) { this.selectitem = JSON.parse(JSON.stringify(item)) } else {
        this.selectitem = null
      }
      this.showMallMemberinfocrete = true
    },
    createMallMemberinfo(data) {
      this.showMallMemberinfocrete = false
      this.getMallMebmerInfo()
    },

    handlePageChange(page) {
      this.req.page = page
      this.getMallMebmerInfo()
    },
    async read() {
      this.req.objSearchMapper.searchKeyword = this.searchKeyword
      await this.getMallMebmerInfo()
    },
    async getMallMebmerInfo() {
      this.tmpTableData = []
      await this.$http.post(`${this.$serverurl}/api/getMallMebmerInfo`, this.req)
        .then(res => {
          if (200 == res.data.code) {
            this.tmpTableData = res.data.body.results
            this.totalCount = res.data.totalCount
          } else {
            this.$notify.error({
              title: '',
              message: res.data.message,
              position: 'bottom-right',
              showClose: true,
              duration: 1000,
            });
            this.tmpTableData = []
          }
        })
      this.$overay(this.loading)
    },

  },
}

</script>
<style></style>
