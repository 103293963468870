<template>
  <div class="container mt-3 relative" style="max-width: 800px !important;">
    <SubTopBar @callback="callbackData" :searchKeywordParent="req.objSearchMapper.searchKeyword" />

    <div class="mt-3 formTitle bold">
      {{ title }}
    </div>

    <el-tabs v-model="activeName" tab-position="center">
      <el-tab-pane :label="item.title" :name="item.name" v-for="(item, index) in types" :key="index">
        <el-row :gutter="20">
          <el-col :md="24">
            <div class="font15 pb-1 mb-3 hand pl-3" style="border-bottom:2px #E4E7ED solid;">
              <span v-for="(subitem, subindex) in item.subitem" :key="subindex"
                @click="changeCategory(subitem.type, subitem.catetory)">
                <label class="mr-4">
                  {{ subitem.title }}
                </label>
              </span>
            </div>
          </el-col>
          <el-col :xs="24" :md="24" :sm="24">
            <div class="newsTitles pl-3">
              <div class="item hand" v-for="(item, index) in tmpTableData" :key="index">
                <el-row class="borderbottom1px">
                  <el-col :xs="24" :md="20" :sm="24">
                    <label class="ellip w100p" @click="$router.push('/noticeView/' + item.type + '/' + item.category + '/' + item.seq)">
                      [{{ categorys[item.category].title }}]
                      {{ item.title }}
                    </label>
                  </el-col>
                  <el-col :xs="24" :md="4" :sm="24" class="right">
                    <label class="font15 colorgray">{{ $YYYYMMDD(item.updated) }}</label>
                  </el-col>
                </el-row>
              </div>

              <div v-if="!tmpTableData.length">
                검색된 정보가 없습니다.
              </div>
            </div>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>

  </div>
</template>

<script>
import SubTopBar from './component-sub-top.vue'
export default {
  components: {
    SubTopBar
  },
  data() {
    return {
      activeName: 'tab1',
      title: '공지사항',
      types: [
        {
          id: 0, title: '공지사항', name: 'tab1', subitem: [
            { id: 0, title: '일반공지', type: 0, category: 0 },
            { id: 1, title: '정책안내', type: 0, catetory: 1 },
            { id: 2, title: '분쟁사례', type: 0, catetory: 2 },
          ]
        },
        {
          id: 2, title: '사용가이드', name: 'tab2', subitem: [
            {
              id: 0, title: '사용가이드',
              type: 2,
              catetory: 0
            }
          ]
        },
        {
          id: 3, title: '자주하는 질문', name: 'tab3', subitem: [
            {
              id: 0, title: '자주하는 질문', type: 3,
              catetory: 0
            }
          ]
        },
      ],
      categorys: [
        { id: 0, title: '일반공지' },
        { id: 1, title: '정책안내' },
        { id: 2, title: '분쟁사례' },
      ],
      req: {
        objSearchMapper: {
          type: 0,
          searchKeyword: null,
          category: 0
        },
        page: 1,
        size: 50
      },
      tmpTableData: [],
      totalCount: 0,
      loginUserInfo: null,
    };
  },
  methods: {

  },
  beforeDestroy() {
  },
  async created() {
    if (this.$route.params.type && this.$route.params.cate) {
      this.req.objSearchMapper.type = this.$route.params.type
      this.req.objSearchMapper.category = this.$route.params.cate
    }
    this.$loginUserInfo = JSON.parse(localStorage.getItem('loginUserInfo'))
    this.$http.defaults.headers.common['exjwtauthorization'] = this.$loginUserInfo?.token
    await this.read()
  },
  methods: {
    callbackData(data) {
      this.req.objSearchMapper.searchKeyword = data
      this.read()
    },
    changeCategory(type, cate) {
      if (!type) { type = 0 }
      this.req.objSearchMapper.type = type
      this.req.objSearchMapper.category = cate
      this.req.objSearchMapper.searchKeyword = null
      this.read()

    },
    async read() {
      await this.$http.post(`${this.$serverurl}/api/getMallNoticeInfo`, this.req)
        .then(res => {
          if (200 == res.data.code) {
            this.tmpTableData = res.data.body.results
            this.totalCount = res.data.body.total
          }
          if (400 == res.data.code) {
            this.tmpTableData = []
            this.totalCount = 0
          }
        })
    },
  }

}
</script>

<style></style>
