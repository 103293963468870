<template>
    <el-dialog title="배송지등록" :fullscreen="$isMobile ? true : false" :visible.sync="visible" width="30%"
        :before-close="handleClose" center v-draggable>
        <div class="mb-2 font15 lh30">
            <el-row class="mb-2">
                <el-col :xl="24" :xs="24" :sm="24">
                    <el-input placeholder="수취인" size="mini" v-model="formData.name">
                    </el-input>
                </el-col>
                <el-col :xl="24" :xs="24" :sm="24">
                    <el-input placeholder="전화번호" size="mini" v-model="formData.tel">
                    </el-input>
                </el-col>
                <el-col :xl="24" :xs="24" :sm="24">
                    <el-input placeholder="휴대폰" size="mini" v-model="formData.phone">
                    </el-input>
                </el-col>
                <el-col :xl="24" :xs="24" :sm="24">
                    <el-input placeholder="우편번호" size="mini" prefix-icon="el-icon-search" readonly class="hand"
                        v-model="formData.postNum" @click.native="loadDaumPostcodeScript">
                    </el-input>
                    <el-input placeholder="주소" size="mini" v-model="formData.address" readonly>
                    </el-input>
                    <el-input placeholder="세부정보" size="mini" v-model="formData.addrSub">
                    </el-input>
                </el-col>
                <el-col :xl="24" :xs="24" :sm="24">
                    <el-checkbox class="ml-2" :checked="formData.basic == 0" @change="changeBasic">기본주소로
                        설정</el-checkbox>
                </el-col>
            </el-row>

        </div>
        <div class="text-right">
            <el-button @click="returnMessage(false)" size="mini">창닫기</el-button>
            <el-button @click="returnMessage(true)" size="mini" type="primary">등록</el-button>
        </div>
    </el-dialog>

</template>

<script>
export default {
    name: 'shippingaddresscreate',
    props: ['data'],
    data() {
        return {
            visible: true,
            tmpTabledata: [],
            formData: {
                name: '',
                tel: '',
                phone: '',
                postNum: '',
                address: '',
                addrSub: '',
                basic: 1, // 백엔드에서  true,false로 변경함
            },
            req: {
                objSearchMapper: {
                    searchKeyword: null,
                },
                page: 1,
                size: 10
            }
        }
    },
    async created() {
        if (this.data) {
            this.formData = this.data
            this.formData.basic = this.data.basic == 'true' ? 0 : 1
        }
    },
    methods: {
        changeBasic(data) {
            if (data) {
                this.formData.basic = 0
            } else {
                this.formData.basic = 1
            }
        },
        async returnMessage(params) {
            if (params) {
                if (!this.formData.name.trim()) { alert('수취인이름을 입력하세요.'); return }
                if (!this.formData.phone.trim()) { alert('휴대폰번호를 입력하세요.'); return }
                if (!this.formData.postNum.trim()) { alert('주소를 선택하세요.'); return }
                if (!this.formData.addrSub.trim()) { alert('세부주소를 입력하세요.'); return }
                await this.$http.post(`${this.$serverurl}/api/setMemberShippingInfo`, this.formData)
                    .then(res => {
                        if (res.data.code == 201) {
                            this.formData = {
                                name: '',
                                tel: '',
                                phone: '',
                                postNum: '',
                                address: '',
                                addrSub: '',
                                basic: 1,
                            }
                            this.$notify.success({
                                title: '',
                                message: res.data.message,
                                position: 'bottom-right',
                                showClose: false
                            });
                            this.$emit('callback', false)
                        } else {
                            alert(res.data.message)
                        }
                    })
            }else{
                this.$emit('callback', false)
            }
        },
        handleClose(done) {
            // this.$emit('callback', false)
            // done()
        },

        loadDaumPostcodeScript() {
            const script = document.createElement("script");
            script.src =
                "//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";
            script.async = true;
            script.onload = () => {
                this.initializeDaumPostcode();
            };
            document.head.appendChild(script);
        },
        initializeDaumPostcode() {
            const daum = null
            new (window).daum.Postcode({
                oncomplete: (data) => {
                    // data에 선택한 주소 정보가 들어 있음
                    this.formData.postNum = data.zonecode
                    this.formData.address = data.address
                },
            }).open();
        },

    }
}
</script>

<style scoped></style>