<template>
    <div class="mt-2">
        <el-row>
            <el-col :xl="8" :xs="24" :md="8" :sm="24">
                <div class="lastBlur">
                    <el-breadcrumb separator-class="el-icon-arrow-left" class="gohomemd">
                        <el-breadcrumb-item class="font18"></el-breadcrumb-item>
                        <el-breadcrumb-item @click.native="$router.go(-1)" class="hand font18">돌아가기</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </el-col>
            <el-col :xl="16" :xs="24" :md="16" :sm="24" class="relative" v-if="!hiddenSearchbox">
                <div class="searchBoxmd"  style="max-width:250px;top:10px;right:0px;position: absolute;">
                    <input type="text" placeholder="Search..." v-model="searchKeyword"
                        @keyup.enter="sendToParent(searchKeyword)" />
                    <button class="searchButton" @click="sendToParent(searchKeyword)">
                        <i class="fa fa-search"></i>
                    </button>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import goHome from './component-gohome.vue'
export default {
    name: 'SubTopBar',
    props: ['searchKeywordParent','hiddenSearchbox'],
    components: {
        goHome
    },
    data() {
        return {
            drawer: false, //모바일 메뉴창
            loginUserInfo: null,
            newsTitles: [],
            searchKeyword: null,
        }
    },
    async created() {
        this.$loginUserInfo = JSON.parse(localStorage.getItem('loginUserInfo'))
        this.$http.defaults.headers.common['exjwtauthorization'] = this.$loginUserInfo?.token
        await this.read()
        setInterval(() => {
            this.startMove()
            this.searchKeyword= this.searchKeywordParent
        }, 3000)

    },
    methods: {
        menuClose(done) {
            done()
        },
        startMove() {
            const lastItem = this.newsTitles.pop()
            this.newsTitles.unshift(lastItem)
        },
        async read() {
            const request = {
                objSearchMapper: {
                    sarchKeyword: null,
                    category: 0,
                    type: 0
                },
                page: 1,
                size: 10
            }
            await this.$http.post(`${this.$serverurl}/api/getMallNoticeInfo`, request)
                .then(res => {
                    if (200 == res.data.code) {
                        this.newsTitles = res.data.body.results
                    }
                })
        },
        sendToParent(data) {
            const encodeSearchKeyword = encodeURIComponent(data)
            this.$emit('callback', data)
            this.drawer = false
        }
    }
}
</script>

<style scoped>
.newsContainer {
    color: #000;
    height: 20px;
    overflow: hidden;
}

.newsContainer .slide {
    position: relative;
}

.slide div {
    transition: transform 1s ease;
    font-size: 15px;
}

.slide-enter-active,
.slide-leave-active {
    transition: transform 0.2s;
}

.slide-enter,
.slide-leave-to {
    transform: translateY(30px);
}
</style>