<template>
  <div class="container mt-3 relative noselect" style="max-width: 1300px !important;">
    <TopBar />
    <TopMenu />
    <el-row :gutter="15">
      <el-col :xl="5" :xs="24" :sm="5">
        <centermenu />
      </el-col>
      <el-col :xl="24" :xs="24" :sm="24">
        <el-row class="borderbox mt-2 p-2">
          <el-col class="borderbottom1px lh40 relative">
            <span class="font18 bold">
              라이센스관리
            </span>
          </el-col>
          <el-col class="relative">
            <div class="mt-2">
            </div>
            <el-row :gutter="10" class="font15 lh40">
              <el-col :xl="24" :xs="24" :sm="24">
                <div class="pl-3 pr-3">
                  <el-row>
                    <el-col :xl="24" :xs="24" :md="24">
                      <el-row>
                        <el-col :xl="4" :md="4" :xs="7">회사이름</el-col>
                        <el-col :xl="20" :md="20" :xs="17" :align="$isMobile ? 'right' : 'left'">
                          <span>{{ tmpTableData.name }}</span>
                        </el-col>
                      </el-row>
                    </el-col>
                    <el-col :xl="24" :xs="24" :md="24">
                      <el-row>
                        <el-col :xl="4" :md="4" :xs="7">라이센스</el-col>
                        <el-col :xl="20" :md="20" :xs="17" :align="$isMobile ? 'right' : 'left'">
                          <span v-if="tmpTableData && tmpTableData.licenseInfo">
                            <span>{{ $comma(tmpTableData.licenseInfo.remaDays>0?tmpTableData.licenseInfo.remaDays:0)
                              }}일</span>
                          </span>
                          <span v-else>
                            <span>-</span>
                          </span>
                        </el-col>
                      </el-row>
                    </el-col>
                    <el-col :xl="24" :xs="24" :md="24">
                      <el-row>
                        <el-col :xl="4" :md="4" :xs="7" v-if="!$isMobile">구매상품</el-col>
                        <el-col :xl="20" :md="20" :xs="$isMobile ? 24 : 17">
                          <el-select :size="!$isMobile ? 'mini' : ''" v-model="formDatatmp.days" placeholder="구매상품"
                            :disabled="!reclick" class="w100p">
                            <!-- :disabled="tmpTableData.point < item.price" -->
                            <el-option v-for="item in $loginUserInfo.mallConfigInfo.licenseValidBuy" :key="item.days"
                              :label="item.title + ' ' + item.days + '일 (' + $comma(item.price) + '원) '"
                              :value="item.days">
                              {{ item.title }} {{ item.days }}일 ({{ $comma(item.price) }}원)
                            </el-option>
                          </el-select>
                        </el-col>
                      </el-row>
                    </el-col>
                    <el-col :xl="24" :xs="24" :md="24">
                      <el-row>
                        <el-col  :xl="4" :md="4" :xs="7">
                          결제방식:
                        </el-col>
                        <el-col :xl="20" :md="20" :xs="17" class="relative">
                          <el-select size="mini" v-model="formData.pgcode" placeholder="선택하세요">
                            <el-option v-for="item in payCate" :key="item.name" :label="item.label" :value="item.name">
                            </el-option>
                          </el-select>
                        </el-col>
                      </el-row>
                    </el-col>
  
                    <el-col :xl="24" :xs="24" :md="24">
                      <el-row>
                        <el-col :xl="4" :md="4" :xs="7">검증번호</el-col>
                        <el-col :xl="20" :md="20" :xs="17" :align="$isMobile ? 'right' : 'left'">
                          <span class="relative">
                            <componentInputText :minlength="4" :maxlength="4" :type="'text'" :size="'mini'"
                              :hiddeinfo="true" :placeholder="'검증번호'" v-model="securetyNumberText" />
                            <span class="absolute badge badge-darklight" style="top:11px;right:6px;">{{ securetyNumber
                              }}</span>
                          </span>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </div>
              </el-col>
              <el-col :xl="24" :xs="24" :sm="24">
                <div class="bigbadge-light p-2 pl-4 mt-2" style="line-height: 20px;"
                  v-html="$toHtml(chargePolicyContent($라이센스사전유의사항))"></div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row class="mt-2">
          <el-col :xl=12 :xs="24" :sm="12">
            <el-checkbox v-model="policyAgree" :disabled="!reclick" class="colorred bold">
              <span class="font18">위의 사항을 확인하였으며 계속 합니다.</span>
            </el-checkbox>
          </el-col>
          <el-col :xl=12 :xs="24" :sm="12" class="text-right">
            <el-button :type="!$isMobile ? 'mini' : ''" size="" class="el-btn-orange" :class="$isMobile ? 'w100p' : ''"
              v-if="policyAgree && reclick" @click="checkout()">
              <i class="far fa-credit-card"></i>
              라이센스구매</el-button>
            <el-button :type="!$isMobile ? 'mini' : ''" size="" class="el-btn-orange" :class="$isMobile ? 'w100p' : ''"
              disabled v-else>
              <i class="far fa-credit-card"></i>
              {{ updateButtontitle }}</el-button>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <div class="font15 mt-5">
      장부
    </div>
    <table class="table-params w100p">
      <tbody>
        <tr>
          <th width="120">거래날자</th>
          <th width="100">결제금액</th>
          <th width="100">처리</th>
          <th>서비스</th>
        </tr>
        <tr v-for="item,index in MallPaymentCallbackInfo" :key="index">
          <td>{{ $YYYYMMDD_HHMM(item.transaction_date) }}</td>
          <td>{{ $comma(item.amount) }}</td>
          <td>{{ item.user_name }}</td>
          <td>{{ item.service_name=='페이레터'? '결제구매':'무료충전' }}</td>
        </tr>
      </tbody>
    </table>
    <el-pagination background class="mt-2 mb-2 text-right" @size-change="handleSizeChange"
      @current-change="handleCurrentChange" :current-page="request.page" :page-sizes="pageSizes" :page-size="request.size"
      layout=" prev, pager, next" :total="MallPaymentCallbackCount">
    </el-pagination>
    <FootBar />
    <el-backtop>
    </el-backtop>
  </div>
</template>
<script>
import componentInputText from './component-input-text.vue';
import TopBar from './component-top.vue'
import TopBanner from './component-top-banner.vue'
import TopNotice from './component-top-notice.vue'
import TopMenu from './component-top-menu.vue'
import FootBar from './component-foot.vue'
import centermenu from './component-cener-left-menu.vue'
import { Loading } from 'element-ui'
export default {
  components: { centermenu, TopBar, TopBanner, TopNotice, TopMenu, FootBar, Loading, componentInputText },
  data() {
    return {
      payCate: [
        { label: '카드결제', value: 0, name: 'creditcard' },
        { label: '인터넷뱅킹', value: 1, name: 'banktransfer' },
        // { label: '가상계좌', value: 3, name: 'VitualAccount' },
      ],
      pageSizes: [12, 50, 100],
      securetyNumberText: '',
      securetyNumber: this.$createSecuryNumber(4),
      updateButtontitle: '라이센스구매',
      reclickSecond: 5,
      reclick: true,
      tmpTableData: [],
      policyAgree: false,
      loading: null,
      MallPaymentCallbackInfo: [],
      MallPaymentCallbackCount: 0,
      formDatatmp: {
        days: 90,
      },
      formData: {
        pgcode: 'banktransfer',
        service_name: '페이레터',  // 서비스이름
        amount: 1000,  // 결제금액
        taxfree_amount: 0, // 비과세금액,
        tax_amount: 0, // 과세금액,
        product_name: '주식회사 008파츠거래 라이센스구매',
        email_flag: 'N', // 결제내역 메일 수신 여부 (Y:사용, N:미사용)
        email_addr: '', // 결제내역 메일 수신 주소 (*email_flag를 "Y"로 세팅한 경우에만 사용)
        autopay_flag: 'N', // 자동결제 여부 (Y:사용, N:미사용)
        receipt_flag: 'N', //현금영수증 입력 창 노출 여부 (Y:사용, N:미사용)(*현금영수증 계약이 되어 있는 경우에만 사용 가능)
        custom_parameter: '아무거나,고객정보,주문정보넣을수있음', //가맹점이 전송하는 임의의 값 ex) 고객정보, 주문정보, 기타 필요한 정보를 세팅하면 결제 결과로 다시 리턴
      },
      request: {
        objSearchMapper: {
          startDate: null,
          endDate: null,
        },
        page: 1,
        size: 12,
      }
    }
  },
  created() {
    window.scrollTo(0, 0);
    this.$loginUserInfo = JSON.parse(localStorage.getItem('loginUserInfo'))
    this.$http.defaults.headers.common['exjwtauthorization'] = this.$loginUserInfo?.token
    this.loading = Loading.service({ fullscreen: true })
    this.$checkAuth(this.$loginUserInfo) // type==0 대표권한
    this.read()
    this.$overay(this.loading)
    this.getMallPaymentCallbackInfo()
  },

  methods: {
    async payRequest() {
      const configData = this.$loginUserInfo.mallConfigInfo.licenseValidBuy.find(item => item.days == this.formDatatmp.days)
      this.formData.amount = this.$delComma(configData.price)
      this.formData.custom_parameter = this.$loginUserInfo.phone
      console.log(this.formData)
      //페이레터결제주소
      const res = await this.$http.post(`${this.$serverurl}/api/payLicenseRequest`, this.formData)
      return res
    },
    async checkout() {
      if (this.securetyNumberText != this.securetyNumber) {
        this.$notify.error({
          title: '',
          message: '검증번호가 일치하지 않습니다.',
          position: 'bottom-right',
          showClose: true,
          duration: 1000,
        });
        return
      }
      const paymentData = await this.payRequest()
      if (paymentData.data.code === 200) {
        const url = paymentData.data.body.results.online_url;
        const width = 600;
        const height = 400;
        const left = (window.innerWidth - width) / 2;
        const top = (window.innerHeight - height) / 2;
        const popup = window.open(url, 'popup', `width=${width},height=${height},left=${left},top=${top}`);
        const checkPopupStatus = setInterval(() => {
          if (popup && !popup.closed) {
            // console.log('팝업 창이 열림.')
          } else {
            // console.log('팝업 창이 닫힘')
            this.$router.go(0);
            this.isLoading = false
            // 타이머 중지
            clearInterval(checkPopupStatus);
          }
        }, 500);
      } else {
        console.log(paymentData)
        alert('결제요청 시 오류가 발생하였습니다.')
      }
    },
    async read() {
      await this.$http.post(`${this.$serverurl}/api/getMyMallCompanyInfo`)
        .then(res => {
          if (200 == res.data.code) {
            this.tmpTableData = res.data.body.results
          } else {
            this.$notify.error({
              title: '',
              message: res.data.message,
              position: 'bottom-right',
              showClose: true,
              duration: 1000,
            });
          }
        })
    },
    async getMallPaymentCallbackInfo() {
      await this.$http.post(`${this.$serverurl}/api/getMallPaymentCallbackInfo`, this.request, this.$setHeader())
        .then(res => {
          if (200 == res.data.code) {
            this.MallPaymentCallbackInfo = res.data.body.results
            this.MallPaymentCallbackCount = res.data.totalCount
          } else {
            this.$notify.error({
              title: '',
              message: res.data.message,
              position: 'bottom-right',
              showClose: true,
              duration: 1000,
            });
          }
        })
    },
    handleSizeChange(val) {
      this.request.size = val
      this.getMallCompanyLicenseLogInfo()
    },
    handleCurrentChange(val) {
      this.request.page = val
      this.getMallCompanyLicenseLogInfo()
    },
    chargePolicyContent() {
      let tmpvalue = this.$라이센스사전유의사항.replace(/\n/g, "<br>")
      tmpvalue = tmpvalue.replace(/xxxcompanyname/g, this.$회사이름한글)
      return tmpvalue
    }
  },
}

</script>
<style></style>
