<template>
  <el-dialog title="체험상태 활성화" :fullscreen="$isMobile ? true : false" :visible.sync="visible" width="300px"
    :before-close="handleClose" center v-draggable :show-close="false">
    <div class="font15 bold colorred mb-2">
      라이센스 구매 후 사용하여 주세요.
    </div>
    <div class="font13 colorgraylight mb-3">
      체험상태에서 저희가 제공하는 차대번호만 검색 하실수 있습니다.
    </div>
    <div class="mb-2 center">
      <el-button size="mini" type="danger" @click="$changeRoute('account/buyLicense')">라이센스구매</el-button>
    </div>
  
  </el-dialog>
</template>

<script>
export default {
  name: 'epcpaymentalert',
  props: ['data'],
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
      }
    }
  },
  data() {
    return {
      visible: true,
    }
  },
  async created() {
  },
  methods: {

    handleClose(done) {
      this.$emit('callback',false)
    },
  }
}
</script>

<style scoped></style>