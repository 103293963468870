<template>
  <div class="mt-2">
    <el-row :gutter="5" class="topbar">
      <!-- Logo -->
      <el-col :xs="9" :sm="12" :span="3">
        <span class="link_logo noselect colorreddeep hand" @click="$changeRoute('main')">PARTSALLIANCE</span>
      </el-col>
      <!-- MOBILE 상단Menu -->
      <el-col :xs="15" :sm="12" :span="21" class="relative d-block d-sm-none">
        <i class="fa fa-bars font18 absolute" style="top:11px;right:0px;" @click="mobileMenuSwicth(true)"></i>
        <topMenuMobile v-if="drawer" @callback="mobileMenuClose" />
      </el-col>
      <!-- PC 상단Menu -->
      <el-col :xs="15" :sm="12" :span="21" class="relative d-none d-md-block">
        <span class="absolute" style="right:0px;top:-18px;height:20px;" v-if="$loginUserInfo != null">
          <span class="badge badge-black mr-1 hand" v-if="$loginUserInfo.remaDays>=0" @click="$changeRoute('account/buyLicense')">
            <i class="fas fa-clock"></i>
            라이센스종료일-D:{{ $loginUserInfo.remaDays}} </span>
          <span v-else class="badge badge-red mr-1 hand" @click="$changeRoute('account/buyLicense')">
            라이센스가 만료되었습니다.
          </span>
  
          <span class="badge badge-black mr-1 hand" @click="$changeRoute('account/buyPointAuto')">
            <i class="fas fa-credit-card"></i> : {{
            $comma($loginUserInfo.companyInfo.point) }}
          </span>
        </span>
        <div class="topSmallmenu text-right noselect mt-2">
          <span class="pl-1" @click="$changeRoute('notice')">
            <i class="fas fa-volume-up colorgraylight"></i>
            공지사항</span>
          <span v-if="$loginUserInfo" @click="$changeRoute('orders')">
            <i class="fas fa-file-invoice colorgraylight"></i>
            구매내역</span>
          <span @click="$changeRoute('login')" v-if="!$loginUserInfo">로그인</span>
          <span @click="$changeRoute('joinus')" v-if="!$loginUserInfo">회원가입</span>
          <span v-if="$loginUserInfo">
            <span class="hand pl-1" @click="$changeRoute('checkout')">
              <i class="fas fa-shopping-cart colorgraylight"></i>
              장바구니</span>
          </span>
          <span v-if="$loginUserInfo">
            <el-dropdown trigger="click" v-if="$loginUserInfo">
              <label class="el-dropdown-link">
                <i class="fas fa-user-circle colorgraylight"></i>
                {{ $loginUserInfo.name }}<i class="el-icon-arrow-down el-icon--right"></i>
              </label>
              <el-dropdown-menu slot="dropdown">
                <div v-if="$loginUserInfo && $loginUserInfo.type == 0">
                  <div v-for="(item, index) in menulist" :key="index">
                    <div v-if="item.type == 1" class="borderbottom1px"></div>
                    <div>
                      <div v-if="item.type == 0" class="menuitem font13 relative"
                        @click="$changeRoute(item.route, item.location)">
                        <span class="pl-3">
                          {{ item.name }}</span>
                        <span class="badge badge-small ml-2"
                          :style="'background-color:' + item.bg + ';color:' + item.color + ';'" v-if="item.badge">
                          {{ item.badge }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div v-for="(item, index) in menulist" :key="index">
                    <div v-if="item.type == 1 && item.userType != 0" class="borderbottom1px"></div>
                    <div>
                      <div v-if="item.type == 0 && item.userType != 0" class="menuitem font13 relative"
                        @click="$changeRoute(item.route, item.location)">
                        <span class="pl-3">
                          {{ item.name }}
                        </span>
                        <span class="badge badge-small ml-2"
                          :style="'background-color:' + item.bg + ';color:' + item.color + ';'" v-if="item.badge">
                          {{ item.badge }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
          </span>
  
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import goHome from './component-gohome.vue'
import topMenuMobile from './component-top-menu-mobile.vue'
export default {
  name: 'TopBar',
  components: {
    goHome, topMenuMobile
  },
  data() {
    return {
      loginTime: 'loading..',
      drawer: false, //모바일 메뉴창
      searchKeyword: null,
      menulist: [
        // { name: '관리센터', type: 0, route: 'account/cenermain', badge: '', },
        { name: '입출금관리', type: 0, route: 'orders/accountBook', badge: '', bg: '#00aa00', color: '#fff' },
        { name: '구매내역', type: 0, route: 'orders', badge: '', },
        { name: 'split', type: 1, route: '' },
        // { name: '제품문의', type: 0, route: 'account/qnaboard', badge: '', },
        { name: '1:1문의글', type: 0, route: 'account/board', badge: '', },
        { name: 'split', type: 1, route: '' },
        { name: '나의정보수정', type: 0, route: 'account/profile', badge: '' },
        { name: '배송지관리', type: 0, route: 'account/shippingAddress', badge: '' },
        { name: 'split', type: 1, route: '' },
        { name: '보안번호설정', type: 0, route: 'account/security', badge: 'SAFE', bg: '#ff0055', color: '#fff', userType: 0, },
        { name: '회사정보수정', type: 0, route: 'account/companyInfo', badge: '', userType: 0, },
        { name: '계정관리', type: 0, route: 'account/accountInfo', badge: '', userType: 0, },
        { name: 'split', type: 1, route: '', userType: 0, },
        // { name: '충전요청', type: 0, route: 'account/buyPoint', badge: '삭제예정', bg: '#eee', color: '#000', userType: 0, },
        { name: '온라인결제(충전)', type: 0, route: 'account/buyPointAuto', badge: 'NEW', bg: '#00aa00', color: '#fff', userType: 0, },
        { name: '라이센스관리', type: 0, route: 'account/buyLicense', badge: 'NEW',bg: '#00aa00',color:'#fff', userType: 0, },
        { name: 'split', type: 1, route: '', userType: 0, },
        { name: '회원탈퇴', type: 0, route: 'account/WithdrawMember', badge: '' },
        { name: 'split', type: 1, route: '' },
        { name: '로그아웃', type: 0, route: 'login', badge: '', location: 'oldx' },
      ],
    }
  },
  async created() {
    if (!this.$loginUserInfo) {
      this.$loginUserInfo = JSON.parse(localStorage.getItem('loginUserInfo'))
    }
    this.readMyInfo()
  },
  methods: {
    async readMyInfo() {
      await this.$http.post(`${this.$serverurl}/api/getMyInfo`, this.formData, this.$setHeader())
        .then(res => {
          if (200 == res.data.code && this.$loginUserInfo) {
            this.$loginUserInfo.companyInfo.point = res.data?.body?.results?.companyInfo?.point | 0
            this.$loginUserInfo.remaDays = res.data?.body?.results?.remaDays
            localStorage.setItem('loginUserInfo', JSON.stringify(this.$loginUserInfo))
          } else {
            this.$loginUserInfo = null
            // this.$changeRoute('login')
          }
        })
    },
    mobileMenuSwicth(data) {
      this.drawer = data
    },
    mobileMenuClose(data) {
      this.drawer = data
    },
    async getLoingTime() {
      try {
        if (!this.$loginUserInfo) { return { time: '00:00:00', rest: 0 } }
        const now = new Date(); // 현재 시간
        const loginDate = new Date(this.$loginUserInfo.loginDate); // 로그인 시간
        const elapsedTime = now.getTime() - loginDate.getTime(); // 로그인 후 경과 시간 (밀리초)
        const expirationTime = this.$loginUserInfo.expirTime; // 만료 시간 (밀리초)
        const remainingTime = expirationTime - elapsedTime; // 남은 시간 (밀리초)
        if (remainingTime <= 0) {
          return { time: '00:00:00', rest: 0 };
        }
        const hours = Math.floor(remainingTime / (60 * 60 * 1000));
        const minutes = Math.floor((remainingTime % (60 * 60 * 1000)) / (60 * 1000));
        const seconds = Math.floor((remainingTime % (60 * 1000)) / 1000);
        return {
          time: `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`,
          rest: remainingTime
        };
      } catch (error) {
        return { time: '00:00:00', rest: 0 };
      }
    },

    hourDiff(startDate, endDate) {
      const diffMilliseconds = Math.abs(endDate.getTime() - startDate.getTime());
      const oneHour = 60 * 60 * 1000; // 1시간을 밀리초로 나타냅니다
      const diffHours = diffMilliseconds / oneHour;
      return diffHours;
    },
    async calcLoginTime() {
      const intervalId = setInterval(async () => {
        const { time, rest } = await this.getLoingTime();
        if (this.$loginUserInfo || localStorage.getItem('loginUserInfo')) {
          this.loginTime = time;
          if (rest <= 0) {
            console.log('남은시간<0...')
            this.loginTime = '00:00:00';
            localStorage.removeItem('loginUserInfo');
            this.$loginUserInfo = null
            if (
              this.$route.fullPath != '/login' &&
              this.$route.fullPath != '/joinus' &&
              this.$route.fullPath != '/main'
            ) {
              this.$changeRoute('main', 'old')
            }
            clearInterval(intervalId)
          }
        } else {
          localStorage.removeItem('loginUserInfo');
          this.$loginUserInfo = null
          if (this.$route.fullPath != '/login' &&
            this.$route.fullPath != '/joinus' &&
            this.$route.fullPath != '/main' &&
            !this.$route.fullPath.includes('productView') &&
            !this.$route.fullPath.includes('aboutUs') &&
            !this.$route.fullPath.includes('policiesService') &&
            !this.$route.fullPath.includes('privacyPolicy') &&
            !this.$route.fullPath.includes('searchCenter') &&
            !this.$route.fullPath.includes('notice')
          ) {
            this.$changeRoute('main', 'old')
          }
        }
      }, 1000);
    },
  },
  mounted() {
    // this.calcLoginTime()
  },

}
</script>

<style scoped></style>