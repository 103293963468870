<template>
  <div class="container mt-3 relative" style="max-width: 1000px !important;">
    <TopBar />
    <TopMenu />
    <!-- <TopBanner /> -->
    <!-- <TopNotice /> -->
    <el-row :gutter="15">
      <el-col :xl="5" :xs="24" :sm="5">
        <centermenu />
      </el-col>
      <el-col :xl="24" :xs="24" :sm="24" v-if="isShow">
        <el-row class="borderbox mt-2 p-2">
          <el-col class="borderbottom1px lh40 relative">
            <span class="font18 bold">
              보안번호설정
            </span>
            <span class="absolute hand fixedTooltip-link" style="top:5px;right:5px;">
            </span>
          </el-col>
          <el-col class="relative mt-2">
            <el-row :gutter="10" class="font15 lh40">
              <el-col :xl="4" :xs="8" :sm="4">보안번호</el-col>
              <el-col :xl="8" :xs="16" :sm="8">
                <div class="borderbox text-center bold pl-3 pr-3">
                  {{ $loginUserInfo.companyInfo.securityNum }}
                </div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row class="mt-2">
          <el-col class="text-right">
            <el-button type="" size="mini" class="el-btn-orange" :disabled="!reclick" @click="setSecurityNumber()">
              <i class="fas fa-arrows-rotate"></i>
              {{ updateButtontitle }}</el-button>
          </el-col>
        </el-row>
      </el-col>
      <el-col :xl="24" :xs="24" :sm="24" v-if="!isShow">
        <el-row class="borderbox mt-2 p-2">
          <el-col class="borderbottom1px lh40 relative">
            <span class="font18 bold">
              보안번호설정
            </span>
            <span class="absolute hand fixedTooltip-link" style="top:5px;right:5px;">

            </span>
          </el-col>
          <el-col class="relative mt-2">
            <el-row :gutter="10" class="font15 lh40">
              <el-col :xl="4" :xs="8" :sm="4">비밀번호</el-col>
              <el-col :xl="16" :xs="16" :sm="16">
                <componentInputText :icon="'fas fa-user-shield'" :minlength="5" :maxlength="20" :type="'password'"
                  :size="'mini'" :placeholder="'현재비밀번호를 입력하세요.'" v-model="formData.pw" @keyup.enter.native="checkPassword()" />
              </el-col>
              <el-col :xl="4" :xs="24" :sm="4">
                <el-button type="" size="mini" class="el-btn-orange w100p" @click="checkPassword()">
                  <i class="fas fa-lock"></i>
                  확인</el-button>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-col>

    </el-row>
    <FootBar />
    <el-backtop>
    </el-backtop>
  </div>
</template>
<script>
import componentInputText from './component-input-text.vue';

import TopBar from './component-top.vue'
import TopBanner from './component-top-banner.vue'
import TopNotice from './component-top-notice.vue'
import TopMenu from './component-top-menu.vue'
import FootBar from './component-foot.vue'
import centermenu from './component-cener-left-menu.vue'
import { Loading } from 'element-ui'
export default {
  components: { centermenu, TopBar, TopBanner, TopNotice, TopMenu, FootBar, Loading, componentInputText },
  data() {
    return {
      updateButtontitle: '보안번호갱신',
      reclickSecond: 5,
      reclick: true,
      isShow: false,
      loginUserInfo: null,
      loading: null,
      formData: {
        pw: '',
      }
    }
  },
  created() {
    window.scrollTo(0, 0);
    this.loading = Loading.service({ fullscreen: true })
    // this.$loginUserInfo = JSON.parse(localStorage.getItem('loginUserInfo'))
    this.$checkAuth(this.$loginUserInfo) // type==0 대표권한
    this.$overay(this.loading)
  },
  methods: {
    async setSecurityNumber() {
      this.reclick = false  // 재차갱신 여부
      this.loading = Loading.service({ fullscreen: true })
      await this.$http.post(`${this.$serverurl}/api/setSecurityNumber`, this.formData)
        .then(res => {
          if (res.data.code == 201) {
            this.$loginUserInfo.companyInfo.securityNum = res.data.body.results
          }
          this.$notify.success({
            title: '',
            message: res.data.message,
            position: 'bottom-right',
            showClose: true,
            duration: 1000,
          });
          this.updateButtontitle = `${this.reclickSecond}초 후 갱신`

          const countDown = setInterval(() => {
            if (this.reclickSecond > 0) {
              this.reclickSecond--
              this.updateButtontitle = `${this.reclickSecond}초 후 갱신`
            } else {
              this.reclickSecond = 5
              this.updateButtontitle = '보안번호갱신'
              this.reclick = true
              clearInterval(countDown)
            }
          }, 1000);
        })
      this.$overay(this.loading)
    },
    reload() {
      this.formData = {
        tel: this.$loginUserInfo.tel,
        phone: this.$loginUserInfo.phone,
        mail: this.$loginUserInfo.mail,
      }
    },
    async checkPassword() {
      if (!this.formData.pw.trim()) {
        alert('현재 비밀번호를 입력하세요.'); return
      }
      this.loading = Loading.service({ fullscreen: true })
      await this.$http.post(`${this.$serverurl}/api/checkPassword`, this.formData)
        .then(res => {
          if(res.data.code==200){
            this.isShow = true
          this.$notify.success({
            title: '',
            message: res.data.message,
            position: 'bottom-right',
            showClose: true,
            duration: 1000,
          });
          }else{
            this.$notify.error({
            title: '',
            message: res.data.message,
            position: 'bottom-right',
            showClose: true,
            duration: 1000,
          });
          }
        })
      this.$overay(this.loading)
    },
  },
}

</script>
<style></style>
