<template>
  <div>

    <el-dialog :title="data.name+data.saleStatus" :fullscreen="$isMobile ? true : false" :visible.sync="visible"
      :width="tmpTableData && tmpTableData.saleStatus == 2 && tmpTableData.hasAmount>0?'1000px':'500px'" :before-close="handleClose" center v-draggable :show-close="tmpTableData && tmpTableData.saleStatus == 2?true:false" destroy-on-close
      close-on-press-escape>

      <productView :data="tmpTableData && tmpTableData" @operationCallback="operationCallback"
        v-if="tmpTableData && tmpTableData.saleStatus == 2 && tmpTableData.hasAmount>0" />

        <div v-else class="font15 bold center">
         <div class="mb-3"> 
          <div v-if="!tmpTableData">
            갱신중입니다.잠시 후 다시 시도해 주세요...
          </div>
          <div v-if="tmpTableData && tmpTableData.hasAmount<=0">
            일시 품절된 상품입니다.
          </div>
          <div v-if="tmpTableData && tmpTableData.saleStatus!=2">
            갱신중입니다.잠시 후 다시 시도해 주세요...
          </div>
        </div>
         <div class="mb-2"> 
          <el-button type="" size="mini" @click="abortview">
           창닫기
          </el-button>
         </div>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import productView from './component-product-view.vue'
export default {
  components: { productView },
  name: 'productviewpop',
  props: ['data'],
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        this.req.seq = newValue.seq
        this.read()
      }
    }
  },
  data() {
    return {
      req: { seq: null },
      visible: true,
      tmpTableData: null,
    }
  },
  async created() {

  },
  methods: {
    abortview() {
      location.reload()
    },
    async operationCallback(data) {
      if (data.fav) { // 즐겨찾기 등록삭제
        await this.$http.post(`${this.$serverurl}/api/setMallProductWishInfo`, { productId: this.tmpTableData.seq })
          .then(res => {
            if (res.data.body.results.fav) {
              this.$notify.success({
                title: '',
                message: '즐겨찾기에 저장되었습니다.',
                position: 'bottom-right',
                showClose: false
              });
            } else {
              this.$notify.error({
                title: '',
                message: '즐겨찾기에서 삭제되었습니다.',
                position: 'bottom-right',
                showClose: false
              });
            }
            this.read()
          })
      }
      if (data.cart) { // 장바구니담기
        const params = {
          managerCompanyId: this.tmpTableData.companyId,
          productId: this.tmpTableData.seq,
          qty: data.hasAmount,
          price: data.price
        }
        await this.$http.post(`${this.$serverurl}/api/addToCart`, params)
          .then(res => {
            this.$notify.success({
              title: '',
              message: '장바구니에 추가하였습니다.',
              position: 'bottom-right',
              showClose: false
            });
            this.read()
          })
      }
      if (data.buy) { // 장바구니담기
        const params = {
          productId: this.tmpTableData.seq,
          managerCompanyId: this.tmpTableData.companyId,
          qty: data.hasAmount,
          price: data.price
        }
        await this.$http.post(`${this.$serverurl}/api/addToCart`, params)
          .then(res => {
            this.$changeRoute('checkout')
          })
      }
    },
    async read() {
      await this.$http.post(`${this.$serverurl}/api/getMallProductInfoOne`, this.req)
        .then(res => {
          if (200 == res.data.code) {
            this.tmpTableData = res.data.body.results
            if (!this.tmpTableData.images) {
              this.tmpTableData.images = []
              this.tmpTableData.images.push(`/static/img/no-image.webp`)
              // this.tmpTableData.images.push(`${this.$imageurl}/upload/no-image.webp`)
            }
            this.tmpTableData.pop = true

          } else {
            this.$router.go(-1)
            alert(res.data.message)
          }
        })
      this.$overay(this.loading)
    },
    handleClose(done) {
      this.$emit('callback', false)
    },
  }
}
</script>

<style scoped></style>