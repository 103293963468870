<template>
  <div :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>
    <!-- 기존사이트사용하려면 아래의 router-view를 사용하세요 -->
    <router-view></router-view>
  </div>
</template>

<script>
export default {

  created() {

    // 기존사이트사용하려면 아래의 코드를 주석처리하세요.
    // window.location.href='http://141.164.39.95:1985/'


    // this.$loginUserInfo = JSON.parse(localStorage.getItem('loginUserInfo'))
    // this.$http.defaults.headers.common['exjwtauthorization'] = this.$loginUserInfo?.token
  }
}
</script>
