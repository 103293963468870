<template>
  <div class="container mt-3 relative noselect" style="max-width: 1300px !important;">
    <TopBar />
    <TopMenu />
    <el-row :gutter="15">
      <el-col :xl="5" :xs="24" :sm="5">
        <centermenu />
      </el-col>
      <el-col :xl="24" :xs="24" :sm="24">
        <el-row class="borderbox mt-2 p-2">
          <el-col class="borderbottom1px lh40 relative">
            <span class="font18 bold">
              충전관리
            </span>
          </el-col>
          <el-col class="relative">
            <div class="mt-2">
            </div>
            <el-row :gutter="10" class="font15 lh40">
              <el-col :xl="24" :xs="24" :sm="24">
                <div class="bold pl-3">
                  <div>
                    <span>
                      회사이름: {{ $loginUserInfo.companyInfo.name }}
                    </span>
                    <span class="ml-3">
                      보유캐시: {{ $comma($loginUserInfo.companyInfo.point) }}
                    </span>
                  </div>
                </div>
              </el-col>
              <el-col :xl="3" :xs="8" :sm="3" class="text-right">
                충전캐시:
              </el-col>
              <el-col :xl="21" :xs="16" :sm="21" class="relative">
                <el-input type="text" size="mini" v-model="formData.point" placeholder="충전캐시" style="max-width: 300px;"
                  :controls="false" :class="$isMobile?'w100p':''"
                  @focus="() => { formData.point = $numberFormat(formData, 'point', 'focus',1000,5000000) }"
                  @input="() => { formData.point = $numberFormat(formData, 'point', 'input'),1000,5000000 }"
                  @blur="() => { formData.point = $numberFormat(formData, 'point', 'blur') ,1000,5000000}"></el-input>
                <span class="fixedTooltip-info d-none d-md-block" style="top:0px;right:5px">
                  충전범위: 5만원~ 500만
                </span>
                <div class="d-none d-md-block">
                  <el-button size="mini" @click="calcPoint(10000)">+만원</el-button>
                  <el-button size="mini" @click="calcPoint(50000)">+5만원</el-button>
                  <el-button size="mini" @click="calcPoint(100000)">+10만원</el-button>
                  <el-button size="mini" @click="calcPoint(500000)">+50만원</el-button>
                  <el-button size="mini" @click="calcPoint(-100000)">-10만원</el-button>
                  <el-button size="mini" @click="calcPoint(-50000)">-5만원</el-button>
                  <el-button size="mini" @click="calcPoint(-10000)">-만원</el-button>
                </div>
              </el-col>
              <el-col :xl="24" :xs="24" :sm="24">
                <div class="bigbadge-light p-2 mt-2" style="line-height: 20px;"
                  v-html="$toHtml(chargePolicyContent($사전유의사항))"></div>
              </el-col>
  
            </el-row>
          </el-col>
        </el-row>
        <el-row class="mt-2">
          <el-col :xl=12 :xs="24" :sm="12">
            <el-checkbox :value="policyAgree" @change="changePolicyAgree">위의 사항을 확인하였으며 계속
              합니다.</el-checkbox>
          </el-col>
          <el-col :xl=12 :xs="24" :sm="12" class="text-right">
            <el-button type="" size="mini" class="el-btn-orange" v-if="policyAgree" @click="requestPoint()">
              <i class="far fa-credit-card"></i>
              충전요청하기</el-button>
            <el-button type="" size="mini" class="el-btn-red" :class="$isMobile?'w100p':''" disabled v-else>
              <i class="far fa-credit-card"></i>
              충전요청하기</el-button>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <FootBar />
    <el-backtop>
    </el-backtop>
  </div>
</template>
<script>
import TopBar from './component-top.vue'
import TopBanner from './component-top-banner.vue'
import TopNotice from './component-top-notice.vue'
import TopMenu from './component-top-menu.vue'
import FootBar from './component-foot.vue'
import centermenu from './component-cener-left-menu.vue'
import { Loading } from 'element-ui'
export default {
  components: { centermenu, TopBar, TopBanner, TopNotice, TopMenu, FootBar, Loading },
  data() {
    return {
      policyAgree: false,
      loading: null,
      formData: {
        point: 0,
      }
    }
  },
  created() {
    window.scrollTo(0, 0);
    this.loading = Loading.service({ fullscreen: true })
    this.$loginUserInfo = JSON.parse(localStorage.getItem('loginUserInfo'))
    this.$checkAuth(this.$loginUserInfo) // type==0 대표권한
    // this.formData = {
    //   point: this.$loginUserInfo.companyInfo.point,
    // }
    this.$overay(this.loading)
  },

  methods: {
    changePolicyAgree() {
      if (!this.formData.point || this.$delComma(this.formData.point )< 1000) {
        alert('최소1,000캐시를 입력하세요.')
        this.policyAgree = false
        return
      }
      if (this.policyAgree) {
        this.policyAgree = false
      } else {
        this.policyAgree = true
      }
    },
    calcPoint(point) {
      this.formData.point = this.$delComma(this.formData.point)
      if (this.formData.point + point <= 5000000 && this.formData.point + point >= 1000) {
        this.formData.point += point
      }
      this.formData.point = this.$comma(this.formData.point)
    },

    async requestPoint() {
      await this.$http.post(`${this.$serverurl}/api/requestBuyPoint`, this.formData)
        .then(res => {
          if (201 == res.data.code) {
            this.$notify.success({
              title: '',
              message: res.data.message,
              position: 'bottom-right',
              showClose: true,
              duration: 1000,
            });
          } else {
            this.$notify.error({
              title: '',
              message: res.data.message,
              position: 'bottom-right',
              showClose: true,
              duration: 1000,
            });
          }
        })
      this.$overay(this.loading)
    },
    chargePolicyContent() {
      let tmpvalue = this.$사전유의사항.replace(/\n/g, "<br>")
      tmpvalue = tmpvalue.replace(/xxxcompanyname/g, this.$회사이름한글)
      return tmpvalue
    }
  },
}

</script>
<style></style>
