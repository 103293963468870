<template>
    <div class="mt-2">
        <!-- Top Banner -->
        <el-row v-if="$route.name != 'SearchCenter' && $route.name != 'ProductView'">
            <el-col>
                <div class="footbanner"></div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    name: 'FooBanner',
    // props: ['searchKeywordsValue'],
    components: {
    },
    data() {
        return {

        }
    },
    async created() {

    },
    mounted() {
      
    },
    methods: {
     
    },
    beforeDestroy() {
       
    }
}
</script>

<style scoped>
.footbanner {
  margin-top: 10px;
  width: 100%;
  height: 150px;
  background-color: #343435;
  background: url('/static/img/banner.webp');
}

.footbanner::after {
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: #fff;
  content: 'W 100% X H 150PX';
}
@media (max-width: 767px) {
  .footbanner {
    border-radius: 10px;
    background: url('/static/img/banner-mobile.webp');
  }
}
</style>