<template>
    <div>
        <el-drawer :title="'메뉴제목'" :with-header="false" :visible.sync="drawerShow" :direction="'rtl'"
            :before-close="menuClose" size="80%">
            <div class="container">
                <el-row :gutter="5" class="mt-3 mb-3">
                    <!-- 회원정보 -->
                    <el-col :xs="24" :sm="24" v-if="$loginUserInfo">
                        <div class="mobilemenuprofile">
                            <el-row>
                                <el-col :xs="5" :sm="5">
                                    <div class="iconDiv">
                                        <div class="icon">
                                            {{ $loginUserInfo.name[0] }}
                                        </div>
                                    </div>
                                </el-col>
                                <el-col :xs="19" :xl="24" :md="24" :sm="19">
                                    <div class="absolute font15 bold" style="top:30px;left:65px;">
                                        {{ $loginUserInfo.name }} {{ 'credit: ' + $loginUserInfo.companyInfo.point }}
                                    </div>
                                    <div class="absolute font12" style="min-width:170px; top:50px;left:65px;">
                                        <el-row :gutter="5">

                                            <el-col :xs="6" :xl="6" :md="6" :sm="6">
                                                구매내역
                                            </el-col>
                                            <el-col :xs="6" :xl="6" :md="6" :sm="6">
                                                정보수정
                                            </el-col>
                                            <el-col :xs="6" :xl="6" :md="6" :sm="10">
                                                주소관리
                                            </el-col>
                                            <el-col :xs="6" :xl="6" :md="6" :sm="10" @click="$router.push('/login')">
                                                로그아웃
                                            </el-col>
                                        </el-row>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                </el-row>
                <el-row class="homemenumobile relative noselect">
                    <el-col :xl="24" :md="24" :sm="24">
                        <span class="font15 hand" @click="$router.push('/')">파츠상품</span>
                    </el-col>
                    <el-col :xl="24" :md="24" :sm="24">
                        <span class="font15 hand">EPC(엔지니어전용)</span>
                    </el-col>
                    <el-col :xl="24" :md="24" :sm="24">
                        <span class="font15 hand" @click="$router.push('/notice')">공지사항</span>
                    </el-col>
                    <el-col :xl="24" :md="24" :sm="24" class="mt-2 mb-2">
                        <el-row :gutter="5" style="padding-left: 30px;">
                            <el-col :xs="6" :xl="6" :md="6" :sm="6" v-for="(item, index) in searchKeywords"
                                :key="index">
                                <div class="hand menubadge badge-danger" @click="sendToParent">{{ item }}</div>
                            </el-col>
                            <el-col :xs="6" :xl="6" :md="6" :sm="6" v-if="searchKeyword">
                                <div class="hand menubadge badge-green" @click="sendToParent">모두보기</div>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>

            </div>
        </el-drawer>
    </div>
</template>

<script>
export default {
    name: 'mobilemenu',
    data() {
        return {
            drawerShow: true,
            searchKeywords: [
                '센서', '패드', '마운트', '커버', '히터', '가스켓', '펌프', '스프링', '쇼바'
            ],
            searchKeyword: null,
        }
    },
    created() {
        this.$loginUserInfo = JSON.parse(localStorage.getItem('loginUserInfo'))
        this.$http.defaults.headers.common['exjwtauthorization'] = this.$loginUserInfo?.token
    },


    methods: {
        menuClose(done) {
            this.$emit('callback', false)
            done()
        },
        sendToParent(data) {
            const encodeSearchKeyword = encodeURIComponent(data.target.textContent)
            const newRoute = '/searchCenter/' + encodeSearchKeyword
            if (this.$route.fullPath !== newRoute) {
                this.$router.push(newRoute)
            }
            this.$emit('callback', false)
        },
    }
}
</script>