<template>
  <div class="container mt-3 relative noselect" style="max-width: 1300px !important;">
    <TopBar />
    <TopMenu />
    <el-row :gutter="15">
      <el-col :xl="5" :xs="24" :sm="5">
        <centermenu />
      </el-col>
      <el-col :xl="24" :xs="24" :sm="24">
        <template>
          <div v-if="!tmpTableData">
            <div class="font15 borderbottom1px">
              등록된 문의글이 없습니다.
            </div>
          </div>
          <!-- 문의글 목록 PC -->
          <el-row class="borderbox mt-2 p-2">
            <el-col class="font15 bold borderbottom3px-orange pb-2">
              문의글 목록
            </el-col>
            <el-col class="mt-3 font13">
              <el-row>
                <el-col :xl="6" :xs="24" :sm="6">
                  <div class="relative" style="right:0px;">
                    <el-input size="mini" type="text" placeholder="검색어를 입력하세요."
                      style="margin: 0px !important; padding: 0px !important;"
                      v-model="searchKeyword" @keyup.enter.native="read()"></el-input>
                    <button class="searchButton absolute" style="top:0px;right:0px;" @click="read()">
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </el-col>
                <el-col :xl="18" :xs="24" :sm="18" class="text-right">
                <!-- 구간검색 -->
                <datearea @callback="dateAreaCallback" />
              </el-col>
              </el-row>
              <el-table size="mini" :data="tmpTableData && tmpTableData" border style="width: 100%" class="mt-3">
                <el-table-column label="거래날자" width="110">
                  <template slot-scope="scope">
                    <div class="fixedTooltip-link-f hand" @click="$changeRoute('account/board/' + scope.row.seq)">
                      {{ $YYYYMMDD(scope.row.updated) }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="문의제목" min-width="250">
                  <template slot-scope="scope">
                    <div class="nowrap relative">{{ scope.row.title }}
                      <span class="absolute badge badge-red badge-small ml-1" style="bottom:10px;" v-if="scope.row.views==2"> new</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="상태" width="150" :align="'center'">
                  <template slot-scope="scope">
                    <div class="nowrap badge badge-primary hand"  @click="$changeRoute('account/board/' + scope.row.seq)">{{ getViewLabel(scope.row) }}</div>
                  </template>
                </el-table-column>
                
              </el-table>
              <div class="text-right mt-2">
                <el-pagination background layout="prev, pager, next" :total="totalCount" :page-size="req.size"
                  @current-change="handlePageChange">
                </el-pagination>
              </div>
            </el-col>
          </el-row>
        </template>
      </el-col>
    </el-row>
    <FootBar />
    <el-backtop>
    </el-backtop>
  </div>
</template>

<script>
import alert from './component-alert.vue'
import TopBar from './component-top.vue'
import TopBanner from './component-top-banner.vue'
import TopNotice from './component-top-notice.vue'
import TopMenu from './component-top-menu.vue'
import FootBar from './component-foot.vue'
import productcard from './component-product-card.vue'
import productcardbutton from './component-product-card-button.vue'
import shippingaddress from './component-checkout-shipping-address.vue'
import centermenu from './component-cener-left-menu.vue'
import { Loading } from 'element-ui'
import datearea from './component-date-area.vue'
export default {
  components: {
    TopBar,
    FootBar,
    TopBanner,
    TopNotice,
    TopMenu,
    Loading,
    productcard,
    productcardbutton,
    alert, shippingaddress, centermenu,datearea,
  },
  data() {
    return {
      showAlertValue: false,
      delmessage: '해당항목이 곧 삭제됩니다.<br/>계속하시겠습니까?',
      delItems: {},
      tmpTableData: [],
      totalCount:0,
      searchKeyword: null,
      req: {
        objSearchMapper: {
          startDate: null,
          endDate: null,
          searchKeyword: null,
        },
        page: 1,
        size: 10
      }
    }
  },
  beforeDestroy() {
  },

  async created() {
    window.scrollTo(0, 0);
    this.$loginUserInfo = JSON.parse(localStorage.getItem('loginUserInfo'))
    this.$http.defaults.headers.common['exjwtauthorization'] = this.$loginUserInfo?.token
    this.loading = Loading.service({ fullscreen: true })
    if(!this.$loginUserInfo){ this.$changeRoute('login');return}
    await this.getMallQnaInfo()
  },

  mounted() {
  },
  methods: {
    getViewLabel(item){
      return this.$boardViewsType.find(typeItem=>typeItem.value==item?.views)?.label
    },
    showAlert(data) {
      this.delItems = data
      this.showAlertValue = true
    },
   

    handlePageChange(page) {
      this.req.page = page
      this.getMallQnaInfo()
    },
    dateAreaCallback(data) { // 구간검색함수
      this.req.objSearchMapper.startDate = data.startDate
      this.req.objSearchMapper.endDate = data.endDate
      this.req.page = 1
      this.getMallQnaInfo()
    },
    async read() {
      this.req.objSearchMapper.startDate = null
      this.req.objSearchMapper.endDate = null
      this.req.objSearchMapper.searchKeyword = this.searchKeyword
      this.req.page = 1
      await this.getMallQnaInfo()
    },
    async getMallQnaInfo() {
      await this.$http.post(`${this.$serverurl}/api/getMallQnaInfo`, this.req)
        .then(res => {
          if (200 == res.data.code) {
            this.tmpTableData = res.data.body.results
            this.tmpTableData.sort((a, b) => {
              return new Date(a.created) - new Date(b.created);
            });
            this.totalCount= res.data.totalCount
          } else {
            if (!res.data.body.results.length) {
              this.$notify.error({
                title: '',
                message: res.data.message,
                position: 'bottom-right',
                showClose: false
              });
            }
          }

          this.$overay(this.loading)
        })
    },
  }

}
</script>