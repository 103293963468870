<template>
  <div class="container mt-3 relative noselect" style="max-width: 1300px !important;">
    <TopBar />
    <TopMenu />
    <TopBanner />
    <TopNotice />
  
    <div v-if="!Object.keys(formOrderData).length">
      <div class="borderbottom1px pb-2 colorreddeep font18 center">
        결제할 정보가 없습니다.
      </div>
      <div class="mt-3">
        <el-row :gutter="10">
          <el-col :xl="12" :xs="12" :md="12" :sm="12" @click.native="readRecommand()"
            class="font15 bold text-left colordark">
            이런상품 좋아요.
          </el-col>
          <el-col :xl="12" :xs="12" :md="12" :sm="12" class="right">
            <span class="hand bold" style="right:10px;" @click="readRecommand()">
              <i class="fa-solid fa-rotate"></i>
              다른상품 보기</span>
          </el-col>
        </el-row>
      </div>
      <el-row :gutter="10">
        <!-- $router.push('/productView/' + item.seq)" -->
        <el-col :xl="3" :xs="8" :md="3" :sm="8" v-for="(item, index) in recommandProducts" :key="index"
          @click.native="selectItem=item" class="carousel-container ">
          <productcard :data="item" />
        </el-col>
      </el-row>
      <productviewpop v-if="selectItem" :data="selectItem" @callback="productviewpopcallback" />
  
    </div>
    <div v-else>
      <template>
        <el-row v-if="notBuylist.length">
          <el-col :xl="24" :xs="24" :sm="24" class="mb-2 font15 bold notosanskr">
            구매불가상품
          </el-col>
        </el-row>
        <el-row v-for="(item, index) in notBuylist" :key="index" :gutter="10" class="font15 borderbottom1px">
          <el-col :xl="1" :xs="24" :sm="1" class="p-2">
            <div :style="setbackground(item.salesProductInfo)" class="productImageItem hand grayscale"
              @click="openView(item)" style="min-height: 25px;max-width:25px;filter:">
            </div>
          </el-col>
          <el-col :xl="10" :xs="24" :sm="10" class="p-2">
            <div class="hand colorgraylight fontline-through" @click="openView(item)">
              {{ item.salesProductInfo.name }}
            </div>
          </el-col>
          <el-col :xl="13" :xs="24" :sm="13" class="p-2 text-right">
            <el-row>
              <el-col :xl="22" :xs="22" :sm="22">
                <div class="mb-2">
                  <!-- 카트수량: {{ item.qty }} -->
                  <span class="badge badge-red">SOLD OUT</span>
                </div>
              </el-col>
              <el-col :xl="2" :xs="2" :sm="2">
                <i class="el-icon-close hand" @click="showAlert(item)"></i>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <!-- 카트상품 -->
        <el-row>
          <el-col :xl="24" :xs="24" :sm="24" class="mb-2 font15 bold">
            카트상품
          </el-col>
          <el-col>
            <div v-for="(value, key, index) in cateGroup" :key="index">
              <el-divider content-position="left">
                <span class="bold">
                  {{key}}
                </span>
                <span class="colorgraylight ml-2">
                  운임{{ $comma(value.deliAmount) }}
                </span>
              </el-divider>
              <el-row v-for="item in formOrderData.filter(items=>items.storageId== value.storageId)" :key="item.seq"
                :gutter="10" class="font15 borderbottom1px">
                <el-col :xl="1" :xs="24" :sm="1" class="p-2">
                  <el-checkbox class="cutCheck" @change="toCart($event, item)"
                    :disabled="shouldDisableCheckbox(item)"></el-checkbox>
                </el-col>
                <el-col :xl="2" :xs="24" :sm="2" class="p-2">
                  <div :style="setbackground(item.salesProductInfo)" class="productImageItemCart hand"
                    @click="openView(item)">
                  </div>
                </el-col>
                <el-col :xl="9" :xs="24" :sm="9" class="p-2">
                  <div class="hand font15 bold" @click="openView(item)">
                    {{ item.salesProductInfo.name }}
                    <span class="badge badge-blue ml-2" v-if="item.import==1">수입</span>
                  </div>
                  <div>{{ item.salesProductInfo.pid }}</div>
                  <div>
                    <span class="badge badge-black">
                      출하지: {{ item.storageName }}
                    </span>
                  </div>
                </el-col>
                <el-col :xl="12" :xs="24" :sm="12" class="p-2 text-right">
                  <el-row>
                    <el-col :xl="22" :xs="22" :sm="22">
                      <div class="mb-2">
                        <el-input-number v-model="item.qty" :min="1" :max="item.salesProductInfo.hasAmount" :step="1"
                          size="mini" readonly class="font15" @change="calctoal(item)"></el-input-number>
                      </div>
                      <div>
                        금액: {{ $totalInTax(item.price, 1, true) }}
                      </div>
                      <div>
                        소계: {{ $totalInTax(item.qty, item.price, true) }}
                      </div>
                    </el-col>
                    <el-col :xl="2" :xs="2" :sm="2">
                      <i class="el-icon-close hand" @click="showAlert(item)"></i>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :xl="1" :xs="24" :sm="1" class="p-2">
            <div>
  
            </div>
          </el-col>
        </el-row>
        <!-- <el-row v-for="item in formOrderData" :key="item.seq" :gutter="10" class="font15 borderbottom1px">
                                                                    <el-col :xl="1" :xs="24" :sm="1" class="p-2">
                                                                      <el-checkbox @change="toCart($event, item)"></el-checkbox>
                                                                    </el-col>
                                                                    <el-col :xl="4" :xs="24" :sm="4" class="p-2" style="max-width: 130px;">
                                                                      <div :style="setbackground(item.salesProductInfo)" class="productImageItemCart hand" @click="openView(item)">
                                                                      </div>
                                                                    </el-col>
                                                                    <el-col :xl="7" :xs="24" :sm="7" class="p-2">
                                                                      <div class="hand font15 bold" @click="openView(item)">
                                                                        {{ item.salesProductInfo.name }}
                                                                        <span class="badge badge-blue ml-2" v-if="item.import==1">수입</span>
                                                                      </div>
                                                                      <div>{{ item.salesProductInfo.pid }}</div>
                                                                      <div>
                                                                        <span class="badge badge-black">
                                                                          {{ item.storageName }}
                                                                        </span>
                                                                      </div>
                                                                    </el-col>
                                                                    <el-col :xl="12" :xs="24" :sm="12" class="p-2 text-right">
                                                                      <el-row>
                                                                        <el-col :xl="22" :xs="22" :sm="22">
                                                                          <div class="mb-2">
                                                                            <el-input-number v-model="item.qty" :min="1" :max="item.salesProductInfo.hasAmount" :step="1"
                                                                              size="mini" readonly class="font15" @change="calctoal(item)"></el-input-number>
                                                                          </div>
                                                                          <div>
                                                                            금액: {{ $totalInTax(item.price, 1, true) }}
                                                                          </div>
                                                                          <div>
                                                                            소계: {{ $totalInTax(item.qty, item.price, true) }}
                                                                          </div>
                                                                        </el-col>
                                                                        <el-col :xl="2" :xs="2" :sm="2">
                                                                          <i class="el-icon-close hand" @click="showAlert(item)"></i>
                                                                        </el-col>
                                                                      </el-row>
                                                                    </el-col>
                                                                  </el-row> -->
      </template>
      <div class="mt-4 font15">
        <div class="bigbadge-border w100p">
          <el-row>
            <el-col :xl="10" :md="10" class="colorred bold pl-2">
              {{ paymengMsg }}
            </el-col>
            <el-col :xl="14" :md="14" class="right">
              <span class="mr-2">
                구매건수: {{ $comma(totalType) }}건
              </span>
              <span class="mr-2">
                구매수량: {{ $comma(totalQty) }}건
              </span>
              <span class="mr-2">
                구매금액: {{ $comma(totalAmount + totalAmount * 0.1) }}원
              </span>
            </el-col>
          </el-row>
        </div>
      </div>
      <el-row :gutter="10" class="mt-3 borderbottom1px pb-2">
        <el-col :xl="3" :xs="24" :sm="3" class="mb-2 font15 bold">
          발송자변경<el-checkbox class="ml-2" @change="handleChangeFromInfo"></el-checkbox>
        </el-col>
        <el-col :xl="5" :xs="24" :sm="5" class="mb-2 font15 bold">
          <el-input placeholder="발송자이름" size="small" v-model="formData.fromName" :disabled="!changeFromInfo">
          </el-input>
        </el-col>
        <el-col :xl="5" :xs="24" :sm="5" class="mb-2 font15 bold">
          <el-input placeholder="연락처" size="small" v-model="formData.fromPhone" :disabled="!changeFromInfo">
          </el-input>
        </el-col>
      </el-row>
  
      <div class="xinfengborder mt-4 p-3">
        <el-row :gutter="10" class=" font15 borderbottom1px pb-2">
          <el-col :xl="24" :xs="24" :sm="24" class="mb-2 font15 bold">
            수취정보
            <span class="badge badge-blue hand" @click="showShippingAddressValue = true"
              v-if="0!=formData.shippingValue && 4!=formData.shippingValue">주소선택</span>
          </el-col>
          <el-col :xl="24" :xs="24" :sm="24" class="mb-2" v-if="0!=formData.shippingValue && 4!=formData.shippingValue">
            <el-row :gutter="10">
              <el-col :xl="5" :xs="24" :sm="5" class="mb-2">
                <el-input placeholder="우편번호" size="small" prefix-icon="el-icon-search" v-model="formData.postNum"
                  class="hand" readonly @click.native="loadDaumPostcodeScript">
                </el-input>
              </el-col>
              <el-col :xl="10" :xs="24" :sm="10" class="mb-2">
                <el-input placeholder="주소" size="small" v-model="formData.address" readonly>
                </el-input>
              </el-col>
              <el-col :xl="9" :xs="24" :sm="9" class="mb-2">
                <el-input placeholder="세부주소" size="small" v-model="formData.addrSub">
                </el-input>
              </el-col>
            </el-row>
          </el-col>
          <el-col :xl="24" :xs="24" :sm="24" class="mb-2">
            <el-row :gutter="10">
              <el-col :xl="5" :xs="24" :sm="5" class="mb-2">
                <el-input placeholder="수추인" size="small" v-model="formData.name">
                </el-input>
              </el-col>
              <el-col :xl="5" :xs="24" :sm="5" class="mb-2">
                <el-input placeholder="연락처" size="small" v-model="formData.phone">
                </el-input>
              </el-col>
              <el-col :xl="14" :xs="24" :sm="14" class="mb-2">
                <el-input placeholder="배송메모" size="small" v-model="formData.memo">
                </el-input>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row :gutter="10" class="mt-3">
          <el-col :xl="3" :xs="24" :sm="3" class="mb-2 font15 bold">
            배송사
          </el-col>
          <el-col :xl="5" :xs="24" :sm="5" class="mb-2 font15 bold">
            <el-select size="small" v-model="formData.shippingValue" placeholder="배송사선택" class="w100p"
              @change="changeShppingType">
              <el-option v-for="item in $shippingType" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <el-col :xl="16" :xs="24" :sm="16" class="mb-2 font15 bold">
            <el-input placeholder="화물영업소지점을 입력하세요.없으면 배송사를 별도선택하세요." size="small" v-model="formData.arrive"
              :disabled="formData.shippingValue != 0">
            </el-input>
          </el-col>
        </el-row>
      </div>
      <!-- <el-row :gutter="10" class="mt-3 borderbottom1px pb-2">
                                        <el-col :xl="3" :xs="24" :sm="3" class="mb-2 font15 bold">
                                          발송자변경<el-checkbox class="ml-2" @change="handleChangeFromInfo"></el-checkbox>
                                        </el-col>
                                        <el-col :xl="5" :xs="24" :sm="5" class="mb-2 font15 bold">
                                          <el-input placeholder="발송자이름" size="small" v-model="formData.fromName" :disabled="!changeFromInfo">
                                          </el-input>
                                        </el-col>
                                        <el-col :xl="5" :xs="24" :sm="5" class="mb-2 font15 bold">
                                          <el-input placeholder="연락처" size="small" v-model="formData.fromPhone" :disabled="!changeFromInfo">
                                          </el-input>
                                        </el-col>
                                      </el-row> -->
  
  
      <el-row :gutter="10" class="mt-3 borderbottom1px pb-2">
        <el-col :xl="3" :xs="24" :sm="3" class="mb-2 font15 bold">
          배송결제
        </el-col>
        <el-col :xl="21" :xs="24" :sm="21" class="mb-2 font15 bold">
          <el-radio-group size="mini" v-model="formData.shippingPayType" @change="setShipping">
            <el-radio-button type="danger" :label="$shippingPayType[0].value"
              v-if="!submitData || submitData[0]&&submitData[0].import==0" :value="$shippingPayType[0].value">
              {{ $shippingPayType[0].label }}
            </el-radio-button>
            <el-radio-button type="danger" :label="$shippingPayType[1].value" :value="$shippingPayType[1].value">
              {{ $shippingPayType[1].label }}
            </el-radio-button>
          </el-radio-group>
          <!-- <span class="ml-3">({{ $totalInTax(formData.shipping, 1, true) }}원)</span> -->
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-3 borderbottom1px pb-2">
        <el-col :xl="3" :xs="24" :sm="3" class="mb-2 font15 bold">
          운송장번호
        </el-col>
        <el-col :xl="21" :xs="24" :sm="21" class="mb-2 font15 bold">
          <span class="bigbadge-dark pr-1">
            <!-- 배송비용은 판매사와 협의하여 주세요. -->
            운송장번호는 출고 후 다음날에 반영됩니다.
          </span>
          <!-- <span class="bigbadge-dark">
                                                                                              {{ $comma(totalAmount + totalAmount * 0.1 + $totalInTax(formData.shipping, 1, false)) }}
                                                                                              <span class="empty ml-1">원</span>
                                                                                          </span>
                                                                                          <span class="ml-2 colorred"
                                                                                              v-if="!isBuy(totalAmount + totalAmount * 0.1 + $totalInTax(formData.shipping, 1, false)).status">
                                                                                              충전잔액이 부족합니다.
                                                                                          </span> -->
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-3 borderbottom1px pb-2">
        <el-col :xl="24" :xs="24" :sm="24" class="mb-2 font15 bold">
          구매전 주의사항
        </el-col>
        <el-col :xl="24" :xs="24" :sm="24" class="mb-2 font15 bold">
          <div v-html="getBuyPolicy()" class="warning-content"
            style="line-height: 20px; font-size: 15px;line-height: 30px; overflow: hidden; overflow-y: auto; height:150px;">
          </div>
        </el-col>
        <el-col>
          <!-- + $totalInTax(formData.shipping, 1, false) -->
          <el-checkbox class="ml-2 colorred bold" v-model="policyAgree" @change="policyAgreeChange"
            v-if="isBuy(totalAmount + totalAmount * 0.1 ).status">
            <span class="font18">본인({{ $loginUserInfo.name }})은 구매주의사항에 동의합니다.</span>
          </el-checkbox>
          <span v-else>
            <el-button type="primary" size="small" class="w100p font13 el-btn-orange"
              @click="$changeRoute('account/buyPointAuto')">
              충전하기
            </el-button>
          </span>
        </el-col>
        <el-col v-if="policyAgree">
          <!-- + $totalInTax(formData.shipping, 1, false) -->
          <el-button type="danger" size="small" icon="el-icon-bank-card" class="el-btn-orange font13 w100p"
            @click="checkout()">
            {{ $comma(totalAmount + totalAmount * 0.1) }}원
            결제하기</el-button>
        </el-col>
      </el-row>
    </div>
  
  
    <alert :message="delmessage" v-if="showAlertValue" @callback="handleDelete" />
    <shippingaddress v-if="showShippingAddressValue" @callback="changeShippingAddress" />
    <FootBar />
    <el-backtop>
    </el-backtop>
  </div>
</template>

<script>
import alert from './component-alert.vue'
import TopBar from './component-top.vue'
import TopBanner from './component-top-banner.vue'
import TopNotice from './component-top-notice.vue'
import TopMenu from './component-top-menu.vue'
import FootBar from './component-foot.vue'
import productcard from './component-product-card.vue'
import productcardbutton from './component-product-card-button.vue'
import shippingaddress from './component-checkout-shipping-address.vue'
import productviewpop from './component-product-view-pop.vue'
import { Loading } from 'element-ui'
export default {
  components: {
    TopBar,
    FootBar,
    TopBanner,
    TopNotice,
    TopMenu,
    Loading,
    productcard,
    productcardbutton,
    alert, shippingaddress, productviewpop
  },
  data() {
    return {
      selectItem: null,
      showShippingAddressValue: false,
      showAlertValue: false,
      delmessage: '해당항목이 곧 삭제됩니다.<br/>계속하시겠습니까?',
      delItems: {},
      cartInfo: {},
      loginUserInfo: null,
      tmpTableData: [],
      totalAmount: 0,
      totalQty: 0,
      totalType: 0,
      changeFromInfo: false, // 발송자정보변경스위치
      policyAgree: false, // 구매약관동의스위치
      formData: {
        fromName: '',  // 발송자이름
        fromPhone: '',    // 발송자연락처
        postNum: null,
        address: null,
        addrSub: null,
        name: null,
        phone: null,
        shipping: 0,//배송요금
        shippingValue: 1, // 배송사 선택값
        arrive: '',//화물영업소지점
        barcode: null, // 운송번호
        memo: null, // 배송메모
        orderItems: [],
        cateGroup: [],

        shippingPayType: 0, // 배송요금입력판단값
      },
      formDataBack: {},
      submitData: [],
      formOrderData: [],
      notBuylist: [],
      recommandProducts: [],
    }
  },
  async created() {
    window.scrollTo(0, 0);
    this.loading = Loading.service({ fullscreen: true })
    this.$loginUserInfo = JSON.parse(localStorage.getItem('loginUserInfo'))
    this.$http.defaults.headers.common['exjwtauthorization'] = this.$loginUserInfo?.token
    // 기본배송정보셋팅
    this.formData = {
      postNum: this.$loginUserInfo.basicShippingInfo?.postNum,
      address: this.$loginUserInfo.basicShippingInfo?.address,
      addrSub: this.$loginUserInfo.basicShippingInfo?.addrSub,
      name: this.$loginUserInfo.basicShippingInfo?.name,
      phone: this.$loginUserInfo.basicShippingInfo?.phone,
      shipping: 0,//배송요금
      shippingValue: 1, // 배송사 선택값
      arrive: null,//화물영업소지점
      memo: '부재시 전화주세요.', // 배송메모
      shippingPayType: 0, // 배송요금입력판단값
      fromName: this.$loginUserInfo.companyInfo.name,
      fromPhone: this.$loginUserInfo.phone,
      orderItems: [],
      eventSource: null,
    }
    this.read()
    await this.readRecommand()

  },

  mounted() {
    this.REAL_TIME()

  },
  beforeDestroy() {
    if (this.eventSource) {
      this.eventSource.close();
    }
  },
  methods: {
    async REAL_TIME() {
      const token = this.$loginUserInfo.token

      this.eventSource = new EventSource(`${this.$serverurl}/event?ExJwtAuthorization=${token}`)
      this.eventSource.onmessage = (event) => {
        let res = JSON.parse(event.data)
        if (200 == res.code) {
          this.tmpTableData = res.body.results
          this.formOrderData = []
          this.notBuylist = []
          for (var item of this.tmpTableData) {
            if (item.buy != 9) {
              // this.totalAmount += item.qty * item.price
              // this.totalQty += item.qty
              // this.totalType += 1
              this.formOrderData.push(item)
            } else {
              this.notBuylist.push(item)
            }
          }
          this.cateGroup = this.tmpTableData.reduce((acc, item) => {
            if (!acc[item.storageName]) {
              acc[item.storageName] = {}
            }
            let deliAmount = 15000
            if (item.import == 0) {
              deliAmount = 6000
            }
            acc[item.storageName] = { name: item.storageName, deliAmount, storageId: item.storageId }
            return acc
          }, {})
        } else {
          if (!res.body.results.length) {
            this.formOrderData = []
          }
        }
      }
      this.eventSource.onerror = (error) => {
        console.error('SSE error:', error);
        this.eventSource.close();
      };
    },

    changeShppingType() {
      this.formDataBack = JSON.parse(JSON.stringify(this.formData))
      // 화물선택> 우번,주소,세부주소 값 삭제 
      if (0 == this.formData.shippingValue) {
        this.formData.postNum = null
        this.formData.address = null
        this.formData.addrSub = null
      } else {
        this.formData = JSON.parse(JSON.stringify(this.formDataBack))
      }


    },

    productviewpopcallback(data) {
      this.selectItem = null
    },
    async readRecommand() {
      this.loading = Loading.service({ fullscreen: true })
      const req = {
        objSearchMapper: { searchKeyword: null, }, page: 1, size: 30
      }
      await this.$http.post(`${this.$serverurl}/api/getRecommandProductInfo`, req)
        .then(res => {
          if (200 == res.data.code) {
            this.recommandProducts = res.data.body.results
          }
        })
      this.$overay(this.loading)
    },
    shouldDisableCheckbox(item) {
      // 체크박스의 disabled 상태를 계산하는 메서드
      if (!this.submitData.length) {
        this.paymengMsg = '결제할 상품을 선택하세요.'
        return false
      } else {
        this.paymengMsg = '출하지 별로 결제를  하셔야합니다.'
        return item.storageId !== this.submitData[0]?.storageId;
      }
    },
    toCart(event, item) {
      if (item.import == 1) {
        this.formData.shippingPayType = 1
      }
      const tmpList = this.submitData.filter(items => items.storageId == item.storageId)
      if (this.submitData.length && !tmpList.length) {
        this.submitData = []
      }
      const tmpItem = JSON.parse(JSON.stringify(item))
      const index = this.submitData.findIndex(data => data.seq === tmpItem.seq)
      if (event) {
        if (index === -1) {
          this.submitData.push(tmpItem)
        }
      } else {
        if (index !== -1) {
          this.submitData.splice(index, 1)
        }
      }
      this.totalAmount = 0
      this.totalQty = 0
      this.totalType = 0
      for (var items of this.submitData) {
        this.totalAmount += items.qty * items.price
        this.totalQty += items.qty
        this.totalType += 1
      }
      this.formData.orderItems = this.submitData
      this.policyAgree = false
    },
    // 결제
    async checkout() {
      if (!this.formData?.orderItems?.length) {
        this.$notify.error({
          title: '',
          message: '결제할 상품을 선택하세요.',
          position: 'bottom-right',
          showClose: true,
          duration: 1000,
        })
        return
      }
      await this.$http.post(`${this.$serverurl}/api/checkout`, this.formData)
        .then(res => {
          localStorage.setItem('paymentSuccess', JSON.stringify(res))
          this.$changeRoute('paymentSuccess')
        })
    },
    // 상품상세페이지
    openView(item) {
      const url = `/productView/` + item.salesProductInfo.seq
      window.open(url)
    },
    isBuy(params) {
      if (this.$loginUserInfo.companyInfo.point >= params) {
        return {
          status: true,
          value: this.$loginUserInfo.companyInfo.point - params
        }
      } else {
        return {
          status: false,
          value: this.$loginUserInfo.companyInfo.point - params
        }
      }
    },
    async calctoal(data) {
      // this.totalAmount = 0
      // this.formData.orderItems.forEach(item => {
      //     this.totalAmount += item.qty * item.price
      // })
      const res = await this.$http.post(`${this.$serverurl}/api/addToCart`, data)
        .then(res => {
          if (res.data.code != 201) {
            this.$notify.error({
              title: '',
              message: res.data.message,
              position: 'bottom-right',
              showClose: true,
              duration: 1000,
            })
          }
        })
      this.read()
    },
    changeShippingAddress(data) {
      if (data) {
        this.formData.name = data.name
        this.formData.phone = data.phone
        this.formData.postNum = data.postNum
        this.formData.address = data.address
        this.formData.addrSub = data.addrSub
      }
      this.showShippingAddressValue = false
    },
    policyAgreeChange(data) {
      if (this.formData.fromName.trim() == '' || this.formData.fromPhone.trim == '') {
        this.$notify.error({
          title: '',
          message: '발송자 정보를 입력하세요.',
          position: 'bottom-right',
          showClose: false
        });
        this.policyAgree = false
        data = false
        return
      }
      if (!this.submitData.length) {
        this.$notify.error({
          title: '',
          message: '구매할 상품을 선택하세요.',
          position: 'bottom-right',
          showClose: false
        });
        this.policyAgree = false
        data = false
        return
      }
      if (data &&
        this.formData.shippingValue == 0 && !this.formData.arrive.trim() == ''
      ) {
        this.$notify.error({
          title: '',
          message: '화물지점을 입력하세요.',
          position: 'bottom-right',
          showClose: false
        });
        data = false

      }
      this.policyAgree = data
    },
    getBuyPolicy() {
      if (this.$loginUserInfo) {
        const tmpBuyPolicy = this.$loginUserInfo.mallConfigInfo.buyPolicy
        return tmpBuyPolicy.replace(/\n/g, '<br>')
      }
    },
    handleChangeFromInfo(data) {
      if (data) {
        this.changeFromInfo = true
        this.formData.fromName = ''
        this.formData.fromPhone = ''
      } else {
        this.changeFromInfo = false
        this.formData.fromName = this.$loginUserInfo.companyInfo.name
        this.formData.fromPhone = this.$loginUserInfo.phone
      }

    },
    setShipping() { // 배송요금설정
      if (this.formData.shippingPayType) {
        this.formData.shipping = 0
      } else {
        this.formData.shipping = 0 //6000 배송요금부분 사용안함
      }
    },
    showAlert(data) {
      this.delItems = data
      this.showAlertValue = true
    },

    async handleDelete(data) {
      this.showAlertValue = false
      if (!data.agree) { return }
      this.loading = Loading.service({ fullscreen: true })
      await this.$http.post(`${this.$serverurl}/api/delCartInfo`, this.delItems)
        .then(res => {
          if (201 == res.data.code) {
            this.$notify.success({
              title: '',
              message: res.data.message,
              position: 'bottom-right',
              showClose: false
            });
            setTimeout(() => {
              this.read()
            }, 300);
            this.$overay(this.loading)
          }
        })
    },

    async read() {
      const req = {
        objSearchMapper: {
          searchKeyword: null,
        },
        page: 1,
        size: 50
      }
      await this.$http.post(`${this.$serverurl}/api/getCartInfo`, req)
        .then(res => {
          if (200 == res.data.code) {
            this.tmpTableData = res.data.body.results
            this.formOrderData = []
            this.notBuylist = []
            for (var item of this.tmpTableData) {
              if (item.buy != 9) {
                // this.totalAmount += item.qty * item.price
                // this.totalQty += item.qty
                // this.totalType += 1
                this.formOrderData.push(item)
              } else {
                this.notBuylist.push(item)
              }
            }
            this.cateGroup = this.tmpTableData.reduce((acc, item) => {
              if (!acc[item.storageName]) {
                acc[item.storageName] = {}
              }
              let deliAmount = 15000
              if (item.import == 0) {
                deliAmount = 6000
              }
              acc[item.storageName] = { name: item.storageName, deliAmount, storageId: item.storageId }
              return acc
            }, {})
          } else {
            if (!res.data.body.results.length) {
              this.formOrderData = []
              // this.$router.go(-1)
              // this.$notify.error({
              //   title: '',
              //   message: res.data.message,
              //   position: 'bottom-right',
              //   showClose: false
              // });
            }
          }
          this.$overay(this.loading)
        })
      // if (!this.formOrderData.length) {
      //   this.$router.go(-1)
      // }
    },
    setbackground(item) {
      if (item && item.images && item.images.length > 0) {
        const https = item.images[0].includes('://')
        if (!https) {
          item.images[0] = this.$imageurl + item.images[0]
        }
        return {
          background: `url(${item.images[0]}) no-repeat center center / cover !important`
        };
      } else {
        return {
          background: `url('${this.$imageurl}/upload/no-image.webp') no-repeat center center / cover`
        };
      }
    },
    loadDaumPostcodeScript() {
      const script = document.createElement("script");
      script.src =
        "//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";
      script.async = true;
      script.onload = () => {
        this.initializeDaumPostcode();
      };
      document.head.appendChild(script);
    },
    initializeDaumPostcode() {
      const daum = null
      new (window).daum.Postcode({
        oncomplete: (data) => {
          // data에 선택한 주소 정보가 들어 있음
          this.formData.postNum = data.zonecode
          this.formData.address = data.address
        },
      }).open();
    },
  }

}
</script>