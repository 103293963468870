<template>
  <div class="container mt-3 relative" style="min-width: 100vw !important;">
    <TopBar />
    <TopMenu />
    <div v-if="$loginUserInfo">
      <!-- 차대번호검색 -->
      <div class="fixed" style="top:140px; right:50px; z-index: 99;">
        <div v-if="!($loginUserInfo.remaDays>=0)">
          이용 불가 시
          <span class="colorblue hand" @click="$changeRoute('account/buyLicense')">
            여기를 클릭
          </span>
          하여 충전 혹은 재
          <span class="colorblue hand" @click="$changeRoute('login')">
            로그인
          </span> 하세요.
        </div>
      </div>
      <div class="fixed" style="bottom:20px; right:50px; z-index: 99;">
        <div class="font20 colorlightddd noselect">
          <span class="mr-2">
            남은검색회수:
          </span>
          <span>
            {{getCarsVinList.vinSearchCount}}
          </span>
        </div>
      </div>
      <el-row class="w100p mt-2" :gutter="15">
        <el-col :xl="8" :md="8" :xs="24" style="background-color: #fff;" class="relative">
          <searchvinbox :dataValid="dataValid" :PropsValid="$loginUserInfo&& $loginUserInfo.remaDays>=0"
            @callback="returnCallback" />
        </el-col>
        <el-col :xl="16" :md="16" :xs="24">
          <!-- 카정보, 필터, 키워드(핫네임) -->
          <div class="hotname-box w100p"
            v-if="Object.keys(getCarsVinList).length || getCarsStructureList.length || getCarsStructureSubList.length">
            <el-button size="mini" type="info" @click="isDrawerOpen = isDrawerOpen ? false : true">car info</el-button>
            <!-- 필터버튼 -->
            <el-button size="mini" type="info" :class="filterData ? 'btn-red' : ''" @click="filter()">
              {{ fitlerbuttontitle }}
            </el-button>
            <!-- 키워드입력 -->
            <span class="relative">
              <el-input size="mini" class="pl-2" style="max-width:250px;" v-model="hotName" placeholder="검색어를 입력하세요."
                @click.native="showHoNameListSwitch()" @keyup.native="showhotNameList = false" @blur="closeHotNameList()"
                @keyup.enter.native="selectHotName(hotName)"></el-input>
              <span class="badge badge-info absolute hand" style="right:5px;top:3px"
                @click="selectHotName(hotName)">enter</span>
              <!-- 기본키워드 -->
              <div v-if="showhotNameList" class="absolute bigbadge-yellow borderbox p-2"
                style="left:10px;top:34px;width:242px;height:300px;overflow:hidden; overflow-y: auto;z-index: 9;;">
                <div v-for="(item, index) in hotNameList" :key="index" class="p-1 bigbadge-hover"
                  @click="selectHotName(item.name); showhotNameList = false;">
                  <span>{{ item.name }}</span>
                </div>
              </div>
            </span>
          </div>
        </el-col>
      </el-row>
      <el-dialog title="" :visible.sync="largeIamge" width="50%" :before-close="handleClose" center v-draggable
        :destroy-on-close="true">
        <div class="center noselect">
          <div style="position:absolute;left:0px;width:100%;height:100%;padding:12%;font-size: 7vw;font-weight:bold;
                                                  color:rgba(0,0,0,0.05)" @click="handleClose">
            008 PARTS
          </div>
          <img :src="img_path && getCatetoryImg(img_path)" style="max-width:100%;" />
        </div>
      </el-dialog>
      <el-row class="w100p" :gutter="15">
        <el-col :xl="8" :md="8" :xs="24" class="mt-2 noselect" v-if="!largeIamge">
          <!-- 그룹카테고리 및 세부좌표이미지 -->
          <el-tabs v-model="leftName" v-if="getCarsGroupList.length" class="drag-box mt-2">
            <!-- 제품그룹카테고리 -->
            <el-tab-pane :label="'제품그룹(' + getCarsGroupList.length + ')'" name="productgroup">
              <el-row class="mt-2" style="height:calc(100vh - 270px);overflow: hidden;overflow-y: auto;">
                <el-col :span="24 / 5" v-for="(item, index) in getCarsGroupList" :key="index">
                  <div class="relative vingroupimg mr-2 mb-2" :style="getCatetoryBgImg(item.img)"
                    @click="item.num != '' && getCarsStructure(item); groups = false">
                    <span class="badge-info text-center absolute badge-small" style="left:0px;top:0px;width:30px;">
                      {{ item.num }}
                    </span>
                    <div class="absolute vingroupimg-title text-center ellipline mb-1"
                      style="left:0px;bottom:0px;width:100%; line-height: 15px;">
                      {{ $getLang(item.label) }}
                    </div>
                  </div>
                </el-col>
              </el-row>
            </el-tab-pane>
            <el-tab-pane :label="'부품도면'" name="partsImage" v-if="Object.keys(getCarsPartsList).length">
              <!-- 좌표이미지 -->
              <div :class="{ fixedSub: isFixed, fixedBottom: isBottom }" style="height:calc(100vh - 270px);">
                <div class="relative">
                  <div ref="partMapBox" class="image-map-container relative move"
                    :style="{ transform: `matrix(${scale}, 0, 0, ${scale}, 0, 0)` }" @mousemove="registerMouseDownHandler"
                    @wheel="checkSizeWheel">
                    <img ref="partdetailImage" class="part-detail-image" :src="img_path && getCatetoryImg(img_path)"
                      alt="part image" usemap="#part_image_map" />
                    <!-- 좌표버튼 -->
                    <span v-for="(item, index) in getCarsPartsList && getCarsPartsList.img_points" :key="index">
                      <div class="area absolute" v-if="item.hsKey !== '00'" :alt="item.hsKey"
                        @click="click_hover(item.hsKey), moveToRow(item.hsKey)" :class="setbg(item.hsKey)"
                        :style="setPoint(item.hsKey, item.hsX, item.hsY, item.maxX, item.maxY)"
                        :ref="'position' + item.hsKey"></div>
                    </span>
                    <div class="map-selector"></div>
                  </div>
                  <!-- 이미지컨트롤 버튼 -->
                  <div class="absolute" style="right:10px;top:80px;">
                    <div id="part-map-opt" class="map-opt">
                      <div class="img-zoom" @click="zoomIn"><i class="fa fa-plus"></i></div>
                      <div class="img-reduce" @click="zoomOut"><i class="fa fa-minus"></i>
                      </div>
                      <div class="img-org" @click="reset"><i class="fa fa-redo"></i></div>
                      <div class="img-org" @click="showLargeImage()">
                        <i class="far fa-window-restore"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 좌표이미지 -->
            </el-tab-pane>
          </el-tabs>
        </el-col>
        <el-col :xl="16" :md="16" :xs="24" class="mt-3">
          <!-- 차량기본정보 -->
          <div class="baseinfo-bo x p-3" style="top:70px"
            v-if="Object.keys(getCarsVinList).length && !getCarsStructureList.length">
            <div class="title mb-2">{{ vin }}</div>
            <table class="table-params w100p mb-5">
              <tbody>
                <tr>
                  <th style="width:120px;">연식</th>
                  <td>{{ getCarsVinList && getCarsVinList.data && getCarsVinList.data.year }}
                    <!-- v-if="$loginUserInfo.companyInfo.level == 'a'" -->
                    <span class="ml-2 colororange hand" @click="copyModel">
                      <i class="fas fa-copy"></i>
                      <span class="font10 ml-1">모델+연식복사</span>
                    </span>
                  </td>
                </tr>
                <tr>
                  <th style="width:120px;">Model Name</th>
                  <td>{{ getCarsVinList && getCarsVinList.data && getCarsVinList.data.name }}
                  </td>
                </tr>
                <tr v-for="(value, key) in getCarsVinList && getCarsVinList.data && getCarsVinList.data.maindata"
                  :key="key">
                  <th style="width:120px;">{{ $getLang(key) }}</th>
                  <td>{{ value }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- 각그룹 -->
          <el-tabs v-model="activeNames" v-if="getCarsStructureList.length ||
                                      getCarsStructureSubList.length ||
                                      group1title
                                    " @tab-click="handleClick" class="w100p detail-box">
            <el-tab-pane :label="'' + group1title" name="group1" v-if="getCarsStructureList.length || group1title">
              <!-- 1차그룹 -->
              <partsgroup1 :data="getCarsStructureList" class="mt-4" @callback="group1callback" />
            </el-tab-pane>
            <div v-if="noSubData && !getCarsPartsList" class="center w100p" style="margin-top: 150px;">
              <el-row>
                <el-col>
                  <label style="max-width: 98px;">
                    <div class="nodata"></div>
                  </label>
                </el-col>
                <el-col>
                  <label style="max-width: 250px;">
                    정보가 없습니다. 필터 해제 후 시도 하세요.
                  </label>
                </el-col>
              </el-row>
  
  
            </div>
            <el-tab-pane :label="'' + group2title" name="group2" v-if="getCarsStructureSubList.length">
              <!-- 2차그룹 -->
              <partsgroup2 :data="getCarsStructureSubList" class="mt-4" @callback="group2callback" />
            </el-tab-pane>
            <el-tab-pane :label="'' + $convert_br(group3title)" name="group3" v-if="Object.keys(getCarsPartsList).length"
              class="relative">
              <!-- 세부정보 -->
              <div class="text-right">
                <pagingbar :currentMid="currentMid" :pageData="pageData" @callback="pageCallback" />
              </div>
              <partsgroup3 :data="getCarsPartsList" :brand="brand" :getCarsTagsList="getCarsTagsList"
                :getCarsVinList="getCarsVinList" :filter="filterData" :selectedItemIndex="selectedItemIndex" class="mt-3" :vin="vin"
                :hotName="hotName" @callback="group3callback" />
              <div class="text-right">
                <pagingbar :currentMid="currentMid" :pageData="pageData" @callback="pageCallback" class="mt-3 mb-3" />
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
      <carinfoSlide :isDrawerOpen="isDrawerOpen" :carInfoImg="carInfoImg"
        :carSpec="getCarsVinList && getCarsVinList.data && getCarsVinList.data.maindata" :carOption="getCarsConfigList"
        @callback="slideCallback" />
      <epcpaymentalert v-if="showdemoalertvalue" @callback="showdemoalertcallback" />
      <!-- <FootBar /> -->
      <!-- <json-viewer :value="info" :expand-depth=1 copyable boxed sort class="mt-3"></json-viewer> -->
      <el-backtop>
      </el-backtop>
    </div>
  </div>
</template>

<script>
import epcpaymentalert from './component-epc-payment-alert.vue'
import carinfoSlide from './component-carinfo-right-slide.vue'
import pagingbar from './component-paging.vue'
import partsgroup1 from './component-epc-parts-group-1.vue'
import partsgroup2 from './component-epc-parts-group-2.vue'
import partsgroup3 from './component-epc-parts-group-3.vue'
import searchvinbox from './component-epc-search-vin-box.vue'
import TopBar from './component-top.vue'
import TopMenu from './component-top-menu.vue'
import FootBar from './component-foot.vue'
import productcard from './component-product-card.vue'
import { Loading } from 'element-ui'
import { color } from 'd3'
export default {
  components: {
    TopBar, FootBar, TopMenu, Loading, productcard
    , searchvinbox, partsgroup1, partsgroup2, partsgroup3, pagingbar, carinfoSlide, epcpaymentalert
  },
  data() {
    return {
      showdemoalertvalue: false,
      dataValid: true,
      largeIamge: false,

      noSubData: false,

      groupcategory: '', // 그룹카테고리 이름

      //필터박스 사용
      filterData: false,
      fitlerbuttontitle: '필터해제',

      activeNames: 'group1',
      carInfoImg: '',
      //차대검색
      hotName: '',
      hotNameList: [],
      hotNameInfo: {},
      getCarsVinList: {},
      getCarsGroupList: [],
      getCarsStructureList: [],
      getCarsStructureBackupList: [],
      getCarsStructureSubList: [],
      getCarsStructureSubBackupList: [],
      getCarsPartsList: {},
      getCarsPartsBackupList: {},
      getCarsTagsList: [],
      getCarsTagsBackupList: [],
      getCarsReplaceList: [],
      showhotNameList: false,
      pid_list: '',
      getCarsConfigList: {},
      getCarsPartBySearchKeyList: [],
      brand: null,
      num: null,
      mcid: null,
      mid: null,
      group1label: '',
      group2label: '',
      subgroup: '',
      structureName: '',

      // 그룹탭 지정한 이름
      leftName: 'productgroup',
      // 부품그룹메인 전달
      group1title: '',
      // 부품그룹서브 전달
      group2title: '',
      //세부목록 전달
      group3title: '',
      // 세부정보페이지
      pageData: [],
      currentMid: '',
      // page: 0,
      // totalPage: 0,

      isDrawerOpen: false,


      vin: null,
      group1Item: {},
      partsgroupmainitem: {},

      //좌표이미지
      isFixed: false,
      scale: 1,
      img_path: '/static/img/no-image.webp',
      setBgPnum: '',
      isBottom: false,
      scale: 1,
      partMapBoxWidth: 0,
      partMapBoxHeight: 0,
      selectedItemIndex: '',


      isLoading: false,

      userallinfo: null,
      remaLimit: 0
    }
  },
  beforeDestroy() {
  },

  async created() {
    window.scrollTo(0, 0);
    this.loading = Loading.service({ fullscreen: true })
    if (!this.$loginUserInfo || !JSON.parse(localStorage.getItem('loginUserInfo'))) {
      this.$changeRoute('login')
    }
    this.$overay(this.loading)
    this.showdemoalert(!this.$loginUserInfo.remaDays || this.$loginUserInfo.remaDays < 0)
  },
  beforeRouteUpdate(to, from, next) {
  },
  computed: {},
  mounted() {
    this.checkSize();
    window.addEventListener("resize", this.checkSize);
    //  스크롤 감시
    window.addEventListener("scroll", this.handleScroll);
  },
  updated() {
    // 3단계페이지의 이미지크기설정
    const image = new Image();
    image.src = this.img_path;
    let width = 0
    let height = 0
    image.onload = () => {
      width = image.naturalWidth;
      height = image.naturalHeight;
      const element = this.$refs.partMapBox
      if (element) {
        this.partMapBoxWidth = element.offsetWidth / width
        this.partMapBoxHeight = element.offsetHeight / height
      }
    };
  },
  methods: {
    showdemoalert(data) {
      let params = data
      if (params !== true && params !== false) {
        params = !this.$loginUserInfo.remaDays || this.$loginUserInfo.remaDays < 0
      }
      this.showdemoalertvalue = params
    },
    showdemoalertcallback() {
      this.showdemoalert(false)
    },
    showLargeImage() {
      // 좌표이미지팝창
      if (this.largeIamge) {
        this.largeIamge = false
      } else {
        this.largeIamge = true
      }
    },
    copyModel() {
      // 임시용기추가
      const textarea = document.createElement('textarea')
      textarea.style.position = 'fixed'
      textarea.style.left = '-9999px'
      const returnData = `${this.getCarsVinList?.data?.name
        + ' ' +
        this.getCarsVinList?.data?.year
        }`
      textarea.value = returnData
      document.body.appendChild(textarea)
      textarea.select();
      document.execCommand('copy')
      document.body.removeChild(textarea)
      this.$notify.success({
        title: '',
        message: returnData + ' 복사되었습니다.',
        position: 'bottom-right',
        showClose: true,
        duration: 1000,
      });
    },

    //슬라이드창콜백
    slideCallback() {
      this.isDrawerOpen = false
    },
    // 좌표이미지에 사용
    checkSize() {
      try {
        const image = new Image();
        image.src = this.img_path;
        let width = 0
        let height = 0
        image.onload = () => {
          width = image.naturalWidth;
          height = image.naturalHeight;
          const element = this.$refs.partMapBox
          if (element) {
            this.partMapBoxWidth = element.offsetWidth / width
            this.partMapBoxHeight = element.offsetHeight / height
          }
        };
      } catch { }
    },
    getCatetoryImg(params) {
      if (!params) {
        return 'https://api.008parts.co.kr/upload/no-image.webp'
      }
      return `${params}`
    },
    setPoint(hsKey, hsX, hsY, maxX, maxY) {
      const w = this.partMapBoxWidth
      const h = this.partMapBoxHeight
      const left = Number(hsKey) < 10 ? hsX * h - 0 : hsX * h
      const tmpw = maxX - hsX
      const tmph = maxY - hsY
      const result = {
        width: tmpw * w + 1 + 'px',
        height: tmph * h + 'px',
        position: 'absolute',
        top: `${hsY * w + -1}px`,
        left: `${left + 0}px`,
      }
      return result
    },
    setbg(hsKey) {
      if (hsKey == this.selectedItemIndex) {
        return 'select-red'
      }
      const isExist = this.getCarsPartsList.part_detail.filter((item) => {
        return item[0].pnum == hsKey
      })
      let bgClass = ''
      if (isExist && isExist.length == 0) {
        bgClass = 'bg-red d-none'
        if (this.setBgPnum != '' && hsKey == this.setBgPnum) { bgClass += 'areamove' }
      } else {
        bgClass = ''
        if (this.setBgPnum != '' && hsKey == this.setBgPnum) { bgClass += 'areamove' }
      }
      return bgClass
    },
    checkSizeWheel(event) {
      if (event.wheelDelta > 0) {
        // this.zoomIn()
      } else {
        // this.zoomOut()
      }
    },
    registerMouseDownHandler() {
      //이미지드래그
      const box = this.$refs.partMapBox;
      box.addEventListener("mousedown", this.handleMouseDown)
    },
    handleMouseDown(e) {
      e.preventDefault()
      const box = this.$refs.partMapBox
      box.style.transition = '0.0s ease'
      if (!box) {
        return;
      }
      const offsetX = e.clientX - box.offsetLeft;
      const offsetY = e.clientY - box.offsetTop;
      document.onmousemove = (e) => {
        const x = e.clientX - offsetX - 0;
        const y = e.clientY - offsetY - (this.isFixed ? 0 : 0);
        box.style.left = x + "px";
        box.style.top = y + "px";
      };
      document.onmouseup = () => {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    },
    zoomIn() {
      if (this.scale > 3) {
        return false
      }
      this.scale += 1;
      const partMapBox = this.$refs.partMapBox
      partMapBox.style.transition = '0.5s ease-in-out'
    },
    zoomOut() {
      this.scale -= 1;
      // }
      if (this.scale < 1) { this.scale = 1 }
      const partMapBox = this.$refs.partMapBox
      partMapBox.style.transition = '0.5s ease-in-out'
    },
    reset() {
      this.scale = 1;
      const partMapBox = this.$refs.partMapBox
      partMapBox.style.left = '0'
      partMapBox.style.top = '0'
      partMapBox.style.transition = '0.5s ease-in-out'
    },
    // 좌표버튼이벤트
    async click_hover(hsKey) {
      const isExist = this.getCarsPartsList.part_detail.filter((item) => {
        item[0].vin = this.vin
        item[0].brand = this.brand
        return item[0].pnum == hsKey
      })
      if (isExist && isExist.length == 0) {
        alert('해당차량에 적합하지않는 제품입니다.')
        return
      }
      this.selectedItemIndex = hsKey
      // 좌표클릭시 검색정보등록  호환품번클릭에도 넣음.
      const baseURL = `${this.$serverurl}/api/mallSearchEpcPartsSet`
      await this.$http.post(baseURL, isExist).then(
        res => { })

    },
    moveToRow(altValue) {
      const targetRow = document.querySelector(`.pnum${altValue}`);
      if (targetRow) {
        const targetRect = targetRow.getBoundingClientRect();
        const targetTop = window.pageYOffset + targetRect.top - 150;
        const maxScroll = document.documentElement.scrollHeight - 400;
        const scrollTo = Math.min(targetTop, maxScroll);
        window.scrollTo({ top: scrollTo, behavior: 'smooth' });
        document.documentElement.style.scrollBehavior = 'auto';
      }
    },
    handleScroll() {
      try {
        const partsImg = this.$refs.partdetailImage
        const scrollPosition = window.scrollY || document.documentElement.scrollTop;
        // top이 115보다 큰 경우에만 isFixed를 true로 설정
        this.isFixed = scrollPosition > 115;

        // 밑부분까지 스크롤 판단
        const maxScroll = document.documentElement.scrollHeight - window.innerHeight;
        if (scrollPosition === maxScroll && partsImg.clientHeight > 150) { this.isBottom = true } else { this.isBottom = false }
      } catch { }
    },
    selectRow(pnum) {
      if (this.findMove) { return }
      this.selectedItemIndex = pnum == this.selectedItemIndex ? '' : pnum;
      // 행선택 배경레드
      const ttt = this.$refs['position' + pnum]
      if (!ttt) { return false }
      ttt[0].classList.add('select-red')

    },
    moveRow(pnum) {
      this.setBgPnum = pnum
    },

    // 차대검색콜백,제품그룹클릭시 콜백
    async returnCallback(data) {
      this.vin = data.vin
      this.brand = data.brand
      this.getCarsStructureList = []
      this.getCarsStructureBackupList = []
      this.getCarsStructureSubList = []
      this.getCarsStructureSubBackupList = []
      this.getCarsPartsList = {}
      this.getCarsTagsList = []

      this.leftName = 'productgroup'
      this.group1title = null
      this.group2title = null
      this.group3title = null
      this.hotName = ''

      await this.getCarsVin(data.brand, data.vin)
    },
    async group1callback(data) { // has_child
      this.getCarsStructureSubList = []
      this.getCarsStructureSubBackupList = []
      this.getCarsPartsList = {}
      this.getCarsTagsList = []
      if (data.has_child == 1) { // 서브그룹있을떄
        await this.getCarsStructureSub(data)
        this.activeNames = 'group2'
      } else {
        this.mid = data.mid
        this.num = data.num
        // 세부정보
        await this.getCarsParts({
          brand: this.brand,
          brand_code: this.brand,
          mcid: this.mcid,
          mid: data.mid,
          num: data.num,
          subgroup: data.subgroup,
          vin: this.vin
        })
        // await this.getCarsTags({ brand_code: this.brand, pid_list: this.pid_list })
        this.group3title = `${data.name} (${data.mid})`
        this.activeNames = 'group3'
        this.leftName = 'partsImage'
      }
    },
    async group2callback(data) {
      this.mid = data.mid
      this.num = data.num
      this.structureName = data.name
      await this.getCarsParts({
        brand: this.brand,
        brand_code: this.brand,
        mcid: this.mcid,
        mid: data.mid,
        num: data.num,
        subgroup: data.subgroup,
        vin: this.vin,
        name: data.name
      })
    },
    async group3callback(data) {
      this.setBgPnum = data.pnum
      // this.selectedItemIndex = data.pnum
      if (data.skip) {
        const mids = data.mid.split(',')   //replace(',', '')
        const mid= mids[0]
        let gcs = this.getCarsStructureList
        if (this.getCarsStructureSubList.length) {
          gcs = this.getCarsStructureSubList
        }
        const Info = gcs.find(items => items.mid == mid)
       if(!Info){
        alert('해당품번을 찾지못하였습니다.')
        return
       }
        await this.getCarsParts({
          brand: this.brand,
          brand_code: this.brand,
          mcid: this.mcid,
          mid: mid,
          num: this.num,
          subgroup: Info.subgroup,
          vin: this.vin,
          name: this.structureName
        })
      }
    },



    filter() {
      const tmpList = JSON.parse(JSON.stringify(this.getCarsStructureBackupList))
      const tmpListSub = JSON.parse(JSON.stringify(this.getCarsStructureSubBackupList))
      if (this.filterData) {
        this.fitlerbuttontitle = '필터해제';
        this.getCarsStructureList = tmpList.filter((item) => item.colorvalue == 1)
        this.pageData = this.getCarsStructureList
        if (tmpListSub.length) {
          this.getCarsStructureSubList = tmpListSub.filter((item) => item.colorvalue == 1)
          this.pageData = this.getCarsStructureSubList

        }
        this.filterData = false
        this.noSubData = true
      } else {
        this.getCarsStructureList = tmpList
        this.pageData = this.getCarsStructureList
        if (tmpListSub.length) {
          this.getCarsStructureSubList = tmpListSub
          this.pageData = this.getCarsStructureSubList
        }
        this.filterData = true;
        this.noSubData = false
        this.fitlerbuttontitle = '필터적용';
      }
    },

    async getCarsVin(brand, vin) {
      this.loading = Loading.service({ fullscreen: true })
      this.getCarsReplaceList = [] // 검색된 호환품번 초기화
      this.getCarsVinList = {}
      const baseURL = `${this.$serverurl}/api/getCarsVin`
      try {
        await this.$http.post(baseURL, {
          vin, brand, lang: 'kr'
        }).then(response => {
          const result = JSON.parse(JSON.stringify(response))
          const res = result.data
          if (res) {
            if (!this.$loginUserInfo.remaDays || this.$loginUserInfo.remaDays < 0) {
              if (vin != 'RLMUG6GX0KV002437' && vin != 'WBA13BK09PCM70327' && vin != 'WUABWGFF8LA906037') {
                this.showdemoalert(true)
                return
              }
            }
            if (res.statusCode == 'OK') {
              this.getCarsVinList = res.body
              this.hotNameList = res.body.default_hot_name
              this.carInfoImg = res.body.imglogo
              this.brand = res.body.brand_code
              this.mcid = res.body.mcid
              this.getCarsGroup(this.brand, res.body.mcid)
            } else {
              this.dataValid = false
              this.vin = null
              this.getCarsVinList = []
              this.getCarsGroupList = []
              alert(res.body.msg)
            }
            this.$overay(this.loading)
          } else {
            alert('정보가 없습니다.')
          }
        })

      } catch (error) {
        this.$overay(this.loading)
        console.log(error)
      }
      this.$overay(this.loading)
    },
    async getCarsGroup(brandCode, mcid) {
      this.getCarsGroupList = []
      const baseURL = `${this.$serverurl}/api/getCarsGroup`
      const params = {
        vin: this.vin,
        brand: brandCode,
        mcid: mcid,
      }
      try {
        const response = await this.$http.post(baseURL, params)
        const result = JSON.parse(JSON.stringify(response))
        const res = result.data
        if (res) {
          if (res.code == 200) {
            this.getCarsGorup = res
            for (var item of res.body.data) {
              if (item.num != '') {
                this.getCarsGroupList.push(item)
              }
            }
            this.groupcategory = 'groupcategory'
            await this.getCarsConfig(this.vin, this.brand)
          } else {
            alert(res.body.msg)
          }
        } else {
          alert('정보가 없습니다.')
        }
      } catch (error) {
        console.log(error)
      }
    },
    async getCarsConfig(vin_number, brand_code) { // car info
      await this.$http.post(`${this.$serverurl}/api/getCarsConfig`, { vin_number, brand_code })
        .then(res => {
          this.getCarsConfigList = res.data?.body?.data?.main_data || null
        })
    },
    async getCarsStructure(params) {
      this.loading = Loading.service({ fullscreen: true })
      this.getCarsStructureList = []
      this.getCarsStructureBackupList = []
      // this.getCarsStructureSubList = []
      // this.getCarsStructureSubBackupList = []
      this.getCarsPartsList = {}
      this.getCarsTagsList = []
      this.group1title = `${params.label} (${params.num})`
      this.activeNames = 'group1'
      this.mcid = params.mcid
      this.group1 = {
        brandCode: this.brand,
        mcid: this.mcid,
        num: params.num,
        label: params.label || null
      };
      const baseURL = `${this.$serverurl}/api/getCarsStructure`
      const data = {
        brand: this.brand,
        mcid: this.mcid, // encodeURIComponent(this.mcid),
        num: params.num,
        vin: this.vin,
        //서브일때 추가
        level: params.level,
        maingroup: params.maingroup,
        subgroup: params.subgroup
      }
      try {
        const response = await this.$http.post(baseURL, data);
        const result = JSON.parse(JSON.stringify(response))
        const res = result.data
        if (res) {
          if (res.code == '200') {
            this.getCarsStructureBackupList = JSON.parse(JSON.stringify(res.body.data))
            this.getCarsStructureList = this.getCarsStructureBackupList.filter((item) => item.colorvalue == 1)
            this.pageData = this.getCarsStructureList
            this.filterData = true
            this.filter()

          } else {
            if (res.body.code == 2) {
              alert(res.body.msg)
            } else {
              alert(res.message)
            }
          }
          this.$overay(this.loading)
        } else {
          alert('정보가 없습니다.')
        }
      } catch (error) {
        console.error(error)
        this.$overay(this.loading)
      }
      this.$overay(this.loading)
    },


    // 세부정보페이징
    async pageCallback(data) {
      await this.getCarsParts({
        brand: data.brand_code,
        brand_code: data.brand_code,
        mcid: data.mcid,
        mid: data.mid,
        num: data.num,
        subgroup: data.subgroup,
        vin: this.vin,
        name: data.name
      })
      this.selectedItemIndex = ''
    },


    async getCarsStructureSub(params) {
      this.loading = Loading.service({ fullscreen: true })
      this.group2title = `${params.name} (${params.subgroup})`
      const baseURL = `${this.$serverurl}/api/getCarsStructure`
      const data = {
        brand: this.brand,
        mcid: this.mcid, // encodeURIComponent(this.mcid),
        num: params.num,
        vin: this.vin,
        //서브일때 추가
        level: params.level,
        maingroup: params.maingroup,
        subgroup: params.subgroup,
      }
      try {
        const response = await this.$http.post(baseURL, data);
        const result = JSON.parse(JSON.stringify(response))
        const res = result.data
        if (res) {
          if (res.code == '200') {
            this.getCarsStructureSubBackupList = JSON.parse(JSON.stringify(res.body.data))
            this.getCarsStructureSubList = this.getCarsStructureSubBackupList.filter((item) => item.colorvalue == 1)
            this.pageData = this.getCarsStructureSubList
            this.filterData = true
            this.filter()
            if (!this.getCarsStructureSubList.length) { this.noSubData = true } else {
              this.noSubData = false
            }
            this.$overay(this.loading)
          } else {
            alert(res.msg)
          }
        } else {
          alert('정보가 없습니다.')
        }
      } catch (error) {
        this.$overay(this.loading)
        console.error(error)
      }
      this.$overay(this.loading)
    },

    async getCarsParts(params) {
      this.currentMid = params.mid
      this.loading = Loading.service({ fullscreen: true })
      this.getCarsPartsList = {} // 검색된 호환품번 초기화
      const baseURL = `${this.$serverurl}/api/getCarsParts`
      const data = {
        brand: params.brand,
        brand_code: params.brand_code,
        mcid: encodeURIComponent(params.mcid),
        mid: params.mid,
        num: params.num,
        subgroup: params.subgroup,
        vin: params.vin,
      }
      try {
        const response = await this.$http.post(baseURL, data);
        const result = JSON.parse(JSON.stringify(response))
        const res = result.data
        if (res) {
          if (res.code == '200') {
            this.getCarsPartsBackupList = JSON.parse(JSON.stringify(res.body))
            this.getCarsPartsList = JSON.parse(JSON.stringify(res.body))
            this.getCarsPartsList.hotNameInfo = this.hotNameInfo
            this.img_path = this.getCarsPartsList.img_path
            // 서브항목 배열
            const tmpPartDetail = JSON.parse(JSON.stringify(this.getCarsPartsList.part_detail))
            this.getCarsPartsList.part_detail = []
            for (var item of tmpPartDetail) {
              if (item.length > 1) {
                for (var sItem of item) {
                  this.getCarsPartsList.part_detail.push([sItem])
                }
              } else if (item.length == 1) {
                this.getCarsPartsList.part_detail.push(item)
              }
            }
            // pid_list작성
            const uniquePids = new Set()
            const uniquePartDetail = [];
            for (const itemArray of this.getCarsPartsList.part_detail) {
              const item = itemArray[0];
              if (item && item.pid && !uniquePids.has(item.pid)) {
                uniquePids.add(item.pid);
                uniquePartDetail.push(itemArray[0].pid);
              }
            }

            // 페이지 설정
            this.page = this.getCarsStructureList.findIndex((item, index) => {
              return item.mid == params.mid
            }) + 1


            // 핫네임 다이렉트 검색 > 콜백
            if (params?.hotNameInfo?.query) {
              const parts_detail = this.getCarsPartsList.part_detail
              const returnItem = parts_detail.find(item => item[0].pid == params?.hotNameInfo?.query)
              this.returnCallback(returnItem)
            }
            const pid_list = uniquePartDetail.join(',')
            this.pid_list = pid_list
            await this.getCarsTags({ brand_code: params.brand_code, pid_list: pid_list }).then(() => {
              // if (Object.keys(this.getCarsTagsList).length) {
              //   this.group3title = `${params.name} (${params.mid})`
              //   this.activeNames = 'group3'
              //   this.leftName = 'partsImage'
              // } else {
              //   this.getCarsPartsList = {}
              // }
              if (Object.keys(this.getCarsPartsList).length) {
                this.group3title = `${params.name} (${params.mid})`
                this.activeNames = 'group3'
                this.leftName = 'partsImage'
              } else {
                this.getCarsPartsList = {}
              }
            })
          } else {
              alert(res.msg || res.message)
          }
        } else {
          alert('정보가 없습니다.')
        }
      } catch (error) {
        this.$overay(this.loading)
        console.error(error)
      }
      this.$overay(this.loading)
    },
    async getCarsTags(params) {
      this.getCarsTagsList = [] // 검색된 호환품번 초기화
      const baseURL = `${this.$serverurl}/api/getCarsTags`
      const data = {
        brand_code: params.brand_code,
        pid_list: params.pid_list,
      }
      try {
        const response = await this.$http.post(baseURL, data);
        const result = JSON.parse(JSON.stringify(response))
        const res = result.data
        if (res) {
          if (res.code == '200') {
            this.getCarsTagsBackupList = JSON.parse(JSON.stringify(res.body.data))
            this.getCarsTagsList = JSON.parse(JSON.stringify(res.body.data))
          } else {
            // alert(res.msg || res.message)
            this.getCarsTagsBackupList=[]
            this.getCarsTagsList=[]
          }
        }
      } catch (error) {
        console.error(error)
      }
    },

    async getCarsPartBySearchKey(params) {
      this.showhotNameList = false
      this.hotName = params
      this.loading = Loading.service({ fullscreen: true })
      this.getCarsPartBySearchKeyList = []
      const baseURL = `${this.$serverurl}/api/getCarsPartBySearchKey`
      const data = {
        vin: this.vin,
        query: params,
      }
      this.hotName = params
      this.hotNameInfo = data
      const response = await this.$http.post(baseURL, data);
      const result = JSON.parse(JSON.stringify(response))
      const res = result.data
      if (res.statusCode == 'OK') {
        const result = res
        if (res.body.code === 1) {
          this.getCarsPartBySearchKeyList = res.body.data
          this.num = result.body.data[0].num
          this.mid = result.body.data[0].mid
          this.mcid = result.body.data[0].mcid
          this.group1label = result.body.data[0].maingroup_label
          this.group2label = result.body.data[0].subgroup_label
          this.subgroup = result.body.data[0].subgroup
          // 부품그룹(메인) 적용
          await this.getCarsStructure({
            brand: this.brand,
            mcid: this.mcid,
            num: this.num,
            vin: this.vin,
          }).then(() => {
            this.group1title = `${this.group1label}(${this.num})`
          })

          // 부품그룹(서브)그룹체크 및 적용
          this.group2title = `${this.group2label}(${this.subgroup})`
          const tmpStructure = this.getCarsStructureList.find(item => item.subgroup == this.subgroup)
          if (tmpStructure?.has_child == 1 && !this.getCarsStructureSubList.length) {
            await this.getCarsStructureSub({
              brand: this.brand,
              mcid: this.mcid,
              num: this.num,
              vin: this.vin,
              level: tmpStructure.level,
              maingroup: tmpStructure.maingroup,
              subgroup: tmpStructure.subgroup,
            }).then((item => {
              this.group2title = `${this.group2label}(${this.subgroup})`
            }))
          }

          // 세부정보적용
          await this.getCarsParts(
            {
              vin: this.vin,
              brand: this.brand, //result.body.data[0].brandCode,
              brand_code: this.brand, // result.body.data[0].brandCode,
              mcid: this.mcid, // result.body.data[0].mcid,
              num: this.num, // result.body.data[0].num,
              mid: this.mid, // result.body.data[0].mid,
              subgroup: result.body.data[0].subgroup,
              name: result.body.data[0].name
            }
          )
          this.activeNames = 'group3'
          this.leftName = 'partsImage'
          this.$overay(this.loading)
        } else {
          this.$overay(this.loading)
          if (res.body.message) {
            alert(res.body.message)
          } else {
            alert(res.body.msg)
          }
          console.error('Request failed with status:', res.message);
        }
      } else {
        this.$overay(this.loading)
        alert(res.message)
      }
    },

    async selectHotName(params) {
      params = this.$removeSpace(params)
      if (!params) {
        alert('검색어를 입력하세요.')
        return
      }
      this.getCarsPartBySearchKey(params)
      const intervalID = setInterval(() => {
        if (this.getCarsPartsList.length && this.getCarsPartBySearchKeyList.length > 0 && this.getCarsPartsList) {
          const point = this.getCarsPartsList?.img_points.find((item) => item.hsKey == this.getCarsPartBySearchKeyList[0].pnum)
          this.click_hover(point.hsKey)
          this.moveToRow(point.hsKey)
          clearInterval(intervalID);
        }
      }, 1000)
    },

    closeHotNameList() {
      setTimeout(() => {
        this.showhotNameList = false
      }, 500);
    },
    showHoNameListSwitch() {
      if (this.showhotNameList) {
        setTimeout(() => {
          this.showhotNameList = false
        }, 500);
      } else {
        this.showhotNameList = true
      }

    },

    // 부품그룹 tabs 이벤트
    async handleClick(tab, event) {
      switch (tab.paneName) {
        case 'group2': {
          const tmpStructure = this.getCarsStructureList.find(item => item.subgroup == this.subgroup)
          if (tmpStructure?.has_child == 1 && !this.getCarsStructureSubList.length) {
            await this.getCarsStructureSub({
              brand: this.brand,
              mcid: this.mcid,
              num: this.num,
              vin: this.vin,
              level: tmpStructure.level,
              maingroup: tmpStructure.maingroup,
              subgroup: tmpStructure.subgroup,
            }).then((item => {
              this.group2title = `${this.group2label}(${this.subgroup})`
            }))
          }
          break;
        }
      }
    },

    getCatetoryBgImg(params) {
      if (!params) {
        return 'https://api.008parts.co.kr/upload/no-image.webp'
      }
      return `
            background:url(${params}) center top 10px/60% no-repeat;`
    },

    handleClose(done) {
      this.largeIamge = false
      // done()
    },

  }

}
</script>

<style scoped lang="scss">
.title {
  font-weight: bold;
}

.drag-box {

  padding: 10px;
  /* position: absolute; */
  top: 100px;
  left: 100px;
  width: 100%;
  min-height: 100px;
  /* max-height: 100%; */
  height: calc(100vh - 21vh);
  background: #fff;
  border-radius: 5px;
  /* box-shadow: 0 4px 12px rgba(0, 0, 0, .15); */
  z-index: 0;
}

.hotname-box {
  padding: 10px;
  /* position: absolute; */
  top: 8px;
  left: 475px;
  min-height: 45px;
  max-height: 750px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, .15);
  z-index: 99;
}

.baseinfo-box {
  padding: 10px;
  position: static;
  top: 8px;
  left: 475px;

  min-height: 45px;
  max-height: 750px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, .15);
  z-index: 99;
}

.detail-box {
  position: relative;
  min-height: 633px !important;
  padding: 10px;
  top: 8px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, .15);
  // z-index: 9999;
}

.image-map-container {
  width: 100%;
  height: auto;
  overflow: hidden;
  transition: transform 0.2s ease-in-out;
}

.part-detail-image {
  max-width: 100%;
  transition: transform 0.2s ease-in-out;
}

.map-opt {
  position: absolute;
  top: 0px;
  right: 10px;
  width: 26px;
}

.map-opt div {
  display: inline-block;
  font-size: 10px;
  width: 26px;
  height: 26px;
  line-height: 26px;
  color: rgba(0, 155, 103, 1);
  text-align: center;
  vertical-align: middle;
  -webkit-box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, .4);
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, .4);
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 1);
  cursor: pointer;
  margin-bottom: 5px !important;
}

.area {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  border-radius: 2px;
}

.area:hover {
  background-color: rgba(0, 110, 255, 0.3);
}

.areamove {
  background-color: rgba(200, 255, 0, 0.5);
}

.bg-red {
  background-color: rgba(255, 0, 85, 0.2) !important;
}

.select-red {
  background: rgba(255, 0, 50, 0.3) !important;
}

.fixedSub {
  /* flex: 0 0 41.666667%;
  position: fixed !important;
  top: 50px;
  left: 16.7vw;
  left: calc(50% - 400px);
  transform: translateX(-50%);
  min-width: 32.533vw;
  overflow: hidden;
  padding-right: 15px;
  padding-left: 15px;
  transition: top 0.3s ease-in-out;
  height: calc(100vh - 60px) !important; */
  flex: 0 0 41.666667%;
  position: fixed !important;
  top: 100px;
  left: 2.2vw;
  max-width: calc(32.166667%);
  padding-right: 15px;
  padding-left: 15px;
  /* transition: transform 0.3s ease-in-out;*/
  transition: top 0.3s ease-in-out;
}

.fixedBottom {
  top: 0px;
  bottom: 300px !important;
  transition: top 0.3s ease-in-out;

}
</style>
