<template>
    <div>
        <div class="drag-box" v-if="isShowDrag">
            <div class="mb-2 move" v-drag>
                <span class="font18 bold">EPC부품문의</span>
            </div>
            <table class="table-params w100p">
                <tbody>
                    <tr>
                        <th style="width:100px;">차대번호</th>
                        <td>{{ qnaViewData.contentSub.vin }}</td>
                    </tr>
                    <tr>
                        <th style="width:100px;">모델명</th>
                        <td>{{ qnaViewData.contentSub.modelName }}</td>
                    </tr>
                    <tr>
                        <th style="width:100px;">제품명칭</th>
                        <td>{{ qnaViewData.contentSub.name }}</td>
                    </tr>
                    <tr>
                        <th style="width:100px;">정품품번</th>
                        <td>{{ qnaViewData.contentSub.pid }}</td>
                    </tr>
                    <tr>
                        <th style="width:100px;">장착수량</th>
                        <td>{{ $convert_br(qnaViewData.contentSub.quantity) }}</td>
                    </tr>
                </tbody>
            </table>
            <table class="table-params w100p mt-2">
                <tbody>
                    <tr>
                        <th style="width:100px;">문의제목</th>
                        <td>
                            {{ qnaViewData.title }}
                        </td>
                    </tr>
                    <tr v-if="!qnaViewData.reply || qnaViewData.creatorMemberId==loginUserInfo.seq">
                        <th style="width:100px;">문의내용</th>
                        <td>
                            {{ qnaViewData.content }}
                        </td>
                    </tr>
                    <tr v-if="qnaViewData.reply">
                        <th style="width:100px;">판매자답글</th>
                        <td>
                            {{ $convert_br(qnaViewData.reply) }}
                        </td>
                    </tr>
                </tbody>
            </table>
    
            <el-row :gutter="10" class="mt-3 mb-3">
                <el-col :xl="24" :md="24" class="text-right">
                    <el-button type="info" size="mini" icon="el-icon-error" @click="closeMe()">
                        창닫기
                    </el-button>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
export default {
  name: 'epcQna',
  data() {
    return {
      isShowDrag: false,
      loginUserInfo: null,
    }
  },
  props: ['switch', 'qnaViewData'],
  watch: {
    switch: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        this.isShowDrag = newValue
      }
    },
    qnaViewData: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        // this.isShowDrag = newValue
      }
    }
  },
  async created() {
    this.loginUserInfo = JSON.parse(localStorage.getItem('loginUserInfo'))
    this.$http.defaults.headers.common['exjwtauthorization'] = this.$loginUserInfo?.token
  },
  methods: {
    closeMe() {
      this.$emit('callback', false)
    },

  },
  directives: {
    drag: { // 타이틀 드래그 > 부모div드래그
      bind: function (el) {
        let offsetX, offsetY;
        el.addEventListener('mousedown', onMouseDown);
        function onMouseDown(event) {
          const parent = el.parentElement;
          offsetX = event.clientX - parent.offsetLeft;
          offsetY = event.clientY - parent.offsetTop;
          document.addEventListener('mousemove', onMouseMove);
          document.addEventListener('mouseup', onMouseUp);
        }

        function onMouseMove(event) {
          const parent = el.parentElement;
          parent.style.left = (event.clientX - offsetX) + 'px';
          parent.style.top = (event.clientY - offsetY) + 'px';
        }
        function onMouseUp() {
          document.removeEventListener('mousemove', onMouseMove);
          document.removeEventListener('mouseup', onMouseUp);
        }
      }
    }
  }
}
</script>

<style scoped>
.titleDiv {
    padding: 5px;
    margin-bottom: 10px;
}

.title {
    font-weight: bold;
}

.drag-box {
    padding: 10px;
    position: fixed;
    top: 200px;
    /*창이 가운데 오게함 */
    left: 50%;
    /*창이 가운데 오게함 */
    transform: translateX(-50%);
    /* transform: translateY(-50%); */
    width: 450px;
    min-height: 100px;
    max-height: 750px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, .15);
    z-index: 99;
}
</style>