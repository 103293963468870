<template>
    <div>
        <div class="drag-box" v-if="isShowDrag">
            <div class="mb-2 move" v-drag>
                <span class="font18 bold">EPC부품문의</span>
            </div>
            <table class="table-params w100p">
                <tbody>
                    <tr>
                        <th style="width:100px;">차대번호</th>
                        <td>{{ boardContent.contentSub.vin }}</td>
                    </tr>
                    <tr>
                        <th style="width:100px;">모델명</th>
                        <td>{{ boardContent.contentSub.modelName }}</td>
                    </tr>
                    <tr>
                        <th style="width:100px;">제품명칭</th>
                        <td>{{ boardContent.contentSub.name }}</td>
                    </tr>
                    <tr>
                        <th style="width:100px;">정품품번</th>
                        <td>{{ boardContent.contentSub.pid }}</td>
                    </tr>
                    <tr>
                        <th style="width:100px;">장착수량</th>
                        <td>{{ $convert_br(boardContent.contentSub.quantity) }}</td>
                    </tr>
                </tbody>
            </table>
            <table class="table-params w100p mt-2">
                <tbody>
                    <tr>
                        <th style="width:100px;">문의제목</th>
                        <td>
                            <el-input type="text" size="mini" v-model="boardContent.title"
                                placeholder="제목을 입력하세요." show-word-limit maxlength="30">
                            </el-input>
                        </td>
                    </tr>
                    <tr>
                        <th style="width:100px;">문의내용</th>
                        <td>
                            <el-input type="textarea" v-model="boardContent.content" placeholder="궁금하신 사항을 입력하세요."
                                show-word-limit maxlength="100">
                            </el-input>
                        </td>
                    </tr>
                </tbody>
            </table>
            <el-row :gutter="10" class="mt-3 mb-3">
                <el-col :xl="24" :md="24" class="text-right">
                    <el-button type="info" size="mini" icon="el-icon-error" @click="closeMe()">
                        창닫기
                    </el-button>
                    <el-button size="mini" class="el-btn-orange" icon="el-icon-success" @click="setEpcQnaInfo()">
                        정보제출
                    </el-button>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
export default {
    name: 'epcQna',
    data() {
        return {
            isShowDrag: false,
        }
    },
    props: ['switch', 'boardContent'],
    watch: {
        switch: {
            deep: true,
            immediate: true,
            async handler(newValue, oldValue) {
                this.isShowDrag = newValue
            }
        },
        boardContent: {
            deep: true,
            immediate: true,
            async handler(newValue, oldValue) {
                // this.isShowDrag = newValue
            }
        }
    },
    async created() {
        // this.$loginUserInfo = JSON.parse(localStorage.getItem('loginUserInfo'))
        this.$http.defaults.headers.common['exjwtauthorization'] = this.$loginUserInfo?.token
    },
    methods: {
        closeMe() {
            this.$emit('callback', false)
        },
        async setEpcQnaInfo() {
            if (this.boardContent.title.trim().length < 5) {
                alert('5자이상의 문의글을 입력하세요.')
                return
            }
            if (this.boardContent.content.trim().length < 10) {
                alert('10자이상의 문의글을 입력하세요.')
                return
            }
            await this.$http.post(`${this.$serverurl}/api/setEpcQnaInfo`, this.boardContent)
                .then(res => {
                    if (res.data.code == 201) {
                        this.$notify.success({
                            title: '',
                            message: res.data.message,
                            position: 'bottom-right',
                            showClose: false
                        });
                        this.boardContent.title = ''
                        this.boardContent.content = ''
                        this.closeMe()
                    } else {
                        alert(res.data.message)
                    }
                })
        },
    },
    directives: {
        drag: { // 타이틀 드래그 > 부모div드래그
            bind: function (el) {
                let offsetX, offsetY;
                el.addEventListener('mousedown', onMouseDown);
                function onMouseDown(event) {
                    const parent = el.parentElement;
                    offsetX = event.clientX - parent.offsetLeft;
                    offsetY = event.clientY - parent.offsetTop;
                    document.addEventListener('mousemove', onMouseMove);
                    document.addEventListener('mouseup', onMouseUp);
                }

                function onMouseMove(event) {
                    const parent = el.parentElement;
                    parent.style.left = (event.clientX - offsetX) + 'px';
                    parent.style.top = (event.clientY - offsetY) + 'px';
                }
                function onMouseUp() {
                    document.removeEventListener('mousemove', onMouseMove);
                    document.removeEventListener('mouseup', onMouseUp);
                }
            }
        }
    }
}
</script>

<style scoped>
.titleDiv {
    padding: 5px;
    margin-bottom: 10px;
}

.title {
    font-weight: bold;
}

.drag-box {
    padding: 10px;
    position: fixed;
    top: 200px;
    /*창이 가운데 오게함 */
    left: 50%;
    /*창이 가운데 오게함 */
    transform: translateX(-50%);
    /* transform: translateY(-50%); */
    width: 450px;
    min-height: 100px;
    max-height: 750px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, .15);
    z-index: 99;
}
</style>