<template>
  <div>
    <div class="drag-box">
      <el-row :gutter="10">
        <el-col :xl="8" :xs="8" :md="8">
          <el-select size="mini" v-model="brand" class="w100p" @change="setchange">
            <el-option v-for="item in brandlist" :key="item.value" :value="item.value" :label="item.label">
            </el-option>
          </el-select>
        </el-col>
        <el-col :xl="16" :xs="16" :md="16" class="relative">
          <div v-if="validRemaDays">
            <el-input size="mini" v-model="vin" :placeholder="placeholder"
              @click.native="showlist = showlist ? false : true; read()" @keyup.enter.native="selectVin(vin)"
              @blur="closeShowlist()">
            </el-input>
          </div>
          <div v-else>
          <!-- 무료 테스사용 -->
            <el-select size="mini" v-model="vin" class="w100p" @change="selectVin(vin)" 
            filterable
            allow-create
            default-first-option
            :placeholder=placeholder>
              <el-option v-for="item in testVinlist" :key="item.label" :value="item.label" :label="item.label">
                <div class="relative">
                  <span>{{ item.label }}</span>
                  <span class="absolute" style="right:0px;">{{ item.brand }}</span>
                </div>
              </el-option>
            </el-select>
          </div>
          <span class="badge badge-info absolute hand" style="right:10px;top:7px" @click="selectVin(vin)">enter</span>

        </el-col>
      </el-row>
      <div v-if="showlist && validRemaDays" class="absolute bigbadge-yellow borderbox p-2"
        style="left:160px;top:55px;width:300px;height:300px;overflow:hidden; overflow-y: auto; z-index: 10;">
        <div v-for="item in tmpTabledata" :key="item.seq" class="p-1 bigbadge-hover"
          @click="selectVin(item.vin); showlist = false;">
          <span>{{ item.vin }}</span>
          <span class="absolute ellip" style="right:15px;max-width: 100px;">
            {{ item.title }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import componentInputText from './component-input-text.vue'
import carinfofilterbar from './component-epc-car-info-filter-bar.vue'
export default {
  name: 'searchvinbox',
  components: { componentInputText, carinfofilterbar },
  data() {
    return {
      testVinlist: [
        { label: 'RLMUG6GX0KV002437', brand: 'benz' },
        { label: 'WBA13BK09PCM70327', brand: 'bmw' },
        { label: 'WUABWGFF8LA906037', brand: 'audi' },
      ],
      placeholder: '차대번호 17자리를 입력하세요.',
      //필터구역
      hotName: '', // null,
      hotNameList: [],
      filterData: true,
      fitlerbuttontitle: '필터해제',
      fileterButtonShow: false,
      showhotNameList: false,


      validRemaDays: false,
      returnData: {},
      num: null,
      active: 'activeline',
      getCarsGroupList: [],
      getCarsVinList: null,
      getCarsGorup: null,
      cate1: null,
      cate2: null,
      brand: 'all',
      brandCode: '',
      vin: '', //SAJAA89B58SH21133,
      carInfoImg: null,
      mcid: null,
      getCarsConfigList: null,
      title: '차대번호 검색',
      brandlist: [
        { value: 'all', label: '모든브랜드' },
        { value: 'bmw', label: 'BMW' },
        { value: 'benz', label: 'BENZ' },
        { value: 'jaguar', label: 'JAGUAR' },
        { value: 'land_rover', label: 'LAND ROVER' },
        { value: 'maserati', label: 'MASERATI' },
      ],
      showlist: false,
      tmpTabledata: [],
      req: {
        objSearchMapper: {
          searchKeyword: null,
        },
        page: 1,
        size: 20
      },
      reqGET: {
        brand_code: 'all'
      }
    }
  },
  props: ['dataValid'],
  watch: {
    dataValid: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        if (!newValue) {
          this.vin = null
        }
      }
    }
  },
  props: ['PropsValid'],
  watch: {
    PropsValid: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
          this.validRemaDays = newValue
      }
    }
  },
  async created() {
    this.brand = 'all'
  },
  mounted() {
  },
  methods: {
    setchange(data) {
      switch (data) {
        case 'all': {
          if (this.vin.length != 17) {
            this.placeholder = ('차대번호 17자리를 입력하세요.')
            return
          }
          break;
        }
        case 'bmw': {
          if (this.vin.length !== 7 && this.vin.length !== 17) {
            this.placeholder = ('차대번호 뒤7자리 혹은 17자리를 입력하세요.');
            return
          }
          break;
        }
        case 'benz': {
          if (this.vin.length !== 8 && this.vin.length !== 17) {
            this.placeholder = ('차대번호 뒤8자리 혹은 17자리를 입력하세요.');
            return
          }
          break;
        }
        case 'jaguar': {
          if (this.vin.length !== 7 && this.vin.length !== 17) {
            this.placeholder = ('차대번호 뒤7자리 혹은 17자리를 입력하세요.');
            return
          }
          break;
        }
        case 'land_rover': {
          if (this.vin.length !== 8 && this.vin.length !== 17) {
            this.placeholder = ('차대번호 뒤8자리 혹은 17자리를 입력하세요.');
            return
          }
          break;
        }
        case 'maserati': {
          if (this.vin.length !== 7 && this.vin.length !== 17) {
            this.placeholder = (this.brand + '차대번호 뒤7자리 혹은 17자리를 입력하세요.');
            return
          }
          break;
        }
      }
    },
    closeShowlist() {
      setTimeout(() => {
        this.showlist = false
      }, 500);
    },
    returnCallback(params) {
      const data = {
        brand: this.brand,
        vin: this.vin,
      }
      this.$emit('callback', data)
    },

    async selectVin(params) {
      if (!params) {
        alert('차대번호를 입력하세요.')
        return
      }
      this.vin = this.$removeSpace(params)
      if (this.vin) {
        switch (this.brand) {
          case 'all': {
            if (this.vin.length != 17) {
              alert('차대번호 17자리를 입력하세요.')
              return
            }
            break;
          }
          case 'bmw': {
            if (this.vin.length !== 7 && this.vin.length !== 17) {
              alert('(BMW)차대번호 7자리 혹은 차대번호 17자리를 입력하세요.');
              return
            }
            break;
          }
          case 'benz': {
            if (this.vin.length !== 8 && this.vin.length !== 17) {
              alert('(BENZ)차대번호 8자리 혹은 차대번호 17자리를 입력하세요.');
              return
            }
            break;
          }
          case 'jaguar': {
            if (this.vin.length !== 7 && this.vin.length !== 17) {
              alert('(JAGUAR)차대번호 7자리 혹은 차대번호 17자리를 입력하세요.');
              return
            }
            break;
          }
          case 'land_rover': {
            if (this.vin.length !== 8 && this.vin.length !== 17) {
              alert('(LAND ROVER)차대번호 8자리 혹은 차대번호 17자리를 입력하세요.');
              return
            }
            break;
          }
          case 'maserati': {
            if (this.vin.length !== 7 && this.vin.length !== 17) {
              alert(this.brand + '(MASERATI)차대번호 7자리 혹은 차대번호 17자리를 입력하세요.');
              return
            }
            break;
          }
        }
      }
      this.showlist = false
      this.returnCallback()
    },

    async read() {
      this.$http.defaults.headers.common['exjwtauthorization'] = this.$loginUserInfo?.token
      await this.$http.post(`${this.$serverurl}/api/getUserSearchHistory`, this.req)
        .then(res => {
          this.tmpTabledata = res.data?.body?.results || []
        })
    },
    async readGET() {
      this.$http.defaults.headers.common['exjwtauthorization'] = this.$loginUserInfo?.token
      const queryString = new URLSearchParams(this.reqGET).toString();
      const url = `${this.$serverurl}/api/getUserSearchHistoryGET?${queryString}`
      await this.$http.get(url)
        .then(res => {
          this.tmpTabledata = res.data?.body?.results || [];
        })
        .catch(err => {
          console.error(err);
        });
    },

  },
}
</script>

<style scoped>
.drag-box {
  /* cursor: move; */
  padding: 10px;
  /* position: absolute; */
  top: 100px;
  left: 100px;
  min-height: 45px;
  max-height: 750px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, .15);
  z-index: 99;
}
</style>