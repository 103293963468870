<template>
    <el-dialog title="계정관리" :fullscreen="$isMobile ? true : false" :visible.sync="visible" width="30%"
        :before-close="handleClose" center v-draggable>
        <div class="mb-2 font15 lh30">
            <el-row class="mb-2">
                <el-col :xl="24" :xs="24" :sm="24" class="mb-2">
                    <div class="borderbox pl-2 w100p" v-if="formData.seq">{{ formData.id }}</div>
                    <div v-else>
                        <el-input placeholder="계정" size="mini" v-model="formData.id">
                        </el-input>
                    </div>
                </el-col>
                <el-col :xl="24" :xs="24" :sm="24" class="mb-2">
                    <div v-if="!formData.seq">
                        <componentInputText :minlength="5" :maxlength="20" :type="'password'" :size="'mini'"
                            :placeholder="'비밀번호'" v-model="formData.pw" />
                    </div>
                </el-col>
                <el-col :xl="24" :xs="24" :sm="24">
                    <el-input placeholder="이름" size="mini" v-model="formData.name">
                    </el-input>
                </el-col>
                <el-col :xl="24" :xs="24" :sm="24">
                    <el-input placeholder="전화번호" size="mini" v-model="formData.tel">
                    </el-input>
                </el-col>
                <el-col :xl="24" :xs="24" :sm="24">
                    <el-input placeholder="휴대폰" size="mini" v-model="formData.phone">
                    </el-input>
                </el-col>
                <el-col :xl="24" :xs="24" :sm="24">
                    <el-input placeholder="메일" size="mini" v-model="formData.mail">
                    </el-input>
                </el-col>
                <el-col :xl="24" :xs="24" :sm="24">
                    <el-input placeholder="허용아이피" size="mini" v-model="formData.availableIp">
                    </el-input>
                </el-col>
                <el-col :xl="24" :xs="24" :sm="24">
                    <el-checkbox class="ml-2" :checked="formData.type == 0" @change="changeType">대표권한</el-checkbox>
                </el-col>
            </el-row>

        </div>
        <div class="text-right">
            <el-button @click="returnMessage(false)" size="mini">창닫기</el-button>
            <el-button @click="returnMessage(true)" size="mini" type="primary">등록</el-button>
        </div>
    </el-dialog>

</template>

<script>
import componentInputText from './component-input-text.vue';
export default {
    name: 'mallmemberinfocreate',
    components: { componentInputText, },
    props: ['data'],
    data() {
        return {
            visible: true,
            tmpTabledata: [],
            formData: {
                pw: '',
                name: '',
                tel: '',
                phone: '',
                mail: '',
                type: 1,
                availableIp: '',
            },
            req: {
                objSearchMapper: {
                    searchKeyword: null,
                },
                page: 1,
                size: 10
            }
        }
    },
    async created() {
        this.$loginUserInfo = JSON.parse(localStorage.getItem('loginUserInfo'))
        if (this.data) {
            this.formData = this.data
        }
    },
    methods: {
        changeType(data) {
            if (data) {
                this.formData.type = 0
            } else {
                this.formData.type = 1
            }
        },
        async returnMessage(params) {
            if (params) {
                if (!this.formData.name.trim()) { alert('이름을 입력하세요.'); return }
                if (!this.formData.phone.trim()) { alert('휴대폰번호를 입력하세요.'); return }
                if (!this.formData.seq && !this.formData.pw.trim()) { alert('비밀번호를 입력하세요.'); return }
                await this.$http.post(`${this.$serverurl}/api/setMallMemberInfo`, this.formData)
                    .then(res => {
                        if (res.data.code == 201) {
                            this.formData = {
                                name: '',
                                tel: '',
                                phone: '',
                                mail: '',
                                availableIp: '',
                                type: 1,
                            }
                            this.$loginUserInfo.type =res.data.body.results.type
                            localStorage.setItem('loginUserInfo',JSON.stringify(this.$loginUserInfo))
                            this.$notify.success({
                                title: '',
                                message: res.data.message,
                                position: 'bottom-right',
                                showClose: false
                            });
                            this.$emit('callback', false)
                        } else {
                            alert(res.data.message)
                        }
                    })
            } else {
                this.$emit('callback', false)
            }
        },
        handleClose(done) {
            // this.$emit('callback', false)
            // done()
        },

    }
}
</script>

<style scoped></style>