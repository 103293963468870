<template>
  <el-dialog title="입출금세부정보" :fullscreen="$isMobile ? true : false" :visible.sync="visible" width="50%"
    :before-close="handleClose" center v-draggable>
    <!-- 거래정보 -->
    <div class="mb-2 font13" v-if="!data.paymentInfo && data.mallOrderInfo">
      <table class="table-params w100p">
        <tbody>
          <tr>
            <th style="width:100px;">거래구분</th>
            <td>
              <span>{{$accountBookType.find(item=>item.value== data.mark).label}}</span>
            </td>
          </tr>
          <tr>
            <th>거래금액</th>
            <td>
              <span v-if="data.pointMinus">- {{ $comma(data.pointMinus) }}</span>
              <span v-else>+ {{ $comma(data.pointPlus) }}</span>
            </td>
          </tr>
          <tr>
            <th>잔액</th>
            <td>
              <span>+ {{ $comma(data.pointResidual) }}</span>
            </td>
          </tr>
          <tr>
            <th>고객메모</th>
            <td>
              <el-input v-if="!data.memo" type="textarea" show-word-limit minlength="10" maxlength="200"
                :autosize="{ minRows: 2, maxRows: 4 }" placeholder="메모를 작성하세요." v-model="formData.memo">
              </el-input>
              <div v-else>
                {{ formData.memo }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <table class="table-params w100p mt-2" v-if="data.mallOrderInfo">
        <tbody>
          <tr>
            <th>상품정보</th>
            <th style="width:60px;" class="text-right">수량</th>
            <th style="width:80px;" class="text-right">금액</th>
            <th style="width:80px;" class="text-right">소계</th>
          </tr>
          <tr v-for="item in data.mallOrderInfo.mallOrderProductInfo" :key="item.seq">
            <td>
              {{ item.salesProductInfo.name }}
              {{ item.salesProductInfo.pid }}
            </td>
            <td class="text-right">
              {{ $comma(item.qty) }}
            </td>
            <td class="text-right">
              - {{ $totalInTax(item.price,1,true) }}
            </td>
            <td class="text-right">
              - {{ $totalInTax(item.qty*item.price,1,true) }}
            </td>
          </tr>
        </tbody>
      </table>
      <table class="table-params w100p mt-2">
        <tbody>
          <tr
            v-if="!data.mallOrderInfo.oldOrderInfo || data.mallOrderInfo.oldOrderInfo&&data.mallOrderInfo.oldOrderInfo.status!=2">
            <th style="width:100px;">배송비</th>
            <td>- {{ $totalInTax(data.mallOrderInfo.shipping,1,true) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- 포인트구매정보 -->
    <div class="mb-2 font13" v-if="data.paymentInfo">
      <table class="table-params w100p mt-2">
        <tbody>
          <tr>
            <th width="100">거래일자</th>
            <td>{{ data.paymentInfo.transaction_date }}</td>
          </tr>
          <tr>
            <th width="100">처리</th>
            <td>{{ data.paymentInfo.user_name }}({{ $toStar(data.paymentInfo.user_id,3) }})</td>
          </tr>
          <tr>
            <th width="100">연락처</th>
            <td>{{ data.paymentInfo.custom_parameter }}</td>
          </tr>
          <tr>
            <th width="100">거래구분</th>
            <td>{{ $accountBookType.find(item=>item.value== data.mark).label }}</td>
          </tr>
          <tr>
            <th width="100">거래번호</th>
            <!-- 자체생성번호-카드사승인번호-카드사일련번호 -->
            <td>{{data.paymentSn }} - {{data.paymentInfo.cid }} - {{data.paymentInfo.tid.split('-')[1] }}</td>
          </tr>
          <tr>
            <th width="100">구매캐시</th>
            <td>{{ $comma(data.paymentInfo.amount) }}
              <span class="badge badge-red badge-small ">C</span>
            </td>
          </tr>
          <tr>
            <th width="100">잔액</th>
            <td>{{ $comma(data.pointResidual) }}
              <span class="badge badge-red badge-small ">C</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="text-right">
      <el-button v-if="!data.memo && formData.memo.length" type="primary" @click="updateMallOrderInfo()"
        size="mini">정보갱신</el-button>
      <el-button @click="handleClose(false)" size="mini">창닫기</el-button>
    </div>
    <!-- <json-viewer :value="data" :expand-depth=1 copyable boxed sort class="mt-3"></json-viewer> -->
  
  </el-dialog>
</template>

<script>
export default {
  name: 'accountBookView',
  props: ['data'],
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        this.formData.memo = newValue.memo || ''
      }
    }
  },
  data() {
    return {
      visible: true,
      tmpTabledata: [],
      formData: {
        memo: '',
      },
      req: {
        objSearchMapper: {
          searchKeyword: null,
        },
        page: 1,
        size: 10
      }
    }
  },
  async created() {
    // this.formData.memo = this.data.memo
  },
  methods: {
    changeBasic(data) {
      if (data) {
        this.formData.basic = 0
      } else {
        this.formData.basic = 1
      }
    },
    async updateMallOrderInfo(params) {
      this.formData.seq = this.data.seq
      await this.$http.post(`${this.$serverurl}/api/updateMallPointAccountMemo`, this.formData)
        .then(res => {
          if (res.data.code == 201) {
            this.$notify.success({
              title: '',
              message: res.data.message,
              position: 'bottom-right',
              showClose: false
            });
            this.$emit('callback', false)
          } else {
            alert(res.data.message)
          }
        })
    },
    handleClose(done) {
      this.$emit('callback', false)
      // done()
    },

    loadDaumPostcodeScript() {
      const script = document.createElement("script");
      script.src =
        "//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";
      script.async = true;
      script.onload = () => {
        this.initializeDaumPostcode();
      };
      document.head.appendChild(script);
    },
    initializeDaumPostcode() {
      const daum = null
      new (window).daum.Postcode({
        oncomplete: (data) => {
          // data에 선택한 주소 정보가 들어 있음
          this.formData.postNum = data.zonecode
          this.formData.address = data.address
        },
      }).open();
    },

  }
}
</script>

<style scoped></style>