<template>
  <div class="container mt-3 relative noselect" style="max-width: 1300px !important;">
    <TopBar />
    <TopMenu />
    <el-row :gutter="15">
      <el-col :xl="5" :xs="24" :sm="5">
        <centermenu />
      </el-col>
      <el-col :xl="24" :xs="24" :sm="24">
        <el-row class="borderbox mt-2 p-2">
          <el-col class="borderbottom1px lh40 relative">
            <span class="font18 bold">
              온라인충전
            </span>
          </el-col>
          <el-col class="relative">
            <div class="mt-2">
            </div>
            <el-row :gutter="10" class="font15 lh40">
              <el-col :xl="24" :xs="24" :sm="24">
                <div class="bold">
                  <div>
                    <span>
                      회사이름: {{ $loginUserInfo.companyInfo.name }}
                    </span>
                    <span class="ml-3">
                      보유캐시: {{ $comma($loginUserInfo.companyInfo.point) }}
                    </span>
                  </div>
                </div>
              </el-col>
              <el-col :xl="3" :xs="8" :sm="3">
                결제방식:
              </el-col>
              <el-col :xl="21" :xs="16" :sm="21" class="relative">
                <el-select size="mini" v-model="formData.pgcode" placeholder="선택하세요">
                  <el-option v-for="item in payCate" :key="item.name" :label="item.label" :value="item.name">
                  </el-option>
                </el-select>
              </el-col>
              <el-col :xl="3" :xs="8" :sm="3">
                충전캐시:
              </el-col>
              <el-col :xl="21" :xs="16" :sm="21" class="relative">
                <el-input type="text" size="mini" v-model="tmpFormData.amount" placeholder="충전캐시"
                  style="max-width: 208px;" :controls="false" :class="$isMobile?'w100p':''"
                  @focus="() => { tmpFormData.amount = $numberFormat(tmpFormData, 'amount', 'focus',1000,5000000) }"
                  @input="() => { tmpFormData.amount = $numberFormat(tmpFormData, 'amount', 'input'),1000,5000000 }"
                  @blur="() => { tmpFormData.amount = $numberFormat(tmpFormData, 'amount', 'blur') ,1000,5000000}"></el-input>
                <!-- <el-input-number size="mini" v-model="formData.amount" :min="50000" :max="5000000" placeholder="충전캐시"
                    :controls="false" :class="$isMobile ? 'w100p' : ''"></el-input-number> -->
                <span class="fixedTooltip-info d-none d-md-block" style="top:0px;right:5px">
                  최소충전범위: 5만원~ 500만
                </span>
                <div class="d-none d-md-block">
                  <el-button size="mini" @click="calcPoint(10000)">+만원</el-button>
                  <el-button size="mini" @click="calcPoint(50000)">+5만원</el-button>
                  <el-button size="mini" @click="calcPoint(100000)">+10만원</el-button>
                  <el-button size="mini" @click="calcPoint(500000)">+50만원</el-button>
                  <el-button size="mini" @click="calcPoint(-100000)">-10만원</el-button>
                  <el-button size="mini" @click="calcPoint(-50000)">-5만원</el-button>
                  <el-button size="mini" @click="calcPoint(-10000)">-만원</el-button>
                </div>
              </el-col>
              <el-col :xl="24" :xs="24" :sm="24">
                <div class="bigbadge-light p-2 mt-2" style="line-height: 20px;" v-html="$toHtml(`<b>결제전 유의사항</b><br/>
  카드결제 취소는 180일이내 가능합니다.
  인터넷뱅킹거래 취소는 당일까지 가능합니다.
  이유없이 충전캐시 반환요청 시 3%수수료가 발생합니다.<br/>
  `)"></div>
              </el-col>
  
            </el-row>
          </el-col>
        </el-row>
        <el-row class="mt-2">
          <el-col :xl=12 :xs="24" :sm="12">
            <el-checkbox class=" colorred bold" :value="policyAgree" @change="changePolicyAgree">
              <span class="font18">위의 사항을 확인하였으며 계속 합니다.</span></el-checkbox>
          </el-col>
          <el-col :xl=12 :xs="24" :sm="12" class="text-right">
            <el-button type="" :size="!$isMobile?'mini':''" class="el-btn-orange" :class="$isMobile ? 'w100p' : ''"
              v-if="policyAgree" @click="checkout()">
              <i class="far fa-credit-card"></i>
              결제하기</el-button>
            <el-button type="" :size="!$isMobile?'mini':''" class="el-btn-red" :class="$isMobile ? 'w100p' : ''" disabled
              v-else>
              <i class="far fa-credit-card"></i>
              결제하기</el-button>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <FootBar />
    <el-backtop>
    </el-backtop>
  </div>
</template>
<script>
import TopBar from './component-top.vue'
import TopBanner from './component-top-banner.vue'
import TopNotice from './component-top-notice.vue'
import TopMenu from './component-top-menu.vue'
import FootBar from './component-foot.vue'
import centermenu from './component-cener-left-menu.vue'
import { Loading } from 'element-ui'
export default {
  components: { centermenu, TopBar, TopBanner, TopNotice, TopMenu, FootBar, Loading },
  data() {
    return {
      policyAgree: false,
      loginUserInfo: null,
      loading: null,
      payCate: [
        { label: '카드결제', value: 0, name: 'creditcard' },
        { label: '인터넷뱅킹', value: 1, name: 'banktransfer' },
        // { label: '가상계좌', value: 3, name: 'VitualAccount' },
      ],
      tmpFormData: { amount: '1,000', },
      formData: {
        pgcode: 'banktransfer',
        service_name: '페이레터',  // 서비스이름
        amount: 1000,  // 결제금액
        taxfree_amount: 0, // 비과세금액,
        tax_amount: 0, // 과세금액,
        product_name: '주식회사 008파츠거래 캐시충전',
        email_flag: 'N', // 결제내역 메일 수신 여부 (Y:사용, N:미사용)
        email_addr: '', // 결제내역 메일 수신 주소 (*email_flag를 "Y"로 세팅한 경우에만 사용)
        autopay_flag: 'N', // 자동결제 여부 (Y:사용, N:미사용)
        receipt_flag: 'N', //현금영수증 입력 창 노출 여부 (Y:사용, N:미사용)(*현금영수증 계약이 되어 있는 경우에만 사용 가능)
        custom_parameter: '아무거나,고객정보,주문정보넣을수있음', //가맹점이 전송하는 임의의 값 ex) 고객정보, 주문정보, 기타 필요한 정보를 세팅하면 결제 결과로 다시 리턴
      }
    }
  },
  created() {
    window.scrollTo(0, 0);
    this.loading = Loading.service({ fullscreen: true })
    this.$checkAuth(this.$loginUserInfo) // type==0 대표권한
    this.$overay(this.loading)
  },

  methods: {

    async payRequest() {
      //페이레터결제주소
      this.formData.amount = this.$delComma(this.tmpFormData.amount)
      this.formData.custom_parameter = this.$loginUserInfo.phone
      const res = await this.$http.post(`${this.$serverurl}/api/payRequest`, this.formData)
      return res
    },
    async checkout() {
      const paymentData = await this.payRequest()
      if (paymentData.data.code === 200) {
        const url = paymentData.data.body.results.online_url;
        const width = 600;
        const height = 400;
        const left = (window.innerWidth - width) / 2;
        const top = (window.innerHeight - height) / 2;
        const popup = window.open(url, 'popup', `width=${width},height=${height},left=${left},top=${top}`);
        const checkPopupStatus = setInterval(() => {
          if (popup && !popup.closed) {
            // console.log('팝업 창이 열림.')
          } else {
            // console.log('팝업 창이 닫힘')
            this.$router.go(0);
            this.isLoading = false
            // 타이머 중지
            clearInterval(checkPopupStatus);
          }
        }, 500);
      } else {
        console.log(paymentData)
        alert('결제요청 시 오류가 발생하였습니다.')
      }
    },

    changePolicyAgree() {
      if (!this.tmpFormData.amount || this.$delComma(this.tmpFormData.amount) < 1000) {
        alert('최소1,000캐시를 입력하세요.')
        this.policyAgree = false
        return
      }
      if (this.policyAgree) {
        this.policyAgree = false
      } else {
        this.policyAgree = true
      }
    },
    calcPoint(point) {
      this.tmpFormData.amount = this.$delComma(this.tmpFormData.amount)
      if (this.tmpFormData.amount + point <= 5000000 && this.tmpFormData.amount + point >= 1000) {
        this.tmpFormData.amount += point
      }
      this.tmpFormData.amount = this.$comma(this.tmpFormData.amount)
    },
  },
}

</script>
<style></style>
