<template>
    <div class="relative" :class="$isMobile ? 'mt-2' : ''">
        <form @submit.prevent="handleSubmit">
            <!-- 입력창 -->
            <input :type="type" :size="size" :minlength="minlength" :maxlength="maxlength" :placeholder="placeholder"
                :style="'width:' + width + ';height:' + ($isMobile ? '40px;' : '32px') + ';' + (hiddeinfov ? '' : 'padding-right: 60px;')"
                autocomplete="off" class="custeminput" v-model="internalValue" @blur="checkleng"
                @input="updateValue($event.target.value)" />
            <!-- 아이콘 -->
            <i :class="icon" class="absolute hand colorlight" style="right:55px;top:13px;"></i>
            <!-- 입력정보길이 leng/maxleng -->
            <span class="absolute" style="right:5px;top:-1px;"
                v-if="!hiddeinfov">
                <span class="infobox">{{ internalValue.length }} / {{ maxlengthv }}</span>
            </span>
        </form>
    </div>
</template>

<script>
export default {
    name: 'inputtext',
    props: ['value', 'type', 'size', 'minlength', 'minlength', 'maxlength', 'icon', 'placeholder', 'hiddeinfo'],
    data() {
        return {
            typev: 'text',
            sizev: 'mini',
            minlengthv: 1,
            maxlengthv: 255,
            placeholderv: null,
            height: this.$isMobile ? '40px' : '32px',
            width: '100%',
            hiddeinfov: true,
            internalValue: this.value,
        }
    },
    created() {
        this.typev = this.type || 'text'
        this.sizev = this.size || 'mini'
        this.minlengthv = this.minlength || 1
        this.maxlengthv = this.maxlength || 255
        this.placeholderv = this.placeholder || ''
        this.hiddeinfov = this.hiddeinfo || false
        if (this.sizev == 'mini') {
            this.height = '28px'
        }
    },
    watch: {
        value(newValue) {
            this.internalValue = newValue;
        }
    },
    methods: {
        checkleng(event) {
            if (this.internalValue.trim() && this.internalValue.length < this.minlengthv) {
                this.$notify.success({
                    title: '',
                    message: `최소${this.minlengthv} 글자이상을 입력하세요`,
                    position: 'bottom-right',
                    showClose: true,
                    duration: 1000,
                });
                event.target.className = 'custeminput badinfo'
                event.target.focus();
            } else {
                event.target.className = 'custeminput'

            }
        },
        updateValue(newValue) {
            this.internalValue = newValue;
            this.$emit('input', newValue);
        },
        handleSubmit() { },

    }
}
</script>

<style scoped>
.custeminput {
    border: 1px #dcdfe6 solid;
    border-radius: 5px;
    padding-left: 15px;
    color: #000
}

.custeminput::placeholder {
    color: #ccc !important;
    font-size: 13px;
}

.infobox {
    color: #999;
    font-size: 12px;
    background-color: #efefef;
    height: 20px;
    /*상 우 하 좌*/
    padding: 0px 5px 0px 5px;
}

.badinfo {
    border: 1px #ff0033 solid;
}
</style>