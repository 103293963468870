<template>
    <div class="container noselect" style="padding-top: 30px !important;">
        <div class="sticky-bottom w100p p-2 text-right noprint" style="background-color: #efefef;">
            <label style="margin-right:20px;">
                <input type="checkbox" @click="복사본포함()" />
                복사본포함
            </label>
            <el-button size="mini" class="btn-small" icon="fa fa fa-print" ref="submitbutton"
                @click="$doPrint()">출력</el-button>
        </div>
        <div v-for="(invoice, index) in visibleItems.items" :key="index" class="">
            <div v-if="index > 0" :style="index > 0 ? { 'margin-top': '50px', 'margin-bottom': '50px' } : ''"
                class="printfont2 noprint text-center">
                <barcode :value="infoContent.sn" format="CODE128" :font="'Consolas'" :fontSize=12 :height=10 :text="' '"
                    :lineColor="'#000'">
                    BARCODE code128
                </barcode>
            </div>
            <div v-for="i in icopy" :key="i" :style="{ 'page-break-after': i == 2 ? 'always' : 'auto' }">
                <!--  헤더 -->
                <div>
                    <div class="row mb-3">
                        <div class="col" style="padding-right:5px;">
                            <div class="mb-2">
                                <img :src="generateImage('거래명세서', 110, 30, '20px', 25, 'bold')" alt="">
                                <barcode class="mt-2 absolute" :value="infoContent.sn" format="CODE128"
                                            :font="'Consolas'" :fontSize=20 :height=16 :width=1
                                            style="top: -8px;right:0px; mix-blend-mode: multiply;" :text="' '"
                                            :lineColor="'#000'">
                                            BARCODE code128
                                        </barcode>
                            </div>
                            <table class="tables-print table-bordereds-print printcustomer"
                                style="height: calc(100% - 38px);">
                                <tr>
                                    <td>
                                        <div class="text-center">
                                            {{ loginUserInfo.companyInfo.name }}
                                            <span class="text-bold">貴中</span>
                                        </div>
                                        <div class="text-center">
                                            {{ infoContent.address }}
                                            {{ infoContent.addrSub }}
                                        </div>
                                        <div class="text-center">☎ {{ $formatTel(infoContent.phone) }}
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="col-7 relative">
                            <div class="cyz" style="right:50px;top:20px;"></div>
                            <table class="tables-print table-bordereds-print w100p">
                                <tr>
                                    <th rowspan="5" class="w30 text-direction relative"
                                        style="letter-spacing:15px;width: 30px;">
                                        <span class="absolute" style="top:30px;left:8px;">공급자</span>
                                    </th>
                                </tr>
                                <tr>
                                    <th class="text-center w100">거래번호</th>
                                    <td colspan="3">
                                        {{ $snPlusFor(infoContent.sn) + '(' + (index + 1) + ')' }}
                                     </td>
    
                                </tr>
                                <tr>
                                    <th class="text-center">사업자등록<br>번호</th>
                                    <td class="center w150">{{ $formatLicense(loginUserInfo.platformInfo.license) }}</td>
                                    <th class="text-center w50">성명</th>
                                    <td>{{ loginUserInfo.platformInfo.ceo }}</td>
                                </tr>
                                <tr>
                                    <th class="text-center">상호</th>
                                    <td class="text-left">{{ loginUserInfo.platformInfo.name }}</td>
                                    <th class="text-center w50">{{ 'TEL' }}</th>
                                    <!-- <td>{{ $formatTel(infoContent.companyInfo.tel) }}</td> -->
                                    <td class="w100">{{ loginUserInfo.platformInfo.tel }}</td>
                                </tr>
                                <tr>
                                    <th class="text-center ">주소</th>
                                    <td colspan="3" class="text-left">{{ loginUserInfo.platformInfo.addr }}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- 총금액 -->
                <div class="row mb-3">
                    <div class="col">
                        <div class="row border-all-dark-2px fontsize16all text-bold"
                            style="margin:0px 0px;padding:5px 5px;">
                            <div class="col-1"></div>
                            <div class="col">
                                <img :src="generateImage((['금액']) + ' : ' + calcCountKor(visibleItems.totalAmount + visibleItems.totalAmountVat)
                                + (['원정']), 290, 30, '16pt', 20, 'bold')
                                " alt="">
                            </div>
                            <div class="col-4 text-right">
                                <img :src="generateImage(' (₩ ' + comma(visibleItems.totalAmount + visibleItems.totalAmountVat) + ')', 200, 30, '16pt', 20, 'bold')
                                " alt="">
                            </div>
                        </div>
                        <div class="border-all-dark-1px p-1 pl-5">
                            <span>
                                {{ $shippingType[infoContent.shippingValue].label }}
                            </span>
                            <span>
                                /
                                <span class="bold"> {{
                                    $shippingPayType.find(item=>item.value==infoContent.shippingPayType).label }}</span>
                                / {{ infoContent.name }}
                                / {{ infoContent.phone }}
                                / ({{ infoContent.postNum }})
                                {{ infoContent.address }}
                                {{ infoContent.addrSub }}
                            </span>
                        </div>
                    </div>
                </div>
                <!-- 항목body -->
                <table class="tables-print table-bordereds-print mt-3">
                    <thead>
                        <tr>
                            <th class="text-center">일자</th>
                            <th>품목코드</th>
                            <th>품명및규격</th>
                            <th>수량</th>
                            <th>단가</th>
                            <th>공급가액</th>
                            <th>부가세</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, itemIndex) in visibleItems.items[index].itemsList" :key="itemIndex">
                            <td class="text-center">
                                {{ infoContent.created.substr(0, 10).split('-')[1] }}/{{
                                infoContent.created.substr(0,
                                10).split('-')[2] }}
                            </td>
                            <td>{{ item.salesProductInfo&&item.salesProductInfo.pid }}</td>
                            <td>
                              {{ item.salesProductInfo&&item.salesProductInfo.name }}
                              {{ item.salesProductInfo&&infoContent.storageName?
                              `[${infoContent.storageName}]`:'' }}
                              <br/>
                              {{ item.salesProductInfo&&item.salesProductInfo.pid }}
                              {{ item.salesProductInfo&&item.salesProductInfo.factoryNum }}
                            </td>
                            <td>{{ comma(item.qty) }}</td>
                            <td>{{ comma(item.price * 1.1) }}</td>
                            <td>{{ comma(item.qty * item.price) }}</td>
                            <td>{{ comma(item.qty * item.price * 0.1)}}</td>
                        </tr>
                    </tbody>
                </table>
                <table class="tables-print table-bordereds-print mt-3" style="border-top:1px #000 solid;">
                    <tr>
                        <th width="40">수량</th>
                        <td class="w100">{{ visibleItems.items[index].pageIncount }}</td>
                        <th width="80">공급가액</th>
                        <td width="100" class="right">{{ comma(visibleItems.items[index].pageInSum) }}</td>
                        <td width="40">{{ 'VAT' }}</td>
                        <td width="100" class="right">
                            {{ visibleItems.items[index].mallOrderProductInfo }}
                            {{ comma(visibleItems.items[index].pageInSum * 0.1) }}
                        </td>
                        <th width="40">합계</th>
                        <td width="100" class="right">
                            {{
                            comma(visibleItems.items[index].pageInSum +
                            visibleItems.items[index].pageInSum * 0.1)
                            }}
                        </td>
                        <th width="40">인수</th>
                        <td>
                            <div class="row">
                                <div class="col text-right">인</div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="10" class="font12">
                            *불량 반품 기간은 수령일로부터 6개월입니다. ((진단기 불량 코드 사진, 불량 작동 영상 등 증거자료 제출이 필요합니다.))
                            *단순 반품 기간은 수령일로부터 7일 이내입니다. ((사용 흔적이 없고, 박스 훼손이 안 되어있는 상태여야 합니다.))
                            *수입물품은 단순 반품 불가능합니다. *정품은 교환, A/S, 환불이 불가능합니다. **판매한 부품에 한해서만 처리 가능합니다.**
                        </td>
                    </tr>
                </table>
                <div class="relative">
                    <!-- <barcode class="mt-2 absolute" :value="infoContent.sn" format="CODE128" :font="'Consolas'" :fontSize=20
                        :height=16 :width=1 style="top: -10px;right:0px; mix-blend-mode: multiply;" :text="' '"
                        :lineColor="'#000'">
                        BARCODE code128
                    </barcode> -->
                </div>
                <div class="text-center relative" v-if="i < 2" style="margin-top: 50px; margin-bottom:30px;">
    
                    ✂️-----------------------------------
                    page: {{ index + 1 + '(' + i + ')' }}/{{ totalPages }}
                    -----------------------------------✂️
                </div>
                <div :style="index > 0 ? { 'margin-top': '20px', 'margin-bottom': '20px' } : ''"
                    class=" noprint text-center">
                    <!-- 시각적띄여쓰기 -->
                </div>
            </div>
        </div>
        <div class="noprint " style="height:100px;"></div>
    </div>
</template>

<script>
import VueBarcode from 'vue-barcode';
export default {
  components: {
    'barcode': VueBarcode,
  },
  props: {

    // items: {
    //     type: Array,
    //     default: () => [] // data list 
    // },
    itemsPerPage: {
      type: Number,
      default: 5
    }
  },
  data() {
    return {
      icopy: 1,
      tmpInVoiceInfo: {},
      currentPage: 1,
      request: {
        page: 1,
        size: 1,
        objSearchMapper: {
          state: null,
          saleStatus: null,
          searchType: 'total',
          searchKeyword: '',
          startDate: null,
          endDate: null
        }
      },
    }
  },

  computed: {
    total() {
      return this.infoContent.mallOrderProductInfo.reduce((acc, cur) => acc + cur.outQty * cur.outPrice, 0);
    },
    totalPages() {
      return Math.ceil(this.infoContent.mallOrderProductInfo.length / this.itemsPerPage);
    },
    visibleItems() {
      var totalAmount = 0
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = Math.min(startIndex + this.itemsPerPage, this.infoContent.mallOrderProductInfo.length);
      const items = this.infoContent.mallOrderProductInfo.slice(startIndex, endIndex);
    //   const emptyRows = Array.from({ length: Math.max(0, this.itemsPerPage - items.length) }, () => ({
    //     baseInfo: [],
    //   }));
      const pages = [];
      for (let i = 0; i < this.totalPages; i++) {
        const startIndex = i * this.itemsPerPage;
        const endIndex = Math.min(startIndex + this.itemsPerPage, this.infoContent.mallOrderProductInfo.length);
        const items = this.infoContent.mallOrderProductInfo.slice(startIndex, endIndex);
        const emptyRows = Array.from({ length: Math.max(0, this.itemsPerPage - items.length) }, () => ({
          baseInfo: [],
        }));
        const pageInSum = items.reduce((acc, cur) => acc + cur.qty * this.delComma(cur.price), 0);
        const pageIncount = items.reduce((acc, cur) => acc + cur.qty, 0);
        totalAmount += pageInSum
        pages.push({
          itemsList: items.concat(emptyRows),
          pageInSum: pageInSum,
          pageIncount: pageIncount,
        });
      }
      return {
        items: pages,
        currentPage: this.currentPage,
        totalAmount: totalAmount,
        totalAmountVat: totalAmount * 0.1
      };
    },
  },
  watch: {
    items: {
      immediate: true,
      handler(newVal) {
        if (this.infoContent && this.infoContent.mallOrderProductInfo.length > 10) {
          this.currentPage = 1;
        }
      }
    }
  },
  methods: {
    delComma(params) {
      params = params || 0;
      const returnValue = (params + '').replace(/[^\d]/g, '');
      const value = isNaN(returnValue) ? '' : returnValue;
      return parseFloat(value)
    },
    generateImage(text, width, height, fontSize, point, bold) {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      if (!context) {
        console.error("캔버스 2D 컨텍스트를 가져올 수 없습니다.");
        return null;
      }
      canvas.width = width;
      canvas.height = height;
      // 배경 이미지
      context.fillStyle = '#ffffff'; // 또는 'white'로 설정 가능
      context.fillRect(0, 0, canvas.width, canvas.height);
      // 상단내용
      context.fillStyle = 'rgba(0,0,0,1)';
      context.textAlign = 'left'
      // const fontSize = window.matchMedia('print').matches ? '20pt' : '16px';
      context.font = `${bold} ${fontSize} "Malgun Gothic"`;
      context.fillText(text, 10, point);

      // 이미지 데이터를 리턴
      return canvas.toDataURL('image/jpeg');
    },
    readInVoiceInfo() {
      //명세서설정정보
      this.request.objSearchMapper.companyId = this.loginUserInfo.companyId;
      this.$http.post(this.$serverurl + "/api/getInVoiceConfig", this.request, this.$setHeader())
        .then(res => {
          // this.$chLogOut(res.data.code)
          this.tmpInVoiceInfo = res.data.body.results;
        })
        .catch(error => { })
        .then(() => { });
    },
    calcCountKor(num) { //숫자 한글변환
      num = parseInt((num + '').replace(/[^0-9]/g, ''), 10) + '';  // 숫자/문자/돈 을 숫자만 있는 문자열로 변환
      if (num == '0')
        return '영';
      var number = ['영', '일', '이', '삼', '사', '오', '육', '칠', '팔', '구'];
      var unit = ['', '만', '억', '조'];
      var smallUnit = ['천', '백', '십', ''];
      var result = [];
      var unitCnt = Math.ceil(num.length / 4);
      num = num.padStart(unitCnt * 4, '0')
      var regexp = /[\w\W]{4}/g;
      var array = num.match(regexp);
      for (var i = array.length - 1, unitCnt = 0; i >= 0; i--, unitCnt++) {
        var hanValue = _makeHan(array[i]);  //한글로 변환된 숫자
        if (hanValue == '')
          continue;
        result.unshift(hanValue + unit[unitCnt]);
      }
      function _makeHan(text) {
        var str = '';
        for (var i = 0; i < text.length; i++) {
          var num = text[i];
          if (num == '0')
            continue;
          str += number[num] + smallUnit[i];
        }
        return str;
      }
      return result.join('');
    },
    comma(params) {
      params = params || 0;
      if (params == 0) { return null }
      return (params + '').length >= 4 ? params.toLocaleString('ko-KR', 0).split('.')[0] : params;
    },
    getProductType(params) {
      const typeInfo = this.$baseTypes.find((item) => item.value === params);
      return typeInfo?.label ?? ''
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage -= 1;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    복사본포함() {
      if (this.icopy == 1) {
        this.icopy = 2
      } else { this.icopy = 1 }
    }
  },
  created() {
    this.loginUserInfo = JSON.parse(localStorage.getItem('loginUserInfo'))
    this.infoContent = JSON.parse(localStorage.getItem('printInfo'))
  },


};
</script>
<style lang="scss" scoped>
body *{
    font-family: 'dotum';
    font-size: 13px;
    letter-spacing: 0.01em;
}
th {
    padding: 2px !important;
    font-weight: bold !important;
}

.sticky-bottom {
    bottom: 0px;
    left: 0px;
    position: fixed;
    z-index: 1000;
}

.border-all-dark-2px {
    border: 2px #000 solid !important;
}

@media print {
    .noprint {
        display: none;
    }

    body * {
        padding-top: 0px;
        transform: scale(100%);
        // font-weight: normal !important;
        /* 크기 조절 (0.5는 50% 크기) */
        transform-origin: top left;
        /* 크기 조절의 기준점 설정 */
        font-size: 14pt !important;
    }

    .printfont14pt {
        font-size: 14pt !important;
    }

    .printcustomer {
        height: calc(100% - 56px) !important;
    }

    th {
        font-weight: bold;
        padding: 5px;
        // background-color: #f7f7f7 !important;
        border-right: 1px solid #000 !important;
        border-bottom: 1px solid #000 !important;
    }

    td {
        height: 30px;
        padding: 0px;
        padding-left: 5px;
        padding-right: 5px;
        border-right: 1px solid #000 !important;
        border-bottom: 1px solid #000 !important;
    }

    table {
        width: 100%;
        border-left: 1px #000 solid !important;
        border-top: 1px #000 solid !important;
    }
}
</style>
<style scoped>
body {
    font-size: 16px !important;
}

.table-bordereds-print th {
    /* text-align: center !important; */
    font-weight: 400;
    padding: 5px;
    /* background: #f7f7f7 !important; */
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
}

.table-bordereds-print td {
    height: 20px;
    padding: 0px;
    padding-left: 5px;
    padding-right: 5px;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
}

.tables-print {
    width: 100%;
    border-left: 1px #000 solid;
    border-top: 1px #000 solid;
}

.tables-print th {
    background-color: rgb(247, 247, 247);
    font-weight: 550;
    text-align: center;
}

.col-7 {
    padding-left: 5px;
}

.text16 {
    font-size: 16pt !important;
}

.border-all-dark-1px {
    border: 1px #000 solid !important;
}
</style>