<template>
  <div class="mt-3 noselect">
    <el-row>
      <el-col :xl="24" :xs="24" :md="24">
        <div class="footbanner"></div>
      </el-col>
    </el-row>
    <!-- pc버전 -->
    <el-row class="mb-3 d-none d-md-block font15">
      <el-col>
        <div class="menubar">
          <span class="hand" @click="$changeRoute('aboutUs')">회사소개</span>
          <el-divider direction="vertical"></el-divider>
          <span class="hand" @click="$changeRoute('policiesService')">이용약관</span>
          <el-divider direction="vertical"></el-divider>
          <span class="hand" @click="$changeRoute('privacyPolicy')">개인정보처리방침</span>
          <el-divider direction="vertical"></el-divider>
          <span class="hand" @click="isShowDrag = true">1 : 1문의</span>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="10" class="d-none d-md-block font15 mb-5">
      <el-col :xl="12" :xs="12" :md="12">
        <div class="font15 bold">
          {{ $회사이름한글 }} 사업자정보
        </div>
        <div class="font13 footitems mt-2">
          <span>대표자 : {{ $대표자 }}</span>
          <el-divider direction="vertical"></el-divider>
          <span>개인정보보호책임자 : {{ $개인정보보호책임자 }}</span>
        </div>
        <div class="font13 footitems">
          <span>통신판매업신고번호 : {{ $통신판매업신고번호 }}</span>
          <el-divider direction="vertical"></el-divider>
          <span>사업자등록번호: {{ $사업자등록번호 }}</span>
        </div>
        <div class="font13 footitems">
          <span>대표전화 : {{ $회사전화 }}</span>
          <el-divider direction="vertical"></el-divider>
          <span>팩스 : {{ $팩스 }}</span>
        </div>
        <div class="font13 footitems">
          <span>도메인 : {{ $도메인 }}</span>
          <el-divider direction="vertical"></el-divider>
          <span>이메일 : {{ $회사메일 }}</span>
        </div>
        <div class="font13 footitems">
          <span>우편번호 : {{ $회사우편번호 }}</span>
          <el-divider direction="vertical"></el-divider>
          <span>주소 : {{ $회사주소 }}</span>
        </div>
      </el-col>

      <el-col :xl="12" :xs="12" :md="12">
        <div class="font15 bold">
          서비스센터 {{ $회사전화 }}
        </div>
        <div class="font13 footitems mt-2">
          운영시간: {{ $운영시간 }}
        </div>
        <div class="font13 footitems">
          <span>사이트이용문의 : {{ $사이트사용문의 }}</span>
        </div>
        <div class="font13 footitems lh25">
          착오(충동)구매의 교환 및 반품처리에 발생되는 비용은 구매자 부담입니다.
          재고제품외 수입품 교환.반품은 진행상황에 따라 15일 ~ 20일이 소요됩니다.
          초기불량은 7일이내 교환.반품가능합니다. 사용(장착) 전 미리 제품의 사용가능성
          을 체크하여 주세요. 장착불가 제품을 사용(장착) 시도 후 반품안됩니다.
        </div>
      </el-col>
    </el-row>
    <!-- 모바일버전 -->
    <el-row class="">
      <el-col class="text-center font15 bold">
        {{ $도메인 }}
      </el-col>
    </el-row>
    <siteQna :switch="isShowDrag" @callback="closeMe" />
  </div>

</template>

<script>
import siteQna from './component-site-qna.vue'
export default {
  name: 'FootBar',
  components: { siteQna },
  data() {
    return {
      isShowDrag: false,
    };
  },
  methods: {
    closeMe(data) {
      this.isShowDrag = data
    }
  },
  beforeDestroy() {
  },
  created() {

  }
}
</script>

<style></style>
