<template>
  <div class="mt-2">
    <el-row class="homemenu relative noselect d-none d-md-block">
      <!-- PC메뉴 -->
      <el-col :xl="19" :md="19" :sm="15" class="lh40">
        <span class="font15 hand" @click="$changeRoute('main')">파츠상품</span>
        <el-dropdown trigger="click">
          <span class="el-dropdown-link font15 mr-2">
            카테고리<i class="el-icon-arrow-down el-icon--right "></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :icon="item.icon" v-for="(item, index) in searchKeywords" :key="index"
              @click.native="sendToParent(item)">
              {{ item.trim() }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <span class="font15 hand" @click="$changeRoute('epc','old')">EPC(엔지니어전용)</span>
        <!-- <span class="font15 hand ml-3" v-if="searchKeyword" @click="sendToParent(null)">모두보기</span> -->
      </el-col>
      <!-- 검색 -->
      <el-col :xl="5" :md="5" :sm="24" class="text-right">
        <div class="relative" style="right:0px;">
          <el-input size="small" type="text" placeholder="검색어를 입력하세요."
            style="margin: 0px !important; padding: 0px !important;" v-model="searchKeyword"
            @keyup.enter.native="sendToParent(searchKeyword)" />
          <i class="fa-solid fa-filter-circle-xmark searchButton" style="top:10px;right:35px;" v-if="searchKeyword"
            @click="searchKeyword='';$changeRoute('main')"></i>
          <button class="searchButton" @click="sendToParent(searchKeyword)" style="top:0px;right:0px;">
            <i class="fa fa-search"></i>
          </button>
        </div>
      </el-col>
    </el-row>
    <!-- 모바일검색창 -->
    <el-row class="d-md-none">
      <el-col>
        <div class="searchBoxmd" style="right:0px;">
          <input type="text" placeholder="Search..." class="" v-model="searchKeyword"
            @keyup.enter="sendToParent(searchKeyword)" />
          <button class="searchButton" @click="sendToParent(searchKeyword)">
            <i class="fa fa-search"></i>
          </button>
        </div>
      </el-col>
    </el-row>
  
  </div>
</template>

<script>
export default {
  name: 'TopMenu',
  props: ['searchKeywordsValue'],
  components: {
  },
  data() {
    return {
      searchKeywords: [],
      searchKeyword: '',
    }
  },
  async created() {
    // const loginuserinfo =  JSON.parse(localStorage.getItem('loginUserInfo'))
    // let cate  = loginuserinfo.mallConfigInfo.mallCategory.split(',')
    // this.searchKeywords =cate
    this.mallConfigInfo()
  },
  watch: {
    searchKeywordsValue: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        this.searchKeyword = newValue
      }
    },
  },
  methods: {
    sendToParent(data) {
      if (!data || data.trim() == '') {
        alert('검색어를 입력하세요.')
        return
      }
      const encodeSearchKeyword = encodeURIComponent(data.trim())
      const newRoute = '/searchCenter/' + encodeSearchKeyword
      if (this.$route.fullPath !== newRoute) {
        this.$router.push(newRoute)
      } else {
        this.$emit('callback', true)
      }
    },
    async mallConfigInfo() {

      await this.$http.get(`${this.$serverurl}/api/getMallConfigInfoCate`)
        .then(res => {
                this.searchKeywords = res.data
        })
    },

  }
}
</script>

<style scoped></style>