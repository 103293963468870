<template>
  <div class="container mt-3 relative pb-5" style="max-width: 1300px !important;">
    <TopBar @sendToParent="callback" />
    <TopMenu />
    <!-- 상품세부정보 PC -->
    <div v-if="!tmpTableData">
      <div class="warningbox mt-3">
        검색된 정보가 없습니다.
      </div>
    </div>
    <productView :data="tmpTableData && tmpTableData" @operationCallback="operationCallback" />
    <div v-if="tmpTableData">
      <el-row class="mt-3">
        <el-col>
          <div class="productInfoTitle font18 bold">
            제품정보
          </div>
        </el-col>
      </el-row>
      <div ref="productInfo" class="mt-3 font15">
        {{ tmpTableData.memo || '' }}
      </div>
      <div ref="produtparams">
        <el-row :gutter="10" class="table-params mt-3">
          <el-col :xl="5" :xs="6" :sm="6" class="th">거래번호</el-col>
          <el-col :xl="19" :xs="18" :sm="18" class="td">{{ tmpTableData.sn }}</el-col>
          <el-col :xl="5" :xs="6" :sm="6" class="th">제품이름</el-col>
          <el-col :xl="19" :xs="18" :sm="18" class="td">{{ tmpTableData.name }}</el-col>
          <el-col :xl="5" :xs="6" :sm="6" class="th">정품품번</el-col>
          <el-col :xl="19" :xs="18" :sm="18" class="td">{{ tmpTableData.pid || 'Empty' }}</el-col>
          <el-col :xl="5" :xs="6" :sm="6" class="th">공장품번</el-col>
          <el-col :xl="19" :xs="18" :sm="18" class="td">{{ tmpTableData.factoryNum || 'Empty' }}</el-col>
          <!-- <el-col :xl="5" :xs="6" :sm="6" class="th">호환품번</el-col>
            <el-col :xl="19" :xs="18" :sm="18" class="td">{{ tmpTableData.pidList || 'Empty' }}</el-col> -->
        </el-row>
      </div>
    </div>
    <FootBar />
    <el-backtop :bottom="100"></el-backtop>
  </div>
</template>

<script>
import io from 'socket.io-client';
import { Carousel, Slide } from 'vue-carousel'
import TopBar from './component-top.vue'
import TopMenu from './component-top-menu.vue'
import FootBar from './component-foot.vue'
import productView from './component-product-view.vue'
import { Loading } from 'element-ui'
export default {
  components: {
    TopBar, FootBar, Carousel, Slide, productView, TopMenu,
  },
  data() {
    return {
      showAlert: false,
      alertMessage: '',
      req: { seq: null },
      tmpTableData: null,
      totalCount: 0,
      FormData: {
        hasAmount: 1
      },
      loading: null,
    };
  },
  mounted() { },
  beforeDestroy() {
  },
  async created() {
    window.scrollTo(0, 0)
    this.loading = Loading.service({ fullscreen: true })
    this.req.seq = this.$route.params.id
    this.$loginUserInfo = JSON.parse(localStorage.getItem('loginUserInfo'))
    this.$http.defaults.headers.common['exjwtauthorization'] = this.$loginUserInfo?.token
    await this.read()
    // this.socketTime()
  },
  methods: {
    async operationCallback(data) {
      if (data.fav) { // 즐겨찾기 등록삭제
        await this.$http.post(`${this.$serverurl}/api/setMallProductWishInfo`, { productId: this.tmpTableData.seq })
          .then(res => {
            if (res.data.body.results.fav) {
              this.$notify.success({
                title: '',
                message: '즐겨찾기에 저장되었습니다.',
                position: 'bottom-right',
                showClose: false
              });
            } else {
              this.$notify.error({
                title: '',
                message: '즐겨찾기에서 삭제되었습니다.',
                position: 'bottom-right',
                showClose: false
              });
            }
            this.read()
          })
      }
      if (data.cart) { // 장바구니담기
        const params = {
          managerCompanyId: this.tmpTableData.companyId,
          productId: this.tmpTableData.seq,
          qty: data.hasAmount,
          price: data.price
        }
        await this.$http.post(`${this.$serverurl}/api/addToCart`, params)
          .then(res => {
            this.$notify.success({
              title: '',
              message: '장바구니에 추가하였습니다.',
              position: 'bottom-right',
              showClose: false
            });
            this.read()
          })
      }
      if (data.buy) { // 장바구니담기
        const params = {
          productId: this.tmpTableData.seq,
          qty: data.hasAmount,
          price: data.price
        }
        await this.$http.post(`${this.$serverurl}/api/addToCart`, params)
          .then(res => {
            this.$changeRoute('checkout')
          })
      }
    },
    async read() {
      await this.$http.post(`${this.$serverurl}/api/getMallProductInfoOne`, this.req)
        .then(res => {
          if (200 == res.data.code) {
            this.tmpTableData = res.data.body.results
            if (this.tmpTableData.saleStatus != 2) {
              this.$router.go(-1)
              alert('정보가 변경되었습니다. 잠시후 다시 시도하세요.')
            }
            if (!this.tmpTableData.images) {
              this.tmpTableData.images = []
              this.tmpTableData.images.push(`/static/img/no-image.webp`)
              // this.tmpTableData.images.push(`${this.$imageurl}/upload/no-image.webp`)
            }
          } else {
            this.$router.go(-1)
            alert(res.data.message)
          }
        })
      this.$overay(this.loading)
    },

    callback(data) {
      this.req.objSearchMapper.searchKeyword = data
      this.read()
    },

    socketTime() {
      const url = 'http://127.0.0.1:888'
      // this.$serverurl
      const socket = io(url, {
        withCredentials: true,
        extraHeaders: {
          exjwtauthorization: `${this.$loginUserInfo?.token}`,
        },
      });
      socket.on("connect", () => {
        connected.value = true;
      });

      socket.on("disconnect", () => {
        connected.value = false;
      });
    }
  }

}
</script>

<style>
.VueCarousels .VueCarousel-dot-container {
  display: none !important;
}
</style>
