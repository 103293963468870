<template>
  <div class="lastBlur">
    <el-breadcrumb separator-class="el-icon-arrow-left" class="gohome">
      <el-breadcrumb-item class="font18"></el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/' }" class="hand font18">홈으로</el-breadcrumb-item>
    </el-breadcrumb>
  </div>

</template>

<script>

export default {
  name: 'goHome',
  data() {
    return {

    };
  },
  methods: {
  },
  beforeDestroy() {
  },
  created() {

  }
}
</script>

<style></style>
